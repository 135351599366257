import { service, BaseService } from './base-service'
import qs from 'querystring'
import * as _ from 'lodash'

const emitEmptyFields = (obj) => _.omitBy(obj, (v) => v == null || v === '')

class NewTrainingEligibleService extends BaseService {
  constructor() {
    super('/new-training-eligible')
  }

  exportByScheduleForFollowUpToPdf(scheduleId, lang) {
    return service.get(
      `${this.baseURL}/by_schedule/${scheduleId}/for_follow_up/exportPdf`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getByTraining(trainingId, includesDeleted = false) {
    return service.get(`${this.baseURL}/by_training/${trainingId}?includesDeleted=${includesDeleted}`)
  }

  getEligiblesByScheduleForCollaborator(scheduleId, keyword, limit, page) {
    const query = qs.stringify({
      keyword,
      limit,
      page
    })
    return service.get(`${this.baseURL}/eligibles_by_schedule/${scheduleId}/for_collaborator?${query}`)
  }

  getByScheduleForFollowUp(scheduleId, filter, limit, page) {
    filter = _.omitBy(filter, (v) => v == null || v === '')

    const query = qs.stringify({
      ...filter,

      limit,
      page
    })

    return service.get(
      `${this.baseURL}/by_schedule/${scheduleId}/for_follow_up?${query}`
    )
  }

  exportByScheduleForFollowUp(scheduleId, lang) {
    if (!scheduleId) {
      throw new Error('scheduleId is required!')
    }
    return service.get(
      `${this.baseURL}/by_schedule/${scheduleId}/for_follow_up/export`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getPendingBySchedule(scheduleId, keyword, limit, page) {
    const data = {
      keyword,
      limit,
      page
    }

    const query = qs.stringify(_.omitBy(data, (v) => v == null || v === ''))

    return service.get(
      `${this.baseURL}/by_schedule/${scheduleId}/pending/by_keyword?${query}`
    )
  }

  getByUserAndStatus(client, status, keyword, limit = null, page = null) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify(
      emitEmptyFields({
        client,

        status,
        keyword,

        limit,
        page
      })
    )

    return service.get(
      `${this.baseURL}/by_user_and_status?${query}`
    )
  }
}

export const newTrainingEligibleService = new NewTrainingEligibleService()
