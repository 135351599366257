const defaultFilter = null

const state = {
  clientDocumentDepositFilter: defaultFilter
}

const getters = {}

const mutations = {
  SET_FILTER: (state, payload) => {
    state.clientDocumentDepositFilter = payload
  }
}

const actions = {
  loadFilter({ commit }, filterData) {
    commit('SET_FILTER', filterData)
  },
  clearDocumentDepositFilter({ commit }) {
    const defaultFilter = null
    commit('SET_FILTER', defaultFilter)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
