const state = {
  userPersonBond: null
}

const mutations = {
  SET_USER_PERSONBOND: (state, payload) => {
    state.userPersonBond = payload
  }
}

const actions = {
  setUserPersonbond({ commit }, userPersonBond) {
    commit('SET_USER_PERSONBOND', userPersonBond)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
