const defaultCurrentWorkdayTabIndex = 0

const state = {
  currentWorkdayTabIndex: defaultCurrentWorkdayTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTWORKDAYTABINDEX: (state, payload) => {
    state.currentWorkdayTabIndex = payload
  }
}

const actions = {
  setCurrentWorkdayTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTWORKDAYTABINDEX', currentTabIndex)
  },
  clearCurrentWorkdayTabIndex({ commit }) {
    const defCurrentWorkdayTabIndex = 0
    commit('SET_CURRENTWORKDAYTABINDEX', defCurrentWorkdayTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
