const defaultCurrentRecruitmentTabIndex = 0

const state = {
  currentRecruitmentTabIndex: defaultCurrentRecruitmentTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTRECRUITMENTTABINDEX: (state, payload) => {
    state.currentRecruitmentTabIndex = payload
  }
}

const actions = {
  setCurrentRecruitmentTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTRECRUITMENTTABINDEX', currentTabIndex)
  },
  clearCurrentRecruitmentTabIndex({ commit }) {
    const defCurrentRecruitmentTabIndex = 0
    commit('SET_CURRENTRECRUITMENTTABINDEX', defCurrentRecruitmentTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
