const tabs = {
  AgencyList: () => import('@/views/recruitment/AgencyList'),
  AgencyForm: () => import('@/views/recruitment/AgencyForm')
}

const state = {
  currentAgencyTab: tabs.AgencyList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentAgencyTab = state.tabs[tab]
  }
}

const actions = {
  setAgencyTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
