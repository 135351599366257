import * as recruitmentFormService from '@/services/recruitment-form-service'

const defaultRecruitmentForm = {
  _id: null,
  name: null,
  description: null,
  hasWeight: true,
  sections: [],
  fields: [],
  files: [],
  client: null,
  createdBy: null,
  updatedBy: null,
  createdByAdmin: false
}

const state = {
  recruitmentForm: defaultRecruitmentForm
}

const getters = {}

const mutations = {
  SET_RECRUITMENTFORM: (state, payload) => {
    state.recruitmentForm = payload
  }
}

const actions = {
  loadRecruitmentForm({ commit }, recruitmentFormData) {
    commit('SET_RECRUITMENTFORM', recruitmentFormData)
  },
  clearRecruitmentForm({ commit }) {
    const defRecruitmentForm = {
      name: null,
      description: null,
      hasWeight: true,
      sections: [],
      fields: [],
      files: [],
      client: null,
      createdBy: null,
      updatedBy: null,
      createdByAdmin: false
    }
    commit('SET_RECRUITMENTFORM', defRecruitmentForm)
  },
  save({ commit, rootState }, recruitmentForm) {
    recruitmentForm.client = rootState.user.userClient
    recruitmentFormService.saveRecruitmentForm(recruitmentForm)
      .then(({ data }) => {
        commit('SET_RECRUITMENTFORM', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
