<template>
  <b-form-select
    id="dmselect"
    v-model="value"
    :options="schema.values"
    :placeholder="$t('escolhaopcao')"
  >
    <template>
      <b-form-select-option
        :value="undefined"
        disabled
      >-- {{ $t("escolhaopcao") }} --</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField]
}
</script>
