import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class AgencyService extends BaseService {
  constructor() {
    super('/agency')
  }

  getByClientAndKeyword = (client, word, page, limit) => {
    const query = qs.stringify({ client, word, page, limit })
    if (!word) {
      throw new Error('Keyword is required!')
    }
    if (!client) {
      throw new Error('Client name is required!')
    }
    return service.get(`/agency/keyword?${query}`)
  }
}

export const agencyService = new AgencyService()
