<template>
  <b-form-radio-group
    v-model="value"
    :options="schema.options"
    name="radio-inline"
    buttons
    button-variant="primary"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField]
}
</script>
