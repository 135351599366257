/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = [
    'admin',
    'editor',
    'mvpdmprocess01',
    'mvpdmprocess02',
    'mvpdmprocess03',
    'mvpdmprocess04',
    'mvpdmprocess05',
    'mvpdmprocess06',
    'mvpdmprocess07',
    'mvpdmprocess08',
    'mvpdmprocess09',
    'mvpdmprocess10'
  ]
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validCPF(strCPF) {
  var sum
  var rest
  sum = 0
  strCPF = strCPF.replace(/[^\d]+/g, '')
  if (
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999'
  ) {
    return false
  }
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(strCPF.substring(9, 10))) {
    return false
  }
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  }
  if (rest !== parseInt(strCPF.substring(10, 11))) {
    return false
  }
  return true
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export function isObjectId(id) {
  return typeof id === 'string' && id.match(/^[0-9a-fA-F]{24}$/)
}

export function isStringBoolean(status) {
  return (
    typeof status === 'string' && (status === 'true' || status === 'false')
  )
}

export const isValidVideoDomain = url => {
  if (!url) return true
  const validDomains = ['youtu.be', 'youtube.com', 'vimeo.com', 'google.com', 'grupoimediato.com.br', 'reccloud.com']
  try {
    if (url.startsWith('//')) {
      url = url.replace('//', 'https://')
    }
    const parsedURL = new URL(url)
    const partialDomains = parsedURL.hostname.split('.')
    let rootDomain = partialDomains.slice(-3).join('.')
    if (partialDomains.slice(-1).join('.') !== 'br') {
      rootDomain = partialDomains.slice(-2).join('.')
    }
    return validDomains.includes(rootDomain)
  } catch (error) {
    return false
  }
}
