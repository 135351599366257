const tabs = {
  SkillsFormsList: () => import('@/views/recruitment/SkillsFormsList'),
  SkillsForm: () => import('@/views/recruitment/SkillsForm'),
  SkillsFormView: () => import('@/views/recruitment/SkillsFormView')
  // SkillsFormAnswerReport: () => import('@/views/recruitment/FilterFormAnswers')
}

const state = {
  currentSkillsFormTab: tabs.SkillsFormsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentSkillsFormTab = state.tabs[tab]
  }
}

const actions = {
  setSkillsFormTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
