import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { centralDemarcoUserService } from '@/services/central-demarco-user-service'
import { userClientConfigurationService } from '@/services/user-client-configuration-service'
import { ecosystemService } from '@/services/ecosystem-service'
import store from '../../store'

const state = {
  token: getToken(),
  name: '',
  userId: '',
  userEmail: '',
  userProfile: '',
  realUserProfile: '',
  userType: '',
  userPosition: '',
  userModules: '',
  userCpf: '',
  userClient: '',
  userValidClients: '',
  userUnit: null,
  userHierarchy: '',
  userTermsOfUse: false,
  roles: [],
  evaluation: {},
  research: {},
  responsavel: null,
  locale: 'pt-BR',
  formulario: null,
  updateForm: false,
  formdata: null,
  linkImage: null,
  updateCargo: false,
  updateUnidade: false,
  tabIndex: 0,
  updatePerson: false,
  updateVacation: false,
  showPositionList: true,
  updateTrainingEvaluation: false,
  userEcosystems: [],
  updateClient: false,
  canViewCollaboratorsInWorkplace: false,
  isManager: false,
  isCycleManager: false,
  isMaster: false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_USEREMAIL: (state, userEmail) => {
    state.userEmail = userEmail
  },
  SET_USERPROFILE: (state, userProfile) => {
    state.userProfile = userProfile
    state.realUserProfile = userProfile
  },
  SET_USERTYPE: (state, userType) => {
    state.userType = userType
  },
  SET_USERPOSITION: (state, userPosition) => {
    state.userPosition = userPosition
  },
  SET_USERMODULES: (state, userModules) => {
    state.userModules = userModules
  },
  SET_USERCPF: (state, userCpf) => {
    state.userCpf = userCpf
  },
  SET_USERCLIENT: (state, userClient) => {
    localStorage.setItem('lastClient', userClient)
    state.userClient = userClient
    if (userClient === process.env.VUE_APP_DEMARCO_CODE) {
      state.userProfile = 'usuario'
    } else state.userProfile = state.realUserProfile
  },
  SET_USERVALIDCLIENTS: (state, userValidClients) => {
    state.userValidClients = userValidClients
  },
  SET_USERUNIT: (state, userUnit) => {
    state.userUnit = userUnit
  },
  SET_USERHIERARCHY: (state, userHierarchy) => {
    state.userHierarchy = userHierarchy
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_EVALUATION: (state, evaluation) => {
    state.evaluation = evaluation
  },
  SET_RESEARCH: (state, research) => {
    state.research = research
  },
  SET_LOCALE: (state, locale) => {
    state.locale = locale
  },
  SET_FORMULARIO: (state, formulario) => {
    state.formulario = formulario
  },
  SET_UPDATEFORM: (state, updateForm) => {
    state.updateForm = updateForm
  },
  SET_FORMDATA: (state, formdata) => {
    state.formdata = formdata
  },
  SET_LINKIMAGE: (state, linkImage) => {
    state.linkImage = linkImage
  },
  SET_UPDATECARGO: (state, updateCargo) => {
    state.updateCargo = updateCargo
  },
  SET_UPDATEUNIDADE: (state, updateUnidade) => {
    state.updateUnidade = updateUnidade
  },
  SET_TABINDEX: (state, tabIndex) => {
    state.tabIndex = tabIndex
  },
  SET_UPDATEPERSON: (state, updatePerson) => {
    state.updatePerson = updatePerson
  },
  SET_UPDATEVACATION: (state, updateVacation) => {
    state.updateVacation = updateVacation
  },
  SET_SHOWPOSITIONLIST: (state, showPositionList) => {
    state.showPositionList = showPositionList
  },
  SET_UPDATETRAININGEVALUATION: (state, updateTrainingEvaluation) => {
    state.updateTrainingEvaluation = updateTrainingEvaluation
  },
  SET_USERECOSYSTEMS: (state, userEcosystems) => {
    state.userEcosystems = userEcosystems
  },
  SET_CANVIEWCOLABWORKPLACE: (state, canViewCollaboratorsInWorkplace) => {
    state.canViewCollaboratorsInWorkplace = canViewCollaboratorsInWorkplace
  },
  SET_TERMSOFUSE: (state, termsOfUse) => {
    state.userTermsOfUse = termsOfUse
  },
  SET_UPDATECLIENT: (state, updateClient) => {
    state.updateClient = updateClient
  },
  SET_ISMANAGER: (state, isManager) => {
    state.isManager = isManager
  },
  SET_ISCYCLEMANAGER: (state, isCycleManager) => {
    if (state.userClient === process.env.VUE_APP_DEMARCO_CODE && !state.isMaster) {
      state.isCycleManager = false
    } else {
      state.isCycleManager = isCycleManager
    }
  },
  SET_IS_MASTER: (state, isMaster) => {
    state.isMaster = isMaster
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_TOKEN', token)
        setToken(token)
        store.dispatch('screens/getScreens')
        store.dispatch('modules/getModules')
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  updatePosition({ commit }, upadatePositionFlag) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_UPDATECARGO', upadatePositionFlag)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  updateTermsOfUse({ commit }, termsOfUseFlag) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_TERMSOFUSE', termsOfUseFlag)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  updatedTrainingEvaluation({ commit }, flag) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_UPDATETRAININGEVALUATION', flag)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  changePositionListVisibility({ commit }, visible) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_SHOWPOSITIONLIST', visible)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  // get user info
  getInfo({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(async (response) => {
          const { data } = response
          if (!data) {
            reject('Verification failed, please Login again.')
          }
          const userData = await centralDemarcoUserService.getByReferenceId(
            data.userid
          )
          const user = userData.data
          if (!user || !user.clients || user.clients.length === 0) {
            return setTimeout(() => {
              return dispatch('logout').then(() =>
                reject(new Error('User is not allowed on this system'))
              )
            }, 1000)
          } else {
            data.roles = ['admin']
            const lastClient =
              localStorage.getItem('lastClient') || user.clients[0]

            const promiseEcosystems = ecosystemService.getByUserAndClient(
              user.referenceId,
              lastClient
            )
            const promiseConfig =
              userClientConfigurationService.getByUserAndClient(
                user.referenceId,
                lastClient
              )
            const [{ data: userEcosystems }, userConfiguration] =
              await Promise.all([promiseEcosystems, promiseConfig])

            const hierarchy = userConfiguration.data
              ? userConfiguration.data.units
              : []
            const userType = user.type ? user.type._id : ''
            const userPosition = user.position ? user.position._id : ''
            commit('SET_ROLES', data.roles)
            commit('SET_NAME', user.name)
            commit('SET_USERID', user.referenceId)
            commit('SET_USEREMAIL', user.email)
            commit('SET_TERMSOFUSE', user.termsOfUse)
            commit('SET_USERPROFILE', user.profile)
            commit('SET_IS_MASTER', user.isMaster)
            commit('SET_USERTYPE', userType)
            commit('SET_USERPOSITION', userPosition)
            // commit('SET_USERMODULES', data.dmpeople_modulos)
            commit('SET_USERCPF', user.document)
            commit('SET_USERVALIDCLIENTS', user.clients)
            commit('SET_USERCLIENT', lastClient)
            commit('SET_USERUNIT', hierarchy)
            commit('SET_USERECOSYSTEMS', userEcosystems)
            commit('SET_ISMANAGER', user.isManager)
            commit('SET_ISCYCLEMANAGER', user.isCycleManager)
            commit(
              'SET_CANVIEWCOLABWORKPLACE',
              user.type
                ? user.type.canViewCollaboratorsInWorkplace
                  ? user.type.canViewCollaboratorsInWorkplace
                  : null
                : true
            )
            resolve(data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUserClient({ commit }, userClient) {
    commit('SET_USERCLIENT', userClient)
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(({ data }) => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch('tagsView/delAllViews', null, { root: true })
          window.location.href = data
          return false
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async (resolve) => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, {
        root: true
      })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },

  getVisibleHierarchy({ commit, state }) {
    return new Promise((resolve, reject) => {
      userClientConfigurationService
        .getByUserAndClient(state.userId, state.userClient)
        .then(({ data }) => {
          /* if (!data) {
            reject('Units not found!!!')
          } */
          const hierarchy = data ? data.units : []
          commit('SET_USERHIERARCHY', hierarchy)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async updateEcosystems({ commit, state }) {
    const { data: userEcosystems } = await ecosystemService.getByUserAndClient(
      state.userId,
      state.userClient
    )
    commit('SET_USERECOSYSTEMS', userEcosystems)
  },

  async updateIsCycleManager({ commit, state }) {
    const { data } = await centralDemarcoUserService.getByReferenceId(
      state.userId
    )
    const isCycleManager = data.isCycleManager
    commit('SET_ISCYCLEMANAGER', isCycleManager)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
