var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dm-field-video-upload" },
    [
      _c("b-input", {
        attrs: {
          type: "text",
          value: _vm.localValue,
          placeholder: _vm.$t("informelinkvideo"),
          state: _vm.isChanged ? _vm.isValid : undefined,
        },
        on: {
          input: function ($event) {
            _vm.localValue = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        { attrs: { state: _vm.isChanged ? _vm.isValid : undefined } },
        [_vm._v("\n    " + _vm._s(_vm.$t("informelinkvideo")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }