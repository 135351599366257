import qs from 'querystring'
import { service } from './base-service'

const getPersonBonds = () => {
  return service.get('/person-bond')
}

const getPersonBondById = (personBondId) => {
  if (!personBondId) {
    throw new Error('Person Bond Id is required!')
  }
  return service.get(`/person-bond/${personBondId}`)
}

const getBondsByPerson = (person) => {
  if (!person) {
    throw new Error('Person Id is required!')
  }
  return service.get(`/person-bond/person/${person}`)
}

const getBondsByAdmissionId = (admissionId) => {
  if (!admissionId) {
    throw new Error('Admission Id is required!')
  }
  return service.get(`/person-bond/admission/${admissionId}`)
}
const getBondsByAdmissionIdWithDeleted = (admissionId) => {
  if (!admissionId) {
    throw new Error('Admission Id is required!')
  }
  return service.get(`/person-bond/admission-with-deleted/${admissionId}`)
}

const getActiveBondsByPerson = (person) => {
  if (!person) {
    throw new Error('Person Id is required!')
  }
  return service.get(`/person-bond/active_bonds_by_person/${person}`)
}

const getActiveBondsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/person-bond/active_bonds_by_client/${client}`)
}

const getActiveBondsByClientSimple = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/person-bond/active_bonds_by_client_simple/${client}`)
}

const getBondsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/person-bond/bonds_by_client/${client}`)
}

const getActiveBondsByPersonAndClient = (person, client) => {
  if (!person) {
    throw new Error('Person Id is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/active_bonds_by_person_client?person=${person}&client=${client}`
  )
}

const getBondsByPersonAndClient = (person, client) => {
  if (!person) {
    throw new Error('Person Id is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/bonds_by_person_client?person=${person}&client=${client}`
  )
}

const getActiveBondsByClientAndHierarchy = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/active_bonds_by_client_hierarchy?client=${client}`
  )
}

const getBondsWithDismissalByClientAndHierarchy = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/bonds_with_dismissal_by_client_hierarchy?client=${client}`
  )
}

const getActiveBondsByClientAndHierarchySimple = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/active_bonds_by_client_hierarchy_simple?client=${client}`
  )
}

const getBondsWithDismissalByClientAndHierarchySimple = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/bonds_with_dismissal_by_client_hierarchy_simple?client=${client}`
  )
}

const getByNameWithDeleted = (client, limit, page, keyword, select = null) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({ client, limit, page, keyword, select })
  return service.get(`/person-bond/by_name/with_deleted?${query}`)
}

const getByName = (
  client,
  limit,
  page,
  keyword,
  select = null,
  idsToIgnore = []
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({
    client,
    limit,
    page,
    keyword,
    select,
    idsToIgnore: idsToIgnore.toString()
  })
  return service.get(`/person-bond/by_name?${query}`)
}

const getByFilterAndName = (
  client,
  limit,
  page,
  keyword,
  filter,
  select = null,
  idsToIgnore = []
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const { unities, positions, roles, bonds } = filter

  const query = qs.stringify({
    client,
    limit,
    page,
    keyword,
    select,
    unities: unities.toString(),
    positions: positions.toString(),
    roles: roles.toString(),
    bonds: bonds.toString(),
    idsToIgnore: idsToIgnore.toString()
  })
  return service.get(`/person-bond/by_filter_and_name?${query}`)
}

const byFormEvaluationPriority = (
  client,
  formId,
  keyword,
  document,
  step = null,
  limit = 20,
  page = 1
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({
    client,
    formId,
    keyword,
    document,
    step,
    limit,
    page
  })
  return service.get(`/person-bond/by_form_evaluation_priority?${query}`)
}

const savePersonBond = (personData) => {
  if (personData._id) {
    return updatePersonBond(personData._id, personData)
  }
  return createPersonBond(personData)
}

const createPersonBond = (personData) => {
  delete personData._id
  return service.post(`/person-bond`, personData)
}

const updatePersonBond = (personId, personData) => {
  return service.put(`/person-bond/${personId}`, personData)
}

const deletePersonBond = (personBondId) => {
  return service.delete(`/person-bond/${personBondId}`)
}

const deletePersonBondByAdmission = async (admissionId) => {
  const { data } = await getBondsByAdmissionId(admissionId)
  return service.delete(`/person-bond/${data._id}`)
}

const updatePersonBondAfterPromotion = async (
  personId,
  newPosition,
  newSalary,
  client
) => {
  const { data } = await getActiveBondsByPersonAndClient(personId, client)
  const personBond = data
  personBond.position = newPosition
  personBond.salary = newSalary
  await savePersonBond(personBond)
}

const updatePersonBondAfterTransfer = async (personId, newUnity, client) => {
  const { data } = await getActiveBondsByPersonAndClient(personId, client)
  const personBond = data
  personBond.unity = newUnity
  await savePersonBond(personBond)
}

const updatePersonBondAfterDismissal = async (
  personId,
  dismissalDate,
  client
) => {
  const { data } = await getBondsByPersonAndClient(personId, client)
  const personBond = data
  personBond.dismissalDate = dismissalDate
  await savePersonBond(personBond)
}

const getByDocumentAndClient = async (document, client) => {
  const query = qs.stringify({ document, client })
  return service.get(`/person-bond/by_document_client?${query}`)
}

const getByDocumentAndClientWithDeleted = async (document, client) => {
  const query = qs.stringify({ document, client })
  return service.get(`/person-bond/by_document_client_with_deleted?${query}`)
}

const movementHistoryGetByDocumentAndClientWithDeleted = async (
  document,
  client
) => {
  document = document.replace(/\D/g, '')
  const query = qs.stringify({ document, client })
  return service.get(
    `/person-bond/movement-history/by_document_client_with_deleted?${query}`
  )
}

const getLeadersByClientAndHierarchy = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/leaders/by_client_hierarchy?client=${client}`
  )
}

const getLeaderlessByUnityHierarchy = (client, unity) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  if (!unity) {
    throw new Error('Unity is required!')
  }

  return service.get(
    `/person-bond/leaderless/by_unity/${unity}?client=${client}`
  )
}

const getBondWithoutLeadershipByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/bond_without_leadership_by_client/${client}`
  )
}
const getBondWithoutLeadershipAndIsNotLeadershipByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/person-bond/bond_without_leadership_and_is_not_leadership_by_client/${client}`
  )
}
const getSearchWithDeleted = (
  client,
  limit,
  page,
  startDate,
  endDate,
  units,
  companies
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    units,
    companies
  })
  return service.get(`/person-bond/search/with_deleted/${client}?${query}`)
}

const getActiveBondsSearch = (client, limit, page, collaborator, unity) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity
  })
  return service.get(`/person-bond/search/${client}?${query}`)
}

const getBondsSearch = (client, limit, page, collaborator, unity, status) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity,
    status
  })
  return service.get(`/person-bond/search/with_dismissal/${client}?${query}`)
}

const getActiveBondsSearchWorksheetSummary = (
  client,
  limit,
  page,
  lang,
  collaborator,
  unity
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity
  })
  return service.get(`/person-bond/search/${client}/worksheet?${query}`, {
    headers: { 'x-custom-lang': lang },
    responseType: 'arraybuffer'
  })
}

const getActiveBondsSearchWorksheetComplete = (
  client,
  limit,
  page,
  lang,
  collaborator,
  unity
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity
  })
  return service.get(
    `/person-bond/search/${client}/worksheet/complete?${query}`,
    { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' }
  )
}

const getBondsSearchWorksheetSummary = (
  client,
  limit,
  page,
  lang,
  collaborator,
  unity
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity
  })
  return service.get(`/person-bond/search-all/${client}/worksheet?${query}`, {
    headers: { 'x-custom-lang': lang },
    responseType: 'arraybuffer'
  })
}

const getBondsSearchWorksheetComplete = (
  client,
  limit,
  page,
  lang,
  collaborator,
  unity
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    limit,
    page,
    collaborator,
    unity
  })
  return service.get(
    `/person-bond/search-all/${client}/worksheet/complete?${query}`,
    { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' }
  )
}

const getStatusByPersonBond = (personBondId) => {
  if (!personBondId) {
    throw new Error('PersonBond Id is required!')
  }
  return service.get(`/person-bond/person-bond-status/${personBondId}`)
}

const getDimissedToLinkJob = (client, limit, page) => {
  if (!client) {
    throw new Error('Client Id is required!')
  }
  const query = qs.stringify({
    limit,
    page
  })
  return service.get(`/person-bond/dismissed_to_link_job/${client}?${query}`)
}

export {
  getPersonBonds,
  getPersonBondById,
  getBondsByPerson,
  getBondsByAdmissionId,
  getActiveBondsByPerson,
  getActiveBondsByClient,
  getActiveBondsByClientSimple,
  getBondsByClient,
  getBondsByPersonAndClient,
  getActiveBondsByPersonAndClient,
  getActiveBondsByClientAndHierarchy,
  getByDocumentAndClient,
  updatePersonBondAfterPromotion,
  updatePersonBondAfterTransfer,
  updatePersonBondAfterDismissal,
  savePersonBond,
  createPersonBond,
  updatePersonBond,
  deletePersonBond,
  deletePersonBondByAdmission,
  getLeadersByClientAndHierarchy,
  getLeaderlessByUnityHierarchy,
  getBondWithoutLeadershipByClient,
  getBondWithoutLeadershipAndIsNotLeadershipByClient,
  getSearchWithDeleted,
  getActiveBondsSearch,
  getActiveBondsSearchWorksheetSummary,
  getActiveBondsSearchWorksheetComplete,
  getStatusByPersonBond,
  getActiveBondsByClientAndHierarchySimple,
  getBondsWithDismissalByClientAndHierarchySimple,
  getByNameWithDeleted,
  byFormEvaluationPriority,
  getBondsSearch,
  getBondsSearchWorksheetSummary,
  getBondsSearchWorksheetComplete,
  getBondsByAdmissionIdWithDeleted,
  getByDocumentAndClientWithDeleted,
  movementHistoryGetByDocumentAndClientWithDeleted,
  getBondsWithDismissalByClientAndHierarchy,
  getDimissedToLinkJob,
  getByName,
  getByFilterAndName
}
