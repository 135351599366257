const defaultCurrentPositionTabIndex = null

const state = {
  currentPositionTabIndex: defaultCurrentPositionTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTPOSITIONTABINDEX: (state, payload) => {
    state.currentPositionTabIndex = payload
  }
}

const actions = {
  setCurrentPositionTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTPOSITIONTABINDEX', currentTabIndex)
  },
  clearCurrentPositionTabIndex({ commit }) {
    const defCurrentPositionTabIndex = 0
    commit('SET_CURRENTPOSITIONTABINDEX', defCurrentPositionTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
