const defaultCurrentDocumentTabIndex = 0

const state = {
  currentDocumentTabIndex: defaultCurrentDocumentTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTDOCUMENTTABINDEX: (state, payload) => {
    state.currentDocumentTabIndex = payload
  }
}

const actions = {
  setCurrentDocumentTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTDOCUMENTTABINDEX', currentTabIndex)
  },
  clearCurrentDocumentTabIndex({ commit }) {
    const defCurrentDocumentTabIndex = 0
    commit('SET_CURRENTDOCUMENTTABINDEX', defCurrentDocumentTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
