<template>
  <star-rating
    v-model="value"
    :increment="0.5"
    :max-rating="schema.maxRating"
    :star-size="40"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField]
}
</script>
