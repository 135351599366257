import * as moduleService from '@/services/module-service'

const state = {
  modules: {}
}

const getters = {}

const actions = {
  async getModules({ commit }) {
    const { data } = await moduleService.getAll()
    commit('SET_MODULES', data)
  }
}

const mutations = {
  SET_MODULES(state, modules) {
    const transformedModules = {}
    if (Array.isArray(modules)) {
      modules.map(module => {
        transformedModules[module.name] = module._id
      })
      state.modules = transformedModules
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
