import * as recruitmentEvaluationService from '@/services/recruitment-evaluation-service'

const defaultRecruitmentEvaluation = {
  _id: null,
  name: null,
  description: null,
  hasScore: null,
  minimumScore: null,
  maximumScore: null,
  sections: [],
  fields: [],
  files: [],
  client: null,
  createdBy: null,
  updatedBy: null,
  createdByAdmin: false
}

const state = {
  recruitmentEvaluation: defaultRecruitmentEvaluation
}

const getters = {}

const mutations = {
  SET_RECRUITMENTEVALUATION: (state, payload) => {
    state.recruitmentEvaluation = payload
  }
}

const actions = {
  loadRecruitmentEvaluation({ commit }, recruitmentEvaluationData) {
    commit('SET_RECRUITMENTEVALUATION', recruitmentEvaluationData)
  },
  clearRecruitmentEvaluation({ commit }) {
    const defRecruitmentEvaluation = {
      name: null,
      description: null,
      hasScore: null,
      minimumScore: null,
      maximumScore: null,
      sections: [],
      fields: [],
      files: [],
      client: null,
      createdBy: null,
      updatedBy: null,
      createdByAdmin: false
    }
    commit('SET_RECRUITMENTEVALUATION', defRecruitmentEvaluation)
  },
  save({ commit, rootState }, recruitmentEvaluation) {
    recruitmentEvaluation.client = rootState.user.userClient
    recruitmentEvaluationService.saveRecruitmentEvaluation(recruitmentEvaluation)
      .then(({ data }) => {
        commit('SET_RECRUITMENTEVALUATION', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
