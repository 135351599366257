const defaultCandidate = {
  _id: null,
  document: null,
  name: null,
  email: null,
  phone: null,
  phoneCountry: null,
  birthDate: null,
  fatherName: null,
  motherName: null,
  country: null,
  postalCode: null,
  street: null,
  addressNumber: null,
  neighborhood: null,
  city: null,
  addressState: null,
  createdBy: null,
  updatedBy: null
}

const defaultMemoryFilter = {
  keyword: null,
  unityId: null,
  jobId: null,
  statusJob: 'approved'
}

const state = {
  candidate: defaultCandidate,
  memoryFilter: defaultMemoryFilter
}

const getters = {}

const mutations = {
  SET_CANDIDATE: (state, payload) => {
    state.candidate = payload
  },
  SET_MEMORY_FILTER: (state, payload) => {
    state.memoryFilter = payload
  }
}

const actions = {
  loadCandidate({ commit }, candidateData) {
    commit('SET_CANDIDATE', candidateData)
  },
  loadMemoryFilter({ commit }, memoryFilterData) {
    commit('SET_MEMORY_FILTER', memoryFilterData)
  },
  clearCandidate({ commit }) {
    const defCandidate = {
      _id: null,
      document: null,
      name: null,
      email: null,
      phone: null,
      phoneCountry: null,
      birthDate: null,
      fatherName: null,
      motherName: null,
      country: null,
      postalCode: null,
      street: null,
      addressNumber: null,
      neighborhood: null,
      city: null,
      addressState: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_CANDIDATE', defCandidate)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
