const tabs = {
  NewResearchFormList: () => import('@/views/researches/NewResearch/Form')
}

const state = {
  currentNewResearchFormTab: tabs.NewResearchFormList,
  tabs: tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentNewResearchFormTab = state.tabs[tab]
  }
}

const actions = {
  setNewResearchFormTabs ({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
