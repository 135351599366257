const tabs = {
  DocumentDepositList: () => import('@/views/client/ClientDocument.vue'),
  DocumentDeposit: () => import('@/views/client/ClientDocumentForm.vue'),
  DocumentGroupDeposit: () =>
    import('@/views/client/ClientDocumentGroupForm.vue')
}

const state = {
  alert: { type: '', message: '' },
  currentDocumentDepositTab: tabs.DocumentDepositList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentDocumentDepositTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentDocumentDepositTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
