export default {
  titletreinamentos: 'Formación',
  sair: 'Salir',
  novotreinamento: 'Nueva formación',
  infotreinamento: 'Información de la formación',
  codigotreinamento: 'Código de la formación',
  nometreinamento: 'Nombre de la formación',
  descricaotreinamento: 'Breve descripción de la formación',
  informecodigotreinamento: 'Ingrese el código de la formación',
  informenometreinamento: 'Ingrese el nombre de la formación',
  informedescricaotreinamento: 'Haz una descripción de la formación.',
  salvar: 'Guardar',
  cancelar: 'Cancelar cambio',
  camposnaopreenchidos: 'Hay campos obligatorios no rellenados',
  editartreinamento: 'Edita la formación',
  excluirtreinamento: 'Eliminar formación',
  treinamentocadastrado: 'Formación registrada con éxito!!!',
  treinamentoatualizado: 'Formación actualizada con éxito!!!',
  atencao: '¡¡¡Atencion!!!',
  aviso: '¡¡¡Advertencia!!!',
  certezaexcluirtreinamento: '¿Estás seguro de que deseas eliminar esta' + '\n' + 'formación?',
  treinamentoexcluido: 'Formación eliminada con éxito!!!',
  treinamentos: 'Formaciónes',
  novo: 'Nuevo',
  referencias: 'Referencias',
  cargo: 'Cargo',
  acoes: 'Acciones',
  imprimir: 'Imprimir',
  exportarpdf: 'Exportar PDF',
  exportarexcel: 'Exportar Excel',
  editar: 'Editar',
  excluir: 'Eliminar',
  treinamento: 'Formación',
  cadastrado: ' registrado con éxito!!!',
  cadastrada: 'registrada con éxito!!!',
  cadastradas: 'registradas con éxito!!!',
  atualizado: 'actualizado con éxito!!!',
  atualizada: 'actualizada con éxito!!!',
  atualizadas: 'actualizadas con éxito!!!',
  excluido: 'eliminado con éxito!!!',
  excluida: 'eliminada con éxito!!!',
  certezaexcluir: '¿Estás seguro de que deseas eliminar',
  certezacancelar: '¿Estás seguro de que deseas cancelar',
  este: 'este',
  esta: 'esta',
  cliqueparaselecionar: 'Haga clicencias para seleccionar',
  selecione: 'Seleccione',
  umoumais: 'uno o mas',
  cargomin: 'cargo',
  cargosmin: 'cargos',
  cadastrorealizado: 'Registro exitoso!!!',
  updaterealizado: 'Actualización exitosa!!!',
  arquivo: 'Archivo',
  arquivomin: 'archivo',
  do: 'del',
  da: 'de la',
  escolhaarquivo: 'Elige un archivo o arrástralo aquí...',
  escolhaarquivos: 'Elige uno o más archivos o arrástrelos aquí...',
  arquivocadastrado: 'Archivo registrado',
  ver: 'Ver',
  baixar: 'Descargar',
  maodeobra: 'Mano de obra',
  arquivorelacionadolocal: 'Documentos / Imagens relacionados con la ubicación',
  textoarquivo: 'Si corresponde, se pueden registrar fotos u otros archivos para demostrar mejor las condiciones ideales para realizar esta tarea.',
  ambiente: 'Medio ambiente',
  oqueusar: '¿Qué necesitas usar para la tarea?',
  textomateriais: 'Se pueden incluir varios materiales. Deben estar registrados uno por uno.',
  informemateriais: 'Ingrese los materiales de la tarea',
  arquivorelacionadomaterial: 'Documentos / Imagens relacionados con el material',
  videorelacionadomaterial: 'Video relacionado con el material',
  informeummaterial: 'Ingrese al menos un material.',
  material: 'Material',
  materialmin: 'material',
  precisausar: 'Qué necesitas usar',
  oquesaber: '¿Qué necesitas saber para lograr la formación?',
  textometodo: 'Se pueden incluir varios conocimientos necesarios. Deben estar registrados uno por uno.',
  informesaber: 'Ingrese lo que necesitas saber',
  arquivorelacionadometodo: 'Documentos / Imagens relacionados con el método',
  videorelacionadometodo: 'Video relacionado con el método',
  informeummetodo: 'Ingrese al menos un método',
  metodo: 'Método',
  metodomin: 'método',
  precisasaber: 'Qué necesitas saber',
  oquecontrolar: '¿Qué necesitas controlar en la formación?',
  textocontrolar: 'Se pueden incluir varias medidas. Deben estar registrados uno por uno.',
  informemedidas: 'Informe as medidas da tarefa',
  arquivorelacionadomedida: 'Documentos / Imagens relacionados con la medida',
  videorelacionadomedida: 'Video relacionado con la medida',
  precisacontrolar: 'Qué necesitas controlar',
  medida: 'Medida',
  informeumamedida: 'Ingrese al menos una medida',
  oqueoperar: '¿Qué necesita para operar en la tarea?',
  textooperar: 'Se pueden incluir varias maquinas. Deben estar registrados uno por uno.',
  informemaquinas: 'Ingrese las máquinas utilizadas en la tarea.',
  arquivorelacionadomaquina: 'Documentos / Imagens relacionados con la maquina',
  videorelacionadomaquina: 'Video relacionado con la maquina',
  precisaoperar: 'Qué necesita para operar',
  maquina: 'Máquina',
  maquinamin: 'máquina',
  informeumamaquina: 'Ingrese al menos una máquina',
  linguagem: 'Idioma',
  portugues: 'Portugues',
  ingles: 'Inglés',
  espanhol: 'Español',
  linkdash: 'Dashboard a consultar',
  nomedash: 'Nombre del Dashboard',
  linkform: 'Formulario para completar',
  linkdmforms: 'Formulario DMForms para completar',
  nomeform: 'Nombre del formulario',
  informelinkdash: 'Ingrese el enlace del dashboard',
  informenomedash: 'Ingrese el nombre del dashboard',
  informelinkform: 'Ingrese el enlace del formulario',
  informenomeform: 'Ingrese el nombre del formulario',
  formulariomin: 'formulario',
  formulario: 'Formulario',
  palavraschave: 'Palabras clave relacionadas con el entrenamiento',
  informepalavrachave: 'Ingrese palabras claves',
  palavrasadicionadas: 'Palabras claves agregadas:',
  excluirpalavra: 'Eliminar palabra clave',
  logomarca: 'Logo',
  obrigadoparticipacao: 'Gracias por su participación',
  respostaenviada: 'Su respuesta ha sido enviada',
  enviaroutraresposta: 'Enviar otra respuesta',
  compartilhar: 'Compartir',
  copiadosucesso: 'Copiado con éxito!!!',
  duplicadosucesso: 'Duplicado con éxito!!!',
  copiar: 'Copiar',
  convidado: 'Invitado',
  dashboardousite: 'Dashboard / Sitio',
  obrigatorio: 'Obligatorio',
  cliqueparabaixar: 'Haga clicencias para descargar',
  cliqueparaabrir: 'Haga clicencias para abrir',
  cliqueparaassistir: 'Haga clicencias para ver',
  arquivos: 'Archivos',
  arquivosmin: 'archivos',
  verouocultar: 'Ver o esconder',
  videorelacionadolocal: 'Video relacionado con la ubicación',
  maquinas: 'Máquinas',
  materiais: 'Materiales',
  cliente: 'Cliente',
  clientemin: 'cliente',
  clientes: 'Clientes',
  clientesmin: 'clientes',
  umaoumais: 'una o mas',
  sim: 'Si',
  nao: 'No',
  emminutos: 'en minutos',
  pesquisa: 'Encuesta',
  pesquisamin: 'encuesta',
  pesquisas: 'Encuestas',
  pesquisasmin: 'encuestas',
  pessoa: 'Persona',
  pessoamin: 'persona',
  pessoas: 'Personas',
  pessoasmin: 'personas',
  atribuicoes: 'Asignaciones',
  recursos: 'Recursos',
  empresa: 'Empresa',
  operacao: 'Operación',
  atividade: 'Actividad',
  treinamentocomplementar: 'Formación complementaria',
  datainicio: 'Fecha de inicio',
  datatermino: 'Fecha de finalización',
  treinamentoe: 'La formación es',
  periodoaplicenciasacao: 'Periodo de aplicenciasación',
  existepontuacaoaprovacao: '¿Existe una puntuación mínima para aprobar?',
  pilar: 'Pilar',
  bloco: 'Bloc',
  item: 'Articulo',
  origem: 'Fuente',
  indicadorassociado: 'Indicador asociado',
  enviar: 'Enviar',
  pilares: 'Pilares',
  blocos: 'Blocs',
  itens: 'Articulos',
  origens: 'Fuentes',
  avaliacao: 'Evaluación',
  cargos: 'Cargos',
  locais: 'Ubicaciones ',
  estrutura: 'Estructura',
  estruturas: 'Estructuras',
  estruturamin: 'estructura',
  estruturasmin: 'estructuras',
  usuarios: 'Usuarios',
  local: 'Ubicación',
  caracteristicas: 'Caracteristicas',
  informelocal: 'Ingrese la ubicación',
  informecaracteristicaslocal: 'Ingrese las características del ubicación',
  localcadastrado: 'Ubicación registrado con éxito!!!',
  localatualizado: 'Ubicación actualizado con éxito!!!',
  localexcluido: 'Ubicación eliminado con éxito!!!',
  certezaexcluirlocal: '¿Estás seguro de que deseas eliminar este' + '\n' + 'ubicación?',
  descricao: 'Descripción',
  informecargo: 'Informar el cargo',
  informedescricaocargo: 'Ingrese la descripción del cargo',
  cargocadastrado: 'Cargo registrado con éxito!!!',
  cargoatualizado: 'Cargo actualizado con éxito!!!',
  cargoexcluido: 'Cargo eliminado con éxito!!!',
  certezaexcluircargo: '¿Estás seguro de que deseas eliminar este' + '\n' + 'cargo?',
  certezaduplicarvaga: '¿Estás seguro de que deseas eliminar esta' + '\n' + 'vacante?',
  grupo: 'Grupo',
  grupos: 'Grupos',
  grupomin: 'grupo',
  informegrupo: 'Ingrese el nombre del grupo',
  grupocadastrado: 'Grupo registrado con éxito!!!',
  grupoatualizado: 'Grupo actualizado con éxito!!!',
  grupoexcluido: 'Grupo eliminado con éxito!!!',
  escondermaquinas: 'Haga clicencias para mostrar o ocultar las máquinas en ese grupo',
  cadastrogrupos: 'Registro de grupos de máquinas',
  cadastromaquinas: 'Registro de máquinas',
  informemaquina: 'Ingresse el nombre de la máquina',
  naohamaquinas: 'No hay máquinas registradas en este grupo',
  cadastrogruposrecursos: 'Registro de grupos de recursos',
  cadastrorecursos: 'Registro de recursos',
  naohamateriais: 'No hay materiales registrados en este grupo',
  informematerial: 'Ingrese el nombre del material',
  empresamin: 'empresa',
  empresasmin: 'empresas',
  nome: 'Nombre',
  informenomeusuario: 'Ingrese el nombre del usuario',
  email: 'Correo electrónico',
  informeemailusuario: 'Ingrese el correo electrónico del usuario',
  celular: 'Móvil',
  informecelularusuario: 'Ingrese el móvil del usuário',
  informecargousuario: 'Ingrese el cargo del usuário',
  ativo: 'Activo',
  usuariocadastrado: 'Usuario registrado con éxito!!!',
  codigounidade: 'Código de la unidad',
  informecodigounidade: 'Ingrese el código de la unidad',
  senha: 'Contraseña',
  informesenhausuario: 'Ingrese la contraseña de usuario',
  tamanhosenha: 'La contraseña debe tener al menos 8 caracteres',
  perfil: 'Perfil',
  perfilmin: 'perfil',
  unidade: 'Unidad',
  unidademin: 'unidad',
  unidades: 'Unidades',
  unidadesmin: 'unidades',
  temacessograde: 'Tiene acceso a la cuadrícula',
  biblioteca: 'Biblioteca',
  bibliotecamin: 'biblioteca',
  bibliotecasmin: 'bibliotecas',
  administradordemarco: 'Administrador Demarco',
  usuarioscadastrados: 'Usuarios registrados',
  cadastroestruturainicial: 'Registro de estructura inicial',
  cadastroestruturasequencial: 'Registro de estructuras secuenciales',
  informenomeestrutura: 'Ingrese el nombre de la estructura',
  informedetalhesestrutura: 'Ingrese los detalles de la estructura',
  ativa: 'Activa',
  ativamin: 'activa',
  detalhes: 'Detalhes',
  opcional: 'opcional',
  cadastrobibliotecainicial: 'Registro de biblioteca inicial',
  cadastrobibliotecasequencial: 'Registro de bibliotecas secuenciales',
  cadastreumcargo: 'Registrar una posición para seleccionar',
  administrador: 'Administrador',
  usuario: 'Usuario',
  tituloambiente: 'Medio ambiente (DONDE se realizará)',
  titulomaodeobra: 'Trabajo (POR QUIEN se realizará)',
  titulomaterial: 'Material (lo que necesitas USAR)',
  titulometodo: 'Método (lo que necesita SABER)',
  titulomedida: 'Medida (lo que necesitas para CONTROLAR)',
  titulomaquina: 'Máquina (lo que necesita para OPERAR)',
  cadastro: 'Registro',
  planejamento: 'Planificación',
  tituloplano: 'QUÉ, POR QUÉ, DÓNDE y POR QUIEN se hará',
  titulocomo: 'CÓMO se hará',
  tituloquando: 'CUANDO se hará',
  titulocustos: 'Componentes de COSTOS de la formación',
  infopessoa: 'Información de la persona',
  catalogo: 'Catálogo',
  catalogomin: 'catálogo',
  cadastrogradesequencial: 'Registro de cuadrículas sequenciales',
  cadastrogradeinicial: 'Registro de cuadrícula inicial',
  informenomegrade: 'Ingrese el nombre de la cuadrícula',
  informedetalhesgrade: 'Ingrese los detalles de la cuadrícula',
  cpf: 'CPF',
  informecpf: 'Ingrese el CPF',
  cadastropessoa: 'Registro de persona',
  novapessoa: 'Nueva persona',
  pessoais: 'Datos personales',
  funcionais: 'Datos funcionales',
  sociais: 'Datos sociales',
  transacionais: 'Datos transaccionales',
  datanascimento: 'Fecha de nacimiento',
  nomepai: 'Nombre del padre',
  nomemae: 'Nombre de la madre',
  informedatanascimento: 'Ingresse la fecha de nacimiento',
  informenomepai: `Ingrese el nombre del padre`,
  informenomemae: `Ingrese el nombre de la madre`,
  dataadmissao: 'Fecha de admisión',
  informedataadmissao: 'Ingrese la fecha de admisión',
  salario: 'Salario',
  informesalario: 'Ingrese el salario',
  codigopostal: 'Código postal',
  cep: 'Código postal',
  informecodigopostal: 'Ingrese el código postal',
  logradouro: 'Calle',
  informelogradouro: 'Ingrese la calle',
  numero: 'Número',
  informenumero: 'Ingrese el número',
  bairro: 'Barrio',
  informebairro: 'Ingrese el barrio',
  cidade: 'Ciudad',
  cidademin: 'ciudad',
  informecidade: 'Ingrese la ciudad',
  estado: 'Estado',
  estadomin: 'estado',
  informeestado: 'Ingrese el estado',
  pais: 'País',
  informepais: 'Ingrese el país',
  jornadatrabalho: 'Jornada de trabajo',
  informejornadatrabalho: 'Ingrese la jornada de trabajo',
  informenome: 'Ingrese el nombre',
  notaminima: 'Puntuación mínima para la aprobación',
  informenotaminima: 'Ingrese la puntuación mínima para la aprobación',
  informenotamaxima: 'Ingrese la puntuación máxima',
  notamaxima: 'Puntuación máxima',
  tempontuacao: 'Hay una puntuación mínima para la aprobación',
  vinculadograde: 'Vinculado a la cuadrícula',
  cadastre: 'Registre',
  um: 'uno',
  uma: 'un',
  incluircampo: 'Incluir campo',
  ondeserarealizado: '¿Donde se llevará a cabo',
  localmin: 'local',
  desejaadicionar: '¿Quieres agregar / eliminar un artículo?',
  selecioneparaadicionar: 'Seleccione uno o más elementos para agregar',
  imagemoudocumento: 'Documentos / Imagens',
  formularioexterno: 'Formulario externo',
  video: 'Vídeo',
  videomin: 'vídeo',
  quemparticipara: '¿Quién participará en la formación?',
  quemparticipa: 'Quien participa',
  naoaplicenciasatreinamento: 'No se aplicenciasa a esta formación',
  preenchermanualmente: 'Llenar información',
  copiardadostreinamento: 'Copiar información de la formación',
  copiardados: 'Copiar información de otra formación',
  informelinkvideo: 'Ingrese el enlace del video en Youtube, Vimeo o Google Drive',
  invalidLinkVideoURL: 'El enlace del video debe ser de Youtube, Vimeo o Google Drive',
  nomevideo: 'Nombre del video',
  informenomevideo: 'Ingrese el nombre del video',
  oqueserafeito: '¿Qué se hará en la formación?',
  informeoqueserafeito: 'Ingrese lo que se hará en la formación',
  porqueserafeito: '¿Por qué debería llevarse a cabo la formación?',
  informeporqueserafeito: 'Informar el motivo de la formación',
  quemeresponsavel: '¿Quién es responsable de realizar la formación?',
  responsavelmin: 'responsable',
  responsavel: 'Responsable',
  passoexecucao: 'Paso para la realización de la formación',
  informeopassoexecucao: 'Ingrese el paso para la realización de la formación',
  frequenciamin: 'frecuencia',
  frequencia: 'Frecuencia',
  comquefrequencia: '¿Con qué frecuencia se llevará a cabo la formación?',
  pordemanda: 'Bajo demanda',
  diaria: 'Diariamente',
  semanal: 'Semanal',
  quinzenal: 'Quincenal',
  mensal: 'Mensual',
  semestral: 'Semestre',
  anual: 'Anualmente',
  segunda: 'Lunes',
  terca: 'Martes',
  quarta: 'Miércoles',
  quinta: 'Jueves',
  sexta: 'Viernes',
  sabado: 'Sabado',
  domingo: 'Domingo',
  janeiro: 'Enero',
  fevereiro: 'Febrero',
  marco: 'Marzo',
  abril: 'Abril',
  maio: 'Mayo',
  junho: 'Junio',
  julho: 'Julio',
  agosto: 'Agosto',
  setembro: 'Septiembre',
  outubro: 'Octubre',
  novembro: 'Noviembre',
  dezembro: 'Diciembre',
  informediavalido: 'Por favor ingrese un día válido',
  informeprazovalido: 'Ingrese una fecha límite válida',
  emmin: '(en min.)',
  fatogerador: 'Evento generador de la demanda',
  informefatogerador: 'Ingrese el evento generador de la demanda',
  diaprimeiraquinzena: 'Día de ejecución en la primera mitad',
  informediaprimeiraquinzena: 'Ingrese el día de ejecución en la primera mitad',
  diasegundaquinzena: 'Día de ejecución en la segunda mitad',
  informediasegundaquinzena: 'Ingrese el día de ejecución en la segunda mitad',
  mesprimeirosemestre: 'Mes del primer semestre',
  messegundosemestre: 'Mes del segundo semestre',
  horadia: 'Hora del día',
  diasemana: 'Dia de la semana',
  diasemanamin: 'dia de la semana',
  diaexecucao: 'dia de ejecucion',
  primeiro: 'Primero',
  segundo: 'Segundo',
  primeiromin: 'primero',
  segundomin: 'segundo',
  diames: 'Dia del mes',
  diamesmin: 'dia del mes',
  mes: 'Mes',
  mesmin: 'mes',
  mesano: 'Month of the year',
  tempoduracaotreinamento: 'Duración del entrenamiento',
  informetempoduracaotreinamento: 'Ingrese la duración de la formación',
  informetempoduracaopesquisa: 'Ingrese la duración de la encuesta',
  informediames: 'Ingrese el día del mes',
  naoinformado: 'No informado',
  treinamentoobrigatorio: 'La formación es obligatoria',
  quaispessoasparticiparaotreinamento: '¿Qué personas participarán en la formación?',
  quaispessoasparticiparaopesquisa: '¿Qué personas participarán en la encuesta?',
  recurso: 'Recurso',
  informerecurso: 'Ingrese el nombre del recurso',
  recursomin: 'recurso',
  grade: 'Cuadrícula',
  grademin: 'cuadrícula',
  adicionargradesequencial: 'Agregar cuadrícula secuencial',
  dataespecifica: 'Fecha específica',
  avaliacaomin: 'evaluación',
  gruposrecursos: 'Grupos de recursos',
  gruposmin: 'grupos',
  recursosmin: 'recursos',
  diariamente: 'Diariamente a las',
  toda: 'Toda',
  dias: 'Dias',
  e: 'y',
  dia: 'Dia',
  nosmesesde: 'En los meses de',
  nomesde: 'Anualmente, en',
  a: 'al',
  o: 'el',
  decadames: 'de cada mes',
  quandoacontecera: 'Cuando sucederá',
  naoidentificado: 'No identificado',
  unidadesparticipantes: 'Unidades participantes',
  cargosparticipantes: 'Cargos participantes',
  planejamentomin: 'planificación',
  arquivorelacionadotreinamento: 'Documentos / Imagens relacionados con la formación',
  quaiscargosdevemparticipar: '¿Qué cargos deben participar?',
  videorelacionadotreinamento: 'Video relacionado com la formación',
  avaliacoes: 'Evaluaciones',
  avaliacoesmin: 'evaluaciones',
  novaavaliacao: 'Nueva evaluación',
  campo: 'Campo',
  campomin: 'campo',
  cadastroavaliacao: 'Registro de evaluación',
  nomeavaliacao: 'Nombre de la evaluación',
  informenomeavaliacao: 'Ingrese el nombre de la evaluación (nombre corto preferiblemente)',
  descricaoavaliacao: 'Descripción de la evaluación',
  informedescricaoavaliacao: 'Ingrese la descripción de la evaluación',
  adicionarsecao: 'Agregar sección',
  adicionarcampo: 'Agregar campo',
  nomesecao: 'Nombre de la sección',
  informenomesecao: 'Ingrese el nombre de la sección',
  salvarsecao: 'Guardar sección',
  cancel: 'Cancelar',
  nomecampo: 'Nombre del campo o pregunta a responder',
  informenomecampo: 'Ingrese el nombre del campo',
  textonomecampo: 'Texto que aparecerá como una etiqueta en la evaluación. No se puede cambiar una vez registradas las respuestas',
  textoincludecampo: 'Texto que aparecerá como una etiqueta en el campo',
  tipocampo: 'Tipo de campo',
  selecionetipocampo: 'Seleccione el tipo de campo',
  listaopcoes: 'Listado de opciones',
  informeopcao: 'Ingrese la opción',
  excluiropcao: 'Eliminar opción',
  campoobrigatorio: 'Campo obligatorio',
  campos: 'Campos',
  camposmin: 'campos',
  secao: 'Sección',
  secaomin: 'sección',
  escolhaopcao: 'Elige una opcion',
  avaliacaoatualizada: 'Evaluación actualizada con éxito!!!',
  avaliacaocadastrada: 'Evaluación registrada con éxito!!!',
  avancar: 'Avanzar',
  preencher: 'Llenar',
  data: 'Fecha',
  opcao: 'Opción',
  selecaounica: 'Selección de artículo individual',
  selecaomultipla: 'Selección de múltiples artículos',
  textocurto: 'Texto corto',
  textolongo: 'Texto largo',
  cliqueeditar: 'Haz clicenciask para editar',
  consultarinformacoes: 'Consultar información',
  informacoesenviadas: 'Información enviada con éxito!!!',
  informacoesexcluidas: 'Información eliminada con éxito!!!',
  preenchidopor: 'Llenado por',
  datapreenchimento: 'Fecha de realización',
  estas: 'estas',
  informacoesmin: 'informaciones',
  telefone: 'Teléfono',
  hora: 'Hora',
  informeemailvalido: 'Ingrese un correo electrónico válido',
  adicionarimagem: 'Agregar imagen',
  adicionarvideo: 'Agregar video',
  nomevideopreview: 'Nombre del video o texto que se mostrará',
  informenomevideopreview: 'Ingrese el nombre del video o texto que se mostrará',
  nomearquivopreview: 'Nombre del archivo o texto que se mostrará',
  informenomearquivopreview: 'Ingrese el nombre del archivo o texto que se mostrará',
  pequena: 'Pequeña',
  grande: 'Grande',
  tamanhonatela: 'Ingrese el tamaño de la imagen en la pantalla',
  imagem: 'Imagen',
  semsecao: 'Antes de la primera sección',
  valorminimo: 'Valor mínimo',
  informevalorminimo: 'Ingrese el valor mínimo',
  valormaximo: 'Valor máximo',
  informevalormaximo: 'Ingrese el valor máximo',
  quantidademinimo: 'Cantidad mínima de opciones seleccionables',
  informequantidademinimo: 'Ingrese la cantidad mínima',
  quantidademaximo: 'Cantidad máximo de opciones seleccionables',
  informequantidademaximo: 'Ingrese la cantidad máxima',
  duplicar: 'Duplicar',
  voltar: 'Vuelve',
  classificacao: 'Clasificación',
  niveis: 'Niveles',
  simbolo: 'Símbolo',
  estrela: 'Estrella',
  treinamentomin: 'formación',
  limparpesquisa: 'Borrar búsqueda',
  informepalavrachaveparapesquisar: 'Ingrese una palabra clave para buscar y presione enter',
  informepalavraparapesquisar: 'Ingrese una palabra para buscar y presione enter',
  valordaquestao: 'Valor de la pregunta',
  informevalordaquestao: 'Ingrese el valor de la pregunta',
  somainvalida: 'La suma de los puntos de las preguntas no puede ser superior a la puntuación máxima de la evaluación',
  valornaopodesernegativo: 'El valor de la pregunta no puede ser menor que cero',
  respostacorreta: 'Respuesta correcta',
  selecionerespostacorreta: 'Selecciona la respuesta correcta',
  respostascorretas: 'Respuestas correctas',
  selecionerespostascorretas: 'Selecciona las respuestas correctas',
  vincular: 'Vincular',
  ocultarinformacoes: 'Ocultar información',
  mostrarinformacoes: 'Mostrar información',
  aprovado: 'Aprobado',
  reprovado: 'Desaprobado',
  pontuacaoobtida: 'Puntuación obtenida',
  resultado: 'Resultado',
  gradesmin: 'cuadrículas',
  inserirnatelade: 'Insertar en el',
  selecionetela: 'Seleccionar la pantalla',
  titulocampo: 'Nombre del campo',
  tipo: 'Tipo',
  tela: 'Pantalla',
  vinculo: 'Enlace Contractual',
  vinculos: 'Enlaces Contractuales',
  vinculomin: 'enlace contractual',
  informevinculo: 'Ingrese el enlace contractual',
  informedescricaovinculo: 'Ingrese la descripción del enlace contractual',
  jornadadetrabalho: 'Jornada Laboral',
  jornadas: 'Jornadas',
  jornadamin: 'jornada laboral',
  informejornada: 'Ingrese la jornada laboral',
  informedescricaojornada: 'Ingrese la descripción de la jornada laboral',
  remuneracao: 'Remuneración',
  remuneracaobeneficios: 'Remuneración y Beneficios',
  contratacao: 'Contratación',
  recrutamentoselecao: 'Reclutamiento y Selección',
  movimentacao: 'Movimiento',
  carreira: 'Carrera',
  novapesquisa: 'Nueva encuesta',
  configuracao: 'Configuración',
  gerenciamento: 'Gestión',
  movimentacaoregistro: 'Movimiento y Registro',
  relacionamento: 'Relación',
  acompanhamentoretencao: 'Seguimiento y Retención',
  desenvolvimento: 'Desarrollo',
  integracaotreinamento: 'Integración y Formación',
  informetelefone: 'Ingrese el teléfono',
  documentoidentificacao: 'Documento de identificación',
  textodocumentoidentificacao: 'En Brasil, informar el CPF',
  gradetreinamentos: 'Cuadrícula de formaciónes',
  gradetbaseconhecimento: 'Cuadrícula de archivos,',
  foto: 'Fotografía',
  pessoasparticipantes: 'Personas participantes',
  textovalidanotaminima: 'La nota mínima aprobatoria no puede ser superior al valor de la evaluación.',
  cadastropesquisa: 'Registro de encuesta',
  nomepesquisa: 'Nombre de la encuesta',
  informenomepesquisa: 'Ingrese el nombre de la encuesta',
  descricaopesquisa: 'Descripción de la encuesta',
  informedescricaopesquisa: 'Ingrese la descripción de la encuesta',
  dataterminoinvalida: 'La fecha de finalización no puede ser anterior a la fecha de inicio',
  dataatestadoinvalida: 'La fecha del certificado no puede ser posterior a la fecha de inicio',
  tempeso: 'Las respuestas tendrán peso',
  opcoes: 'Opciones',
  pesos: 'Pesos',
  respostaobrigatoria: 'Respuesta obligatoria',
  pesoresposta: 'Peso de la respuesta',
  documento: 'Documento',
  admissao: 'Admisión',
  afastamentos: 'Licencias',
  ausencias: 'Ausencias',
  demissao: 'Despido',
  tipodemissao: 'Tipo de despido',
  tipoocorrencia: 'Tipo de incidente',
  datademissao: 'Fecha de despido',
  dataocorrencia: 'Fecha de incidente',
  ferias: 'Vacaciones',
  jornada: 'Jornada',
  ocorrencias: 'Incidentes',
  transferencias: 'Traslados',
  demissoes: 'Despidos',
  cadastrosubcategoriademissao: 'Registro de subcategoria de despido',
  cadastrocategoriademissao: 'Registro de categoria de despido',
  informecategoria: 'Ingrese el nombre de la categoria',
  informedetalhescategoria: 'Ingrese los detalles de la categoria',
  categoria: 'Categoria',
  categoriamin: 'categoria',
  adicionarsubcategoria: 'Agregar subcategoria',
  cadastrosubcategoriaocorrencia: 'Registro de subcategoria de incidente',
  cadastrocategoriaocorrencia: 'Registro de categoria de incidente',
  cadastrosubcategoriatransferencia: 'Registro de subcategoria de traslado',
  cadastrocategoriatransferencia: 'Registro de categoria de traslado',
  cadastrosubcategoriaafastamento: 'Registro de subcategoria de licencia',
  cadastrocategoriaafastamento: 'Registro de categoria de licencia',
  exigejustificativa: 'Requiere justificación y documento de respaldo',
  exigedocumentocomprobatorio: 'Requiere documento de respaldo',
  exigeatestado: 'Requiere presentación de certificado médico',
  cadastrosubcategoriaausencia: 'Registro de subcategoria de ausencia',
  cadastrocategoriaausencia: 'Registro de subcategoria de ausencia',
  cadastrosubcategoriapromocao: 'Registro de subcategoria de ascenso',
  cadastrocategoriapromocao: 'Registro de categoria de ascenso',
  colaborador: 'Colaborador',
  dataafastamento: 'Fecha de la licencia',
  tipoafastamento: 'Tipo de licencia',
  cadastreumafastamento: 'Registre un tipo de licencia en Configuración / Licencias',
  cadastreumademissao: 'Registre un tipo de despido en Configuración / Despidos',
  cadastreumaocorrencia: 'Registre un tipo de incidente en Configuración / Incidentes',
  tipomin: 'tipo',
  justificativa: 'Justificación',
  informejustificativa: 'Ingrese la justificación de la licencia',
  arquivosrelacionadosafastamento: 'Archivos relacionados con la licencia',
  arquivosrelacionadosdemissao: 'Archivos relacionados con la despido',
  arquivosrelacionadosocorrencia: 'Archivos relacionados con el incidente',
  dataterminoafastamento: 'Fecha de finalización de la salida',
  dataretorno: 'Fecha de regreso',
  afastamentocadastrado: 'Licencia registrada con éxito!!!',
  afastamentoatualizado: 'Licencia actualizada con éxito!!!',
  afastamentoexcluido: 'Licencia eliminada con éxito!!!',
  esteafastamento: 'esta licencia',
  estademissao: 'este despido',
  estaocorrencia: 'este incidente',
  demissaocadastrado: 'Despido registrada con éxito!!!',
  demissaoatualizado: 'Despido actualizada con éxito!!!',
  demissaoexcluido: 'Despido eliminado con éxito!!!',
  demissaocancelada: 'Despido cancelado con éxito!!!',
  ocorrenciaatualizado: 'Incidente registrado con éxito!!',
  ocorrenciaexcluido: 'Incidente eliminado con éxito!!!',
  ocorrenciacancelada: 'Incidente cancelado con éxito!!!',
  baixarimagem: 'Descargar imagen',
  baixardocumento: 'Descargar documento',
  arquivoscadastrados: 'Archivos registrados',
  tipoausencia: 'Tipo de ausencia',
  cadastreumaausencia: 'Registre un tipo de ausencia en Configuración / Ausencias',
  arquivosrelacionadosausencia: 'Archivos relacionados con la ausencia',
  ausenciacadastrada: 'Ausencia registrada con éxito!!!',
  ausenciaatualizada: 'Ausencia actualizada con éxito!!!',
  ausenciaexcluida: 'Ausencia eliminada con éxito!!!',
  estaausencia: 'esta ausencia',
  tipoatestado: 'Tipo de certificado',
  medico: 'Médico',
  odontologico: 'Dental',
  codigodoenca: 'Código de enfermedad',
  informecodigodoenca: 'Ingrese el código de la enfermedad o complete con No informado',
  identificacaomedico: 'Identificación del médico / dentista',
  informeidentificacaomedico: 'Ingrese la identificación del médico / dentista',
  textocid: 'Como el paciente puede solicitar que no se mencione el código de la enfermedad en el certificado, la ausencia de esta información también debe registrarse en el sistema: “NO INFORMADO”',
  dataatestado: 'Fecha del certificado',
  promocao: 'Ascenso',
  promocoes: 'Ascensos',
  transferenciaexcluida: 'Traslado eliminado con éxito!!!',
  transferenciacancelada: 'Traslado cancelado con éxito!!!',
  estatransferencia: 'este traslado',
  transferenciaatualizada: 'Traslado actualizado con éxito!!!',
  transferenciacadastrada: 'Traslado registrado con éxito!!!',
  motivoentrada: 'Razón de entrada',
  motivosaida: 'Razón de salida',
  estruturadestino: 'Estructura destino',
  estruturaatual: 'Estructura actual',
  datamovimentacao: 'Fecha de movimiento',
  arquivosrelacionadostransferencia: 'Archivos relacionados con el traslado',
  cadastreumatransferencia: 'Registre un tipo de traslado en Configuración / Traslados',
  unidadedestinoinvalida: 'La estructura de destino debe ser diferente de la estructura actual',
  peridodoaplicacao: 'Periodo de aplicación',
  datainiciopromocao: 'Fecha de inicio del ascenso',
  cargoatual: 'Cargo actual',
  salarioatual: 'Salario actual',
  novocargo: 'Nuevo cargo',
  novosalario: 'Nuevo salario',
  informenovosalario: 'Ingrese el nuevo salario',
  tipopromocao: 'Tipo de ascenso',
  cadastreumapromocao: 'Registre un tipo de ascenso en Configuración / Ascensos',
  arquivosrelacionadospromocao: 'Archivos relacionados con el ascenso',
  datapromocao: 'Fecha de ascenso',
  promocaomin: 'ascenso',
  arquivosrelacionadospesquisa: 'Archivos relacionados con la encuesta',
  tempoduracaopesquisa: 'Duración de la encuesta',
  marcartodos: 'Marcar / Desmarcar todos',
  marcartodas: 'Marcar / Desmarcar todas',
  desenvolvimentoecarreira: 'Desarrollo y Carrera',
  dossievirtual: 'Workplace',
  eventosfolha: 'Eventos Nómina',
  encargos: 'Encargos',
  provisoes: 'Provisiones',
  remuneracaovariavel: 'Remuneración Variable',
  adiantamentosalarial: 'Adelanto de Salario',
  beneficios: 'Beneficios',
  dadosbancarios: 'Datos Bancarios',
  parametros: 'Parámetros',
  recibos: 'Recibos',
  folhadepagamento: 'Nómina de sueldos',
  relatorios: 'Informes',
  programacao: 'Programación',
  controle: 'Control',
  documentos: 'Documentos',
  numeroperiodoaquisitivo: 'Número de período adquisitivo (PA) de vacaciones',
  informenumeroperiodoaquisitivo: 'Ingrese el número secuencial al que se refiere este período adquisitivo',
  datainicioperiodoaquisitivo: 'Fecha de inicio del período adquisitivo (PA)',
  dataterminoperiodoaquisitivo: 'Fecha de finalización del período adquisitivo (PA)',
  diasantecedencia: 'Días de anticipación para ir de vacaciones antes de que finalice el período adquisitivo (PA)',
  informediasantecedencia: 'Informar el número de días de anticipación',
  datalimitesairferias: 'Fecha límite para ir de vacaciones',
  numerofaltasperiodoaquisitivo: 'Número de ausencias en el período adquisitivo',
  informenumerofaltaspa: 'Ingrese el número de ausencias',
  numerodiasferias: 'Número de días de vacaciones',
  informenumerodiasferias: 'Ingrese el número de días que el colaborador se irá de vacaciones',
  opcaoabonopecuniario: 'Opción de asignación económica',
  opcaoantecipacao: 'Opción de prepago 1.a cuota del decimotercer salario',
  datainicioferias: 'Fecha de inicio de vacaciones',
  dataterminoferias: 'Fecha de finalización de vacaciones',
  numeroperiodoaquisitivoinvalido: 'El número del período adquisitivo debe ser mayor que 0',
  diasantecedenciainvalido: 'El número de días de anticipación debe ser mayor o igual a 30',
  diasferiasinvalido: 'El número de días de vacaciones debe ser superior a 0',
  datainicioferiasinvalida: 'La fecha de inicio de las vacaciones no puede ser anterior a la fecha de inicio del período adquisitivo',
  datainicioferiasfimperiodo: 'La fecha de inicio de las vacaciones no puede ser posterior a la fecha de finalización del período de concesión',
  numerofaltasinvalido: 'El número de ausencias debe ser mayor o igual a 0',
  colaboradorsaldozero: 'El colaborador no tiene saldo de vacaciones para el período adquisitivo informado',
  saldoferiasinsuficiente: 'Número de días de vacaciones superior al saldo del colaborador. Saldo actual: ',
  numeropa: 'Número del PA',
  datainiciopa: 'Fecha início PA',
  dataterminopa: 'Fecha finalización PA',
  numerofaltas: 'Número ausencias',
  saldodiasferias: 'Saldo días de vacaciones',
  iniciogozoferias: 'Inicio vacaciones',
  terminogozoferias: 'Finalización vacaciones',
  totaldiasferias: 'Días de vacaciones totales',
  abonopecuniario: 'Asignación financiera',
  antecipar13sal: 'Antecipar 13º sal. (S/N)',
  aprovada: 'Aprobada (S/N)',
  S: 'S',
  N: 'N',
  feriasmin: 'vacaciones',
  solicitacaoabonopecuniario: 'Solicitud de asignación financiera',
  solicitacaoantecipar13sal: 'Solicitud anticipo del 13º salario',
  avisoferias: 'Advertencia de vacaciones',
  reciboferias: 'Recibo de vacaciones',
  gerar: 'Generar',
  vagas: 'Vacantes',
  vaga: 'Vacante',
  vagamin: 'vacante',
  candidatos: 'Candidatos',
  candidatosmin: 'candidatos',
  formularios: 'Formularios',
  entrevistas: 'Entrevistas',
  exames: 'Exámenes',
  agenciasrs: 'Agencias R&S',
  informediasabono: 'Ingrese el número de días referidos a la asignación',
  diasabonoinvalido: 'El número de días de asignación debe ser mayor o igual a 0',
  diasabonoinsuficiente: 'El número de días de vacaciones agregado al número de días de asignación no puede ser mayor que el saldo de días de vacaciones. Saldo actual:',
  escalas: 'Escalas',
  marcacoes: 'Marcaciones',
  compensacoes: 'Compensaciones',
  adicionais: 'Adicionales',
  quadropessoal: 'Cuadro de Personal',
  quantidadeidealpessoas: 'Número óptimo de personas',
  staffnuminvalido: 'El número de personas debe ser mayor o igual a 0',
  camporepetido: 'No se pueden insertar dos campos con el mismo nombre',
  secaorepetida: 'No se pueden insertar dos secciones con el mismo nombre',
  empresatrabalha: 'Empresa para la que trabaja',
  empresasacessar: 'Empresas a las que accederás',
  permissoesempresa: 'Permisos en la empresa ',
  admin_sistema: 'Administrador Demarco',
  camposobrigatorios: 'Campos obligatórios',
  exibirusuarios: 'Ver usuarios',
  ativos: 'Activos',
  inativos: 'Inactivos',
  hierarquia: 'Jerarquía',
  reunioes: 'Reuniones',
  feedback: 'Feedback',
  planos: 'Planes',
  pipeline: 'Pipeline',
  geral: 'General',
  condutor: 'Conductor',
  planoacao: 'Plan de Acción',
  aprovacoes: 'Aprobaciones',
  marqueitensaprovacao: 'Marque los elementos que necesitan aprobación',
  atividades: 'Actividades',
  experiencia: 'Experiencia',
  habilidades: 'Habilidades',
  funcoeseresponsabilidadescargo: 'Funciones y responsabilidades del cargo',
  textocargoplanejamento: 'La selección de cargos es obligatoria si no se selecciona ninguna persona',
  textopessoasplanejamento: 'La selección de personas es obligatoria si no se seleccionan cargos',
  caracteristica: 'Característica',
  caracteristicamin: 'característica',
  informecaracteristica: 'Ingrese la característica',
  peso: 'Peso',
  informepeso: 'Ingrese el peso de esta característica',
  opcoesresposta: 'Opciones de respuesta',
  informeopcaoresposta: 'Ingrese las opciones de respuesta',
  opcoesadicionadas: 'Opciones agregadas',
  referencia: 'Referencia',
  referenciamin: 'referencia',
  nivelexigencia: 'Nível de demanda',
  desejavel: 'Deseable',
  recomendavel: 'Recomendable',
  pesoinvalido: 'El valor del peso debe ser mayor o igual a cero',
  informecargoparapesquisar: 'Ingrese uno cargo para buscar',
  contratacoes: 'Contrataciones',
  cadastrosubcategoriacontratacao: 'Registro de subcategoria de contratación',
  cadastrocategoriacontratacao: 'Registro de categoria de contratación',
  requisicao: 'Requisición',
  aprovacao: 'Aprobación',
  anunciarvaga: 'Anunciar vacante',
  novavaga: 'Nueva vacante',
  caracteristicasnaoinformadas: 'Características no informadas',
  datarequisicao: 'Fecha de requisición',
  codigovaga: 'Código de vacante',
  informecodigovaga: 'Ingrese el código de la vacante',
  nomevaga: 'Nombre de la vacante',
  informenomevaga: 'Ingrese el nombre de la vacante',
  justificativacontratacao: 'Justificación de la contratación',
  cadastreummotivo: 'Registre una razón en Configuración / Contrataciones',
  motivomin: 'razón',
  quantidadevagas: 'Número de vacantes',
  informequantidadevagas: 'Ingrese el número de vacantes',
  salarioreferencia: 'Salario de referencia',
  informesalarioreferencia: 'Ingrese el salario de referencia',
  prazocontratacao: 'Plazo de contratación (en días)',
  informeprazocontratacao: 'Ingrese el plazo de contratación',
  tiporecrutamento: 'Tipo de reclutamiento',
  interno: 'Interno',
  externo: 'Externo',
  misto: 'Mezclado',
  nomeaprovador: 'Nombre del aprobador',
  informenomeaprovador: 'Ingrese el nombre del aprobador',
  emailaprovador: 'Email del aprobador',
  informeemailaprovador: 'Ingrese el email del aprobador',
  celularaprovador: 'Móvil del aprobador',
  informecelularaprovador: 'Ingrese el móvil del aprovador',
  cadastreumaestrutura: 'Registre una estructura en Configuración / Estructura',
  quantidadevagasinvalida: 'El número de vacantes debe ser mayor que 0',
  salarioinvalido: 'El salario debe ser superior a 0',
  prazoinvalido: 'El plazo de contratación debe ser superior a 0',
  aguardandoaprovacao: 'Esperando aprobación',
  aprovadavaga: 'Aprobada',
  reprovada: 'Desaprobada',
  horariotrabalho: 'Horario de trabajo',
  informehorariotrabalho: 'Ingrese el horario de trabajo',
  aprovaroureprovar: 'Aprobar o desaprobar',
  aprovarvaga: 'Aprobar vacante',
  reprovarvaga: 'Desaprobar vacante',
  formulariocandidatos: 'Formulario candidatos',
  formularioinscricao: 'Formulario de suscripción',
  tituloformulario: 'Título del formulario',
  informetituloformulario: 'Ingrese el título del formulario',
  icp: 'Índice de cumplimiento del perfil',
  icpparaaprovacao: 'Índice de cumplimiento del perfil mínimo (%)',
  informeicpparaaprovacao: 'Ingrese el valor mínimo de ICP para aprobación',
  descricaoformulario: 'Descripción del formulario',
  informedescricaoformulario: 'Ingrese la descripción del formulario',
  formulariocadastrocandidatos: 'Formulario de registro de candidatos',
  formularioinscricaocandidatos: 'Formulário de suscripción de candidatos',
  textoicpaprovacao: 'Valor mínimo de ICP para que el candidato pueda avanzar a la siguiente fase del proceso de selección',
  requisitosadicionais: 'Requisitos adicionales',
  informerequisitosadicionais: 'Ingrese requisitos adicionales, si los hubiera',
  ecolaboradordaempresa: '¿Eres colaborador de la empresa?',
  outrasinformacoes: 'Otras informaciones',
  novoform: 'Nuevo formulario',
  cadastroformulario: 'Registro de formulario',
  nomeformulario: 'Nombre del formulario',
  informenomeformulario: 'Ingrese el nombre del formulario',
  vagaaqualserefere: 'Vacante a la que se refiere',
  cadastreumavaga: 'Registre una vacante',
  identificacaocandidato: 'Identificación del candidato',
  formularionaopodeseralterado: 'Este formulario no puede ser cambiado, ya que hay respuestas vinculadas a él',
  checkhabilidades: 'Verificación de habilidades',
  formulariosgerais: 'Formularios generales',
  formulariocheckhabilidades: 'Registro de formulario de verificación de habilidades',
  parecerentrevistador: 'Opinión del entrevistador',
  apto: 'Apto',
  inapto: 'No apto',
  ocandidatoesta: 'El candidato es',
  opcaomin: 'opción',
  selecao: 'Selección',
  linkinscricao: 'Enlace de suscripción',
  formulariocheckhabilidadesparaocargo: 'Formulario de verificación de habilidades para el cargo de ',
  novocandidato: 'Nuevo candidato',
  candidato: 'Candidato',
  naofoiencontradocolaborador: 'No se encontró colaborador con el documento informado en la inscripción del candidato',
  candidatocomrestricao: 'El candidato no cumple con las características obligatorias de perfil y/o experiencia',
  novaadmissao: 'Nueva admisión',
  admissaomin: 'admisión',
  cancelada: 'cancelada con éxito!!!',
  cancelado: 'cancelado con éxito!!!',
  afastamento: 'Licencia',
  afastamentocancelado: 'Licencia cancelada con éxito!!!',
  ausencia: 'Ausencia',
  funilcontratacao: 'Embudo de Contratación',
  informedocumento: 'Ingrese el documento',
  informetipoclinica: 'Seleccione el tipo de clínica / médico',
  clinicasalva: 'Clínica registrada con éxito!!!',
  clinicaatualizada: 'Clínica actualizada con éxito!!!',
  clinica: 'Clínica',
  novaclinica: 'Nueva Clínica',
  certezaexcluirclinica: '¿Está seguro de que deseas eliminar esta clínica?',
  clinicaexcluida: 'Clínica eliminada con éxito!!!',
  juridica: 'Persona Jurídica',
  fisica: 'Persona Física',
  informetipo: 'Ingrese el tipo',
  novaagencia: 'Nueva agencia',
  agenciamin: 'agencia',
  agencia: 'Agencia',
  certezaexcluiragencia: '¿Estás seguro de que deseas eliminar esta' + '\n' + 'agencia?',
  agenciaexcluida: 'Agencia eliminada con éxito!!!',
  informetipodocumento: 'Ingrese el tipo de documento',
  etapa: 'Etapa',
  etapamin: 'etapa',
  informeetapa: 'Ingrese el nombre de la etapa',
  parecerfinal: 'Opinión final',
  justificativamin: 'justificación',
  situacao: 'Situación',
  situacaomin: 'situación',
  inscricoesrecebidas: 'Inscripciones recibidas',
  inscricoesconcluidas: 'Inscripciones completadas',
  inscricoesvalidadas: 'Inscripciones validadas',
  prazoemdias: 'Fecha límite (días)',
  etapasselecao: 'Etapas de la selección',
  agendamentos: 'Calendarios',
  agendamento: 'Calendario',
  registros: 'Registros',
  formulariosassociados: 'Formularios asociados',
  avaliacoesassociadas: 'Evaluaciones asociadas',
  cadastreetapasfunil: 'Registre las etapas del embudo en Configuración / Embudo de Contratación',
  formulariosmin: 'formularios',
  nomeclinica: 'Nombre de la clínica / médico',
  informenomeclinica: 'Ingrese el nombre de la clínica / médico',
  informenomeagencia: 'Ingrese el nombre de la agencia',
  informedocumentoidentificacao: 'Ingrese el documento de identificación',
  pessoajuridica: 'Persona Física',
  pessoafisica: 'Persona Jurídica',
  selecionetipoagencia: 'Seleccione el tipo de la agencia',
  pesodaetapa: 'Peso de la etapa en el proceso de selección',
  pesoetapa: 'Peso de la etapa',
  anterior: 'Anterior',
  proximo: 'Próximo',
  formularioassociado: 'Formulario asociado',
  cadastreformulariorecrutamento: 'Registre uno formulario en Reclutamiento y Selección / Formularios',
  avaliacaoassociada: 'Evaluación asociada',
  cadastreavaliacaorecrutamento: 'Registre una evaluación em Reclutamiento y Selección / Evaluaciones',
  etapasdeselecao: 'Etapas de selección',
  agendaretapa: 'Arreglar etapa',
  candidatomin: 'candidato',
  gerenciaretapas: 'Gerenciar Etapas',
  aderenciavaga: 'Adherencia a la vacante',
  verdetalhesetapas: 'Ver detalles de las etapas',
  incluirnocalculodeaderencia: 'Incluir en el cálculo de adherencia a la vacante',
  atualizaaderecencia: 'Actualiza adherencia',
  atribuiretapa: 'Asignar etapa',
  cumprimentoprocesso: '% Cumplimiento del proceso',
  canceladavaga: 'Cancelada',
  congelada: 'Congelada',
  finalizada: 'Completada',
  comentario: 'Comentario',
  indefinido: 'Indefinido',
  parecerfinalprocesso: 'Opinión final del proceso',
  comentariosfinais: 'Comentarios finales',
  bancocandidatos: 'Banco de candidatos',
  checklistadmissao: 'Lista de control de admisión',
  candidatosememail: 'El candidato no informó del correo electrónico. No es posible enviar la lista de admisión.',
  candidatosememailcadastrado: 'El candidato no informó del correo electrónico. No es posible envíale un correo electrónico.',
  veroupreenchercheckhabilidades: 'Ver o completar la verificación de habilidades',
  parecermin: 'opinión',
  tipoagendamento: 'Tipo de calendario',
  envioform: 'Envio de formulario / evaluación',
  entrevista: 'Entrevista',
  examemedico: 'Examen médico',
  avaliacaomedica: 'Evaluación médica',
  avaliacaopsicologica: 'Evaluación psicologica',
  examepsicologico: 'Examen psicologico',
  exametoxicologico: 'Examen toxicologico',
  horarioinicio: 'Hora de inicio',
  horarioinicioopcional: 'Hora de inicio (opcional)',
  horariotermino: 'Hora de fin',
  horarioterminoopcional: 'Hora de fin (opcional)',
  tipolocal: 'Tipo de ubicación',
  clinicacadastradasistema: 'Clínica registrada en el sistema',
  localcadastradosistema: 'Ubicación registrada en el sistema',
  localnaocadastradosistema: 'Ubicación no registrada en el sistema',
  enviarmensagempara: 'Enviar mensaje a',
  lideranca: 'Liderazgo',
  clinicamin: 'clínica',
  mensagemlideranca: 'Mensaje de liderazgo',
  mensagemcandidato: 'Mensaje al candidato',
  informemensagem: 'Ingrese la mensaje',
  informeemaillideranca: 'Ingrese el correo electrónico de liderazgo',
  nomesliderancas: 'Nombre(s) de liderazgo(s)',
  verdetalhesagendamento: 'Ver detalles del calendario',
  congelarvaga: 'Congelar vacante',
  cancelarvaga: 'Cancelar vacante',
  finalizarvaga: 'Terminar vacante',
  descongelarvaga: 'Descongelar vacante',
  editaroualterarstatusvaga: 'Editar información o cambiar la situación de la vacante',
  certezacancelarvaga: '¿Estás seguro de que deseas cancelar esta' + '\n' + 'vacante?',
  certezafinalizarvaga: '¿Estás seguro de que deseas terminar esta' + '\n' + 'vacante?',
  vaganaoativa: 'La vacante seleccionada no está activa actualmente',
  mensagemaprovador: 'Mensaje para el aprobador',
  informeremensagemaprovador: 'Ingrese el texto de la mensaje que se enviará al aprobador',
  enviado: 'enviado con éxito!!!',
  numdiasprevistos: 'Número de días previstos para finalización',
  responsaveletapa: 'Responsable de la etapa',
  diasrealizacao: 'Días para realizar',
  informedescricaoetapa: 'Ingrese una descripción para la etapa',
  informenumdias: 'Ingrese el número de días',
  informetelefonecelular: 'Ingrese el teléfono móvil',
  informeemail: 'Ingrese el correo electrónico',
  selecionefuncionalidades: 'Seleccione las funciones a las que puede acceder el cargo en la aplicación Macro',
  permissoesmacro: 'Permisos en Macro',
  ecolaborador: '¿Eres colaborador?',
  admin_central_demarco: 'Administrador Central Demarco',
  administradorcentraldemarco: 'Administrador Central Demarco',
  erroaosalvarregistro: 'Error al guardar el registro',
  erroaoexcluirregistro: 'Error al eliminar el registro',
  exportarparaexcel: 'Exportar datos a Excel',
  cadastradopor: 'Registrado Por',
  datacadastro: 'Fecha de Registro',
  atualizadopor: 'Actualizado Por',
  dataatualizacao: 'Fecha de Actualización',
  transferencia: 'Traslado',
  emoji: 'Emoji',
  significadoemojis: 'Significado de los emojis',
  informesignificadoemoji: 'Ingrese el significado del emoji',
  odio: 'odio',
  desapontamento: 'Decepción',
  neutro: 'Neutral',
  bom: 'Bien',
  excelente: 'Estupendo',
  significadoemoji: 'Significado del emoji',
  mesesperiodoconcessivo: 'Número de meses del período de concesión',
  informeperiodoconcessivo: 'Ingrese el número de meses del período de concesión',
  periodo: 'Período',
  erroafastamento: 'No se pudo insertar el registro. Colaborador en licencia en la período/datos informado',
  erroausencia: 'No se pudo insertar el registro. Colaborador ausente en la período/datos informado',
  erroferias: 'No se pudo insertar el registro. Colaborador de vacaciones en la período/datos informado',
  copia: 'Copia',
  datainicioperiodo2invalida: 'La fecha de inicio del período 2 debe ser posterior al final del período 1',
  datainicioperiodo3invalida: 'La fecha de inicio del período 3 debe ser posterior al final del período 2',
  dataterminoperiodoinvalida: 'La fecha de finalización del período debe ser posterior a la fecha de inicio',
  periodoconcessivoinvalido: 'El número de meses del período de concesión debe ser mayor que 0',
  sebrasil: 'si Brasil',
  existepessoavinculadaaodocumento: 'Hay una persona vinculada a este documento',
  limiterespostas: 'Número máximo de opciones seleccionadas en la respuesta',
  selecionenomaximo: 'Seleccione un máximo de',
  opcoesmin: 'opciones',
  nessaquestao: 'en esta pregunta',
  informelimiterespostas: 'Ingrese el número máximo de opciones que se pueden seleccionar',
  limiterespostainvalido: 'El número máximo de opciones seleccionadas debe ser mayor o igual a 1 y menor o igual al número de opciones de respuesta',
  planilha: 'Planilla',
  escolhaplanilha: 'Elige una planilla o arrástrala aquí...',
  treinamentodemarcoacademy: 'Es una formación de Demarco Academy',
  cargouousalarioinvalido: 'El cargo o salario debe ser diferente al actual',
  novosalarionaopodesermenor: 'El nuevo salario no puede ser inferior al salario actual',
  exportardadosresumidos: 'Exportar datos de resumen',
  exportardadoscompletos: 'Exportar datos completos',
  considerarcalculoabsenteismo: 'Considere al calcular el absentismo',
  considerarcalculoturnover: 'Considere al calcular la rotación',
  importaradmissoes: 'Importar admisiones',
  importar: 'Importar',
  dadosimportados: 'Datos importados con éxito!!!',
  registrosnaoimportados: 'Registros no importados',
  IsWorkdayNotExist: 'jornada de trabajo no encontrada',
  isNotEmpty: 'campo(s) obligatorio(s) no completado(s)',
  isBoolean: 'debe ser SI o NO',
  isTrue: 'debe ser SI',
  isString: 'debe ser un valor textual',
  isPhoneNumber: 'formato de número de teléfono incorrecto',
  isTimeString: 'formato de hora incorrecto',
  isDateString: 'formato de fecha incorrecto',
  isUrl: 'debe ser una dirección URL',
  isNumber: 'debe ser un valor numérico',
  isPositive: 'Debe ser un número positivo',
  max: 'no puede ser mayor que el límite especificado',
  min: 'no puede ser inferior al límite especificado',
  isValidOption: 'debe ser una opción válida',
  isArray: 'debe ser una lista separada por ,',
  arrayContains: 'debe contener solo opciones válidas',
  arrayUnique: 'todos los elementos de la lista deben ser únicos',
  arrayNotEmpty: 'la lista no puede estar vacia',
  IsPositionNotExist: 'cargo no encontrado',
  IsReasonNotExist: 'razón de admisión no encontrada',
  IsUnityNotExist: 'unidad no encontrada',
  IsBondNotExist: 'enlace contractual no encontrado',
  IsCompanyNotExist: 'empresa no encontrada',
  IsRoleNotExist: 'rol no encontrada',
  isPersonAlreadExists: 'registo duplicado o persona previamente admitida',
  isEmail: 'email inválido',
  vagasemcargo: '¡No se puede acceder a la vacante porque su cargo no se encontró en el sistema!',
  tiposausencias: 'Tipos de ausencias',
  motivosausencias: 'Razones de las Ausencias',
  informetipoausencia: 'Ingrese el tipo de ausencia',
  informedescricaotipoausencia: 'Ingrese la descripción del tipo de ausencia',
  certezaexcluirtipoausencia: '¿Estás seguro de que deseas eliminar este' + '\n' + 'tipo de ausencia?',
  motivoausencia: 'Razon de la ausencia',
  informemotivoausencia: 'Ingrese el motivo de la ausencia',
  cadastreumtipoausencia: 'Registrar un tipo de ausencia',
  motivo: 'Razón',
  consideracalculoabsenteismo: '¿Consideras el cálculo del absentismo?',
  exigedocumentacao: '¿Requiere documento de respaldo?',
  tiposmin: 'tipos',
  certezaexcluirmotivoausencia: '¿Está seguro de que desea eliminar esta' + '\n' + 'razon de la ausencia?',
  cadastreummotivoausencia: 'Registrar una razón de ausencia',
  formasdemissao: 'Moldes de Despido',
  tiposdemissao: 'Tipos de Despido',
  motivosdemissao: 'Razones de Despido',
  forma: 'Molde',
  informeformademissao: 'Ingrese el molde de despido',
  iniciativa: 'Iniciativa',
  informedescricao: 'Ingrese la descripción',
  certezaexcluirforma: `¿Estás seguro de que deseas eliminar este \n molde de despido?`,
  informetipodemissao: 'Ingrese el tipo de despido',
  formasdemisssaoassociadas: 'Moldes de despido asociados',
  formasmin: 'modes',
  certezaexcluirtipodemissao: `¿Estás seguro de que deseas eliminar este \n tipo de despido?`,
  formacadastrada: 'Molde de despido registrado con éxito!!!',
  formaatualizada: 'Molde de despido actualizado con éxito!!!',
  formaexcluida: 'Molde de despido eliminado con éxito!!!',
  motivoadmissao: 'Razón de admisión',
  motivodemissao: 'Razón de despido',
  informemotivodemissao: 'Ingrese la razón de despido',
  tiposdemisssaoassociados: 'Tipos de despido associados',
  motivodemissaoatualizado: 'Razón de despido actualizada con éxito!!!',
  motivodemissaocadastrado: 'Razón de despido registrada con éxito!!!',
  certezaexcluirmotivodemissao: `¿Estás seguro de que deseas eliminar esta \n razón de despido?`,
  motivodemissaoexcluido: 'Razón de despido eliminada con éxito!!!',
  formademissao: 'Molde de despido',
  placeholderbuscaadmissao: 'Ingrese un nombre, documento, fecha de admisión, cargo o estructura',
  placeholderbuscaafastamento: 'Ingrese un nombre, documento, cargo o tipo de licencia',
  placeholderbuscaausencia: 'Ingrese un nombre, documento, cargo, tipo o razón de la ausencia',
  placeholderbuscademissao: 'Ingrese un nombre, docuemnto, cargo, molde, tipo o razón del despido',
  placeholderbuscaferias: 'Ingrese un nombre, documento o cargo',
  placeholderbuscapromocao: 'Ingrese un nombre, documento, cargo, salario o tipo de ascenso',
  placeholderbuscaocorrencia: 'Ingrese un nombre, documento, cargo o tipo de incidente',
  placeholderbuscatransferencia: 'Ingrese un nombre, documento, cargo o razón del traslado',
  placeholderbuscacargo: 'Ingrese nombre o descripción del cargo',
  placeholderbuscaformulario: 'Ingrese nombre o descripción del formulario',
  placeholderbuscanomeformulario: 'Ingrese el nombre del formulario',
  placeholderbuscaavaliacao: 'Ingrese nombre o descripción de la evaluación',
  placeholderbuscavaga: 'Ingrese el nombre de la vacante, nombre o email del aprobador',
  placeholderbuscacandidato: 'Ingrese el nombre o documento del candidato',
  placeholderbuscaagencia: 'Ingrese el nombre de la agencia, ciudad, estado o país',
  placeholderbuscaclinica: 'Ingrese el nombre de la clinica, ciudad, estado o país',
  placeholderbuscatreinamento: 'Ingrese nombre, descripción, palabra clave o cuadrícula de la formación',
  placeholderbuscapesquisa: 'Ingrese nombre o descripción de la encuesta',
  placeholderbuscaciclo: 'Ingrese el nombre del plan de acción',
  media: 'Media',
  copiarregistrosde: 'Copiar registros de',
  formasdemissoes: 'Moldes de despidos',
  tiposdemissoes: 'Tipos de despidos',
  motivosdemissoes: 'Razones de despidos',
  tiposocorrencias: 'Tipos de incidentes',
  tipospromocoes: 'Tipos de ascensos',
  tipostransferencias: 'Tipos de traslados',
  copiarde: 'Copiar del cliente',
  copiarpara: 'Copiar al cliente',
  registromin: 'registro',
  importante: 'Importante',
  textocopiaregistros: 'Si no importa todos los registros a la vez, haga lo siguiente:',
  tiposausenciaantesmotivo: 'Importar tipos de ausencias antes de importar razones de ausencias',
  formademissaoantesdotipo: 'Importar moldes de despido antes de importar tipos de despido',
  tiposdemissaoantesmotivo: 'Importar tipos de despido antes de importar razones de despido',
  copiaconfiguracao: 'Copias de configuraciones',
  erroaocopiarregistro: 'Error al copiar registros',
  selecioneumoumaistiposderegistros: 'Seleccione uno o más tipos de registros',
  registroscopiados: 'Registros copiados con éxito!!!',
  copiados: 'copiados con éxito!!!',
  tiposafastamentos: 'Tipos de licencias',
  tiposjornada: 'Tipos de jornadas',
  tiposvinculo: 'Tipos de enlaces contractuales',
  tiposcontratacao: 'Tipos de contrataciones',
  visibilidadeseraperdida: `Se perderá la visibilidad de los datos \n vinculados a él!`,
  certezaexcluirunidade: `¿Estás seguro de que deseas eliminar esta \n estructura?`,
  condicionarexibicao: 'Enlace a una respuesta anterior',
  exibirse: 'Mostrar si la respuesta a la pregunta',
  foriguala: 'Es igual a',
  candidatonaoencontrado: 'No se encontró ningún candidato con el documento proporcionado!',
  tempomaximoemminutos: 'tiempo máximo para enviar respuestas (en min.)',
  administradorcliente: 'Administrador Cliente',
  admin_cliente: 'Administrador Cliente',
  tipocargo: 'Tipo de cargo',
  tiposcargos: 'Tipos de cargos',
  informetipocargo: 'Ingrese el tipo de cargo',
  informedescricaotipocargo: 'Ingrese la descripción del tipo de cargo',
  certezaexcluirtipocargo: '¿Estás seguro de que deseas eliminar este \n tipo de cargo?',
  nomecompleto: 'Nombre completo',
  informelink: 'Ingrese el enlace',
  link: 'Enlace',
  duracao: 'duración',
  informeCPFvalido: 'Ingrese un CPF válido',
  templatecertificado: 'Plantilla de Certificado',
  somenteimagempng: 'solamente imagen con extensión .png',
  naopodeexcluiretapavinculadaavaga: 'No puede eliminar este paso porque está vinculado a una o más vacantes',
  configuracoescliente: 'Configuraciones de clientes',
  modulosacessar: 'Módulos a los que accederá',
  modulosmin: 'módulos',
  testecomporatamental: 'Prueba de perfil de comportamiento',
  integracaogeg: 'Integra con Gente e Gestão',
  pesquisasecuritaria: 'Investigación de seguridad',
  realiza: 'Realiza',
  modulos: 'Módulos',
  configuracaocliente: 'configuración de cliente',
  somapontuacaoinvalida: 'La suma de la puntuación de las preguntas no puede ser diferente de la puntuación máxima de la evaluación. Suma de preguntas',
  cpfsebrasil: 'número CPF si eres de Brasil',
  erroetapaselecao: 'No se puede eliminar el registro porque está vinculado a una o más etapas de selección',
  arquivosabios: 'Resultado de la prueba del perfil de comportamiento de Sabios',
  concordareceberwhatsapp: '¿Aceptas recibir mensajes del proceso de selección a través de Whatsapp?',
  enviarwhatsapp: 'Enviar Whatsapp?',
  documentojaregistradoparavaga: 'Ya existe inscripción para esta vacante con el documento aportado',
  formularioavaliacaoprocessoseletivo: 'Formulario de evaluación del proceso de selección',
  descricaoformularioavaliacaoprocessoseletivo: 'En una escala del 1 al 10, donde 1 es el valor más bajo y el peor, ¿cuánto le indicaría nuestro proceso de selección a un amigo o familiar?',
  descricaonps: 'comenta tu respuesta',
  enviarnpsefeedback: 'Envíe NPS y procese comentarios',
  feedbackcandidatoaprovado: 'Mensaje para candidatos seleccionados',
  feedbackcandidatoreprovado: 'Mensaje para candidatos desaprobado',
  feedbackcandidatoindefinido: 'Mensaje a otros candidatos (banco candidato o estado indefinido)',
  resultadonetpromoterscoretitulo: 'Resultado de Net Promoter Score',
  npstotalrespostas: 'Respuestas totales',
  promotores: 'Promotores',
  neutros: 'Neutral',
  detratores: 'Detractores',
  filtrar: 'Filtrar',
  admitircandidato: 'Admitir candidato',
  possuitestecomportamental: '¿Esta vacante tiene una prueba de perfil de comportamiento?',
  certezaaprovarsemupload: '¿Realmente desea aprobar al candidato sin cargar el \n resultado de la prueba del perfil \n de comportamiento?',
  etapasmin: 'etapas',
  etapas: 'Etapas',
  tiposagendamento: 'Tipos de calendario',
  escolhaimagem: 'Elige una imagen o arrástrala aquí...',
  nomelink: 'Nombre del elance o texto que se mostrará',
  informenomelink: 'Ingrese el nombre del enlace o texto que se mostrará',
  uploadcurriculo: 'Carga currículum en el registro de candidatos',
  uploadcurriculoopcao: 'La carga de currículum será',
  opcionalmaiuscula: 'Opcional',
  curriculo: 'Currículum',
  escolhacurriculo: 'Elija un archivo de currículum o arrástrelo aquí ...',
  curriculomin: 'currículum',
  datainscricao: 'Fecha de suscripción',
  naopertenceagrade: 'No vinculado a ninguna cuadrícula',
  justificativasdemissao: 'Justificaciones de Despido',
  justificativademissao: 'Justificación de Despido',
  motivosdemisssaoassociados: 'Razones de despido associados',
  certezaexcluirjustificativademissao: `¿Estás seguro de que deseas eliminar esta \n justificación de despido?`,
  justificativademissaoatualizada: 'Justificación de despido actualizada con éxito!!!',
  justificativademissaocadastrada: 'Justificación de despido registrada con éxito!!!',
  justificativademissaoexcluida: 'Justificación de despido eliminada con éxito!!!',
  informejustificativademissao: 'Ingrese la justificación de despido',
  motivosdemissaoantesmotivo: 'Importar razones de despido antes de importar justificaciones de despido',
  informepis: 'Ingrese el PIS (si es Brasil)',
  cadastrados: ' registrados con éxito!!!',
  textoarquivos: 'Para guardar agregue el archivo AFDT o el archivo ACJEF o ambos',
  extensaotxt: 'La extensión del archivo debe ser txt',
  cadastradomin: 'registrado',
  dataupload: 'Fecha de carga',
  estes: 'estos',
  excluidos: 'eliminados con éxito!!!',
  atualizados: 'actualizados con éxito!!!',
  vernps: 'Ver NPS de',
  cargosantesetapas: 'Importar cargos antes de importar etapas de selección',
  ano: 'año',
  anomax: 'Año',
  anoreferenciamin: 'año de referencia',
  mesreferencia: 'mes de referencia',
  datafinalinvalida: 'La fecha de finalización debe ser posterior a la fecha de inicio',
  datainicial: 'Fecha de inicio',
  datafinal: 'Fecha de finalización',
  placeholderbuscahierarquia: 'Ingrese un nombre de líder o un nombre de subordinado de jerarquía',
  nomelider: 'Nombre del líder',
  estruturalider: 'Estructura del líder',
  nomeliderado: 'Nombre del subordinado',
  estruturaliderado: 'Estructura del subordinado',
  cargoliderado: 'Cargo del subordinado',
  lembreatualizarhiearquia: `Actualizar la jerarquía de liderazgo, \nsi corresponde!!!`,
  novahierarquia: 'Nueva jerarquía',
  liderados: 'Subordinados',
  lideradomin: 'subordinado',
  lidermin: 'líder',
  cadastreumalideranca: 'Registrar um líder para seleccionar',
  liderado: 'Subordinado',
  adicionar: 'Agregar',
  datapadrao: 'Fecha predeterminada',
  datapadraomin: 'fecha predeterminada',
  limpar: 'Borrar',
  datapadraoselecionados: 'fecha predeterminada para seleccionados',
  lideradosmin: 'subordinados',
  errodataadmissao: 'La fecha debe ser mayor que la fecha de inicio de de inicio de prestación del servicio del subordinado y el líder',
  erroeventocolaborador: 'El empleado tiene un evento en la fecha informada',
  cadastreumliderado: 'Registrar um subordinado para seleccionar',
  estahierarquia: 'esta jerarquía',
  aproveitarcandidatonavaga: 'Capturar candidato en la vacante',
  inscricaocopiada: 'Suscripción copiada con éxito!!!',
  aproveitarcandidato: 'Capturar candidato en otra vacante',
  etapascargo: 'Etapas por cargo',
  etapasantesselecaoporcargos: 'Importar cargos, formularios, evaluaciones y etapas de selección generales antes de importar etapas de selección por cargo',
  etapasporcargo: 'Etapas de selección por cargo',
  datafinalmaioratual: 'La fecha de finalización debe ser anterior a la fecha actual',
  lideradojatemlideranca: 'El subordinado ya tiene una jerarquía de liderazgo activa',
  etapaanterior: 'Etapa anterior',
  etapaprimeira: 'Esta es la primera etapa',
  tenteacessarnovamente: 'No se puede acceder al sistema. Por favor, inténtelo de nuevo más tarde',
  datanascimentomaioratual: 'La fecha de nacimiento debe ser anterior a la fecha actual',
  dataanoinvalido: 'El año de la fecha debe ser mayor que 1900',
  etapatempesquisasecuritaria: 'En esta etapa, se lleva a cabo la Investigación de Seguridad',
  verresultadopesquisasecuritaria: 'Ver resultado de la investigación de seguridad',
  enviarpesquisasecuritaria: 'Enviar investigación de seguridad',
  referenciaspessoais: 'Referencias Personales',
  referenciascomerciais: 'Referencias comerciales',
  statusperfilsecutirario: 'Estado del perfil de seguridad',
  datavalidade: 'Fecha de validez',
  dataenvio: 'Fecha de envio',
  protocolopesquisa: 'Protocolo de investigación',
  status: 'Estado',
  codigoperfil: 'Código perfil',
  observacoes: 'Comentarios',
  excluirimagem: 'Eliminar imagen',
  imagemsemnome: 'Imagen sin nombre',
  videosemnome: 'Video sin nombre',
  comperfilsecuritario: 'Con perfil de seguridad',
  carregardoformulario: 'Cargar respuestas del formulario',
  naoforamencontradasrespostasvalidas: 'No se encontraron respuestas válidas',
  outro: 'Otro',
  solicitarhorarioausencia: 'Solicitar hora de inicio y finalización',
  funcao: 'Rol',
  informefuncao: 'Ingrese la rol',
  informedescricaofuncao: 'Ingrese la descripción de la rol',
  certezaexcluirfuncao: '¿Estás seguro de que deseas eliminar esta' + '\n' + 'rol?',
  funcoes: 'Roles',
  funcoesmin: 'roles',
  funcaomin: 'rol',
  referenciasprofissionais: 'Referencias Profesionales',
  tiposdocumentos: 'Tipos de Documentos',
  nomedocumento: 'Nombre del documento',
  informenomedocumento: 'Ingrese el nombre del documento',
  informedescricaodocumento: 'Ingrese la descripción del documento',
  tipodocumento: 'Tipo de documento',
  certezaexcluirtipodocumento: `¿Estás seguro de que deseas eliminar este \n tipo de documento?`,
  cadastreumtipodocumento: 'Registre un tipo de documento',
  documentomin: 'documento',
  certezaexcluirdocumento: `¿Estás seguro de que deseas eliminar este \ndocumento?`,
  placeholderbuscadocumento: 'Ingrese el nombre del colaborador, estructura, el nombre del documento o la fecha de envío',
  placeholderbuscacolaborador: 'Ingrese el nombre del colaborador',
  ciclo: 'Ciclo',
  ciclomin: 'ciclo',
  elegiveis: 'Elegibles',
  comite: 'Comité',
  resultados: 'Resultados',
  acompanhamento: 'Seguimiento',
  enviowhatsappcarreira: 'Envío de whatsapp en el módulo de Desarrollo y Carrera',
  de: 'De',
  ate: 'A',
  informeclassificacao: 'Ingrese la clasificación',
  informevalorinicial: 'Ingrese el valor inicial',
  informevalorfinal: 'Ingrese el valor final',
  parametro: 'Parámetro',
  certezaexcluirparametro: `¿Estás seguro de que deseas eliminar este \n parámetro?`,
  valorincialmaiorigualzero: 'El valor inicial debe ser mayor o igual a 0',
  valorfinalmenorigualcem: 'El valor final debe ser menor o igual a 100',
  valorfinalmenorvalorinicial: 'El valor final debe ser mayor que el valor inicial',
  faixasdeclassificacaoinvalidas: '¡¡¡Aviso!!! Los rangos de clasificación deben comenzar en 0 y terminar en 100',
  intervalosdefaixasinvalidos: '¡¡¡Aviso!!! Los rangos de clasificación no están en la secuencia correcta',
  enviowhatsapprecrutamento: 'Envío de whatsapp en el módulo de Reclutamiento y Selección',
  cadastrar: 'Registrar',
  depositar: 'Depositar',
  consultar: 'Consultar',
  adicionardocumento: 'Agregar documento',
  excluirdocumento: 'Eliminar documento',
  depositado: 'depositado con éxito!!!',
  depositados: 'depositados con éxito!!!',
  workplace: 'Workplace',
  nomeciclo: 'Nombre del ciclo',
  tipoavaliacao: 'Tipo de evaluación',
  escolhanome: 'Elije un nombre',
  haveracomite: 'Habrá comité',
  tempodeempresa: 'Tiempo en la empresa',
  comites: 'Comitês',
  novociclo: 'Crear Un Nuevo Ciclo',
  gerarcomunicado: 'Generar Anuncio',
  certezaexcluirciclo: '¿Estás seguro de que deseas eliminar este \n ciclo?',
  zeroatresmeses: '0 a 3 meses',
  tresaseismeses: '3 a 6 meses',
  seisadozemeses: '6 a 12 meses',
  acimadedozemeses: 'Más de 12 meses',
  tipoavaliacaonoventa: '90°: El colaborador se evalúa a sí mismo - El liderazgo evalúa al colaborador',
  tipoavaliacaocentoeoitenta: '180°: El colaborador se evalúa a sí mismo - El liderazgo evalúa al colaborador - El colaborador evalúa el liderazgo',
  tipoavaliacaotrezentosesessenta: '360°: El colaborador se evalúa a sí mismo - El liderazgo evalúa al colaborador - El colaborador evalúa el liderazgo - Los invitados pueden evaluar',
  datainicioinvalida: 'La fecha de inicio debe ser mayor que la fecha actual',
  iniciodiautil: 'Todos los pasos deben comenzar en un día hábil.',
  datainicialmaiorquefinal: 'La fecha de inicio no puede ser mayor que la fecha de finalización',
  datafinalmenorqueinicial: 'La fecha de finalización no puede ser menor que la fecha de inicio',
  erroaocadastrarciclo: 'Se produjo un error al intentar guardar el ciclo',
  follow: 'Follow',
  temposempresamin: 'tiempos de casa',
  documentoselecionado: 'Documento seleccionado',
  candidatosememailesemoptin: 'El candidato no informó un correo electrónico y no aceptó recibir Whatsapp. No es posible enviarle mensajes.',
  salveowhatsapp: 'Guarde el Whatsapp de DMPeople en sus contactos, para facilitar la identificación de mensajes',
  naopodeagendarentrevistaeform: 'No es posible programar una Entrevista y Envío de formulario / evaluación simultáneamente al enviar Whatsapp',
  voltarparalista: 'Volver a la lista',
  certezaexcluirelegivel: `¿Está seguro de que desea eliminar este elegible?`,
  novoelegivel: 'Nuevo elegible',
  sucessoenvioemail: 'Correos electrónicos enviados correctamente',
  errosaoenviaremail: 'Error al enviar correos electrónicos',
  codigo: 'Código',
  informecodigocliente: 'Ingrese el código del cliente',
  informenomecliente: 'Ingrese el nombre del cliente',
  codigoclienteexiste: 'Ya existe un cliente con este código',
  nomeclienteexiste: 'Ya existe un cliente con este nombre',
  configuracoesacesso: 'Configuración de acceso',
  placeholderplanejamentocomite: 'Buscar por nombre, correo electrónico o fecha',
  colaboradores: 'Empleados',
  convidados: 'Invitados',
  convidadosExternos: 'Invitados externos',
  dataehorariorealizacao: 'Fecha / Hora de realización',
  listadeconfirmacao: 'Lista de confirmación',
  conferencia: 'Conferencia',
  ou: 'o',
  obrigadocontribuicao: 'Gracias por el aporte',
  comparecerevento: '¿Asistirás al evento?',
  evento: 'Evento',
  talvez: 'Quizás',
  tituloModalConfirmacao: 'Lista de confirmación',
  emespera: 'En espera',
  recusado: 'Rechazado',
  aceito: 'Aceptado',
  linkcomite: 'Link comité',
  certezaexcluircomite: '¿Estás seguro de que deseas eliminar este comité?',
  selecionecolaboradoresavaliados: 'Seleccione los colaboradores para ser evaluados',
  selecionepessoascomite: 'Seleccionar a las personas que participarón en el comité',
  desajaincluirforaorganizacao: '¿Quiere incluir personas ajenas a su organización?',
  informedatahorariocomite: 'Ingrese la fecha y hora del comité',
  comitefisicoouconferencia: '¿El comité se llevará a cabo en un lugar físico o mediante una conferencia?',
  informeurlcomite: 'Ingrese la url de la sala donde se realizará el comité',
  infomerlocalcomite: 'Informar del lugar donde se celebrará el comité',
  fisico: 'Físico',
  ambos: 'Ambos',
  colaboradoresmin: 'colaboradores',
  datarealizacao: 'Fecha de realización',
  erroaocadastrarcomite: 'Se produjo un error al intentar guardar el comité',
  horariofinalmaiorqueinicial: 'La hora de finalización debe ser mayor que la hora de inicio',
  comitemin: 'comité',
  urlinvalida: 'URL invalida',
  colaboradorsemlideranca: 'Hay colaboradores sin liderazgo registrado. \nNo serán incluidos en el ciclo. \n¿Le gustaría continuar?',
  oportunidademelhoria: 'Oportunidad de mejora identificada',
  motivoacao: 'Motivo de la acción',
  desenvolverpontencializarcompetencia:
    '¿Qué voy a hacer para desarrollar o mejorar la competencia identificada?',
  quempodeajudar: '¿Quién puede ayudarme?',
  selecionecolaborador: 'Seleccionar un colaborador',
  adcionarnovaacao: 'Agregar nueva acción',
  datafollow: 'Fecha de seguimiento',
  adicionarfollow: 'Agregar seguimiento',
  removerfollow: 'Eliminar seguimiento',
  acao: 'Acción',
  removeracao: 'Eliminar acción',
  erroaocadastrarplanodeacao: 'Error al registrar el plan de acción',
  novoplanoacao: 'Nuevo Plan De Acción',
  certezaexcluirplanoacao: '¿Estás seguro que deseas eliminar este plan de acción?',
  planoacaoexcluido: 'Plan de Acción eliminado con éxito!!!',
  planoacaocadastrado: 'Plan de Acción agregado con éxito!!!',
  erroaoexcluirplanoacao: 'Error al eliminar el plan de acción',
  semregistros: 'No hay registros para mostrar',
  elegivelexcluido: 'Elegible eliminado con éxito!!!',
  elegivelcadastrado: 'Elegible agregado con éxito!!!',
  carregandoelegiveis: 'La selección de elegibles está en progreso',
  errocarregarelegiveis: 'Error al seleccionar elegible',
  elegiveiscarregados: 'Selección de elegibles completados con éxito!!!',
  classificacoesepesos: 'Clasificaciones y Pesos',
  veracao: 'Ver acción',
  editarplanoacao: 'Editar Plan',
  follows: 'Follows',
  realizarfollow: 'Realizar el follow',
  semfollowsvalidos: 'Sin follow válido todavía',
  selecionefollow: 'Seleccione un follow',
  selecionefollowstatus: 'Seleccione los status',
  actionstatusnotstarted: 'No iniciado',
  actionstatuspending: 'En proceso',
  actionstatusdone: 'concluido',
  convite: 'Invitación',
  avaliados: 'Calificado',
  numerodiasprevistos: 'Número de días que se espera realizar',
  informenumerodias: 'Ingrese el número de días',
  diaspararealizacao: 'Días a realizar',
  certezaexcluiretapaciclo: '¿Estás seguro de que deseas eliminar esta \n etapa?',
  semautorizacao: 'No estás autorizado para realizar esta operación',
  semAutorizacaoAtualizarFormulario: 'No estás autorizado para cambiar este formulario',
  semAutorizacaoAlterarCamposFormulario: 'No estás autorizado para cambiar los campos creados por los usuarios administradores',
  configuracoesvisibilidade: 'Configuración de visibilidad',
  grupoempresas: 'Grupo de Empresas',
  ecossistema: 'Ecosistema',
  ecossistemamin: 'ecosistema',
  ecossistemas: 'Ecosistemas',
  ecossistemasmin: 'ecosistemas',
  nomeecossistema: 'Nombre del Ecosistema',
  informenomeecossistema: 'Ingrese el nombre del ecosistema',
  certezaexcluirecossistema: '¿Estás seguro de que deseas eliminar este ecosistema?',
  nomeecossistemaexiste: 'Ya existe un ecosistema con este nombre',
  existemunidadesnesseecossistema: 'No se puede eliminar. Hay unidades vinculadas a este ecosistema',
  novousuario: 'Nuevo usuario',
  placeholderbuscausuario: 'Ingrese el correo electrónico del usuario',
  pesquisar: 'Buscar',
  tiposusuarios: 'Tipos de usuarios',
  informetipousuario: 'Ingrese el tipo de usuario',
  informedescricaotipousuario: 'Ingrese la descripción del tipo de usuario',
  tipousuario: 'Tipo de usuario',
  certezaexcluirtipousuario: '¿Estás seguro de que deseas eliminar este tipo de usuario?',
  actionplan_duplicated: 'No es posible crear más de un plan de acción para el mismo colaborador por ciclo',
  jobstage_blocked_with_candidates: 'No es posible cambiar etapas de vacantes que tienen candidatos inscritos',
  acessototal: 'Acceso completo',
  somenteleitura: 'Solo leyendo',
  semacesso: 'Sin acceso',
  cadastrardocumentos: 'Registrar documentos',
  consultardocumentos: 'Consultar documentos',
  depositardocumentos: 'Depositar documentos',
  depositardocumento: 'Depositar documento',
  configuracoesgerais: 'Configuración general',
  controleferias: 'Control de vacaciones',
  documentosferias: 'Documentos de vacaciones',
  programacaoferias: 'Calendario de vacaciones',
  tipocontrato: 'Tipo de contrato',
  tipousuarioexiste: 'Este tipo de usuario ya existe!!!',
  dataalteracaovinculo: 'Fecha de cambio de enlace',
  novovinculo: 'Nuevo enlace',
  vinculoanterior: 'Enlace anterior',
  arquivosrelacionadosalteracao: 'Archivos relacionados con el cambio',
  tipovinculoanterior: 'Tipo de enlace anterior',
  tipovinculoatual: 'Tipo de enlace actual',
  certezaexcluirvinculacao: '¿Estás seguro de que deseas eliminar este enlace?',
  vinculacaoexcluida: 'Enlace eliminado con éxito!!!',
  vinculacaosalva: 'Enlace guardado con éxito!!!',
  dataalteracao: 'Fecha de cambio',
  certezaexcluirtipocontrato: '¿Estás seguro de que deseas eliminar este \n tipo de contrato?',
  tipocontratocadastrado: 'Tipo de contrato registrado con éxito!!!',
  tipocontratoatualizado: 'Tipo de contrato actualizado con éxito!!!',
  tipocontratoexcluido: 'Tipo de contrato eliminado con éxito!!!',
  placeholderbuscatipocontrato: 'Ingrese un nombre, documento, o tipo de enlace',
  emavaliacao: 'Em evaluación',
  datasuperioradmissiao: 'La fecha debe ser posterior a la fecha de admisión',
  previacampo: 'Esta es una vista previa de su campo',
  arrastecampos: 'Arrastra campos para construir tu formulario',
  construindoformulario: 'Forma de construcción',
  visaopublica: 'Vista pública',
  mostrar: 'Mostrar',
  ocultar: 'Esconder',
  seacondicaofor: 'Si la condición es',
  verdadeira: 'Verdadera',
  falsa: 'Falsa',
  condicional: 'Condicional',
  seocenarioabaixoacontecer: 'Si ocurre el siguiente escenario',
  infomenomecondicional: 'Ingrese el nombre condicional',
  condicionaisemcampos: 'Condicionales encampos',
  exibaouocultecampos: 'Mostrar u ocultar campos de formulario según las respuestas del usuario.',
  adcionarnovacondicional: 'Agregar nuevo condicional',
  adicionarregra: 'Añadir regla',
  adicionargrupo: 'Agregar grupo',
  escolhacampo: 'Elige el campo',
  camposmostradosdifentesdeocultados: 'Los campos a ocultar deben ser diferentes de los que se muestran',
  novoformulario: 'Crear un Nuevo Formulario',
  verformulario: 'Ver Formulario',
  nomedoformulario: 'Nome del Formulario',
  certezaexcluirformulario: '¿Estás seguro de que deseas eliminar este formulario?',
  textodeajuda: 'Texto de ayuda',
  textodedica: 'Texto del consejo',
  solteabaixo: 'Suelta los campos deseados abajo',
  campocontemcondicional: 'Este campo ya contiene condicional',
  and: 'Y',
  or: 'O',
  tipogrupo: 'Tipo de grupo',
  campoduplicadonaoadicionado: 'Condicional duplicado no agregado',
  certezaexcluircampoformulario: 'Este campo es parte de una condición. ¿Estás seguro de que deseas eliminar este campo?',
  certezaexcluirgrupoformulario: '¿Está seguro de que deseas eliminar este grupo?',
  linkinvalido: 'Link Inválido',
  autoCompleteColaborator: 'Seleccione el colaborador elegible',
  adicionartexto: 'Añadir texto',
  paragrafo: 'Párrafo',
  informeparagrafo: 'Informar párrafo',
  informepesoresposta: 'Ingrese el peso de la respuesta',
  mostrarcabecalho: '¿Quiere que el encabezado no se oculte durante las respuestas?',
  formulariosalvo: 'Formulario guardado con éxito!!!',
  avaliacaosalva: 'Evaluación guardada con éxito!!!',
  certezaexcluiravaliacao: '¿Está seguro de que deseas eliminar esta evaluación?',
  usuarioduplicado: 'Ya existe un usuario registrado con este correo electrónico',
  certezaexcluirusuario: '¿Estás seguro de que deseas eliminar este usuario?',
  reativarusuario: 'Reactivar usuario',
  inativarusuario: 'Inactivar usuario',
  certezainativarusuario: 'Estás seguro de que deseas desactivar este usuario?',
  usuarioinativado: 'Usuario desactivado con éxito!!!',
  erroaoinativarousuario: 'No se puede desactivar al usuario',
  usuarioreativado: 'Usuario reactivado con éxito!!!',
  certezaexcluirconfiguracao: '¿Estás seguro de que deseas eliminar esta configuración?',
  placeholderbuscadocumentocliente: 'Ingrese el nombre del documento o la fecha de envío',
  acessaanalytics: 'Tiene acceso a informes y / o dashboards de DMAnalytics',
  descricaodocargo: 'Descripción del cargo',
  etapasdeselecaodavaga: 'Etapas de selección de vacantes',
  requisicaodevaga: 'Requisición de vacante',
  cadastroavaliacoes: 'Registro de evaluaciones',
  cadastroformularios: 'Registro de formularios',
  listaciclos: 'Lista de ciclos',
  analisardocumentos: 'Analizar documentos',
  vinculardocumentos: 'Vincular documentos',
  parametrizar: 'Parametrizar',
  analisar: 'Analizar',
  numeroidentificacao: 'Número de identificación',
  numeroidentificacaocnpj: 'Número de identificación (CNPJ, si Brasil)',
  informenumeroidentificacao: 'Ingrese el número de identificación de la empresa',
  informenomeempresa: 'Ingrese el nombre de la empresa',
  homepage: 'Home page',
  informehomepageempresa: 'Ingrese la home page de la empresa',
  empresas: 'Empresas',
  periodicidade: 'Frecuencia',
  selecioneperiodicidade: 'Seleccione la frecuencia',
  naocorrencia: 'En la ocurrencia',
  diario: 'Diario',
  bimestral: 'Bimensual',
  trimestral: 'Trimestral',
  cadastral: 'Catastral',
  fiscal: 'Supervisor',
  gestao: 'Gestión',
  saudeeseguranca: 'Salud y Seguridad',
  trabalhista: 'Labor',
  selecionecategoria: 'Selecciona la categoría',
  complexidade: 'Complejidad',
  texto: 'Texto',
  comoobter: 'Cómo llegar',
  normasrelacionadas: 'Normas relacionadas',
  duvidasfrequentes: 'Preguntas frecuentes',
  links: 'Enlaces',
  removerlink: 'Eliminar enlace',
  adicionarlink: 'Agregar enlace',
  textos: 'Textos',
  removertexto: 'Eliminar texto',
  informetexto: 'Ingrese el texto',
  rotinadiaria: 'Rutina diaria',
  naohouveregistro: 'No hubo registros en el periodo reportado',
  naohouveoperacao: 'No hubo operación en el periodo reportado',
  periodosemregistro: 'Período sin registro',
  periodosemoperacao: 'Período sin operación',
  certezaexcluirrotinadiaria: '¿Estás seguro que deseas eliminar esta rutina diaria?',
  ocorrencia: 'Ocurrencia',
  selecioneocorrencia: 'Seleccionar ocurrencia',
  admissional: 'Admisión',
  monitoramento: 'Supervisión',
  demissional: 'Despido',
  selecionetipodocumento: 'Seleccionar tipo de documento',
  conternodossie: 'Contener en el expediente',
  novencimento: 'Al vencimiento',
  novencimentoadmissao: 'En la fecha de vencimiento posterior a la admisión',
  parametrizardocumentos: 'Parametrizar documentos',
  tiposempresas: 'Tipos de Empresas',
  tipoempresa: 'Tipo de empresa',
  informetipoempresa: 'Ingrese el tipo de empresa',
  informedescricaotipoempresa: 'Ingrese la descripción del tipo de empresa',
  certezaexcluirtipoempresa: '¿Estás seguro que deseas eliminar este tipo de empresa?',
  exigirdadosCNH: 'Requiere detalles de la licencia de conducir',
  avaliacaoRegraPontos: 'No pude salvar! La suma de puntos debe ser igual o menor que el valor máximo',
  confirmarPontosInsuficientes: 'La suma de los puntos es menor que el valor máximo. ¿Está seguro de que deseas guardar este formulario?',
  fieldIsRequired: '¡Este campo es obligatorio!',
  invalidFormat: '¡Formato inválido!',
  numberTooSmall: '¡El número es demasiado pequeño! Mínimo: {0}',
  numberTooBig: '¡El número es demasiado grande! Máximo: {0}',
  invalidNumber: 'Número invalido',
  invalidInteger: 'El valor no es un número entero.',
  textTooSmall: '¡La longitud del texto es demasiado pequeña! Actual: {0}, Mínimo: {1}',
  textTooBig: '¡La longitud del texto es demasiado grande! Actual: {0}, Máximo: {1}',
  thisNotText: '¡Esto no es un texto!',
  thisNotArray: '¡Esto no es una matriz!',
  selectMinItems: '¡Seleccione un mínimo de {0} artículos!',
  selectMaxItems: '¡Seleccione un máximo de {0} artículos!',
  invalidDate: '¡Fecha invalida!',
  dateIsEarly: '¡La fecha es demasiado temprana! Actual: {0}, Mínimo: {1}',
  dateIsLate: '¡La fecha es demasiado tarde! Actual: {0}, Máximo: {1}',
  invalidEmail: '¡Dirección de correo electrónico no válida!',
  invalidURL: '¡URL invalida!',
  invalidCard: '¡Formato de tarjeta no válido!',
  invalidCardNumber: '¡Numero de tarjeta invalido!',
  invalidTextContainNumber: '¡Texto no válido! No puede contener números o caracteres especiales',
  invalidTextContainSpec: '¡Texto no válido! No puede contener caracteres especiales',
  erroOpcoesIguais: 'Cada opción debe ser única',
  informeumaopcao: 'Ingresa al menos una opción',
  alteracoes: 'Cambios',
  empresaatual: 'Empresa actual',
  empresadestino: 'Empresa destino',
  unidadenaovinculadaaempresa: 'La unidad seleccionada no está vinculada a la empresa seleccionada',
  cadastreumaempresa: 'Registre una empresa',
  empresadestinoinvalida: 'La empresa de destino debe ser diferente de la empresa actual',
  empresaselecionadainvalida: 'La empresa seleccionada no está vinculada a la empresa seleccionada',
  cargoeousalario: 'Cargo e/o salario',
  funcaoatual: 'Rol actual',
  novafuncao: 'Nuevo rol',
  obrigatoriosecargotemfuncao: 'Obligatorio si el cargo tiene una o más roles vinculadas',
  certezaexcluircliente: '¿Estás seguro que deseas eliminar este cliente?',
  nomefantasia: 'Nombre comercial',
  informenomefantasiaempresa: 'Ingrese el nombre comercial de la empresa',
  certezaexcluirempresa: '¿Estás seguro que deseas eliminar esta empresa?',
  justificativaspadrao: 'Justificaciones por defecto',
  adicionarestruturasequencial: 'Agregar estructura secuencial',
  funcaoselecionadainvalida: 'El nuevo rol no puede ser el mismo que el rol actual',
  cadastreumafuncao: 'Registre un rol',
  datapromocaoanterioradmissao: 'La fecha de ascenso no puede ser anterior a la fecha de admisión del colaborador',
  datapromocaoanteriorinicioprestacao: 'La fecha de ascenso no puede ser anterior a la fecha de inicio de prestación del servicio del colaborador',
  papellideranca: 'Papel de liderazgo',
  passouserlider: 'Conviértete en un líder',
  deixouserlider: 'Dejó de ser líder',
  competencia: 'Competencia',
  datavencimento: 'Fecha de caducidad',
  tipodedocumento: 'Tipo de documento',
  tipodedocumentomin: 'tipo de documento',
  statusmin: 'status',
  statusnaoenviado: 'No enviado',
  statusdepositado: 'Depositado',
  statuscancelado: 'Cancelado',
  statusfinalizado: 'Terminado',
  enviardocumento: 'Enviar documento',
  verdocumento: 'Ver documento',
  editardocumento: 'Editar documento',
  datepicker_label_prevDecade: 'Década anterior',
  datepicker_label_prevYear: 'El año pasado',
  datepicker_label_prevMonth: 'Mes pasado',
  datepicker_label_currentMonth: 'Mes actual',
  datepicker_label_nextMonth: 'Proximo mes',
  datepicker_label_nextYear: 'Próximo año',
  datepicker_label_nextDecade: 'Próxima década',
  datepicker_label_today: 'Hoy dia',
  datepicker_label_selected: 'Fecha seleccionada',
  datepicker_label_noDateSelected: 'Sin fecha seleccionada',
  datepicker_label_calendar: 'Calendario',
  datepicker_label_nav: 'Calendario de navegación',
  datepicker_label_help: 'Utilice las teclas de flecha para navegar por el calendario',
  selecionarhoje: 'Seleccionar hoy',
  fechar: 'Cerrar',
  multiselect_placeholder: 'Seleccione una o más opciones',
  multiselect_select_label: 'Presiona enter para seleccionar',
  multiselect_select_group_label: 'Presione enter para seleccionar el grupo',
  multiselect_selected_label: 'Seleccionado',
  multiselect_deselect_label: 'Presiona enter para eliminar',
  multiselect_deselect_group_label: 'Presiona enter para deseleccionar el grupo',
  multiselect_max_elements: 'Máximo de {max} opciones seleccionadas. Primero elimine una opción seleccionada para seleccionar otra',
  feed: 'Feed',
  consultarcolaboradores: 'Consultar colaboradores',
  acessararquivos: 'Acceder a archivos',
  cadastrararquivos: 'Registrar archivos',
  baseconhecimento: 'Base de conocimientos',
  certezaexcluirgrade: '¿Estás seguro que deseas eliminar esta cuadrícula?',
  formulariocopiado: 'Formulario copiado con éxito!!!',
  avaliacaocopiada: 'Evaluación copiada con éxito!!!',
  dataadmissaoinvalida: 'La fecha de admisión debe ser menor o igual a la fecha actual',
  datafinalsuperiorinvalida: 'La fecha de finalización no puede ser anterior a la fecha de inicio',
  dataproximovencimento: 'Próxima fecha de caducidad',
  tolltipdataproximovencimento: 'Fecha en que se debe volver a depositar el documento',
  placeholderbuscanomedocumento: 'Ingrese el nombre del documento',
  formularioparametrizacao: 'Formulario de parametrización',
  trazerdadoscolaboradoresativosmes: 'Traer datos de colaboradores activos en el mes',
  trazerdadoscolaboradoresdesligados: 'Traer datos de empleados desconectados',
  aceitanaoaplicavel: 'Acepta "No Aplicable"',
  aceitavinculacao: 'Aceptar enlace',
  analisardocumento: 'Analizar documento',
  analisepelocliente: 'Opinión del cliente',
  reanalisepelocliente: 'Reanalizar por Cliente',
  gerabloqueio: '¿Generar bloque?',
  antecipavel: '¿Es anticipatorio?',
  tempovalidacao: 'Tiempo de validación',
  parametrizacaodocumento: 'Document parameterization',
  informenomearquivo: 'Ingrese el nombre del archivo',
  informedescricaoarquivo: 'Ingrese la descripción del archivo',
  palavraschavearquivo: 'Palabras claves',
  videorelacionado: 'Video relacionado',
  comodisponibilizar: 'Vinculado a',
  arquivosoulinks: 'Agregar al menos un archivo o elemento',
  arquivoporecossistemas: 'Enlace a ecosistemas',
  arquivoporclientes: 'Clientes, estructuras y cargos',
  placeholderbuscaarquivo: 'Ingrese un nombre, descripción o palabras clave del archivo',
  certezaexcluirarquivo: '¿Estás seguro de que deseas eliminar este archivo?',
  linkdoform: 'Link de Formulario',
  linkdowebsite: 'Link del Dashboard',
  linkdovideo: 'Link de Video',
  selecioneagrade: 'Seleccione la cuadrícula',
  informacoesporcliente: 'clientes, estructuras e cargos',
  selecioneempresa: 'Seleccionar empresa',
  selecionecargo: 'Seleccionar posición',
  responsavelanalise: 'Responsable del análisis',
  selecionecargoresponsavelporanalise: 'Seleccione el cargo responsable del análisis',
  atividadeespecial: 'Actividad especial',
  vinculacaodocumento: 'Enlace de documentos',
  resumidomin: 'resumido',
  completomin: 'completo',
  periodicidadedocumento: 'Periodicidad de presentación',
  categoriadocumento: 'Categoría de documento',
  complexidadeanalise: 'Complejidad de análisis',
  exibirdossiecolaborador: 'Mostrar en dossier de colaborador',
  justificativacancelamento: 'Justificación de cancelación',
  comoobterdocumento: 'Cómo obtener este documento',
  normasdocumento: 'Normas relacionadas con documentos',
  duvidasdocumento: 'Dudas sobre el documento',
  modelodocumento: 'Plantilla de documento',
  fichadegente: 'Hoja de la Gente',
  remover: 'Eliminar',
  conteudo: 'Contenido',
  conteudos: 'Contenido',
  conteudomin: 'contenido',
  conteudosmin: 'contenido',
  informenomeconteudo: 'Ingrese el nombre del contenido',
  informedescricaoconteudo: 'Ingrese la descripción del contenido',
  palavraschaveconteudo: 'Palabras clave',
  conteudosoulinks: 'Añadir al menos un contenido o elemento',
  escolhaconteudos: 'Elige uno o más contenidos o arrástralos...',
  certezaexcluirconteudo: '¿Está seguro de que desea eliminar este contenido?',
  placeholderbuscaconteudo: 'Ingrese un nombre, descripción o palabras clave de contenido',
  novaentrevista: 'Nueva entrevista',
  recuperacaosenha: 'Recuperación de contraseña',
  recuperarsenha: 'Recuperar contraseña',
  usuarionaoencontrado: 'Usurio no encontrado',
  linkrecuperasenha: 'Enlace para restablecer contraseña',
  termosdeuso: 'Términos de uso',
  aceitotermosdeuso: 'He leído y acepto las condiciones de uso',
  dossiedocolaborador: 'Expediente del empleado',
  statusativo: 'Activo',
  statusafastado: 'Fuera',
  statusausente: 'Ausente',
  statusferias: 'De vacaciones',
  statusdesligado: 'Desactivado',
  statusdemitido: 'Despedido',
  documentacao: 'Documentación',
  historicomovimentacao: 'Historial de movimientos',
  situacaoatualcolaborador: 'Estado actual del empleado',
  consultahierarquia: 'Consulta jerarquía',
  collaborator: 'Colaborador',
  leaderships: 'Liderazgos',
  subordinates: 'Subordinato',
  participantes: 'Participantes',
  selecioneparticipantes: 'Seleccione los participantes',
  liderancas: 'Liderazgos',
  avaliadoresdaetapa: 'Evaluadores de la etapa',
  avaliadonaetapa: 'Evaluado en etapa',
  selecioneavaliadores: 'Seleccione los evaluadores',
  selecioneavaliado: 'Seleccione el evaluado',
  adicionarlideradosdahierarquiadeestruturas: 'Seleccionar subordinatos de la jerarquía de la estructura del líder',
  adicionarlideradosforadahierarquiadeestruturas: 'Seleccionar subordinatos de otra estructura',
  considerarsomentenovasocorrencias: 'Considere solo ocurrencias realizadas después del registro del documento',
  naoaplicavel: 'No aplicable',
  criteriosdeaceitacao: 'Criterios de aceptación',
  criacaocamposanalise: 'Creación de campos de análisis',
  existeregistromovimentacao: 'Existen registros de movimiento de este empleado posterior al ingreso. Elimine estos registros antes de cancelar el registro de admisión.',
  aceitamaisdeumarespostaporusuario: 'Acepta más de una respuesta por usuario',
  analiseanaoanalisada: 'No analizado',
  analiseanalisada: 'Aprobado',
  analisecancelada: 'Cancelado',
  reanalisar: 'Reanalizar',
  documentopossuideposito: 'Hay depósitos para este documento. Si se cambia, los estados actuales se pueden actualizar. ¿Desea continuar?',
  analizadopor: 'Analizado por',
  dataanalise: 'Fecha del análisis',
  historico: 'Historial',
  btableemptytext: 'No hay registros para mostrar',
  datadeposito: 'Fecha del depósito',
  situacaocandidato: 'Estado de candidato',
  errocolaboradorativo: 'No se pudo eliminar esta estructura porque hay colaboradores activos vinculados a ella o a sus subestructuras',
  contemanalise: 'Analizar documento',
  'aviso-doc-nao-parametrizado': 'No se puede analizar el documento porque no está parametrizado',
  verjustificativarelacionamento: 'Ver justificación de cancelación',
  porfavoraguardesuasconfiguracoes: 'Por favor, mientras cargamos su configuración de inicio de sesión.',
  tipodedocumentoinvalido: `¡Extensión de documento no válida! Aceptado: {acceptTypes}`,
  statusexcluido: 'Excluido',
  parametrizacaotipodedocumentoexcluido: '¡Esta parametrización es para un documento eliminado y no se puede cambiar!',
  depositotipodedocumentoexcluido: '¡Este depósito es para un documento eliminado y no se puede cambiar!',
  analisetipodedocumentoexcluido: '¡Esta análisis es para un documento eliminado y no se puede cambiar!',
  statusdocumento: 'Estado del documento',
  datainiciomonitoramento: 'Fecha de inicio del seguimiento',
  certezacancelardocumento: '¿Está seguro de que desea cancelar este documento?',
  documentocancelado: 'Documento cancelado con éxito!!!',
  erroaocancelardocumento: 'Error al cancelar el documento',
  analisedocumento: 'Análisis de documentos',
  impossivelexibirdocumento: 'No es posible visualizar este documento, puede haber sido eliminado.',
  documentopossuianalise: 'Esta parametrización ya contiene análisis realizados y no se puede modificar',
  dadosdodepositante: 'Datos del depositante',
  dadosdocolaborador: 'Datos de empleados',
  horadeposito: 'Tiempo de depósito',
  deposito: 'Depósito',
  analise: 'Análisis',
  verhistorico: 'Ver historial',
  informecomentario: 'Ingrese el comentario',
  pesquisaeanonima: 'La encuesta es anónima',
  idadeinvalida: 'La edad del colaborador debe ser mayor o igual a 14 años',
  idadeadmissaoinvalida: 'La edad del colaborador debe ser mayor o igual a 14 años a la fecha de admisión',
  erroanoinferior1920: 'El año no puede ser inferior a 1920',
  datainicioinferioradmissao: 'La fecha de inicio debe ser mayor a la fecha de inicio de prestación del servicio del colaborador',
  datainiciosuperioratual: 'La fecha de inicio no puede ser posterior a la fecha actual',
  anosuperior2090: 'El año no puede ser mayor a 2090',
  datainferioradmissao: 'La fecha debe ser mayor a la fecha de admisión del colaborador',
  datainferiorprestacaoservicos: 'La fecha debe ser mayor a la fecha de inicio de prestación del servicio del colaborador',
  datasuperioratual: 'La fecha no puede ser posterior a la fecha actual',
  dataterminoperiodosuperior3anos: 'La fecha de finalización de las vacaciones no puede ser más de 3 años a partir de la fecha actual',
  desejadesvicularcliente: 'Si desvincula a cualquier cliente, todas las unidades de ese cliente que estén vinculadas al ecosistema se desvincularán. ¿Desea continuar?',
  analiseserelatorios: 'Análisis e Informes',
  analises: 'Análisis',
  admissoes: 'Admisiones',
  colaboradoresativos: 'Colaboradores activos',
  vercolaboradoresativos: 'Ver colaboradores activos',
  hierarquias: 'Jerarquías',
  rotinasdiarias: 'Rutinas diarias',
  tiposdecontratos: 'Tipos de contratos',
  depositos: 'Depósitos',
  consultadedocumentos: 'Consulta de documentos',
  selecioneanovaestruturasuperior: 'Seleccione la nueva estructura superior',
  alterarestruturasuperior: 'Cambiar la estructura superior',
  existecandidatonavaga: 'No es posible incluir, excluir o cambiar de etapa, ya que hay candidatos registrados para la vacante',
  pesquisacomresposta: 'La encuesta tiene una respuesta vinculada, ¡no se dpuede cambiar!',
  statusexcluida: 'Eliminada',
  inspecoes: 'Inspecciones',
  novainspecao: 'Nueva inspección',
  diautilfechamento: 'Día hábil para cerrar',
  informediautilfechamento: 'Ingrese el día hábil para cerrar',
  diautilinvalido: 'El día debe ser mayor que 0 y menor que 22',
  certezaexcluirvinculo: `¿Está seguro de que deseas eliminar este \nenlace contractual?`,
  selecionesituacao: 'Seleccione el estado del candidato',
  agendamentodoisoumaiscandidatos: 'Horario para dos o más candidatos',
  candidatossememailcadastrado: 'Hay candidatos que no facilitaron su email. No puedo enviarles mensajes.',
  candidatossememailesemoption: 'Hay candidatos que no informaron el correo y no aceptaron recibir Whatsapp. No puedo enviarles mensajes.',
  agendamentosemsegundoplano: '¡¡Horarios que se están haciendo en segundo plano!!!',
  horarios: 'Horarios',
  copiarparatodos: 'Copia para todos',
  isValidCPF: 'CPF invalido',
  isValidBirthDate: 'fecha de nacimiento invalida',
  isValidAdmissionDate: 'fecha de admisión invalida',
  datapromocaoposteriordataatual: 'La fecha de ascenso no puede ser posterior a la fecha actual',
  numerodediasaposadmissao: 'Número de días después de la admisión',
  informenumerodedias: 'Ingrese el número de días',
  ecossistemadocumento: 'Ecosistema de documentos',
  selecioneecosistema: 'Selecione o ecossistema',
  realizado: 'Realizado',
  realizada: 'Realizada',
  organizacaoempresa: 'Organización / Empresa',
  trazerlistadecolaboradoresativos: 'Traer lista de colaboradores activos en la referencia',
  documentocopiado: '¡¡Documento copiado con éxito!!!',
  aceitadepositovinculado: 'Acepta depósito vinculado',
  colaboradoresativosnacompetencia: 'Colaboradores activos en la competencia',
  selecionepelomenosumapendencia: 'Seleccione al menos una competencia',
  depositarpara: 'Depósito para',
  depositarparatodos: 'Depósito para todos',
  analisecomdepositovinculado: 'Este análisis se refiere a un depósito vinculado, es decir, analizará para varias unidades. ¿Desea continuar?',
  dadosdodeposito: 'Depositar datos',
  aceiteparcial: 'Aceptación parcial',
  veranaliseparcial: 'Ver análisis parcial',
  colaboradoresnaoconforme: 'Empleados no conformes',
  selecioneostatusunidade: 'Seleccione el estado de cada unidad',
  apenasnovosregistrosaposdocumento: 'Considerar solo los registros realizados después del registro del documento',
  alterarregistrodeletado: 'No se puede cambiar un registro eliminado',
  acidentedoencadotrabalho: 'Accidente / Enfermedad Profesional',
  salariomaiorpromocaorecente: 'El salario no puede ser mayor que la promoción más reciente',
  geracobrancadecat: 'Genera requisito CAT',
  admissaocontraida: 'Adm',
  demissaocontraida: 'Des',
  geracobrancadedocumento: 'Genera requisito de documento',
  afastamentosuperiordias: 'Ausencia de más de 30 días',
  unidadescomexigenciadodocumento: 'Unidades con requisito de documento',
  hasWorkLeave: 'El empleado está fuera en este momento',
  AlreadyExistsWorkleaveError: 'El empleado está fuera en este momento',
  hasAbsence: 'El empleado está ausente durante este período',
  AlreadyExistsAbsenceError: 'El empleado está ausente durante este período',
  hasVacation: 'El empleado está de vacaciones en este momento',
  AlreadyExistsVacationError: 'El empleado está de vacaciones en este momento',
  hasTransfer: 'El empleado tiene un traslado durante este período',
  hasRise: 'El empleado tiene un ascenso en este momento',
  hasOccurrence: 'El empleado tiene un incidente en este momento',
  hasBondChange: 'El empleado tiene un cambio de bonos en este momento',
  hasMovement: 'No se puede guardar el registro. Hay movimientos posteriores a este movimiento',
  demissoesnaocadastradas: 'Despidos no registrados',
  ausenciasnaocadastradas: 'Ausencias no registrados',
  superioratodoscolaboradores: 'La fecha debe ser posterior a la fecha de inicio de prestación del servicio de todos los empleados',
  demissoesemsegundoplano: '¡¡¡Se están haciendo despidos de fondo!!!',
  ausenciasemsegundoplano: '¡¡¡Se están haciendo ausencias de fondo!!!',
  demissoescadastrado: 'Despidos registrados con exito!!!',
  ausenciascadastrado: 'Ausencias registrados con exito!!!',
  tipodedemissaoobrigatorio: 'El tipo de despido es obligatorio',
  motivodemissaoobrigatorio: 'El motivo de despido es obligatorio',
  feriasultrapassamolimiteconcessivo: 'Las vacaciones lanzadas superan el límite concesional, ¿quieres continuar?',
  acessaconsultaworkplace: 'Accede a una lista de colaboradores en Workplace, aunque no sea colaborador',
  naopossivelcancelaradmissao: 'No es posible cancelar la entrada. Hay documentos depositados para el empleado.',
  nomecampoexiste: 'Ya existe un campo con ese nombre',
  baixarmodelo: 'Descargar plantilla',
  modeloimportacaoadmissao: 'importacion_admision_plantilla',
  errodemissao: 'No se pudo insertar el registro. El colaborador fue despedido o se le canceló la admisión',
  exercepapeldegestor: 'Desempeñar el papel de administrador',
  EmployeeIsFiredError: 'No se puede registrar. Empleado despedido o con admisión cancelada',
  erroexclusaomovimentacaoposterior: 'No se pudo eliminar el registro. Hay movimientos posteriores a este movimiento',
  AbsenceDateCannotBeLessThanAdmissionDateError: 'No se puede registrar. La fecha de inicio de la ausencia es anterior a la fecha de admisión',
  AbsenceDateCannotBeLessThanServiceProvisionDateError: 'No se puede registrar. La fecha de inicio de la ausencia es anterior a la fecha de prestación del servicio',
  reabrirvaga: 'Reabrir vacante',
  desejareabrir: `¿Estás seguro de que deseas reabrir \n esta vacante?`,
  datadecorte: 'Fecha de corte',
  datacontabilizartempocasa: 'Fecha considerada para contabilizar el tiempo empleado',
  asrespostasseraoanonimas: 'Las respuestas serán anónimas',
  possuiplanejamento: 'Tiene planeación',
  tipoderesposta: 'Tipo de respuesta',
  autoresposta: 'Responde sobre ti mismo',
  respostasobreoutrocolaborador: 'Respuesta sobre otro colaborador',
  respostasobreoutrocolaboradorgeg: 'Respuesta sobre los empleados de Gente e Gestão',
  prioridadedeavaliacao: 'Evaluation priority',
  gestor: 'Manager',
  tempomaximodevesermaiorquezero: 'El tiempo máximo de respuesta debe ser mayor o igual a 0',
  exigeinformacoesatestado: 'Requiere información del certificado',
  concordareceberwhatsappdaempresa: 'Aceptas recibir mensajes de la empresa a través de Whatsapp',
  jaexisteregistroferiasparaessecolaborador: 'There is already a vacation record for this employee in the informed vesting period. Update the existing record.',
  configuracaodociclo: 'Configuración de Ciclo',
  execucaodociclo: 'Ejecución de Ciclo',
  ciclodeavaliacoes: 'Ciclo de Evaluación',
  datadefim: 'Fecha final',
  etapasdociclo: 'Etapas del ciclo',
  regraseparametrosdociclo: 'Reglas y parámetros',
  funcaoobrigatorio: 'El campo de rol es obligatorio',
  funcoesobrigatorio: 'El campo roles es obligatorio',
  funcaoobrigatorioclient: 'La función es obligatoria',
  algumregistrovazio: 'Todos los campos obligatorios deben ser completados',
  solicitacoes: 'Solicitudes',
  disponibilizarformworkplace: 'Hacer que el formulario esté disponible en Workplace',
  desejaformwokplace: '¿Realmente desea que este formulario esté disponible en Workplace?',
  removerformworkplace: 'Eliminar del Workplace',
  desejaremoverformwokplace: '¿Realmente desea eliminar este formulario de Workplace?',
  erroaodisponibilizarworkplace: 'No se pudo vincular este formulario a Workplace',
  responderformulario: 'Llenar el formulario',
  identificacaodocolaborador: 'identificación del empleado',
  formularionaoencontrado: 'formulario no encontrado',
  semplanejamentosativos: 'Sin planes activos',
  usuarionaoidentificado: 'usuario no identificado',
  formdataforadoperiodopermitido: 'El formulario no puede ser respondido. La fecha está fuera del período permitido',
  formrespondidomaisdeumavez: 'El formulario no puede ser respondido más de una vez',
  aceitarespostasdatadetermino: 'Aceptar respuestas después de la fecha de finalización',
  arquivorelacionados: 'Archivos relacionados',
  tempoduracao: 'Duración',
  informetempoduracao: 'Ingrese la duración',
  quaispessoasparticiparao: '¿Qué personas participarán ?',
  nomedocolaborador: 'Nombre del colaborador',
  documentodocolaborador: 'Documento del colaborador',
  nomedousuario: 'Nombre del usuario',
  documentodousuario: 'Documento del usuario',
  estruturadocolaborador: 'Estructura del colaborador',
  nomedoautor: 'Nombre del autor',
  documentodoautor: 'Documento del autor ',
  estruturadoautor: 'Estructura del autor',
  naoeliderougerente: 'No está autorizado a contestar este formulario. Debe ser un líder o gerente',
  formrespondidomaisdeumavezmesmapessoa: 'Formulário não pode ser respondido mais de uma vez for a mesma pessoa',
  selecioneumparticipante: 'Seleccione un participante',
  enviarplanejamentoporparticipante: 'Enviar planificación por participante',
  liderancaimediata: 'Liderazgo inmediato',
  informetempomaximo: 'Ingresa el tiempo máximo',
  duracaodevesermaiorquezero: 'El tiempo de duración debe ser mayor o igual a 0',
  confirmarcolaboradoressememail: 'Hay uno o más colaboradores sin email asociado. ¿Desea continuar?',
  colaboradoressememail: 'El colaborador no tiene email asociado',
  colaboradoressememailetelefone: 'El colaborador no tiene email ni teléfono asociado',
  horariodaresposta: 'Hora de respuesta',
  'interview-form-answer': {
    nomedocolaborador: 'Nombre del colaborador entrevistado',
    documentodocolaborador: 'Documento del colaborador entrevistado',
    estruturadocolaborador: 'Estructura del colaborador entrevistado'
  },
  'health-form-answer': {
    nomedocolaborador: 'Nombre del colaborador inspeccionado',
    documentodocolaborador: 'Documento del colaborador inspeccionado',
    estruturadocolaborador: 'Estructura del colaborador inspeccionado'
  },
  avaliadonomedocolaborador: 'Nombre del colaborador evaluado',
  avaliadodocumentodocolaborador: 'Documento del colaborador evaluado',
  avaliadoestruturadocolaborador: 'Estructura del colaborador evaluado',
  formulariodisponibilizado: 'Formulario disponible exitosamente',
  enviowhatsappformulario: 'Envio de whatsapp para acceder a formularios',
  confirmarcolaboradoressememailetel: 'Hay uno o más colaboradores sin email ni teléfono asociado. ¿Desea continuar?',
  funcoesparticipantes: 'Roles participantes',
  quaisfuncoesdevemparticipar: '¿Qué roles deben participar?',
  apartirde: 'A partir del',
  colaboradorsememailesemoptin:
    'El empleado no informó el correo electrónico y no aceptó recibir Whatsapp. No puedo enviarle mensajes.',
  colaboradoressememailesemoptin:
    'Hay uno o más colaboradores que no informaron correo electrónico y no accedieron a recibir Whatsapp. No puedo enviarles mensajes.',
  formularioremovido: 'Formulario eliminado con éxito',
  permitirusuariocomumaddcampo: 'Permitir que los usuarios regulares agreguen campos',
  campoadmin: 'Campo creado por administrador - No editable',
  horariossaoobrigatorios: 'Horarios son obligatorios',
  reprovarcandidato: 'Desaprobar candidato',
  certezareprovarcandidato: '¿Estás seguro de que deseas desaprobar a este candidato?',
  candidatoreprovado: 'Candidato desaprobado con éxito!!!',
  erroaoreprovarcandidato: 'Error ao reprobar candidato!!!',
  erroexclusaocolaboradornaoexiste: 'No se puede eliminar el registro porque el colaborador está desconectado',
  esteformularioeglobal: 'Este formulario es global',
  visibilidade: 'Visibilidad',
  vincularatodososclientes: 'Vincular a todos los clientes',
  vincularaumoumaisecossistemas: 'Vincular a um ou mais ecossistemas',
  usuarionaocolaborador: 'No eres colaborador de este cliente',
  gerademandadereposicao: 'Genera demanda de reemplazo',
  colaboradoressubstituidos: 'Empleados a ser reemplazados',
  informecolaboradoressubstituidos: 'Ingrese a los empleados que serán reemplazados',
  colaboradormaiorquequantidadevaga: 'No es posible seleccionar más colaboradores que el número de vacantes',
  informeadatadefechamento: 'Ingrese la fecha de cierre',
  errodatadefechamentoinvalida: 'Fecha de cierre inválida',
  erropessoasouestruturasativas: 'No es posible eliminar esta empresa porque hay colaboradores o estructuras activas asociados a ella',
  prontuariodocolaborador: 'Registro de colaborador',
  tipodemovimentacao: 'Tipo de movimiento',
  selecioneotipodemovimentacao: 'Seleccione el tipo de movimiento',
  templatededatacomhorario: 'DD/MM/YYYY [a las] HH:mm',
  dataexclusao: 'Fecha de Eliminación',
  excluidopor: 'Eliminado Por',
  erroempresasouestruturasativas: 'No es posible eliminar este cliente porque hay empresas o estructuras activas asociados a él',
  days_after_admission: 'Días después de la admisión',
  admitted_until: 'Admitido hasta la fecha',
  general: 'General',
  aplicacaociclo: 'Aplicación de ciclo',
  aplicacaociclomin: 'aplicación de ciclo',
  tiposdevinculos: 'Tipos de enlaces',
  tiposdevinculosmin: 'tipos de enlaces',
  informediasaposadmissaoiniciaociclo: 'Informar a los dias del ingreso se inicia el ciclo',
  admitidosate: 'Admitido hasta',
  selecionedatacorteadmitidos: 'Seleccione fecha de corte para admitido',
  datainicialmenorqueciclo: 'La fecha de inicio del paso no puede ser anterior a la fecha de inicio del ciclo',
  impossivelcadastrarciclo: 'No es posible registrar este ciclo. Hay empleados sin una jerarquía de liderazgo registrada',
  excluirelegiveis: 'Eliminar elegibles',
  naoutilizair: 'No usar índice de resultados en este ciclo',
  porcentagemir: '(% IR - Índice de resultados)',
  valorinicialporcentagem: 'Ingrese el valor inicial en %',
  valorfinalporcentagem: 'Ingrese el valor final en %',
  parecerderesultadoir: 'Opinión de Resultados IR',
  parecerderesultadoirmin: 'opinión de resultados IR',
  informeparecerderesultadosir: 'Informar el nombre de la opinión de resultados IR',
  arvoredeclassificacoes: 'Árbol de clasificación',
  irciclo: 'IR',
  iccciclo: 'ICC',
  indicederesultados: 'Índice de resultados',
  indicederesultadosmin: 'índice de resultados',
  result_is_not_unique: {
    results_index: 'No puede haber dos opiniones de resultados con el mismo nombre',
    compliances_index: 'No puede haber dos opiniones de resultados con el mismo nombre',
    classification_tree: 'No puede haber dos clasificaciones con el mismo nombre'
  },
  pairs_is_not_unique: 'No puede haber dos clasificaciones con el mismo IR y ICC',
  invalid_from_or_to_field: 'Los rangos de porcentaje no pueden superponerse a registros ya creados',
  certezaexcluirir: '¿Está seguro de que desea eliminar este índice de resultados?',
  porcentagemicc: '(% ICC - Índice de Cumplimiento del Comportamiento)',
  parecerderesultadoicc: 'Opinión de Resultados ICC',
  parecerderesultadoiccmin: 'opinión de resultados ICC',
  informeparecerderesultadosicc: 'Informar la opinión de los resultados de la ICC',
  pesodaautoavaliacao: 'Peso de autoevaluación',
  informepesoautoavaliacao: 'Informar el peso (%) de la Autoevaluación',
  pesodaavaliacaodedesempenho: 'Peso de evaluación de rendimiento',
  informeopesodaavaliacaodedesempenho: 'Informar el peso (%) de la Evaluación de Desempeño',
  valormenorque0oumaiorque100: 'El valor no puede ser menor que 0 ni mayor que 100',
  sum_of_weights_different_from_100: 'La suma de los pesos debe sumar 100',
  indicedeconformidadecomportamental: 'Behavioral compliance index',
  indicedeconformidadecomportamentalmin: 'behavioral compliance index',
  certezaexcluiricc: '¿Está seguro de que desea eliminar este índice de cumplimiento de comportamiento?',
  certezaexcluirarvoredeclassificao: '¿Está seguro de que desea eliminar esta clasificación?',
  result_index_is_required_in_this_cycle: 'Se requiere el índice de resultados en este ciclo',
  this_cycle_no_accept_result_index: 'Este ciclo no usa índice de resultados',
  parecerderesultados: 'Opinión de resultados',
  alterarliderancaparacolaborador: 'Quiero cambiar liderazgo a empleado',
  alterarliderancadeumgrupo: 'Quiero cambiar el liderazgo de un grupo',
  liderancanociclo: 'Liderazgo en el ciclo',
  selecioneoliderparaociclodeavaliacoes: 'Seleccione el líder para el ciclo de evaluación',
  personalizarhierarquia: 'Personalizar jerarquía',
  placeholderbuscalideranca: 'Ingrese un líder de la jerarquía o el nombre de un subordinado',
  valormaiorquezero: 'El valor debe ser mayor que cero',
  estecolaboradornaotemliderança: 'Este colaborador não tem liderança',
  hierarquiapersonaliza: 'Jerarquía personalizada',
  certezaexcluircadastrodelegivel: `¿Está seguro de que desea eliminar este registro elegible?`,
  subordinate_and_cycle_leadership_must_be_different: 'El subordinado debe ser diferente del líder',
  subordinate_already_exists_for_this_cycle: 'Ya existe una jerarquía personalizada en este ciclo para este empleado',
  subordinate_hierarchy_not_found: 'Jerarquía actual no encontrada',
  subordinate_is_invalid: 'Este empleado ya no pertenece a este cliente',
  cycle_leadership_is_invalid: 'El líder ya no pertenece a este cliente',
  cycle_leadership_not_is_leadership: 'El empleado seleccionado como líder ya no es líder',
  hierarquiaspersonalizadasemsegundoplano: '¡¡Las jerarquías personalizadas se están haciendo en segundo plano!!!',
  hierarquiaspersonalizadascadastradas: '¡¡Jerarquías personalizadas registradas correctamente!!!',
  selecioneumcolaborador: 'Seleccione un colaborador',
  capacitacaoesensibilizacao: 'Formación y Sensibilización',
  autoavaliacao: 'Autoevaluación',
  avaliacaodedesempenho: 'Evaluación del desempeño',
  avaliacaoderesultados: 'Evaluación de Resultados',
  reuniaodegente: 'Reunión de personas',
  planodeacao: 'Plan de Acción',
  followplanodeacao: 'Seguimiento del plan de acción',
  naovourealizaretapanociclo: 'No voy a realizar este paso en el ciclo',
  informediasaposadmissaoiniciaaetapa: 'Informar a los cuantos días del ingreso comienza la etapa',
  selecioneoformularioassociadoaetapa: 'Seleccione el formulario asociado con este paso',
  informeclassificacoesqueparticipamdaetapa: 'Informar clasificaciones que participan en la etapa',
  classificacoes: 'Calificaciones',
  classificacoesquerealizampdi: 'Clasificaciones que realizan PDI',
  classificacoesquerealizampr: 'Calificaciones que realizan PR',
  informeasclassificacoesquerealizampdi: 'Informar las clasificaciones que realizan PDI',
  informeasclassificacoesquerealizampr: 'Informar las calificaciones que realizan PR',
  alteraclassificacao: 'Cambiar clasificación',
  quantidadedefollows: 'Número de follows',
  informeaquantidadedefollows: 'Ingrese el número de follows',
  informeasclassificacoesqueparticipamdaetapa: 'Informar las clasificaciones que participan en la etapa',
  datadeiniciodo: 'Fecha de inicio de',
  datadeterminodo: 'Fecha de finalización de',
  proximaetapa: 'Siguiente paso',
  datafinalmaiorquefinalciclo: 'La fecha de finalización no puede ser posterior a la fecha de finalización del ciclo',
  etapajainiciada: 'La fecha de inicio no se puede cambiar, ya que este paso ya comenzó',
  valordevesermaiorqueum: 'El valor debe ser mayor o igual a 1',
  quantosdiasaposadmissaoocorreraofollow: '¿En cuántos días después de la admisión ocurrirá el seguimiento?',
  certezaexcluirirs: 'Se eliminarán los índices de resultados registrados. ¿De verdad quieres borrarlos?',
  nomedaetapa: 'Nombre del paso',
  etapaspersonalizadasdociclo: 'Pasos de ciclo personalizados',
  incluirnocalculodeaderenciadociclo: 'Incluir en el cálculo de adherencia al ciclo',
  enviaretapaparatodososcolaboradores: 'Enviar el paso a todos los colaboradores',
  enviaretapaparaclassificacoesselecionadas: 'Enviar paso a clasificaciones seleccionadas',
  informeonomedaetapa: 'Informar el nombre del paso',
  selecioneaetapaanteriordociclo: 'Selecciona el paso anterior del ciclo',
  selecioneestruturasenviaretapa: 'Seleccione una o más estructuras a las que desea enviar este paso',
  selecioneumoumaiscargosparaenviaretapa: 'Seleccione uno o más trabajos para enviar este paso',
  selecionequaiscolaboradoresenviaretapa: 'Seleccione a qué empleados se enviará este paso',
  selecioneasclassificacoesqueparticipamdaetapa: 'Seleccionar las clasificaciones que participan en la etapa',
  days_after_admission_invalid: 'El número de días posteriores al ingreso no puede ser menor al informado en el paso anterior',
  days_after_admission_invalid_trainingandawareness: 'El número de días posteriores al ingreso no puede ser inferior al informado en la inscripción al ciclo',
  invalid_end_date: 'La fecha de finalización debe ser menor o igual a la fecha de finalización del ciclo',
  invalid_start_date: 'La fecha de inicio no pude ser anterior a la fecha de inicio del paso anterior',
  invalid_start_date_trainingandawareness: 'La fecha de inicio de la etapa no puede ser anterior a la fecha de inicio del ciclo',
  other_steps_depend_on_this: 'Otros pasos dependen de esto',
  start_date_greater_than_child: 'La fecha de inicio debe ser anterior a la fecha de inicio de los pasos dependientes',
  datafollowinferioraantecessor: 'La fecha de inicio debe ser menor o igual a la fecha de inicio de los seguimientos posteriores',
  datafollowsuperioraposterior: 'La fecha de inicio del seguimiento no pude ser anterior a la fecha de inicio del seguimiento anterior',
  datainicialmenorqueatual: 'La fecha de inicio del paso no puede ser anterior a la fecha actual',
  dataciclomaiorqueatual: 'La fecha de inicio del ciclo debe ser mayor que la fecha actual',
  diasfollowsuperioraposterior: 'Los días posteriores al ingreso deben ser mayores o iguales a los días posteriores al ingreso del seguimiento anterior',
  diasfollowinferioraantecessor: 'Los días posteriores a la admisión deben ser menores o iguales a los días posteriores a la admisión de seguimientos posteriores',
  dataterminoalteradainvalida: 'Como el ciclo ya inició, la fecha de finalización solo se puede cambiar a una fecha posterior a la informada en el registro del ciclo',
  cicloincompleto: 'Por favor complete el registro de las etapas de este ciclo antes de intentar duplicar o copiar',
  clienteinvalido: 'Cliente inválido',
  has_answers_in_this_step: 'El formulario asociado a este paso ya contiene respuestas, no es posible cambiarlo',
  ciclocopiadosucesso: 'Ciclo copiado con éxito!!!',
  cicloduplicadosucesso: 'Ciclo duplicado con éxito!!!',
  same_name_as_custom_step: 'Ya existe otro paso personalizado con este nombre',
  same_name_as_default_step: 'No se pueden guardar los pasos personalizados con el nombre de los pasos predeterminados',
  incluir: 'Incluir',
  informediasaposadmissaoterminaciclo: 'Informar el límite de días posteriores al ingreso para participar en el ciclo',
  valordevesermaiorouigualosdiasaposadmissao: 'El límite de días posteriores al ingreso debe ser mayor o igual al valor inicial',
  days_after_admission_limit_invalid: 'El número de días después de la admisión no puede exceder el límite ingresado en el registro del ciclo',
  daysAfterAdmission_greater_than_child: 'La cantidad de días después de la admisión debe ser menor que la cantidad de días después de la admisión para los pasos que dependen de este',
  previous_step_invalid: 'Paso anterior inválido',
  has_custom_steps_as_child: 'No es posible deshabilitar este paso, ya que hay pasos personalizados que dependen de él',
  this_client_has_collaborators_without_hierarchy: 'El cliente seleccionado para copiar tiene colaboradores sin jerarquía de liderazgo registradas',
  nomedalideranca: 'Nombre del liderazgo',
  selecioneosliderados: 'Seleccione los seguidores',
  mensagemcomunicadociclo: 'Comenzará nuestro desarrollo y evaluación de carrera, uno de los principales procesos de Gestión de Personas en nuestra empresa.\n\nEl proceso de desarrollo y carrera tiene como objetivo analizar el desempeño de los colaboradores, a través de la autoevaluación, evaluación de líderes, verificación de logros de metas, a través de indicadores generar una clasificación y proponer acciones encaminadas al desarrollo personal.\n\nPrepárate, porque el proceso se llevará a cabo en las siguientes fechas:',
  ola: 'Hola',
  nomecolaboradorvinculadoaociclo: 'Nombre del empleado vinculado al ciclo',
  colaboradorsemhierarquia: 'No se puede agregar este colaborador elegible sin una jerarquía de liderazgo definida',
  naopodeexcluirformvinculoaetapa: 'No es posible eliminar este formulario porque está vinculado a una o más etapas del ciclo',
  informacoesdociclo: 'Información del ciclo',
  enviodeformulariosporetapa: 'Envío de formularios por pasos',
  etapaspadraodociclo: 'Pasos de ciclo estándar',
  statusetapaspadraodociclo: 'Estado de los pasos predeterminados del ciclo',
  selecioneociclo: 'Seleccione lo ciclo',
  naoliderougerentenaoelegivelciclo: 'No es un liderazgo/gerente y no es elegible para un ciclo',
  naoepossivelresponderpoisexistemetapasnaoconcluidas: 'No es posible responder a este empleado, ya que hay pasos anteriores que no se han completado',
  faroldeavaliacoes: 'Faro de Evaluaciones',
  on_delete_rule_and_parameter_cycle_started: 'No es posible eliminar este registro porque el ciclo ya comenzó',
  on_update_rule_and_parameter_cycle_started: 'No es posible cambiar este registro porque el ciclo ya comenzó',
  this_record_is_being_used_in_classifications_tree: 'No se puede eliminar este registro ya que se está utilizando en el árbol de clasificaciones',
  salvareimprimir: 'Guardar e imprimir',
  classificacaocomir: 'No se pueden cambiar los índices de resultados mientras haya clasificaciones',
  ircicloiniciado: 'No es posible cambiar los índices de resultados porque el ciclo ya comenzó',
  selecionelideranca: 'Seleccione el liderazgo',
  selecionestatusdesejavisualizar: 'Seleccione los estados que desea ver',
  realizacao: 'Realización',
  datalimite: 'Fecha limite',
  sensibilizacao: 'Sensibilización',
  okforadoprazo: 'OK - Fuera de Tiempo',
  inputdemetas: 'Entrada de objetivos',
  exercepapeldegestordeciclo: 'Desempeña el papel de administrador del ciclo de evaluación',
  cycle_information_step_status_not_started: 'Paso no iniciado',
  cycle_information_step_status_progress: 'Paso en progreso',
  cycle_information_step_status_complete: 'Paso completado',
  statusetapascustomizadadociclo: 'Pasos de ciclo personalizados',
  cycle_information_modal_title_send_step_form: 'Enviar formulario - {formName} por participante',
  cycle_information_modal_title_view_step_form: 'Formulario - {formName}',
  cycle_information_sent_to_eligible_success: 'Formulario enviado a los participantes',
  selecionequaisparticipantesenviaretapa: 'Seleccione a qué participantes se enviará este paso',
  selecione_um_ou_mais_participantes: 'Seleccione uno o más participantes',
  meta: 'meta',
  atingimento: 'logro',
  informeameta: 'informar el objetivo',
  abreviacaoexemplo: 'Ej.',
  calcular: 'calcular',
  avaliacoeseinspecoes: 'Evaluaciones y Inspecciones',
  etapatemtestecomportamental: 'En esta etapa, se realiza una prueba de perfil de comportamiento',
  historicodocolaborador: 'Historial de colaboradores',
  cicloanterior: 'Ciclo anterior',
  consultaravaliacoes: 'Consultar valoraciones',
  cicloatual: 'Ciclo actual',
  registrodareuniao: 'Registro de la reunión',
  classificacaocicloanterior: 'Clasificación ciclo anterior',
  classificacaosugerida: 'Calificación sugerida',
  classificacaofinal: 'Clasificación final',
  comentariodareuniao: 'Comentarios de la reunión',
  resposta: 'Respuesta',
  selecioneaprimeiraavaliacao: 'Seleccione la primera revisión',
  selecioneasegundaavaliacao: 'Seleccione la segunda revisión',
  compararavaliacoes: 'Comparar calificaciones',
  voltarparavisualizacaounica: 'Volver a la vista individual',
  classificacaofinaldociclo: 'Clasificación final del ciclo',
  informeaclassificacaofinaldociclo: 'Informar la clasificación final del ciclo',
  comentariosdareuniao: 'Comentarios de la reunión',
  participantesdareuniaodegente: 'Personas conociendo participantes',
  the_cycle_has_ended: 'Este ciclo ya terminó',
  cycle_not_found: 'Ciclo no encontrado',
  step_not_found: 'Paso no encontrado',
  performance_evaluation_soma_dos_pesos_diferente_de_cem: 'La suma de los pesos es diferente de 100%',
  performance_evaluation_meta_cadastrada_com_sucesso: 'Meta registrada con éxito',
  performance_evaluation_meta_atualizada_com_sucesso: 'Meta actualizado con éxito',
  performance_evaluation_meta_salva_com_sucesso: 'Meta guardada con éxito',
  performance_evaluation_metas_salvas_com_sucesso: 'Metas guardadas con éxito',
  performance_evaluation_metas_cadastradas_com_sucesso: 'Metas registradas con éxito',
  performance_evaluation_meta_excluida_com_sucesso: 'Meta eliminada con éxito',
  certezaexcluirmeta: '¿Está seguro de que desea eliminar esta meta?',
  placeholderbuscaplanoacao: 'Ingrese el nombre del plan de acción',
  dataaprovacao: 'Fecha de aprobación',
  aprovarreprovarplano: 'Aprobar/Desaprobar Plan',
  cadastrodoplano: 'Registro del plan',
  analisedecausa: 'Análisis de causas',
  motivodaacao: 'Razón de la acción',
  tipodaacao: 'Tipo de acción',
  porquefazeressaacao: '¿Por qué voy a hacer esta acción?',
  comorealizaracao: '¿Cómo voy a realizar esta acción?',
  desenvolvercompetencia: 'Desarrollar competencia',
  potencializarcompetencia: 'Mejorar la competencia',
  onthejob: 'On the job',
  interacaonetworking: 'Interacción / Networking',
  treinamentoestudo: 'Formación / Estudio',
  outros: 'Otro',
  informeotipodeacao: 'Informar el tipo de acción',
  informemotivoacao: 'Informar el porqué de la acción',
  adicionarnovaacao: 'Agregar nueva acción',
  aprovarplano: 'Aprobar Plan',
  concluircadastro: 'Registro completo',
  causasbaixodesempenho: 'Causas del bajo rendimiento',
  analisecincoporques: 'Análisis de los 5 porqués',
  selecioneoformulario: 'Seleccione el formulario',
  selecioneasquestoesquedescrevemobaixodesempenho: 'Seleccione las preguntas que describen el bajo rendimiento',
  apenassalario: 'Salario solamente',
  mapeamento: 'mapeo',
  mapeamentoassociado: 'Mapeo asociado',
  potencialbrutoparaaprovacao: 'Potencial bruto mínimo para la aprobación (%)',
  textopotencialbrutoaprovacao: 'Valor mínimo de potencial bruto para que el candidato pueda avanzar a la siguiente fase del proceso de selección',
  informepotencialbrutoparaaprovacao: 'Ingrese la cantidad mínima de potencial bruto para aprobación',
  matchparaaprovacao: 'Match mínima para la aprobación (%)',
  textomatchaprovacao: 'Valor mínimo de match para que el candidato pueda avanzar a la siguiente fase del proceso de selección',
  informematchparaaprovacao: 'Ingrese el valor mínimo de match para la aprobación',
  potencialbruto: 'Potencial bruto',
  match: 'Match',
  cliqueaquiparaverorelatorio: 'Haz clic aquí para ver el informe',
  oscincoporques: 'LOS 5 POR QUÉ',
  causasdoproblema: 'Causas del problema',
  porque: 'Por qué',
  aprovar: 'Aprobar',
  reprovar: 'Reprobar',
  reprovaracao: 'Reprobar acción',
  aprovaracao: 'Aprobar acción',
  justificativadareprovacao: 'Justificación de la desaprobación',
  itemsalvo: '¡Elemento guardado!',
  concluir: 'Finalizar',
  acaoreprovadacomsucesso: '¡¡¡Acción reprobada con éxito!!!',
  certezaexcluiracao: '¿Está seguro de que desea eliminar esta acción?',
  cadastrecausasnaabacausasdobaixodesempenho: 'Registrar las causas en la pestaña "Causas del bajo rendimiento"',
  analisedos5porquesconcluida: '¡¡¡Análisis de los 5 porqués completado con éxito!!!',
  digitepelomentos3caracteres: 'Escriba al menos 3 caracteres para buscar el nombre o documento del colaborador',
  placeholderbuscacolaboradornomeedocumento: 'Escriba el nombre o documento del colaborador',
  acaoreprovada: 'Acción Reprobada',
  historicodeacoesreprovadas: 'Historial de Acciones Reprobadas',
  naohaacoesreprovadas: 'No hay acciones reprobadas',
  datadaacao: 'Fecha de Acción',
  hierarquiapersonalizadanaocriada: 'Jerarquías personalizadas no creadas',
  cargosvinculados: 'Cargos asociados',
  clientnaopossuinenhumcargo: 'El cliente no tiene ninguna de las posiciones',
  selecioneumfollow: 'Seleccionar un seguimiento',
  justificativadareprogramacao: 'Justificación de la reprogramación',
  statusdaacao: 'Estado de la acción',
  anexar: 'Adjuntar',
  acaonoprazo: 'A tiempo',
  acaoconcluida: 'Terminado',
  acaocancelada: 'Cancelado',
  acaoatrasada: 'Retrasado',
  comentariosdofollow: 'Seguir comentarios',
  baixararquivo: 'Descargar archivo',
  datainicioinferioracriacaodaacao: 'La fecha de inicio no puede ser anterior a la fecha de aprobación del plan',
  recomendavelseraposreuniaodegente: 'Al enviar una etapa por clasificación, se recomienda que la etapa anterior sea una reunión de personas o posterior',
  vocejarealizouestaetapa: 'Ya has completado este paso. No es posible volver a responder este formulario.',
  dadosdodocumento: 'Datos del documento',
  alterarsituacao: 'Cambiar situación',
  alterarsituacaoparatodos: 'Cambiar estado para todos',
  alterarsituacaodoscandidatos: 'Cambiar el estado del candidato',
  alterarsituacaopara: 'Cambiar el estado a',
  candidatosselecionados: 'Candidatos seleccionados',
  candidatosatualizadoscomsucesso: '¡¡¡Candidatos actualizados exitosamente!!!',
  erroaoatualizarsituacaodecandidatos: 'Se produjo un error al intentar actualizar el estado del candidato',
  parecerfinaldaetapa: 'Opinión final de la etapa',
  monitoramentomin: 'monitoreo',
  informepelomenosumaformadecontato: 'Ingrese al menos una forma de contacto, correo electrónico o teléfono',
  emailIsRequired: 'El correo electrónico es un campo obligatorio',
  emailOrPhoneIsRequired: 'Ingrese al menos una forma de contacto (correo electrónico o teléfono)',
  emailMustBeAnEmail: 'Formato de correo electrónico no válido',
  hasProfileNotAnswered: 'Hay campos no completados en la pestaña Perfil',
  hasExperienceNotAnswered: 'Hay campos sin completar en la pestaña Experiencia',
  dataterminomonitoramento: 'Fecha de finalización del seguimiento',
  datafinalmenorouigualatual: 'La fecha de finalización del seguimiento debe ser inferior o igual a la fecha actual',
  datafinaldomonitoramentoinferioradatadeinicio: 'La fecha de finalización del seguimiento no puede ser menor o igual a la fecha de inicio del seguimiento',
  colaborators_linked_to_the_unity: 'No se puede actualizar esta estructura porque hay colaboradores activos vinculados a ella',
  has_movement_after_monitoring_end_date: 'No fue posible actualizar esta estructura, ya que hay movimientos posteriores a la fecha de finalización del seguimiento',
  unity_already_inactive: 'No fue posible actualizar esta estructura, ya que ya está inactiva',
  has_unity_child_monitored: 'No fue posible actualizar esta estructura, todavía hay estructuras secundarias monitoreadas.',
  unidadenaoemaismonitorada: 'No se pueden guardar datos, la estructura ya no está monitoreada',
  erroaoselecionarestruturanaomaismonitorada: 'No fue posible seleccionar la estructura, ya que ya no está monitoreada',
  naoepossivelatualizarpoisaestruturanaoemaismonitorada: 'No es posible actualizar porque la estructura ya no está monitoreada',
  unityNoLongerMonitored: 'No es posible guardar porque la estructura ya no está monitoreada',
  oldUnityNoLongerMonitored: 'No es posible guardar porque la estructura actual ya no se supervisa',
  newUnityNoLongerMonitored: 'No es posible guardar porque la estructura de destino ya no está supervisada',
  unidadeinativa: 'Unidad inactiva',
  subordinateUnityNoLongerMonitored: 'No se puede guardar porque la estructura subordinada ya no está monitoreada',
  leadershipUnityNoLongerMonitored: 'No se puede guardar porque la estructura del líder ya no está monitoreada',
  oldUnityNoLongerMonitoredOnDelete: 'No se puede eliminar porque la estructura actual ya no está monitoreada',
  newUnityNoLongerMonitoredOnDelete: 'No se puede eliminar porque la estructura de destino ya no está monitoreada',
  unitsNoLongerMonitored: 'No es posible guardar porque una de las estructuras ya no está siendo monitoreada',
  unityNoLongerMonitoredOnDelete: 'No se puede eliminar porque la estructura ya no está monitoreada',
  datainicialinferiora: 'Fecha de inicio menor que ',
  adicionardata: 'Agregar fecha',
  datainferioraminima: 'No es posible guardar, ya que hay una fecha de inicio inferior al mínimo permitido',
  datafinalnaopreenchida: 'No es posible guardar, ya que hay una fecha de finalización del seguimiento sin completar',
  datafinalsuperiordataincial: 'No es posible guardar, hay algún intervalo con la fecha de finalización mayor que la fecha de finalización',
  intervalossesobrepondo: 'No se puede guardar, hay rangos de fechas de monitoreo superpuestos',
  datainicialmenorqueafinaldeanteriores: 'La fecha de inicio debe ser mayor que la fecha de finalización del seguimiento anterior',
  opcionalsetelefone: 'opcional, si proporcionas el número de teléfono',
  opcionalseemail: 'opcional, si proporcionas el email',
  erro: 'Error',
  funcaoobrigatoria: 'No es posible guardar, ya que la función es obligatoria',
  alteracaoinvalidadocampo: 'No tienes permiso para cambiar todos los campos',
  categoryCNHNotEmpty: 'La categoría CNH es un campo obligatorio',
  registerCNHNotEmpty: 'El número de registro CNH es un campo obligatorio',
  firstCNHNotEmpty: 'La primera fecha de calificación es un campo obligatorio',
  emissionCNHNotEmpty: 'La fecha de emisión CNH es un campo obligatorio',
  validityCNHNotEmpty: 'La validez CNH es un campo obligatorio',
  renachCNHNotEmpty: 'El número de Renach es un campo obligatorio',
  mirrorCNHNotEmpty: 'El número espejo CNH es un campo obligatorio',
  statusCNHNotEmpty: 'El estado CNH es un campo obligatorio',
  stateCNHNotEmpty: 'UF CNH es un campo obligatorio',
  cityCNHNotEmpty: 'El municipio CNH es un campo obligatorio',
  driverStateNotEmpty: 'El lugar de nacimiento del conductor de UF es un campo obligatorio',
  driverCountryNotEmpty: 'El país de nacionalidad del conductor es un campo obligatorio',
  driverCityNotEmpty: 'El municipio de nacimiento del conductor es un campo obligatorio',
  contabilizarnomodulodeperformance: 'Cuenta en el módulo de rendimiento',
  erroadmissao: 'No se puede insertar el registro. El empleado fue contratado en la fecha/período informado',
  dismissalTypeIsMandatory: 'No se puede insertar el registro. El tipo de despido es obligatorio.',
  dismissalReasonIsMandatory: 'No se puede insertar el registro. El motivo del despido es obligatorio.',
  dismissalReasonIsInvalid: 'No se puede insertar el registro. El motivo del despido no es válido.',
  dismissalReasonJustificationIsMandatory: 'No se puede insertar el registro. La justificación de despido es obligatoria.',
  resignationDateIsAfterCurrentDate: 'No se puede insertar el registro. La fecha de despido no puede ser posterior a la actual.',
  resignationDateIsBeforeAdmissionDate: 'No se puede insertar el registro. La fecha de despido no podrá ser inferior a la fecha de inicio de prestación del servicio del trabajador.',
  permitegerarocorrenciasnopreenchimento: 'Permite generar ocurrencias en el llenado',
  cadastrarumaocorrencia: 'Registrar una incidencia',
  occurrenceform: 'Formulario de Incidente',
  indicadorcorrespondentenomodulodeperformance: 'Indicador correspondiente en el módulo de desempeño',
  selecioneoindicador: 'Seleccionar indicador',
  datainicioprestacaoservicos: 'Fecha de inicio de prestación del servicio',
  informedatainicioprestacaoservicos: 'Ingresse la fecha de inicio de prestación del servicio',
  datainicioprestacaoservicosinvalida: 'La fecha de inicio de la prestación del servicio no es válida',
  datainicioprestacaoservicosinferioradmissao: 'La fecha de inicio de prestación del servicio no puede ser inferior a la fecha de admisión',
  hamovimentacoesanterioresadatainicioprestacaoservicos: 'No pueden existir movimientos anteriores a la fecha de inicio de prestación del servicio',
  campoinvalido: 'Campo no válido',
  isSameOrBeforeNow: 'La fecha no puede ser mayor que la fecha actual',
  datainicioprestacaoservicosemaiorquehoje: 'La fecha de inicio de prestación del servicio debe ser menor o igual a la fecha actual',
  skills_acquisition_process: 'Proceso de adquisición de habilidades',
  days_after_admission_or_rise: 'Días después de la admisión o promoción',
  informediasaposadmissaooupromocaoiniciaociclo: 'Ingrese el límite de días posteriores al ingreso o promoción para participar en el ciclo',
  feriascadastradas: 'No es posible guardar, ya que hay vacaciones registradas para el empleado',
  planejamentoemmassajaenviado: 'La planificación masiva solo se puede enviar una vez',
  consultarenvios: 'Consultar envíos',
  digiteumnomeoudocumento: 'Ingrese un nombre o documento',
  tipodeenviodoplanejamento: 'Tipo de envío',
  planejamentoenviadoemmassa: 'En masa',
  planejamentoenviadoseparadamente: 'Individual',
  selecionetipodeenvio: 'Selecciona el tipo de envío',
  horaenvio: 'Tiempo de envío',
  relatoriodeenvios: 'Informe de envío',
  vagaduplicada: 'Vacante duplicada con éxito!!!',
  is_not_possible_to_change_the_parent: 'Etapa anterior no válido',
  cadastros: 'Registros',
  academy: 'Academy',
  replanejamento: 'Replanificación',
  treinamentosagendados: 'Formaciones Programados',
  listadepresenca: 'Lista de asistencia',
  calendario: 'Calendario',
  selecioneonivelnagrade: 'Seleccione el nivel en la grilla',
  dataprevista: 'Fecha prevista',
  naoobrigatorio: 'No obligatorio',
  obrigatoriedade: 'Obligatoriedad',
  aderenciageral: 'Adherencia General',
  aderenciaprincipal: 'Adherencia principal',
  vocepodeselecionaronivelnoqualotreinamentoestaalocadoparafiltraralistadetreinamentos: 'Puede seleccionar el nivel en el que se asigna la capacitación para filtrar la lista de capacitación.',
  permitircadastrodetreinamentonestenivel: 'Permitir el registro de formación en este nivel',
  permitirtreinamentosatralados: 'Permite vincular el entrenamiento a este nivel de la grilla',
  jaexistetreinamentosnestenivel: 'No es posible desactivar el registro de formación en este nivel, ya que ya hay formaciones vinculadas',
  responsaveleinstrutor: 'Responsable/Instructor',
  instrutor: 'Instructor',
  calendariodetreinamentos: 'CALENDARIO DE ENTRENAMIENTO',
  exportar: 'Exportar',
  buscarpornome: 'Buscar por nombre',
  buscarpordescricao: 'Buscar por descripción',
  nomedotreinamento: 'Nombre del Entrenamiento',
  dataprevistadeinicio: 'Fecha de inicio esperada',
  datadetermino: 'Fecha de finalización',
  statusdotreinamento: 'Estado de Entrenamiento',
  datadefinal: 'Fecha final',
  buscarpornomeoudescricao: 'Buscar por nombre',
  exercepapeldegestornaareadegente: 'Desempeña el papel de administrador del área de Personas',
  concluido: 'Completado',
  agendado: 'Programado',
  emandamento: 'En progreso',
  atrasado: 'Retrasado',
  anoreferencia: 'Año de referencia',
  campocustomizadoinvalido: 'Algún campo personalizado no es válido',
  datadotreinamento: 'Fecha de entrenamiento',
  presenca: 'Presencia',
  mesdotreinamento: 'Mes de entrenamiento',
  anodotreinamento: 'Año de entrenamiento',
  iniciodaprestacaodeservicos: 'Inicio de la prestación del servicio',
  atraso: 'Demora',
  previsto: 'Visto el futuro',
  realizadoem: 'Llevado a cabo en',
  selecioneumtreinamento: 'Seleccione una formación',
  presente: 'Presente',
  visualizarmotivos: 'Ver razones',
  deletar: 'Borrar',
  placeholderbuscanomeoudescricaotreinamento: 'Buscar por nombre o objetivo de la formación',
  placeholderbuscanometreinamento: 'Buscar por nombre de la formación',
  prorrogado: 'Extendido',
  marcacaomanual: 'Marcado manual',
  pendente: 'Pendiente',
  faltou: 'Omitido',
  valordotreinamento: 'Valor de la formación',
  palavraschaverelacionadasaotreinamento: 'Palabras clave relacionadas con la formación',
  planejamentorecorrente: 'Planificación recurrente',
  marqueessaopcaoparatreinamentosqueacontecemdeformacontinua: 'Seleccione esta opción para la formación que se realiza de forma continua',
  otreinamentoeobrigatorio: '¿Es obligatoria la formación?',
  tipodevinculo: 'Tipo de enlace',
  tipodecargo: 'Tipo de puesto',
  quaiskpismetricassaoimpactadospelarealizacaodestetreinamento: 'Qué kpi/métricas se ven afectados al llevar a cabo esta capacitación.',
  valormonetariorecursofinanceiroqueseraaplicadonestetreinamento: 'valor monetario, recurso financiero que se aplicará en esta capacitación.',
  objetivodotreinamento: 'Objetivo de entrenamiento',
  selecioneocertificado: 'Seleccione el certificado',
  quaisfuncoesparticiparaodotreinamento: '¿Qué funciones participarán en la formación?',
  quaiscolaboradoresparticiparaodotreinamento: '¿Qué empleados participarán en la formación?',
  diasaposiniciodaprestacaodeservicos: 'Días después del inicio de la prestación del servicio',
  quantidadedeagendamentos: 'Número de citas',
  admitidosapartirde: 'Admitido desde',
  insiraaquantidadededias: 'Introduzca el número de días',
  campoparadatadecortedoscolaboradoreselegiveisaotreinamento: 'Campo para la fecha límite para los empleados elegibles para recibir capacitación',
  execucao: 'Ejecución',
  ocamponomedotreinamentodevecontercaracteresvalidos: 'El campo del nombre del entrenamiento debe contener caracteres válidos.',
  gradessequenciascriadasporadm: 'No es posible eliminar, ya que hay grids secuenciales creados por un administrador',
  jaexisteumtreinamentocomonomeinformado: 'Ya existe una formación con el nombre proporcionado',
  rascunho: 'Borrador',
  vocenaotempermissaopararealizaressaoperacao: 'No tienes permiso para realizar esta operación',
  digitenomeparanovotreinamento: 'Ingrese el nombre de la nueva capacitación',
  nomeparanovotreinamento: 'Nombre de la nueva capacitación',
  paraserdupliacadootreinamentodeveterumnovonome: 'Para ser duplicado o treinamento deve ter um novo nome',
  treinamentoduplicadosucesso: '¡¡¡Duplica el entrenamiento con éxito!!!',
  naopossivelduplicarpoisonomejaeusadoemoutrotreinamento: 'No es posible duplicar porque el nombre ya se usa en otra capacitación',
  treinamentocopiadosucesso: '¡¡¡Entrenamiento copiado con éxito!!!',
  naoepossivelcopiarpoisnaoexistemessesvinculosnoclientedestino: 'No es posible copiar porque no hay "enlaces contractuales" en el cliente de destino',
  naoepossivelcopiarpoisnaoexistemessecargosnoclientedestino: 'No es posible copiar porque no hay "cargos" en el cliente de destino',
  naoepossivelcopiarpoisnaoexisteagradenoclientedestino: 'No es posible copiar porque no hay una "cuadrícula" en el cliente de destino',
  naoepossivelsalvarouatualizarpoisociclojafinalizou: 'No es posible guardar ni actualizar, porque el ciclo ya terminó',
  naoepossivelexcluirpoisociclojafinalizou: 'No es posible eliminar porque el ciclo ya terminó',
  documentosnaoanalisados: 'Documentos no analizados',
  relatoriodocumentosnaoanalizados: 'Informe de documentos no analizados',
  gerarrelatorio: 'Generar informe',
  erroaogerarrelatorio: 'Error al generar el informe',
  informedatacompetenciaate: 'Proporcionar datos de competencia en un plazo de 15 días.',
  competenciaate: 'Competencia hasta',
  relatorio: 'Informe',
  relatoriosrecentes: 'Informes recientes',
  seurelatorioficoupronto: 'Your report is ready',
  cliquenobotaodownloadparabaixarorelatorio: 'Haga clic en el botón de descarga para descargar su informe.',
  download: 'descargar',
  dowloademprogresso: 'En progreso',
  downloadfinalizado: 'Completo',
  selecioneaquantidadedeagendamentos: 'Seleccione el número de citas',
  selecioneumtipodeagendamento: 'Seleccione un tipo de cita',
  selecioneumoumaistiposdevinculo: 'Seleccione uno o más tipos de enlaces',
  ocamponaopodetermaisque500caracteres: 'El campo del nombre de la capacitación no puede exceder los 500 caracteres',
  selecioneoanoreferencia: 'Seleccione el año de referencia',
  selecioneoagendamento: 'Seleccione la cita',
  selecioneaestrutura: 'Seleccione la estructura',
  selecioneostatus: 'Seleccione el estado',
  selecioneaaderenciageral: 'Seleccione la adherencia general',
  selecioneaaderenciaprincial: 'Seleccione la adherencia principal',
  selecioneaobrigatoriedade: 'Seleccione la obligatoriedad',
  erroaotentaratualizarpoisexistetreinamentos: 'Error al intentar actualizar, ya hay formaciones registrados en este nivel del grid',
  tempodeduracaodoagendamentoemminutos: 'Duración del horario (en min.)',
  vincularcheckderetencao: 'Verificación de retención de enlace',
  indicarinstrutor: 'Indicar instructor',
  vincularavaliacaodereacao: 'Vincular evaluación de reacción',
  exportarhabilitados: 'Exportar HABILITADOS',
  datadeprorrogacao: 'Fecha de prórroga',
  visualizar: 'Ver',
  cliqueparaselecionarumformulario: 'Haga clic para seleccionar un formulario',
  cliqueparaselecionarumaavaliacao: 'Haga clic para seleccionar una reseña',
  digiteonomedoinstrutor: 'Ingrese el nombre del instructor',
  atribuirusuariosaestrutura: 'Asignar usuarios a la estructura.',
  atribuirusuarios: 'Asignar usuarios',
  selecioneotipodeusuario: 'Seleccione el tipo de usuario',
  atribuir: 'Atribuir',
  scheduledtrainingsalert1: 'Los campos completados en esta pantalla filtran las opciones de formaciones mostradas aquí.',
  scheduledtrainingsalert2: 'Si no encuentras algún entrenamiento en particular, asegúrate de que coincida con los filtros aplicados',
  totaldecolaboradores: 'Empleados totales',
  datainicialmaiorouigualquefinal: 'La fecha de inicio debe ser menor que la fecha de finalización',
  datafinaldevesermaiorqueinicial: 'La fecha de finalización debe ser mayor que la fecha de inicio',
  vincularformulario: 'Formulario de enlace',
  vincularavaliacao: 'Evaluación de enlaces',
  naoepossivelatualizarpoisotreinamentojainiciou: 'No se puede actualizar porque la capacitación ya comenzó',
  tipodeagendamento: 'Tipo de calendario',
  naoepossivelatualizarumagendamentojainiciado: 'No es posible actualizar un calendario que ya comenzó',
  erroaotentaratualizarpoisexistetreinamentosnosfilhos: 'Error al intentar eliminar, porque ya hay entrenamientos registrados en este nivel de la grilla o en los niveles secuenciales',
  naoepossivelsalvarpoisjaexisteumtreinamentocomestenome: 'Error al intentar guardar, ya hay una formación registrada con este nombre',
  dataagendamentoinferioraantecessor: 'La fecha de inicio debe ser menor o igual a la fecha de inicio de las programaciones posteriores',
  dataagendamentosuperioraposterior: 'La fecha de inicio del cronograma no puede ser inferior a la fecha de inicio del cronograma anterior',
  certezaalterarpresenca: '¿Estás seguro de que quieres cambiar la presencia de esta persona?',
  informeadataprevistadetermino: 'Introduzca la fecha de finalización prevista',
  filtrartabela: 'Filtrar tabla',
  informeanovadatadetermino: 'Introduzca la nueva fecha de finalización',
  novadatadetermino: 'Nueva fecha de finalización',
  nenhumtreinamentofoiencontrado: 'No se encontró fomación',
  oagendamentoaindanaoterminou: 'La cita aún no ha terminado',
  naoepossiveleditardatalimiteatingida: 'No se puede editar, se alcanzó la fecha límite',
  treinamentosdisponiveis: 'Formaciónes disponibles',
  datafinalizacao: 'Fecha de Finalización',
  datafinalmaioratualtrainamento: 'La fecha de finalización prevista no puede ser inferior a la fecha actual',
  estecolaboradornaopodeserremovido: '¡Este colaborador no se puede eliminar!',
  vinculadoaumcolaboradordeoutraunidade: 'No es posible eliminar, este elemento está vinculado a colaboradores en otra unidad',
  dataderealizacao: 'Fecha de realización',
  uploaddalistadepresenca: 'Subir la lista de asistencia',
  anexarlistadepresençadotreinamento: 'Adjuntar lista de asistencia del entrenamiento',
  quaiscolaboradoresparticiparamdotreinamento: '¿Qué colaboradores participaron en el entrenamiento?',
  arquivosanteriores: 'Archivos anteriores',
  visualizarcolaboradores: 'Visualizar colaboradores',
  listadecolaboradores: 'Lista de colaboradores',
  certezaexcluirlistadepresenca: '¿Estás seguro de que deseas eliminar esta lista de asistencia?',
  planejamentopendente: 'Planificación pendiente',
  agendamentodisponivelparainicioimediato: 'Cita disponible para inicio inmediato.',
  parabensvocejaconcluiuesteagendamento: '¡Felicitaciones, ya ha completado esta cita!',
  agendamentorealizadodeformapresencial: 'Programación realizada personalmente.',
  adatadeterminodesteagendamentojapassou: 'La fecha de finalización de esta cita ha pasado.',
  agendamentoindisponivelaguardeadatadeinicio: 'Horario no disponible, espere la fecha de inicio.',
  nenhumcolaboradorencontrado: 'No se encontraron colaboradores',
  todososxagendamentosforamconcluidos: 'Todos los {quantity} agendamientos han sido completados',
  voceaindanaorealizouxagendamentos: 'Aún no se han completado los {quantity} agendamientos',
  voceaindanaorealizouxagendamento: 'Aún no has realizado {quantity} cita',
  informeotempodeduracaoemmin: 'Introduzca la duración en minutos',
  paisdenacionalidadedocondutor: 'País de Nacionalidad del Conductor',
  categoriadacnh: 'Categoría de Licencia de Conducir',
  numeroderegistrodacnh: 'Número de Registro de Licencia de Conducir',
  dataprimeirahabilitacao: '1ra Fecha de Licencia',
  dataemissaocnh: 'Fecha de Emisión de la Licencia de Conducir',
  situacaocnh: 'Situación de la Licencia de Conducir',
  numeroespelhocnh: 'Número de Espejo de la Licencia de Conducir',
  numerorenach: 'Número de Renach',
  municipiocnh: 'Municipio de la Licencia de Conducir',
  validadedacnh: 'Fecha de Vencimiento de la Licencia de Conducir',
  ufdenaturalidadecondutor: 'Estado de Naturalidad del Conductor',
  ufcnh: 'Estado de la Licencia de Conducir',
  selecioneasituacao: 'Seleccione la Situación',
  municipionaturalidadecondutor: 'Municipio de Naturalidad del Conductor',
  tipodelicenca: 'Tipo de Licencia',
  cnh: 'CNH',
  licencadeconducaoparaestrangeiros: 'Licencia de Conducir para Extranjeros',
  validadelicencadeconducao: 'Validez del Licencia de Conducir',
  validadepermissaodeconducaonobrasil: 'Validez del Permiso de Conducir en Brasil',
  informevalidadelicencadeconducao: 'Ingrese el validez del licencia de conducir',
  informevalidadepermissaodeconducaonobrasil: 'Ingrese el validez del permiso de conducir en Brasil',
  datadevalidadedalicencadeconducaodocolaboradornoseupaisdeorigem: 'Datos de validez del permiso de conducir del empleado en su país de origen.',
  datalimitequeestecolaboradorpoderaconduzirnobrasilusandoapermissaodeconducaosemacnh: 'Fecha límite para que este empleado pueda conducir en Brasil con Permiso de Conducir, sin CNH.',
  checkderetencao: 'Chequeo de retención',
  avaliacaodereacao: 'Evaluación de reacción',
  avisoprazoparaconcluiragendamento: 'Atención - El plazo máximo para completar este cronograma es: {date}, complételo antes de esta fecha para evitar posibles inconvenientes por incumplimiento del plazo.',
  finalizaravaliacao: 'Finalizar evaluación',
  descreveroindicador: 'Describir el indicador',
  descrevaoobjetivodotreinamento: 'Describe el objetivo de la formación.',
  nenhumresultadoencontrado: 'No se han encontrado resultados',
  nenhumaopcaodisponivel: 'No hay opciones disponibles',
  semsubopcoes: 'Sin sub-opciones',
  pressioneenterparaselecionar: 'Presione enter para seleccionar',
  presioneenterpararemover: 'Presione enter para eliminar',
  selecionado: 'Seleccionado',
  resultadodaavaliacaopontos: 'Resultado de la evaluación: {score} puntos',
  vocejarespondeuestaavaliacao: 'Ya has respondido esta evaluación',
  avisoagendamentofeitopresencial: 'Atención: El chequeo de retención de esta cita se realizó de forma presencial. Puedes acceder al contenido a continuación, pero no es posible enviar respuestas.',
  datadeterminoagendamento: 'Fecha de finalización del calendario',
  naoepossivelcopiarpoisonomejaeusadoemumtreinamentonoclientedestino: 'No es posible copiar, ya que el nombre ya se utiliza en la formación del cliente de destino.',
  seuusuarionaopodeeditarestecampo: 'Su usuario no puede editar este campo',
  voceconcluiuxdeyagendamentos: 'Ha completado {completed} de {total} citas',
  na: 'N/A',
  prazofinalparaconclusaodoagendamento: 'Fecha límite para completar la cita',
  agendamentodisponivelporemforadoprazo: 'Cita disponible pero tarde.',
  onomeinformadoparaagradenaoevalido: 'El nombre ingresado para la cuadrícula no es válido',
  formcomagendamentosvinculados: 'No es posible eliminarlo, ya que el formulario está vinculado a una o más citas.',
  avaliacaocomagendamentosvinculados: 'No es posible eliminar, ya que la evaluación está vinculada a uno o más cronogramas.',
  copiadeformulario: 'Copia del formulario',
  copiadeavaliacao: 'Copia de evaluación',
  onomeinformadojaeutilizadoemoutragrade: 'El nombre ingresado ya se usa en otra cuadrícula',
  otreinamentocomstatusrascunhonaopodesermanipulado: 'La formación con estado de borrador no se puede manipulado',
  naoepossiveleditarformulariopoisjaexisteresposta: 'No es posible editar porque ya hay una respuesta registrada para este formulario',
  naoepossiveleditaravaliacaopoisjaexisteresposta: 'No es posible editar porque ya hay una respuesta registrada para esta evaluación.',
  nenhumclienteencontrado: 'No se encontraron clientes',
  naoepossiveldeletarpoiscontemrespostasaosformulariosdestetreinamento: 'No es posible eliminarlo, ya que contiene respuestas a los formularios de esta capacitación',
  dataprevistadeterminoinvalida: 'Fecha de finalización prevista no válida',
  esteagendamentonaoestamaisdisponivel: 'Esta cita ya no está disponible',
  erroessenomejaestaemuso: 'Ese nombre ya está en uso',
  formularioduplicado: 'Formulario duplicado con éxito!!!',
  erroaoduplicarregistro: 'Error al duplicar registros',
  avaliacaoduplicada: 'Evaluación duplicada con éxito!!!',
  naoepossiveldeletarotreinamentopoiscontemrespostasasavaliacoesdomesmo: 'No es posible eliminar la capacitación ya que contiene respuestas a las evaluaciones de la capacitación',
  naoepossiveldeletarotreinamentopoisexisterespostasdeavaliacoeseformulariorespondidos: 'No es posible eliminar la capacitación ya que hay respuestas de evaluaciones y formularios completados',
  vocedeveselecionaronivelnoqualotreinamentoseraalocado: 'Debes seleccionar el nivel en el que se asignará la formación',
  consultaprontuariodocondutor: 'Consulta de registro de conductor',
  informeodocumentoparaconsultar: 'Presente el documento para consulta',
  consultarprontuario: 'Consultar prontuario',
  informacoesdocolaborador: 'Información del Colaborador',
  informacoesdocondutor: 'Información del Conductor',
  pontuacaodeprontuario: 'Puntuación del Expediente',
  motivosdobloqueio: 'Motivos del Bloqueo',
  datadobloqueio: 'Fecha del Bloqueo',
  bloqueado: 'Bloqueado',
  endereco: 'Dirección',
  vencimentodacnh: 'validez de la CNH',
  previsaodedesbloqueio: 'Desbloquear predicción',
  datavencimentogr: 'Fecha de vencimiento GR',
  condutornaoencontradocomestedocumento: 'No se encontró conductor con el documento',
  grupodeanalise: 'Grupo de análisis',
  selecioneumgrupodeanalise: 'Seleccione un grupo de análisis',
  selecioneumrelatorio: 'Seleccione un informe',
  naofoipossivelcarregarodashboard: 'No se pudo cargar el tablero, intente nuevamente',
  verdashboardemtelacheia: 'Ver tablero en pantalla completa',
  exportarparapdf: 'Exportar datos a PDF',
  integracombeedoo: 'Integración con BEEDOO?',
  documentoexcluido: '(Documento eliminado)',
  limparfiltros: 'Filtros limpios',
  atualizardadoscadastrais: 'Actualizar datos de registro',
  atualizacaodedadosdocolaborador: 'Actualización de datos del colaborador',
  cancelaratualizacaodedados: 'Cancelar actualización de datos',
  mensagemconfirmacaocancelardadoscadastrais: 'Al confirmar la cancelación de esta acción, todas las ediciones realizadas serán descartadas y los campos volverán a mostrar la información original.',
  atualizacaodosdados: 'actualización de datos',
  desejarealmentecancelaraatualizacaodosdados: '¿Realmente desea cancelar la',
  confirmarcancelamento: 'Confirmar cancelación',
  continuareditando: 'Continuar editando',
  atualizacaodedadospessoaisconcluida: 'Actualización de datos personales completada',
  atualizacaodedadossociaisconcluida: 'Actualización de datos sociales completada',
  atualizacaodedadosfuncionaisconcluida: 'Actualización de datos funcionales completada',
  conteudomodalsalvardadoscadastrais1: 'Los datos en la pestaña',
  conteudomodalsalvardadoscadastrais2: 'se han actualizado con éxito.',
  perguntamodalsalvardadoscadastrais: '¿Qué desea hacer ahora?',
  voltarparaoprontuario: 'Volver al expediente',
  editardadosfuncionais: 'Editar datos funcionales',
  editardadossociais: 'Editar datos sociales',
  seuusuarionaopossuiprivilegioparaeditarestecampo: 'Su usuario no tiene privilegios para editar este campo.',
  naohadadosfuncionaisparaseremeditados: 'No hay datos funcionales para editar',
  unidadenaoaceitamovimentacaonessenivel: 'La unidad no acepta movimientos a este nivel',
  permitircadastrodemovimentacoesnestenivel: 'Permitir registro de movimientos en este nivel',
  permitequesejamatreladoscolaboradoresnesteniveldaestrutura: 'Permite vincular empleados a este nivel de la estructura',
  unidadeinvalida: 'Unidad no válida',
  colaborators_linked_to_the_unity_for_no_allow_movements: 'No puede bloquear este nivel de estructura. Tiene empleados asignados en este nivel y debe ser movido antes de bloquear la estructura',
  unityNotAllowMovements: 'La unidad no permite el movimiento.',
  numerocelularinvalido: 'Número de celular no válido',
  certezacopiartreinamento: '¿Está seguro de que desea copiar este entrenamiento?'
}
