var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-tel-input", {
    attrs: {
      "valid-characters-only": "",
      "default-country": "BR",
      placeholder: _vm.$t("informetelefone"),
      readonly: _vm.schema.readonly,
    },
    on: { "country-changed": _vm.countryChangedHandle },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }