import * as positionService from '@/services/position-service'

const defaultPosition = {
  _id: null,
  name: null,
  description: null,
  type: null,
  roles: [],
  minimumScore: null,
  profile: [],
  experience: [],
  skills: [],
  createdBy: null,
  updatedBy: null,
  client: null
}

const state = {
  position: defaultPosition
}

const getters = {}

const mutations = {
  SET_POSITION: (state, payload) => {
    state.position = payload
  }
}

const actions = {
  load({ commit }, positionData) {
    commit('SET_POSITION', positionData)
  },
  clear({ commit }) {
    const defPosition = {
      _id: null,
      name: null,
      description: null,
      type: null,
      roles: [],
      minimumScore: null,
      profile: [],
      experience: [],
      skills: [],
      createdBy: null,
      updatedBy: null,
      client: null
    }
    commit('SET_POSITION', defPosition)
  },
  async save({ commit, rootState }, data) {
    const { position, path } = data

    position.client = rootState.user.userClient
    position.path = path
    const { data: positionAfterSave } = await positionService.savePosition(position)
    commit('SET_POSITION', positionAfterSave)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
