import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueTheMask from 'vue-the-mask'
import i18n from '@/plugins/i18n'
import FlagIcon from 'vue-flag-icon'
import VueSimpleSVG from 'vue-simple-svg'

import Cleave from 'vue-cleave-component'
import VueMask from 'v-mask'
import excel from 'vue-excel-export'

import * as filters from './filters' // global filters
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import StarRating from 'vue-star-rating'
import VueTelInput from 'vue-tel-input'

import fieldHora from './views/forms/fieldHora.vue'
import fieldData from './views/forms/fieldData.vue'
import fieldTelefone from './views/forms/fieldTelefone.vue'
import fieldMail from './views/forms/fieldMail.vue'
import fieldVideoPreview from './views/forms/fieldVideoPreview.vue'
import fieldImagePreview from './views/forms/fieldImagePreview.vue'
import fieldImageUpload from './views/forms/fieldImageUpload.vue'
import fieldFileUpload from './views/forms/fieldFileUpload.vue'
import fieldVideoUpload from './views/forms/fieldVideoUpload.vue'
import fieldNumero from './views/forms/fieldNumero.vue'
import fieldRate from './views/forms/fieldRate.vue'
import fieldRateNumber from './views/forms/fieldRateNumber.vue'
import fieldRateEmoji from './views/forms/fieldRateEmoji.vue'
import fieldConditional from './views/forms/fieldConditional.vue'
import fieldLink from './views/forms/fieldLink.vue'
import fieldAutoCompleteColaborator from './views/forms/fieldAutoCompleteColaborator.vue'
import fieldDMCheckbox from './views/forms/fieldDMCheckbox.vue'
import fieldDMSelect from './views/forms/fieldDMSelect.vue'
import fieldDMMultiselect from './views/forms/fieldDMMultiselect.vue'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import AsyncComputed from 'vue-async-computed'
import VueSocketIO from 'vue-socket.io'

import { abilitiesPlugin } from '@casl/vue'
import { ability, defineAbilityFor } from './config/ability'
import VSwitch from 'v-switch-case'
import Vuelidate from 'vuelidate'

import VueFormGenerator from 'vue-form-generator/src/index'
import validators from './components/form/DMFormGenerator/validators'
import limitInputDate from './directive/limitInputDate'
import VueNumberFormat from '@coders-tm/vue-number-format'
import money from 'v-money'

Vue.directive('limitInputDate', limitInputDate)

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_SOCKET_URL,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    },
    options: { transports: ['websocket'] }
  })
)

ability.update(defineAbilityFor(store.getters.userProfile))

Vue.use(AsyncComputed)

Vue.use(Loading)

library.add(faCoffee)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueTheMask)
Vue.use(Cleave)
Vue.use(VueMask)
Vue.use(excel)
// Vue.use(VueTimepicker)
Vue.use(FlagIcon)
Vue.use(VueSimpleSVG)
Vue.use(VueTelInput)
Vue.use(Autocomplete)
Vue.use(VSwitch)
Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})

Vue.use(Vuelidate)

const optionalFields = require.context(
  'vue-form-generator/src/fields/optional',
  false,
  /^\.\/field([\w-_]+)\.vue$/
)
optionalFields.keys().forEach((key) => {
  const compName = key.replace(/^\.\//, '').replace(/\.vue/, '')
  const component = optionalFields(key).default
  Vue.component(compName, component)
})

Vue.use(VueFormGenerator, { validators })
Vue.use(VueNumberFormat, { precision: 4 })
Vue.use(money, { precision: 4 })

Vue.component('star-rating', StarRating)
Vue.component('multiselect', Multiselect)
Vue.component('fieldHora', fieldHora)
Vue.component('fieldData', fieldData)
Vue.component('fieldTelefone', fieldTelefone)
Vue.component('fieldMail', fieldMail)
Vue.component('fieldVideoPreview', fieldVideoPreview)
Vue.component('fieldImagePreview', fieldImagePreview)
Vue.component('fieldLink', fieldLink)
Vue.component('fieldImageUpload', fieldImageUpload)
Vue.component('fieldFileUpload', fieldFileUpload)
Vue.component('fieldVideoUpload', fieldVideoUpload)
Vue.component('fieldNumero', fieldNumero)
Vue.component('fieldRate', fieldRate)
Vue.component('fieldRateNumber', fieldRateNumber)
Vue.component('fieldRateEmoji', fieldRateEmoji)
Vue.component('fieldConditional', fieldConditional)
Vue.component('fieldAutoCompleteColaborator', fieldAutoCompleteColaborator)
Vue.component('fieldDmcheckbox', fieldDMCheckbox)
Vue.component('fieldDmselect', fieldDMSelect)
Vue.component('fieldDmmultiselect', fieldDMMultiselect)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
})

