const tabs = {
  DocumentDepositList: () => import('@/components/DMDocument/DMDocumentDeposit/DMDocumentDepositList'),
  DocumentDeposit: () => import('@/components/DMDocument/DMDocumentDeposit')
}

const state = {
  alert: { type: '', message: '' },
  currentDocumentDepositTab: tabs.DocumentDepositList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentDocumentDepositTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentDocumentDepositTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit, state }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
