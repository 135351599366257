import * as personService from '@/services/person-service'

const defaultPerson = {
  _id: null,
  name: null,
  documentType: 'cpf',
  document: null,
  birthDate: null,
  phone: '',
  phoneCountry: '',
  email: null,
  fatherName: null,
  motherName: null,
  country: null,
  postalCode: null,
  street: null,
  number: null,
  neighborhood: null,
  city: null,
  state: null,
  customFields: [{}, {}],
  pis: null,
  photo: null,
  optin: false
}

const state = {
  person: defaultPerson,
  isValidPersonalData: false,
  isValidFunctionalData: false,
  isValidSocialData: false
}

const mutations = {
  SET_PERSON: (state, payload) => {
    state.person = payload
  },
  SET_VALID_PERSONAL_DATA: (state, isValid) => {
    state.isValidPersonalData = isValid
  },
  SET_VALID_FUNCTIONAL_DATA: (state, isValid) => {
    state.isValidFunctionalData = isValid
  },
  SET_VALID_SOCIAL_DATA: (state, isValid) => {
    state.isValidSocialData = isValid
  }
}

const actions = {
  loadPerson({ commit }, personData) {
    commit('SET_PERSON', personData)
  },

  clearPerson({ commit }) {
    const defPerson = {
      _id: null,
      name: null,
      documentType: 'cpf',
      document: null,
      birthDate: null,
      phone: '',
      phoneCountry: '',
      email: null,
      fatherName: null,
      motherName: null,
      country: null,
      postalCode: null,
      street: null,
      number: null,
      neighborhood: null,
      city: null,
      state: null,
      pis: null,
      customFields: [{}, {}],
      photo: null,
      optin: false
    }
    commit('SET_PERSON', defPerson)
  },

  async savePerson({ dispatch, commit, rootState }, payload) {
    let { person } = payload
    person.client = rootState.user.userClient

    const { persist } = payload
    if (persist) {
      const personClone = Object.assign({}, person)
      let newPhoto
      if (personClone && personClone.photo instanceof File) {
        newPhoto = personClone.photo
        delete personClone.photo
      }
      const { data } = await personService.savePerson(personClone)
      person = Object.assign({ ...personClone, ...data })
      if (newPhoto) {
        person.photo = await dispatch('savePhoto', { _id: data._id, photo: newPhoto })
      }
    }

    commit('SET_PERSON', person)
  },

  async savePhoto({ commit }, { _id, photo }) {
    const { data } = await personService.uploadPhoto(_id, photo)
    return data
  },

  setValidPersonalData({ commit }, isValid) {
    commit('SET_VALID_PERSONAL_DATA', isValid)
  },

  setValidFunctionalData({ commit }, isValid) {
    commit('SET_VALID_FUNCTIONAL_DATA', isValid)
  },

  setValidSocialData({ commit }, isValid) {
    commit('SET_VALID_SOCIAL_DATA', isValid)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
