import { leadershipHierarchyService } from '@/services/leadership-hierarchy-service'

const defaultLeadershipHierarchy = () => ({
  _id: null,
  leadership: {
    _id: null,
    person: { name: '' }
  },
  subordinate: {
    _id: null,
    person: { name: '' }
  },
  initialDate: null,
  endDate: null
})

const state = {
  leadershipHierarchy: Object.assign({}, defaultLeadershipHierarchy())
}

const getters = {}

const mutations = {
  SET_LEADERSHIPHIERARCHY: (state, payload) => {
    state.leadershipHierarchy = payload
  }
}

const actions = {
  loadLeadershipHierarchy({ commit }, leadershipHierarchyData) {
    commit('SET_LEADERSHIPHIERARCHY', leadershipHierarchyData)
  },

  clearLeadershipHierarchy({ commit }) {
    commit('SET_LEADERSHIPHIERARCHY', defaultLeadershipHierarchy())
  },

  async saveLeadershipHierarchy({ rootState, commit }, payload) {
    const { persist } = payload
    let { leadershipHierarchy } = payload
    if (persist) {
      if (!payload.client) {
        payload.client = rootState.user.userClient
      }

      const { data } = await leadershipHierarchyService.save(
        leadershipHierarchy
      )
      leadershipHierarchy = Object.assign(leadershipHierarchy, data)
    }
    commit('SET_LEADERSHIPHIERARCHY', leadershipHierarchy)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
