import { BaseService } from './base-service'

class ClientConfigurationService extends BaseService {
  constructor() {
    super('/client-configuration')
  }
}

export const clientConfigurationService = new ClientConfigurationService()

