const tabs = {
  CandidatesList: () => import('@/views/recruitment/CandidatesList'),
  CandidateTabs: () => import('@/views/recruitment/CandidateTabs')
  // AnswerReport: () => import('@/views/recruitment/AnswerReport')
}

const state = {
  currentCandidateTab: tabs.CandidatesList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentCandidateTab = state.tabs[tab]
  }
}

const actions = {
  setCandidateTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
