import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class EcosystemService extends BaseService {
  constructor() {
    super('/ecosystem')
  }

  getByName = (name) => {
    return service.get(`/ecosystem/by_name/${name}`)
  };

  getByClient = (client) => {
    return service.get(`/ecosystem/by_client/${client}`)
  };

  getByUserAndClient = (user, client) => {
    const query = qs.stringify({ user, client })
    return service.get(`/ecosystem/by_user_client?${query}`)
  };
}

export const ecosystemService = new EcosystemService()
