import * as clinicService from '@/services/clinic-service'

const defaultClinic = {
  _Id: null,
  name: null,
  type: null,
  document: null,
  phone: null,
  email: null,
  country: null,
  state: null,
  city: null,
  neighborhood: null,
  street: null,
  postalCode: null,
  client: null,
  createdBy: null,
  updatedBy: null
}

const state = {
  clinic: defaultClinic
}

const getters = {}

const mutations = {
  SET_CLINIC: (state, payload) => {
    state.clinic = payload
  }
}

const actions = {
  loadClinic({ commit }, clinicFormData) {
    commit('SET_CLINIC', clinicFormData)
  },
  clearClinic({ commit }) {
    const defaultClinic = {
      _Id: null,
      name: null,
      type: null,
      document: null,
      phone: '',
      email: null,
      country: null,
      state: null,
      city: null,
      neighborhood: null,
      street: null,
      postalCode: null,
      client: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_CLINIC', defaultClinic)
  },
  save({ commit, rootState }, clinic) {
    clinic.client = rootState.user.userClient
    clinicService.saveClinic(clinic)
      .then(({ data }) => {
        commit('SET_CLINIC', data)
      })
  },
  async remove({ commit }, clinicId) {
    return clinicService.deleteClinic(clinicId)
      .then(() => {
        commit('SET_CLINIC', defaultClinic)
        return Promise.resolve()
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
