import { service } from './base-service'
import * as storageService from './storage-service'

const getPersons = () => {
  return service.get('/person')
}

const getPersonById = (personId) => {
  if (!personId) {
    throw new Error('Person Id is required!')
  }
  return service.get(`/person/${personId}`)
}

const isValidDate = (personId, date) => {
  if (!personId) {
    throw new Error('Person Id is required!')
  }
  if (!date) {
    throw new Error('Date Id is required!')
  }
  return service.get(`/person/isValidDate/${personId}?date=${date}`)
}

const getPersonByDocument = (document) => {
  if (!document) {
    throw new Error('Client name is required!')
  }
  return service.get(`/person/document/${document}`)
}

const savePerson = (personData) => {
  if (personData._id) {
    return update(personData._id, personData)
  }
  delete personData._id
  return create(personData)
}

const create = (personData) => {
  delete personData._id
  return service.post(`/person`, personData)
}

const update = (personId, personData) => {
  return service.put(`/person/${personId}`, personData)
}

const uploadPhoto = (personID, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return storageService.upload(
    formData,
    `person/${personID}/upload`
  )
}

const parseCustomData = (customFields, schema) => {
  const data = {}

  const { fields } = schema || []
  for (const field of fields) {
    let value = customFields[field.model]
    if (value === '') {
      value = null
    }

    if (field.type !== 'textArea' && field.inputType !== 'text') {
      if (typeof value === 'string' && value.replace(/\s*/g, '') === '-') {
        value = null
      }
    }

    switch (field.type) {
      // case 'imageUpload':
      //   value = store.getters.linkImage
      //   // Todo: set state directly is not a practice, create a mutation and an action
      //   store.state.user.linkImage = null
      //   break

      case 'telefone':
        if (typeof value === 'string') {
          value = value.replace(/[^\d]+/g, '')
        }
        break

      case 'textArea':
        if (value == null) {
          value = '-'
        }
        break
    }

    switch (field.inputType) {
      case 'text':
        if (value == null) {
          value = '-'
        }
        break
    }

    data[field.model] = value
  }

  return data
}

export {
  getPersons,
  getPersonById,
  getPersonByDocument,
  savePerson,
  create,
  update,
  uploadPhoto,
  isValidDate,
  parseCustomData
}
