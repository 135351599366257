const defaultCurrentClientTabIndex = 0

const state = {
  currentClientTabIndex: defaultCurrentClientTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTCLIENTTABINDEX: (state, payload) => {
    state.currentClientTabIndex = payload
  }
}

const actions = {
  setCurrentClientTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTCLIENTTABINDEX', currentTabIndex)
  },
  clearCurrentClientTabIndex({ commit }) {
    const defCurrentClientTabIndex = 0
    commit('SET_CURRENTCLIENTTABINDEX', defCurrentClientTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
