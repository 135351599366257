const defaultCurrentCompanyTabIndex = 0

const state = {
  currentCompanyTabIndex: defaultCurrentCompanyTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTCOMPANYTABINDEX: (state, payload) => {
    state.currentCompanyTabIndex = payload
  }
}

const actions = {
  setCurrentCompanyTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTCOMPANYTABINDEX', currentTabIndex)
  },
  clearCurrentCompanyTabIndex({ commit }) {
    const defCurrentCompanyTabIndex = 0
    commit('SET_CURRENTCOMPANYTABINDEX', defCurrentCompanyTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
