import { service } from './base-service'

const getApprovals = () => {
  return service.get('/approval')
}

const getApprovalById = (approvalId) => {
  if (!approvalId) {
    throw new Error('Approval Id is required!')
  }
  return service.get(`/approval/${approvalId}`)
}

const getApprovalsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/approval/client/${client}`)
}

const createApproval = (approvalData) => {
  return service.post(`/approval`, approvalData)
}

const updateApproval = (approvalId, approvalData) => {
  return service.put(`/approval/${approvalId}`, approvalData)
}

const saveApproval = (approvalId, approvalData) => {
  if (approvalId) {
    return service.put(`/approval/${approvalId}`, approvalData)
  }
  delete approvalData._id
  return service.post(`/approval`, approvalData)
}

const deleteApproval = (approvalId) => {
  return service.delete(`/approval/${approvalId}`)
}

export {
  getApprovals,
  getApprovalById,
  getApprovalsByClient,
  createApproval,
  updateApproval,
  deleteApproval,
  saveApproval
}
