import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import ptBr from './pt-br'
import es from './es'

Vue.use(VueI18n)

const messages = { 'pt-BR': ptBr, en, es }

const i18n = new VueI18n({
  locale: 'pt-BR', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages // set locale messages
})

export default i18n
