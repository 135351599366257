const tabs = {
  TrainingsList: () => import('@/views/trainings/academy/TrainingsList'),
  TrainingTabs: () => import('@/views/trainings/academy/TrainingTabs'),
  TrainingsRegisterTabs: () => import('@/views/trainings/records/training')
}

const state = {
  currentTab: tabs.TrainingsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = state.tabs[tab]
  }
}

const actions = {
  setTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
