const defaultCurrentResearchTabIndex = 0
const state = {
  currentResearchTabIndex: defaultCurrentResearchTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTRESEARCHTABINDEX: (state, payload) => {
    state.currentResearchTabIndex = payload
  }
}

const actions = {
  setCurrentResearchTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTRESEARCHTABINDEX', currentTabIndex)
  },
  clearCurrentResearchTabIndex({ commit }) {
    const defCurrentResearchTabIndex = 0
    commit('SET_CURRENTRESEARCHTABINDEX', defCurrentResearchTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
