import * as _ from 'lodash'
import qs from 'querystring'

import { BaseService, service } from './base-service'

const emitEmptyFields = (obj) => _.omitBy(obj, (v) => v == null || v === '')

class NewTrainingService extends BaseService {
  constructor() {
    super('/new-training')
  }

  updateSchedules(trainingId, data) {
    const { schedules } = data
    if (!schedules || !schedules.length === 0) {
      throw new Error('Schedules is required!')
    }

    return service.put(`${this.baseURL}/${trainingId}/planning`, data)
  }

  getById(id, options = {}, includesDeletedPersonBonds = false) {
    if (!id) {
      throw new Error('Id is required!')
    }

    const opts = qs.stringify({ ...options, includesDeletedPersonBonds })

    return service.get(`${this.baseURL}/${id}?${opts}`)
  }

  getByScheduleId(scheduleId) {
    if (!scheduleId) {
      throw new Error('scheduleId is required!')
    }
    return service.get(`${this.baseURL}/schedule/${scheduleId}`)
  }

  getByScheduleIdForFollowUp(scheduleId) {
    if (!scheduleId) {
      throw new Error('scheduleId is required!')
    }
    return service.get(`${this.baseURL}/schedule/${scheduleId}/for-follow-up`)
  }

  getByKeywordAndFilter(client, word, filter, limit = null, page = null) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({
      ...filter,

      client,
      word,

      limit,
      page
    })
    return service.get(
      `${this.baseURL}/by_client_hierarchy_word_and_filter?${query}`
    )
  }

  getForSelect(filter, limit = null, page = null) {
    filter = _.omitBy(filter, (v) => v == null || v === '')

    if (!filter.client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({
      ...filter,

      limit,
      page
    })

    return service.get(`${this.baseURL}/for_select?${query}`)
  }

  getForReplanning(id, filter, limit = null, page = null) {
    filter = _.omitBy(filter, (v) => v == null || v === '')

    if (!filter.client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({
      ...filter,
      limit,
      page
    })

    return service.get(`${this.baseURL}/${id}/for-replanning?${query}`)
  }

  exportForReplanning(id, filter, lang) {
    filter = _.omitBy(filter, (v) => v == null || v === '')

    const query = qs.stringify(filter)

    return service.get(
      `${this.baseURL}/${id}/for-replanning/export?${query}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  exportPdfForReplaning(id, lang) {
    return service.get(`${this.baseURL}/${id}/for-replaning/exportPdf`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getByFilter(filter, limit = null, page = null) {
    let query = {
      training: filter.training,
      status: filter.status,

      expectedEndDateMin: filter.expectedDate.min,
      expectedEndDateMax: filter.expectedDate.max,

      mainAdherenceMin: filter.mainAdherence.min,
      mainAdherenceMax: filter.mainAdherence.max,

      generalAdherenceMin: filter.generalAdherence.min,
      generalAdherenceMax: filter.generalAdherence.max,

      limit,
      page
    }

    query = _.omitBy(query, (v) => v == null || v === '')

    return service.get(
      `${this.baseURL}/by_filter?${qs.stringify(query)}`
    )
  }

  getSchedulesByFilter(filter, limit = null, page = null) {
    let query = {
      client: filter.client,
      grid: filter.grid,
      unity: filter.unity,
      isRequired: filter.isRequired,
      status: filter.status,
      referenceYear: filter.referenceYear,
      month: filter.month,
      training: filter.training,
      limit,
      page
    }

    query = _.omitBy(query, (v) => v == null || v === '')

    return service.get(
      `${this.baseURL}/schedules_by_filter?${qs.stringify(query)}`
    )
  }

  exportSchedulesByFilter(filter, lang) {
    let query = {
      client: filter.client,
      grid: filter.grid,
      unity: filter.unity,
      isRequired: filter.isRequired,
      status: filter.status,
      referenceYear: filter.referenceYear,
      month: filter.month,
      training: filter.training
    }

    query = _.omitBy(query, (v) => v == null || v === '')

    return service.get(
      `${this.baseURL}/schedules_by_filter/export?${qs.stringify(query)}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  // CALENDARIO
  exportToPdfSchedulesByFilter(filter, lang) {
    let query = {
      client: filter.client,
      grid: filter.grid,
      unity: filter.unity,
      isRequired: filter.isRequired,
      status: filter.status,
      referenceYear: filter.referenceYear,
      month: filter.month,
      training: filter.training
    }

    query = _.omitBy(query, (v) => v == null || v === '')

    return service.get(
      `${this.baseURL}/schedules_by_filter/exportPdf?${qs.stringify(query)}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  async duplicate(newTrainingId, name, toClient) {
    if (!newTrainingId) {
      throw new Error('newTraining id is required!')
    }

    return service.post(`${this.baseURL}/${newTrainingId}/duplicate`, {
      name,
      toClient
    })
  }

  updateNewTrainingEligibleScheduleStatus(eligibleId, scheduleId, data) {
    const body = emitEmptyFields({
      client: data.client,

      newStatus: data.newStatus,
      extensionDate: data.extensionDate,
      realizedAt: data.realizedAt
    })

    if (!eligibleId) {
      throw Error('eligibleId must not be empty')
    }

    if (!scheduleId) {
      throw Error('scheduleId must not be empty')
    }

    if (!body.client) {
      throw Error('client must not be empty')
    }

    if (!body.newStatus) {
      throw Error('newStatus must not be empty')
    }

    return service.put(
      `${this.baseURL}/${eligibleId}/${scheduleId}/status`,
      body
    )
  }

  updateNewTrainingSchedule(trainingId, scheduleId, data) {
    const body = emitEmptyFields({
      client: data.client,

      extensionDate: data.extensionDate,
      endDate: data.endDate
    })

    if (!trainingId) {
      throw Error('trainingId must not be empty')
    }

    if (!scheduleId) {
      throw Error('scheduleId must not be empty')
    }

    if (!body.client) {
      throw Error('client must not be empty')
    }

    return service.put(
      `${this.baseURL}/${trainingId}/${scheduleId}`,
      body
    )
  }

  updateNewTrainingEligibleSchedule(client, id, newTrainingEligibleId, scheduleId, data) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    return service.put(
      `${this.baseURL}/${id}/${newTrainingEligibleId}/${scheduleId}`,
      { client, ...data }
    )
  }
}

export const newTrainingService = new NewTrainingService()
