export default {
  bind (el, _, vnode) {
    el.addEventListener('change', function(e) {
      if (e.target.value.length > 10) {
        e.target.classList.add('dm-input-date-limit-error')
        setTimeout(() => {
          e.target.value = ''
          vnode.elm.dispatchEvent(new Event('change'))
          e.target.classList.remove('dm-input-date-limit-error')
        }, 1000)
      }
    })
  }
}
