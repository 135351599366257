import { documentParameterizeService } from '@/services/document-parameterize-service'

const defaultDocument = () => ({
  // acceptBinding: false,
  // acceptNotApplicable: false,
  // analyzeDocument: false,
  // bringActiveEmployees: false,
  // bringTerminatedEmployees: false,
  client: null,
  createdBy: null,
  createdByAdmin: false,
  customForm: null,
  // customerAnalysis: false,
  // customerReview: false,
  documentType: null,
  // generateBlocking: false,
  // isAnticipatory: false,
  status: null,
  // validationTime: false,
  _id: null
})

const state = {
  document: defaultDocument(),
  newParameterize: {
    has: false,
    message: ''
  }
}

const getters = {
  hasDocument: state => state.document._id != null
}

const mutations = {
  SET_DOCUMENT: (state, payload) => {
    state.document = payload
  }
}

const actions = {
  load({ commit }, document) {
    commit('SET_DOCUMENT', document)
  },
  clear({ commit }) {
    commit('SET_DOCUMENT', defaultDocument())
  },
  async save({ commit, rootState }, document) {
    document.client = rootState.user.userClient
    const { data } = await documentParameterizeService.save(document)
    commit('SET_DOCUMENT', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
