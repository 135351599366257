
const defaultCommittee = () => ({
  _id: null,
  evaluatedCollaborators: [],
  typePlace: '',
  evaluatedCollaborator: '',
  evaluatingCollaborators: [],
  guestReviewers: [],
  realizationDate: '',
  initTime: '',
  endTime: '',
  lang: '',
  place: {
    physical: null,
    conference: ''
  }
})

const state = {
  committee: defaultCommittee()
}

const getters = {}

const mutations = {
  SET_COMMITTEE: (state, payload) => {
    state.committee = payload
  }
}

const actions = {
  load({ commit }, committee) {
    commit('SET_COMMITTEE', committee)
  },
  clear({ commit }) {
    commit('SET_COMMITTEE', defaultCommittee())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
