import * as skillsFormService from '@/services/skills-form-service'

const defaultSkillsForm = {
  _id: null,
  position: null,
  name: null,
  description: null,
  skillsFields: [],
  sections: [],
  fields: [],
  files: [],
  client: null,
  createdBy: null,
  updatedBy: null
}

const state = {
  skillsForm: defaultSkillsForm
}

const getters = {}

const mutations = {
  SET_SKILLSFORM: (state, payload) => {
    state.skillsForm = payload
  }
}

const actions = {
  loadSkillsForm({ commit }, skillsFormData) {
    commit('SET_SKILLSFORM', skillsFormData)
  },
  clearSkillsForm({ commit }) {
    const defSkillsForm = {
      position: null,
      name: null,
      description: null,
      skillsFields: [],
      sections: [],
      fields: [],
      files: [],
      client: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_SKILLSFORM', defSkillsForm)
  },
  save({ commit, rootState }, skillsForm) {
    skillsForm.client = rootState.user.userClient
    skillsFormService.saveSkillsForm(skillsForm)
      .then(({ data }) => {
        commit('SET_SKILLSFORM', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
