import { cyclePeopleMeetingService } from '@/services/cycle-people-meeting-service'

const state = {
  unity: null,
  leadership: {},
  selectedCollaborator: {},
  collaborators: []
}

const mutations = {
  SET_COLLABORATORS: (state, collaborators) => {
    state.collaborators = collaborators
  },
  SET_SELECTED_COLLABORATOR: (state, selectedCollaborator) => {
    state.selectedCollaborator = selectedCollaborator
  },
  RESET_SELECTED_COLLABORATOR: (state) => {
    state.selectedCollaborator = {}
    state.leadership = {}
  },
  RESET_SELECTED_UNITY: (state) => {
    state.unity = null
  },
  SET_UNITY: (state, unity) => {
    state.unity = unity
  }
}

const actions = {
  setSelectedCollaborator({ commit }, { selectedCollaborator }) {
    commit('SET_SELECTED_COLLABORATOR', selectedCollaborator)
  },
  resetSelectedCollaborator({ commit }) {
    commit('RESET_SELECTED_COLLABORATOR')
    return []
  },
  resetSelectedUnity({ commit }) {
    commit('RESET_SELECTED_UNITY')
    return []
  },
  async setUnity({ commit }, { unity }) {
    commit('SET_UNITY', unity)
  },
  async searchCollaborators(
    { rootState, rootGetters, commit },
    { cycle, term }
  ) {
    const { user } = rootState
    if (user.isCycleManager || rootGetters.isAdmin) {
      const { data } =
        await cyclePeopleMeetingService.searchCollaboratorsAsCycleManager(
          user.userClient,
          user.userCpf,
          cycle,
          term,
          rootState.cyclePeopleMeeting.unity
        )
      return commit('SET_COLLABORATORS', data)
    }

    const { data } = await cyclePeopleMeetingService.searchCollaborators(
      user.userClient,
      user.userCpf,
      cycle,
      term,
      rootState.cyclePeopleMeeting.unity
    )
    commit('SET_COLLABORATORS', data)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
