var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        options: _vm.schema.values,
        multiple: true,
        "close-on-select": true,
        min: _vm.schema.min || null,
        max: _vm.schema.max || null,
        "allow-empty": !_vm.schema.required,
        placeholder: _vm.$t("multiselect_placeholder"),
        "select-label": _vm.$t("multiselect_select_label"),
        "select-group-label": _vm.$t("multiselect_select_group_label"),
        "selected-label": _vm.$t("multiselect_selected_label"),
        "deselect-label": _vm.$t("multiselect_deselect_label"),
        "deselect-group-label": _vm.$t("multiselect_deselect_group_label"),
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "maxElements" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm
                .$t("multiselect_max_elements")
                .replace("{max}", _vm.schema.max)
            ) +
            "\n  "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }