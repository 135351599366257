<template>
  <div v-show="false" />
</template>
<script>
import Swal from 'sweetalert2'
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'DMReportNotification',
  sockets: {
    connect: function () {
      console.log('Report notification connected')
    },
    reportAvailableForDownloadEvent: async function (data) {
      if (data.requestedBy._id !== this.userId) return
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: this.$t('seurelatorioficoupronto'),
        text: this.$t('cliquenobotaodownloadparabaixarorelatorio'),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#53933a',
        cancelButtonColor: '#d33',
        confirmButtonText: _.capitalize(this.$t('download')),
        cancelButtonText: _.capitalize(this.$t('cancel'))
      }).then((result) => {
        if (result.isConfirmed) {
          this.download(data.file.file, data.file.originalName)
        }
      })
    }
  },
  computed: {
    ...mapState('user', ['userId'])
  },
  methods: {
    async download(fileUrl, fileName) {
      var link = document.createElement('a')
      link.href = fileUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

</script>
