const tabs = {
  DocumentAnalyzeList: () =>
    import('@/views/client/ClientDocumentAnalyzeList'),
  DocumentAnalyze: () =>
    import('@/views/client/ClientDocumentAnalyze')
}

const state = {
  currentDocumentAnalyzeTab: tabs.DocumentAnalyzeList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentDocumentAnalyzeTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentDocumentAnalyzeTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  },
  goToList({ commit }) {
    commit('SET_TABS', 'DocumentAnalyzeList')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
