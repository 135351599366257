const defaultCurrentUserMenuTabIndex = 0

const state = {
  currentUserMenuTabIndex: defaultCurrentUserMenuTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTUSERMENUTABINDEX: (state, payload) => {
    state.currentUserMenuTabIndex = payload
  }
}

const actions = {
  setCurrentUserMenuTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTUSERMENUTABINDEX', currentTabIndex)
  },
  clearCurrentUserMenuTabIndex({ commit }) {
    const defCurrentUserMenuTabIndex = 0
    commit('SET_CURRENTUSERMENUTABINDEX', defCurrentUserMenuTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
