<template>
  <b-input
    v-model="value"
    v-limitInputDate
    type="date"
    placeholder=""
    today-button
    close-button
    hide-header
    :min="schema.min || '1900-01-01'"
    :max="schema.max || '2099-12-31'"
    :label-today-button="$t('selecionarhoje')"
    :label-close-button="$t('fechar')"
    :locale="$store.getters.locale"
    @blur="customValidateDate"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField],
  methods: {
    customValidateDate() {
      if (this.value && this.value.length > 10) {
        this.value = null
        return
      }

      const date = new Date(this.value)

      const year = date.getFullYear().toString()
      if (year.length !== 4) {
        this.value = null
      }
    }
  }
}
</script>
