import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class DocumentParameterizeService extends BaseService {
  constructor() {
    super('/document-type-parameterize')
  }

  getByKeywordWithModule(client, module, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!module) {
      throw new Error('Module is required!')
    }
    const query = qs.stringify({ client, limit, page, word, module })
    return service.get(
      `${this.baseURL}/by_client_and_module_hierarchy_word?${query}`
    )
  }

  getByClientAndModule(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client_and_module/${client}/${module}?${query}`
    )
  }

  getByDocumentType(documentTypeId) {
    if (!documentTypeId) {
      throw new Error('Document type id is required!')
    }
    return service.get(`${this.baseURL}/by_document_type/${documentTypeId}`)
  }
}

export const documentParameterizeService = new DocumentParameterizeService()
