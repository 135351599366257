const state = {
  updatedItem: null
}

const mutations = {
  SET_UPDATED_ITEM: (state, payload) => {
    state.updatedItem = payload
  },
  RESET_UPDATED_ITEM: (state) => {
    state.updatedItem = null
  }
}

const actions = {
  setUpdateItem({ commit }, item) {
    commit('SET_UPDATED_ITEM', item)
  },
  resetUpdateItem({ commit }) {
    commit('RESET_UPDATED_ITEM')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
