
import qs from 'querystring'
import { BaseService, service } from './base-service'

export class ActionPlanCauseAnalysisService extends BaseService {
  constructor() {
    super('/action-plan-cause-analysis')
  }
  async getByEligible(eligible) {
    if (!eligible) {
      throw new Error('Eligible is required!')
    }

    return await service.get(`${this.baseURL}/by_eligible/${eligible}`)
  }
  async getFormsByCycle(cycleId) {
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }

    return await service.get(`${this.baseURL}/forms_by_cycle/${cycleId}`)
  }

  async getQuestionsAndAnswers(form, cycle, eligible) {
    if (!form) {
      throw new Error('formId required!')
    }
    if (!cycle) {
      throw new Error('cycleId is required!')
    }
    if (!eligible) {
      throw new Error('eligibleDocument is required!')
    }

    const query = qs.stringify({ form, cycle, eligible })
    return await service.get(`${this.baseURL}/questions_and_answers?${query}`)
  }
}

export const actionPlanCauseAnalysisService = new ActionPlanCauseAnalysisService()
