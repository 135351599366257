import * as cyclePerformanceEvaluationService from '@/services/cycle-performance-evaluation'
import * as personBondService from '@/services/person-bond-service'
import { unityService } from '@/services/unity-service'
import slugify from 'slugify'
import { hideLoader, showLoader } from '@/utils/loading'

const initGoal = (goalNumber) => {
  return {
    number: goalNumber,
    name: '',
    weight: '',
    achievement: ''
  }
}

const reorderGoals = (goals) => {
  return goals.map((goal, index) => ({ ...goal, number: index + 1 }))
}

const parseGoals = (goals) => {
  return goals.map((goal) => ({
    ...goal,
    weight: Number(goal.weight),
    achievement: Number(goal.achievement)
  }))
}

const state = {
  hierarchy: '',
  leadership: {},
  selectedCollaborator: {},
  collaborators: [],
  goals: [initGoal(1)],
  result: {
    name: '',
    percentage: 0,
    result: null
  },
  showResult: false
}

const mutations = {
  SHOW_RESULT: (state) => {
    state.showResult = true
  },
  SET_LEADERSHIP: (state, leadership) => {
    state.leadership = leadership
  },
  SET_COLLABORATORS: (state, collaborators) => {
    state.collaborators = collaborators
  },
  SET_SELECTED_COLLABORATOR: (state, selectedCollaborator) => {
    state.selectedCollaborator = selectedCollaborator
  },
  SET_HIERARCHY: (state, hierarchy) => {
    state.hierarchy = hierarchy
  },
  RESET_SELECTED_COLLABORATOR: (state) => {
    state.selectedCollaborator = {}
    state.hierarchy = ''

    state.goals = [initGoal(1)]
    state.result = {
      name: '',
      percentage: 0,
      result: null
    }
    state.showResult = false
  },
  ADD_NEW_GOAL: (state) => {
    state.goals.push(initGoal(state.goals.length + 1))
  },
  REMOVE_GOAL: (state, goalNumber) => {
    state.goals = state.goals.filter((goal) => goal.number !== goalNumber)
    if (state.goals.length === 0) {
      state.goals.push(initGoal(1))
    }
    state.goals = reorderGoals(state.goals)
  },
  LOAD_RESULT_BY_COLLABORATOR: (state, result) => {
    state.result = result || {}
    state.goals = reorderGoals(result.goals || [initGoal(1)])
  },
  SET_RESULT_NAME_AND_PERCENTAGE: (state, { name, percentage }) => {
    state.result = { ...state.result, result: { name, percentage }}
  },
  SET_GOAL_ID: (state, { goalNumber, goalId }) => {
    for (const goal of state.goals) {
      if (goal.number === goalNumber) {
        goal._id = goalId
        break
      }
    }
  }
}

const actions = {
  setSelectedCollaborator({ commit }, { selectedCollaborator }) {
    commit('SET_SELECTED_COLLABORATOR', selectedCollaborator)
  },
  resetSelectedCollaborator({ commit }) {
    commit('RESET_SELECTED_COLLABORATOR')
    return []
  },

  addNewGoal({ commit }) {
    commit('ADD_NEW_GOAL')
  },

  async removeGoal({ commit, dispatch, state, rootState }, goalNumber) {
    try {
      showLoader()
      const goal = state.goals.find((goal) => goal.number === goalNumber)
      if (goal._id) {
        await cyclePerformanceEvaluationService.deleteGoal(
          state.result._id,
          goal._id
        )
      }
      commit('REMOVE_GOAL', goalNumber)

      if (state.showResult) {
        await dispatch('calculateResult', {
          cycle: rootState.cycle.cycle._id,
          goals: state.goals
        })
      }
    } finally {
      hideLoader()
    }
  },

  hasGoalWithName({ state }, name) {
    const slugOptions = { strict: true, lower: true }
    return (
      state.goals.filter((goal) => {
        return slugify(goal.name, slugOptions) === slugify(name, slugOptions)
      }).length > 1
    )
  },

  async setLeadership({ commit }, { client, document }) {
    const { data } = await personBondService.getByDocumentAndClient(
      document,
      client
    )
    commit('SET_LEADERSHIP', data)
  },

  async searchCollaborators(
    { rootState, rootGetters, commit },
    { cycle, leadership, term }
  ) {
    const { user } = rootState
    if (user.isCycleManager || rootGetters.isAdmin) {
      const { data } =
        await cyclePerformanceEvaluationService.searchCollaboratorsAsCycleManager(
          user.userClient,
          user.userCpf,
          cycle,
          term
        )
      return commit('SET_COLLABORATORS', data)
    }

    if (cycle && leadership) {
      const { data } =
      await cyclePerformanceEvaluationService.searchCollaborators(
        user.userCpf,
        cycle,
        leadership,
        term
      )
      commit('SET_COLLABORATORS', data)
    }
  },

  async setHierarchy({ commit }, { client, unity }) {
    if (!client || !unity) return ''
    const { data: units } = await unityService.getByClient(client)
    const hierarchy = unityService.getSuperiorHierarchyByUnity(
      unity,
      units,
      ' > '
    )
    commit('SET_HIERARCHY', hierarchy)
  },

  async loadResultByCollaborator({ commit }, { cycle, collaborator }) {
    try {
      showLoader()

      const { data } =
        await cyclePerformanceEvaluationService.getResultByCollaborator(
          cycle,
          collaborator
        )

      commit('LOAD_RESULT_BY_COLLABORATOR', data)

      if (data && data._id) {
        commit('SHOW_RESULT')
      }
    } finally {
      hideLoader()
    }
  },

  async saveGoal(
    { commit, rootState, state, dispatch },
    { performanceEvaluation, goal }
  ) {
    try {
      showLoader()
      if (!goal) return

      if (goal._id) {
        await cyclePerformanceEvaluationService.updateGoal(
          performanceEvaluation,
          goal._id,
          parseGoals([goal])[0]
        )
      } else {
        const { data } = await cyclePerformanceEvaluationService.addGoal(
          performanceEvaluation,
          parseGoals([goal])[0]
        )
        commit('SET_GOAL_ID', { goalNumber: goal.number, goalId: data._id })
      }

      if (state.showResult) {
        await dispatch('calculateResult', {
          cycle: rootState.cycle.cycle._id,
          goals: state.goals
        })
      }
    } finally {
      hideLoader()
    }
  },

  async saveAllGoals(
    { dispatch, state, commit },
    { performanceEvaluation }
  ) {
    try {
      showLoader()
      if (!state.goals) return

      for (const goal of state.goals) {
        await dispatch('saveGoal', { performanceEvaluation, goal })
      }

      commit('SHOW_RESULT')
    } finally {
      hideLoader()
    }
  },

  async create({ commit }, { client, cycle, collaborator, goals }) {
    try {
      showLoader()
      const payload = {
        client,
        cycle,
        eligible: collaborator,
        goals: parseGoals(goals)
      }
      const { data } = await cyclePerformanceEvaluationService.create(payload)
      commit('LOAD_RESULT_BY_COLLABORATOR', data)
    } finally {
      hideLoader()
    }
  },

  async createByButton({ commit, dispatch }, { client, cycle, collaborator, goals }) {
    await dispatch('create', { client, cycle, collaborator, goals })

    commit('SHOW_RESULT')
  },

  async calculateResult({ commit, rootState, dispatch }, { cycle, goals }) {
    try {
      showLoader()

      const parsedGoals = parseGoals(goals)

      const weights = parsedGoals.map((goal) => goal.weight)
      const achievements = parsedGoals.map((goal) => goal.achievement)

      const { user } = rootState
      const client = user.userClient

      const { data } = await cyclePerformanceEvaluationService.calculateResult(
        client,
        cycle,
        weights,
        achievements
      )
      commit('SET_RESULT_NAME_AND_PERCENTAGE', data)
    } finally {
      hideLoader()
    }
  },

  async calculateResultByButton({ commit, rootState, dispatch, state }) {
    await dispatch('calculateResult', {
      cycle: rootState.cycle.cycle._id,
      goals: state.goals
    })

    commit('SHOW_RESULT')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
