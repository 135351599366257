const tabs = {
  DocumentBindList: () => import('@/components/DMDocument/DMDocumentBind'),
  DocumentBind: () => import('@/components/DMDocument/DMDocumentBind/DMDocumentBind')
}

const state = {
  currentDocumentBindTab: tabs.DocumentBindList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentDocumentBindTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentDocumentBindTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit, state }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
