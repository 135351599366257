const tabs = {
  LeadershipHierarchyList: () => import('@/views/management/LeadershipHierarchyList'),
  LeadershipHierarchy: () => import('@/views/management/LeadershipHierarchy')
}

const state = {
  currentTab: tabs.LeadershipHierarchyList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = state.tabs[tab]
  }
}

const actions = {
  setLeadershipHierarchyTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
