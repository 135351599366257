import { documentTypeService } from '@/services/document-type-service'
import { unityService } from '@/services/unity-service'
import { getPositionsByClient } from '@/services/position-service'
import { getBondsByClient } from '@/services/bond-service'
import { documentLinkService } from '@/services/document-link-service'

const defaultDocumentLinks = () => []
const defaultDocumentLinksTotalRows = () => 0
const defaultDocumentLinksTotalPages = () => 1

const state = {
  unitsHierarchy: [],
  documentTypes: [],
  positions: [],
  availablePositions: [],
  bonds: [],
  link: {
    _id: null,
    documentType: null,
    unities: [],
    positions: [],
    bonds: []
  },

  documentLinks: defaultDocumentLinks(),
  documentLinksTotalRows: defaultDocumentLinksTotalRows(),
  documentLinksTotalPages: defaultDocumentLinksTotalPages()
}

const defaultLink = () => ({
  _id: null,
  documentType: null,
  unities: [],
  positions: [],
  bonds: []
})

const mutations = {
  SET_UNITS_HIERARCHY: (state, payload) => {
    state.unitsHierarchy = payload
  },
  SET_DOCUMENT_TYPES: (state, payload) => {
    state.documentTypes = payload
  },
  SET_POSITIONS: (state, payload) => {
    state.positions = payload
  },
  SET_BONDS: (state, payload) => {
    state.bonds = payload
  },
  SET_LINK: (state, payload) => {
    state.link = payload
  },
  SET_DOCUMENT_LINKS: (state, payload) => {
    state.documentLinks = payload
  },
  SET_DOCUMENT_LINKS_TOTAL_ROWS: (state, payload) => {
    state.documentLinksTotalRows = payload
  },
  SET_DOCUMENT_LINKS_TOTAL_PAGES: (state, payload) => {
    state.documentLinksTotalPages = payload
  },
  SET_AVAILABLE_POSITIONS: (state, payload) => {
    state.availablePositions = payload
  },
  SET_LINK_POSITIONS: (state, payload) => {
    state.link.positions = payload
  }
}

const getters = {
  IS_EDIT: (state) => {
    return state.link._id !== null
  },
  positionsList: (state) => {
    if (state.availablePositions.length > 0) {
      return state.availablePositions
    }

    return state.positions
  }
}

const defaultModule = 'movimentacaoregistro'

const actions = {
  async fetchDocumentLinks(
    { commit, rootGetters, state },
    options = { limit: 20, page: 1 }
  ) {
    const { data } = await documentLinkService.getAllDocumentLinksByClient(
      rootGetters['userClient'],
      options.limit,
      options.page
    )

    commit('SET_DOCUMENT_LINKS', data.docs)
    commit('SET_DOCUMENT_LINKS_TOTAL_ROWS', data.totalDocs)
    commit('SET_DOCUMENT_LINKS_TOTAL_PAGES', data.totalPages)
  },
  async fetchUnitsHierarchy({ commit, rootGetters }) {
    const { data } = await unityService.getTreeByHierarchyWithoutNotMonitoring(
      rootGetters['userClient']
    )
    commit('SET_UNITS_HIERARCHY', data)
  },
  async fetchDocumentTypes({ commit, rootGetters }) {
    const { data } = await documentTypeService.getByClientAndModule(
      rootGetters['userClient'],
      rootGetters['modules'][defaultModule]
    )
    commit('SET_DOCUMENT_TYPES', data)
  },
  async fetchDocumentLinkByDocumentType({ commit }, payload) {
    const { data } = await documentLinkService.getDocumentLinkByDocumentType(
      payload
    )
    return data
  },
  async fetchPositions({ rootGetters, commit }) {
    const { data } = await getPositionsByClient(rootGetters['userClient'])
    commit('SET_POSITIONS', data)
  },

  async setPositionsList({ commit }, payload) {
    commit('SET_POSITIONS', payload)
    commit('SET_LINK_POSITIONS', [])
  },
  setAvailablePositionsList({ commit }, payload) {
    commit('SET_AVAILABLE_POSITIONS', payload)

    if (payload.length > 0) {
      commit('SET_LINK_POSITIONS', payload)
    }
  },
  async fetchBonds({ rootGetters, commit }) {
    const { data } = await getBondsByClient(rootGetters['userClient'])
    commit('SET_BONDS', data)
  },
  async createOrUpdateLink({ state, getters, rootGetters, dispatch }) {
    if (!getters.IS_EDIT) {
      await documentLinkService.createDocumentLink({
        client: rootGetters['userClient'],
        documentType: state.link.documentType,
        bonds: state.link.bonds.map((bond) => bond._id),
        unities: state.link.unities,
        positions: state.link.positions.map((pos) => pos._id)
      })
    }

    if (getters.IS_EDIT) {
      await documentLinkService.updateDocumentLink(state.link._id, {
        client: rootGetters['userClient'],
        documentType: state.link.documentType,
        bonds: state.link.bonds.map((bond) => bond._id),
        unities: state.link.unities,
        positions: state.link.positions.map((pos) => pos._id)
      })
    }
  },

  updateDocumentLink({ commit }, payload) {
    commit('SET_LINK', payload)
  },

  async deleteDocumentLink({ commit, dispatch }, payload) {
    await documentLinkService.deleteDocumentLink(payload)
  },

  resetDocumentLink({ commit }) {
    commit('SET_LINK', defaultLink())
  },

  resetDocumentLinks({ commit }) {
    commit('SET_DOCUMENT_LINKS', defaultDocumentLinks())
    commit('SET_DOCUMENT_LINKS_TOTAL_ROWS', defaultDocumentLinksTotalRows())
    commit('SET_DOCUMENT_LINKS_TOTAL_PAGES', defaultDocumentLinksTotalPages())
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
