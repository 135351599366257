import { screenService } from '@/services/screen-service'

// const defScreens = {
//   companyTypes: '6201161b24069184be0d1d8a',
//   company: '61cca09983b728106c40a1c6',
//   structure: '61cca0cd83b728106c40a1c7',
//   userTypes: '61cca15a83b728106c40a1c8',
//   users: '61cca17283b728106c40a1c9',
//   registerDocuments_inCompanyModule: '61cca1af83b728106c40a1ca',
//   parameterizeDocuments_inCompanyModule: '61fd27f1f332b0ca636c9151'
//   linkDocuments_inCompanyModule: '61d8a4145d5564316824ae17',
//   uploadDocuments_inCompanyModule: '61cca1c383b728106c40a1cb',
//   analyseDocuments_inCompanyModule: '61d8a49c5d5564316824ae1a',
//   consultDocuments_inCompanyModule: '61cca1d883b728106c40a1cc',
//   configurations: '61cdc27fbd1a392e8c5c637a',
//   selectionStages: '61d895505d5564316824adf5',
//   positions_inRecruitmentModule: '61d895a35d5564316824adf6',
//   forms_inRecruitmentModule: '61d895b75d5564316824adf7',
//   evaluations_inRecruitmentModule: '61d895c65d5564316824adf8',
//   jobs: '61d895d55d5564316824adf9',
//   positionDescription: '61d895ed5d5564316824adfa',
//   jobRequest: '61d896015d5564316824adfb',
//   jobSelectionStages: '61d896125d5564316824adfc',
//   registrationForm: '61d896305d5564316824adfd',
//   registrationLink: '61d896455d5564316824adfe',
//   candidates: '61d896535d5564316824adff',
//   selection: '61d896625d5564316824ae00',
//   schedules: '61d8966f5d5564316824ae01',
//   manageStages: '61d8967b5d5564316824ae02',
//   exams: '61d8968c5d5564316824ae03',
//   agencies: '61d896985d5564316824ae04',
//   insuranceResearch: '61d896a65d5564316824ae05',
//   dailyRoutine: '61f2f0e5ee6b5f2fd0756688',
//   admission: '61cdc635bd1a392e8c5c637b',
//   workLeaves: '61cdc664bd1a392e8c5c637c',
//   absences: '61cdc678bd1a392e8c5c637d',
//   dismissal: '61cdc692bd1a392e8c5c637e',
//   vacation: '61cdc6b0bd1a392e8c5c637f',
//   rises: '61cdc6ecbd1a392e8c5c6382',
//   occurrences: '61cdc6ffbd1a392e8c5c6383',
//   transfer: '61cdc70fbd1a392e8c5c6384',
//   hierarchy_inMovementModule: '61cdc71ebd1a392e8c5c6385',
//   registerDocuments_inMovementModule: '61cdc73bbd1a392e8c5c6386',
//   parameterizeDocuments_inMovementModule: '61fd284df332b0170b6c9152,
//   linkDocuments_inMovementModule: '61d8a4705d5564316824ae18',
//   uploadDocuments_inMovementModule: '61cdc74fbd1a392e8c5c6387',
//   analyseDocuments_inMovementModule: '61d8a4835d5564316824ae19',
//   consultDocuments_inMovementModule: '61cdc761bd1a392e8c5c6388',
//   contractType: '61cdc773bd1a392e8c5c6389',
//   files: '61cde02dbd1a392e8c5c638a',
//   training: '61d89a115d5564316824ae06',
//   grid: '61d89a235d5564316824ae07',
//   researches: '61d89a7c5d5564316824ae08',
//   cyclesList: '61d89c395d5564316824ae09',
//   cycleRegistration: '61d89c4d5d5564316824ae0a',
//   eligibles: '61d89c5a5d5564316824ae0b',
//   cycleElegiblesEvaluations: '61d89c685d5564316824ae0c',
//   classificationsAndWeights: '61d89c745d5564316824ae0d',
//   comittee: '61d89c805d5564316824ae0e',
//   results: '61d89c8c5d5564316824ae0f',
//   feedback: '61d89c985d5564316824ae10',
//   actionPlan: '61d89ca45d5564316824ae11',
//   cycleStages: '61d89cb65d5564316824ae12',
//   forms_inCarrerModule: '61d89cce5d5564316824ae13',
//   evaluations_inCareerModule: '61d89d465d5564316824ae14',
//   hierarchy_inCareerModule: '61d89d545d5564316824ae15',
//   feed: '623094ec82fbee30e4faed98',
//   consultCollaborators: '6230963b82fbeefa80faed99',
//   files_inWorkplaceModule: '623096c282fbee62dcfaed9a',
//   grid_inWorkplaceModule: '6230973282fbee03b3faed9b',
//   registerFiles_inWorkplaceModule: '623097f782fbee3ffafaed9c',
//   interviews: '6238791f12e3ddedb7b52c4c',
//   consultHierarchies: '626bda79d3b9341380467079',
//   reports: '62bde0609def4b48a3e5cb55',
//   analysis: '62bde0b89def4b6501e5cb56'
//   requests_inWorkplaceModule: '63bc05834d0360a68c458d58'
//   customHierarchy: '6410bbb1182eff490097ad6f'
// }

const state = {
  screens: {}
}

const getters = {}

const actions = {
  async getScreens({ commit }) {
    const { data } = await screenService.get()
    commit('SET_SCREENS', data)
  }
}

const mutations = {
  SET_SCREENS(state, screens) {
    const transformedScreens = {}
    if (Array.isArray(screens)) {
      screens.map(screen => {
        transformedScreens[screen.name] = screen._id
      })
      state.screens = transformedScreens
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
