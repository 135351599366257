import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { clientConfigurationService } from '@/services/client-configuration-service'
import { centralDemarcoUserService } from './services/central-demarco-user-service'
import { ability, defineAbilityFor } from './config/ability'
import { userTypeConfigurationService } from '@/services/user-type-configuration-service'
import { isObjectId, isStringBoolean } from '@/utils/validate'
import { screenService } from '@/services/screen-service'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/insurance-research/form', '/login', '/auth-redirect', '/shared-recruitment-form', '/error',
  '/shared-recruitment-evaluation', '/shared-candidate-form', '/shared-evaluation',
  '/shared-research', '/sharedform', '/authenticate', '/auth/callback', '/auth/logout', '/nps/form', '/form-generator', '/committee-invite/invite', '/shared-answer-form',
  '/shared-answer-evaluation', '/terms-not-accepted'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo

      if (whiteList.indexOf(to.path) !== -1) {
        let queryIsValid = true

        if (to.path === '/shared-recruitment-form') {
          if (isStringBoolean(to.query.isAdmissionChecklist) && to.query.isAdmissionChecklist === 'true') {
            queryIsValid = isObjectId(to.query.form) && isObjectId(to.query.job)
          } else {
            queryIsValid = isObjectId(to.query.form) && isObjectId(to.query.job) && isObjectId(to.query.stage)
          }
        }

        return queryIsValid ? next() : next('/home')
      }
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        const client = store.getters.userClient
        const { data } = await clientConfigurationService.getByClient(client)

        const userModules = {
          client: client,
          modules: data.modules
        }

        const screensData = await screenService.get()
        const screens = screensData ? screensData.data : []

        const profile = store.getters.userProfile
        const userType = store.getters.userType
        let userTypeConfiguration = null

        if (profile === 'usuario') {
          const configuration = await userTypeConfigurationService.getByUserType(userType)
          userTypeConfiguration = configuration ? configuration.data : null
        }
        ability.update(defineAbilityFor(store.getters.userProfile, userModules, data, screens, userTypeConfiguration))
        const canNavigate = to.matched.some(route => {
          return ability.can(route.meta.action || 'read', route.meta.resource || 'all')
        })
        if ((!data && !userModules) || canNavigate) {
          return next()
        }
        if (to.path.includes('/home')) {
          return next()
        }
        // const firstPath = data.modules[0].path[0]
        // let firstPath = userModules ? userModules.modules.find(({ name }) => ability.can('read', name)) : data.modules.find(({ name }) => ability.can('read', name))
        // if (firstPath.length === 0) {
        //   firstPath = data.modules.find(({ name }) => ability.can('read', name))
        // }
        return next('/home')
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const data = await store.dispatch('user/getInfo')

          const userData = await centralDemarcoUserService.getByReferenceId(data.userid)
          const user = userData.data
          if (!user || !user.clients || user.clients.length === 0) {
            console.log('user has no clients!')
            await store.dispatch('user/logout')
          }
          const { roles } = data
          await store.dispatch('user/getVisibleHierarchy')

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          next(`/`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      let queryIsValid = true

      if (to.path === '/shared-recruitment-form') {
        if (isStringBoolean(to.query.isAdmissionChecklist) && to.query.isAdmissionChecklist === 'true') {
          queryIsValid = isObjectId(to.query.form) && isObjectId(to.query.job)
        } else {
          queryIsValid = isObjectId(to.query.form) && isObjectId(to.query.job) && isObjectId(to.query.stage)
        }
      }

      return queryIsValid ? next() : next('/home')
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      if (process.env.NODE_ENV === 'production') {
        location.href = `https://dmpeople.com.br/site/index.html`
        return NProgress.done()
      }
      location.href = `${process.env.VUE_APP_BASE_API}/auth?next=${location.pathname}`

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
