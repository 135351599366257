import { service } from './base-service'

const getRecruitmentForms = () => {
  return service.get('/recruitment-form')
}

const getRecruitmentFormById = (recruitmentFormId) => {
  if (!recruitmentFormId) {
    throw new Error('RecruitmentForm Id is required!')
  }
  return service.get(`/recruitment-form/${recruitmentFormId}`)
}

const getRecruitmentFormAndJobStatus = (formId, jobId) => {
  if (!formId) {
    throw new Error('Form Id is required!')
  }
  if (!jobId) {
    throw new Error('Job Id is required!')
  }
  return service.get(`/recruitment-form/shared?formId=${formId}&jobId=${jobId}`)
}

const getRecruitmentFormsByClient = (client, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/recruitment-form/client/${client}?limit=${limit}&page=${page}`)
}

const getRecruitmentFormsByKeyword = (client, word, page, limit) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/recruitment-form/by_client_word?client=${client}&word=${word}&page=${page}&limit=${limit}`)
}

const getRecruitmentFormsByUser = (userId) => {
  if (!userId) {
    throw new Error('User ID is required')
  }
  return service.get(`/recruitment-form/user/${userId}`)
}

const createRecruitmentForm = (recruitmentFormData) => {
  return service.post(`/recruitment-form`, recruitmentFormData)
}

const updateRecruitmentForm = (recruitmentFormId, recruitmentFormData) => {
  return service.put(`/recruitment-form/${recruitmentFormId}`, recruitmentFormData)
}

const saveRecruitmentForm = (recruitmentFormId, recruitmentFormData) => {
  if (recruitmentFormId) {
    return service.put(`/recruitment-form/${recruitmentFormId}`, recruitmentFormData)
  }
  return service.post(`/recruitment-form`, recruitmentFormData)
}

const deleteRecruitmentForm = (recruitmentFormId) => {
  return service.delete(`/recruitment-form/${recruitmentFormId}`)
}

export {
  getRecruitmentForms,
  getRecruitmentFormById,
  getRecruitmentFormAndJobStatus,
  getRecruitmentFormsByClient,
  getRecruitmentFormsByKeyword,
  getRecruitmentFormsByUser,
  createRecruitmentForm,
  updateRecruitmentForm,
  saveRecruitmentForm,
  deleteRecruitmentForm
}
