<template>
  <div class="img-container">
    <img :src="imageURL" :class="schema.class">
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'
import * as storage from '@/services/storage-service'
import { hideLoader, showLoader } from '@/utils/loading'

export default {
  mixins: [abstractField],
  data () {
    return {
      imageURL: ''
    }
  },
  watch: {
    async 'schema.image' (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        showLoader()
        await this.getImageURL()
        hideLoader()
      }
    }
  },
  async mounted() {
    if (!this.imageURL) {
      await this.getImageURL()
    }
  },
  methods: {
    async getImageURL () {
      let image = this.schema.image
      if (this.schema && this.schema.image) {
        if (typeof image === 'object' && image.hasOwnProperty('_id')) {
          image = image._id
        }
      } else {
        image = this.schema.resourceId // Old forms has only resourceId
      }
      const { data } = await storage.findById(image)
      this.imageURL = data.file
    }
  }
}
</script>

<style scoped>
.img-container {
  width: 100%;
}

img {
  display: block;
  height: auto;
}

.pequena {
  max-width: 100px !important;
}

.media {
  max-width: 50% !important;
  object-fit: contain;
}

.grande {
  max-width: 100% !important;
  object-fit: contain;
}
</style>
