import { BaseService, service } from './base-service'
import qs from 'querystring'

export class EligibleService extends BaseService {
  constructor() {
    super('/eligible')
  }

  sendAnnouncement(id) {
    return service.post(`/eligible/sendemail/${id}`)
  }

  getByName(
    cycle,
    client,
    limit,
    page,
    keyword,
    select = null,
    idsToIgnore = [],
    positions = [],
    units = []
  ) {
    if (!cycle) {
      throw new Error('Cycle name is required!')
    }

    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({
      cycle,
      client,
      limit,
      page,
      keyword,
      select,
      idsToIgnore: idsToIgnore.toString(),
      positions: positions.toString(),
      units: units.toString()
    })
    return service.get(`${this.baseURL}/by_name?${query}`)
  }

  async getByCycleCondition(client, limit, page, cycleId, search = '') {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, search })
    return service.get(
      `${this.baseURL}/by_cycle_condition_excluded/${cycleId}?${query}`
    )
  }

  saveByPersonBondAndCycle(personBondId, cycleId) {
    if (!personBondId) {
      throw new Error('PersonBond id is required!')
    }
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }
    const query = qs.stringify({ personBondId, cycleId })
    return service.post(
      `${this.baseURL}/create-by-person-bond-and-cycle?${query}`,
      {}
    )
  }

  getByCyclePositionsAndUnits(
    client,
    cycle,
    limit,
    page,
    search = '',
    positions = [],
    units = []
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({
      client,
      cycle,
      positions,
      units,
      limit,
      page,
      search
    })
    return service.get(
      `${this.baseURL}/list-eligible-by-cycle-unity-positions?${query}`
    )
  }

  getByCyclePositionsAndUnitsAllStatus(
    client,
    cycle,
    limit,
    page,
    search = '',
    positions = [],
    units = []
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({
      client,
      cycle,
      positions,
      units,
      limit,
      page,
      search
    })
    return service.get(
      `${this.baseURL}/list-eligible-by-cycle-unity-positions-all-status?${query}`
    )
  }

  getBySkapCyclePositionsAndUnits(
    client,
    cycle,
    limit,
    page,
    search = '',
    positions = [],
    units = []
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({
      client,
      cycle,
      positions,
      units,
      limit,
      page,
      search
    })
    return service.get(
      `${this.baseURL}/list-eligible-by-skap-cycle-unity-positions?${query}`
    )
  }

  getPossibleByCycleAndKeyword(cycleId, keyword = null) {
    if (!cycleId) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ keyword })
    return service.get(
      `${this.baseURL}/list-possible-eligible/${cycleId}?${query}`
    )
  }

  getByDocumentAndCycle(document, cycleId) {
    if (!cycleId || !document) {
      throw new Error('Cycle and document is required!')
    }
    return service.get(
      `${this.baseURL}/by-document-and-cycle/${document}/${cycleId}`
    )
  }
  searchCanExecuteByStep(stepId, term) {
    if (!stepId) {
      throw new Error('Step is required!')
    }
    const query = qs.stringify({ step: stepId, term })
    return service.get(
      `${this.baseURL}/search-can-execute-by-step/${stepId}?${query}`
    )
  }

  evaluationBeacomGetByCycle(cycleId) {
    if (!cycleId) {
      throw new Error('Cycle is required!')
    }
    return service.get(`${this.baseURL}/evaluation-beacom/by-cycle/${cycleId}`)
  }

  evaluationBeacomSearch(
    cycleId,
    limit,
    page,
    { unity, leadership, collaborator, status }
  ) {
    if (!cycleId) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({
      limit,
      page,

      unity,
      leadership,
      collaborator,
      status
    })
    return service.get(
      `${this.baseURL}/evaluation-beacom/search/${cycleId}?${query}`
    )
  }

  evaluationBeacomSearchExport(cycleId, lang) {
    if (!cycleId) {
      throw new Error('Cycle is required!')
    }
    return service.get(
      `${this.baseURL}/evaluation-beacom/search/${cycleId}/export`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getPreviousCycleData(eligible, client) {
    if (!eligible || !client) {
      throw new Error('EligibleId and client is required!')
    }

    const url = `${this.baseURL}/get-previous-cycle-by-eligible-and-client/${eligible}/${client}`
    return service.get(url)
  }

  getPreviousCycleClassification(eligible, client) {
    if (!eligible || !client) {
      throw new Error('EligibleId and client is required!')
    }

    const url = `${this.baseURL}/get-previous-cycle-classification-by-eligible-and-client/${eligible}/${client}`
    return service.get(url)
  }

  getCurrentCycleData(eligible) {
    if (!eligible) {
      throw new Error('Client is required!')
    }

    const url = `${this.baseURL}/get-current-cycle-by-eligible-and-client/${eligible}`
    return service.get(url)
  }
  getSuggestedRatingByEligible(eligible) {
    if (!eligible) {
      throw new Error('Client is required!')
    }

    const url = `${this.baseURL}/get-suggested-rating-by-eligible-and-client/${eligible}`
    return service.get(url)
  }
}

export const eligibleService = new EligibleService()
