const defaultCurrentManagementTabIndex = 0

const state = {
  currentManagementTabIndex: defaultCurrentManagementTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTMANAGEMENTTABINDEX: (state, payload) => {
    state.currentManagementTabIndex = payload
  }
}

const actions = {
  setCurrentManagementTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTMANAGEMENTTABINDEX', currentTabIndex)
  },
  clearCurrentManagementTabIndex({ commit }) {
    const defCurrentManagementTabIndex = 0
    commit('SET_CURRENTMANAGEMENTTABINDEX', defCurrentManagementTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
