const tabs = {
  Clinic: () => import('@/views/recruitment/Clinic'),
  ClinicList: () => import('@/views/recruitment/ClinicList')
}

const state = {
  currentClinicTab: tabs.ClinicList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentClinicTab = state.tabs[tab]
  }
}

const actions = {
  setClinicTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
