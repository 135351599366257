import { actionPlanService } from '@/services/action-plan-service'

const state = {
  actionPlanFollowStep: null
}

const mutations = {
  SET_ACTION_PLAN_FOLLOW_STEP: (state, payload) => {
    state.actionPlanFollowStep = payload
  }
}

const actions = {
  async save({ rootState, rootGetters }, actionPlan) {
    actionPlan.client = rootState.user.userClient
    if (actionPlan._id) {
      actionPlan.updatedBy = rootGetters.userId
    } else {
      actionPlan.createdBy = rootGetters.userId
    }
    const { data } = await actionPlanService.saveFollow(actionPlan)
    return data
  },
  setActionPlanFollowStep({ commit }, actionPlanStep) {
    commit('SET_ACTION_PLAN_FOLLOW_STEP', actionPlanStep)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
