import * as trainingService from '@/services/training-service'
// import { Message } from 'element-ui'

// import i18n from '@/plugins/i18n'

const defaultTraining = {
  _id: '',
  name: '',
  description: '',
  isRequired: null,
  createdAt: null,
  updatedAt: null,
  trainingGroup: null,
  createdBy: null,
  updatedBy: null,
  client: null,
  keywords: [],
  resources: [],
  linkedDemarcoAcademy: false,
  certificateTemplate: null,
  demarcoAcademyCode: null
}

const state = {
  training: defaultTraining
}

const getters = {}

const mutations = {
  SET_TRAINING: (state, payload) => {
    state.training = payload
  }
}

const actions = {
  load({ commit }, trainingId) {
    trainingService.getById(trainingId)
      .then(({ data }) => {
        data.trainingGroup = data.trainingGroup ? data.trainingGroup._id : null
        commit('SET_TRAINING', data)
      })
  },
  async save({ commit, rootState }, training) {
    training.client = rootState.user.userClient
    return trainingService.save(training)
      .then(({ data }) => {
        data.trainingGroup = data.trainingGroup ? data.trainingGroup._id : null
        commit('SET_TRAINING', data)
        // Message.success(i18n.t('treinamentocadastrado'))
        return Promise.resolve()
      })
  },
  async remove({ commit }, trainingId) {
    return trainingService.remove(trainingId)
      .then(() => {
        commit('SET_TRAINING', defaultTraining)
        // Message.success(i18n.t('treinamentoexcluido'))
        return Promise.resolve()
      })
  },
  clear({ commit }) {
    const defTraining = {
      _id: '',
      name: '',
      description: '',
      isRequired: null,
      createdAt: null,
      updatedAt: null,
      trainingGroup: null,
      createdBy: null,
      updatedBy: null,
      client: null,
      keywords: [],
      resources: [],
      certificateTemplate: null,
      linkedDemarcoAcademy: false,
      demarcoAcademyCode: null
    }
    commit('SET_TRAINING', defTraining)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
