const tabs = {
  ActionPlanList: () => import('@/views/career/actionplan/ActionPlanList'),
  ActionPlan: () => import('@/views/career/actionplan/ActionPlanRegistration'),
  ActionPlanChecker: () => import('@/views/career/actionplan/ActionPlanChecker'),
  Follow: () => import('@/views/career/actionplan/ActionPlanFollow')
}

const state = {
  alert: { type: '', message: '' },
  currentActionPlanTab: tabs.ActionPlanList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentActionPlanTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentActionPlanTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit, state }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
