const state = {
  selectedUsers: []
}

const mutations = {
  ADD_USER: (state, id) => {
    state.selectedUsers.push(id)
  },
  REMOVE_USER: (state, idToRemove) => {
    state.selectedUsers = state.selectedUsers.filter((id) => id !== idToRemove)
  },
  CLEAR_USERS: (state) => {
    state.selectedUsers = []
  }
}

const actions = {
  addUser({ commit }, userId) {
    commit('ADD_USER', userId)
  },
  removeUser({ commit }, userId) {
    commit('REMOVE_USER', userId)
  },
  clearUsers({ commit }) {
    commit('CLEAR_USERS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
