import { service } from './base-service'
import qs from 'querystring'
import { param } from '@/utils'

const getPositions = () => {
  return service.get('/position')
}

const getPositionById = (positionId) => {
  if (!positionId) {
    throw new Error('Position Id is required!')
  }
  return service.get(`/position/${positionId}`)
}

const getPositionsByClient = (client, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ page, limit })
  return service.get(`/position/client/${client}?${query}`)
}

const getSimplePositionsByClient = (client, fields, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ fields, page, limit })
  return service.get(`/position/simple/client/${client}?${query}`)
}

const getPositionsWithDeletedByClient = (client, fields, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ fields, page, limit })
  return service.get(`/position/with_deleted/client/${client}?${query}`)
}

const getPositionsByKeyword = (client, word, page, limit) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  if (page) param.page = page
  if (limit) param.limit = limit

  const query = qs.stringify({ client, word, page, limit })
  return service.get(`/position/by_keyword?${query}`)
}

const getPositionsByUser = (userId) => {
  if (!userId) {
    throw new Error('User ID is required')
  }
  return service.get(`/position/user/${userId}`)
}

const getPositionsByTypes = (client, page, limit, types) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ page, limit })
  const paramsTypes = types.map((type) => type._id).join(',')
  return service.get(
    `/position/by_client_hierarchy_types/${paramsTypes}?${query}`
  )
}

const getPositionsByIds = (jobStageIds) => {
  const ids = encodeURIComponent(JSON.stringify(jobStageIds))
  if (!jobStageIds) {
    throw new Error('Position Ids is required!')
  }
  return service.get(`/position/ids/${ids}`)
}

const createPosition = (positionData) => {
  return service.post(`/position`, positionData)
}

const updatePosition = (positionId, positionData) => {
  return service.put(`/position/${positionId}`, positionData)
}

const savePosition = (positionData) => {
  if (positionData._id) {
    return updatePosition(positionData._id, positionData)
  }
  delete positionData._id
  return createPosition(positionData)
}

const deletePosition = (positionId) => {
  return service.delete(`/position/${positionId}`)
}

export {
  getPositions,
  getPositionById,
  getPositionsByClient,
  getSimplePositionsByClient,
  getPositionsWithDeletedByClient,
  getPositionsByUser,
  getPositionsByTypes,
  createPosition,
  savePosition,
  updatePosition,
  deletePosition,
  getPositionsByKeyword,
  getPositionsByIds
}
