import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class UserClientConfigurationService extends BaseService {
  constructor() {
    super('/user-client-configuration')
  }

  getByUser = (user, limit, page) => {
    const query = qs.stringify({ limit, page })
    return service.get(`/user-client-configuration/user/${user}?${query}`)
  }

  getByUserAndClient = (user, client) => {
    const query = qs.stringify({ user, client })
    return service.get(`/user-client-configuration/by_user_client?${query}`)
  }
}

export const userClientConfigurationService = new UserClientConfigurationService()
