import { service } from './base-service'

const getAll = () => {
  return service.get('/training')
}

const getByClient = (client, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/training/client/${client}?page=${page}&limit=${limit}`)
}

const getByClientSimple = (client, page, limit) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/training/client/simple/${client}?page=${page}&limit=${limit}`)
}

const getByClientAndKeyword = (client, word, page, limit) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/training/by_client_word?client=${client}&word=${word}&page=${page}&limit=${limit}`)
}

const getByClientAndKeywordSimple = (client, word, page, limit) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/training/by_client_word/simple?client=${client}&word=${word}&page=${page}&limit=${limit}`)
}

const getById = (trainingId) => {
  if (!trainingId) throw new Error('trainingId is required!')
  return service.get(`/training/${trainingId}`)
}

const save = (trainingData) => {
  if (trainingData._id) {
    return update(trainingData._id, trainingData)
  }
  if (Array.isArray(trainingData.resources)) {
    trainingData.resources = trainingData.resources.map(({ _id }) => _id)
  }
  return create(trainingData)
}

const create = (trainingData) => {
  delete trainingData._id
  return service.post(`/training`, trainingData)
}

const update = (trainingId, trainingData) => {
  return service.put(`/training/${trainingId}`, trainingData)
}

const remove = (trainingId) => {
  return service.delete(`/training/${trainingId}`)
}

export {
  getAll,
  getByClient,
  getByClientSimple,
  getByClientAndKeyword,
  getByClientAndKeywordSimple,
  getById,
  save,
  create,
  update,
  remove
}
