var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("autocomplete", {
    ref: "autocompletePersonDocument",
    staticStyle: { width: "100%" },
    attrs: {
      "auto-select": "",
      search: _vm.filterPeople,
      "get-result-value": _vm.getPeopleResult,
      "debounce-time": 500,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }