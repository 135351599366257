var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("b-form-input", {
        attrs: {
          type: "number",
          "aria-describedby": "input-live-feedback",
          min: _vm.schema.min,
          max: _vm.schema.max,
          state: _vm.validaNumero,
        },
        on: { change: _vm.validaNumero2 },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("b-form-invalid-feedback", { attrs: { id: "input-live-feedback" } }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("valorminimo") +
                ": " +
                (_vm.schema.min || "-") +
                " - " +
                _vm.$t("valormaximo") +
                ": " +
                (_vm.schema.max || "-")
            ) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }