import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class DocumentLinkService extends BaseService {
  constructor() {
    super('/document-link')
  }

  createDocumentLink(data) {
    if (!data) {
      throw new Error('Data for creating a document link is required!')
    }
    return service.post(this.baseURL, data)
  }

  updateDocumentLink(id, data) {
    if (!id) {
      throw new Error('Document link ID is required for updating!')
    }
    if (!data) {
      throw new Error('Data for updating the document link is required!')
    }
    return service.put(`${this.baseURL}/${id}`, data)
  }

  deleteDocumentLink(id) {
    if (!id) {
      throw new Error('Document link ID is required for deletion!')
    }
    return service.delete(`${this.baseURL}/${id}`)
  }
  deleteByDocumentType(documentType) {
    if (!documentType) {
      throw new Error('Document type ID is required for deletion!')
    }
    return service.delete(`${this.baseURL}/by_document_type/${documentType}`)
  }

  getDocumentLinksByClient(id, client, limit, page) {
    const query = qs.stringify({ client, limit, page })

    if (!id) {
      throw new Error('Document link ID is required to retrieve data!')
    }
    return service.get(`${this.baseURL}/${id}?${query}`)
  }

  getDocumentLinkById(id, client, limit, page) {
    const query = qs.stringify({ client, limit, page })
    if (!id) {
      throw new Error('Document link ID is required to retrieve data!')
    }
    return service.get(`${this.baseURL}/${id}?${query}`)
  }

  getDocumentLinkByDocumentType(documentTypeId) {
    if (!documentTypeId) {
      throw new Error('Document type ID is required to retrieve data!')
    }
    return service.get(`${this.baseURL}/by_document_type/${documentTypeId}`)
  }

  getAllDocumentLinksByClient(client, limit, page) {
    const query = qs.stringify({ client, limit, page })
    return service.get(`${this.baseURL}/client/${client}?${query}`)
  }
}

export const documentLinkService = new DocumentLinkService()
