import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class ClinicService extends BaseService {
  constructor() {
    super('/clinic')
  }

  getByClientAndKeyword = (client, word, page, limit) => {
    const query = qs.stringify({ client, word, page, limit })
    if (!word) {
      throw new Error('Keyword is required!')
    }
    if (!client) {
      throw new Error('Client name is required!')
    }
    return service.get(`/clinic/by_client_word?${query}`)
  }
}

export const clinicService = new ClinicService()
