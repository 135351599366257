import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'
import store from '@/store'

class DocumentDepositService extends BaseService {
  constructor() {
    super('/document-deposit')
  }

  getDepositUnitsByFilter(client, limit, page, documentType, competence) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      client,
      limit,
      page,
      documentType,
      competence
    })
    return service.get(`/document-deposit/company/by_filter?${query}`)
  }

  getByFilter(
    client,
    limit,
    page,
    unity,
    startDate,
    endDate,
    status,
    collaborator,
    frequency,
    documentType
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      client,
      limit,
      page,
      unity,
      startDate,
      endDate,
      documentType,
      collaborator,
      frequency,
      status
    })
    return service.get(`/document-deposit/by_filter?${query}`)
  }

  getByKeywordWithModule(client, module, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!module) {
      throw new Error('Module is required!')
    }

    const query = qs.stringify({ client, limit, page, word, module })
    return service.get(
      `${this.baseURL}/by_client_and_module_hierarchy_word?${query}`
    )
  }

  getByClientAndModule(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client_and_module/${client}/${module}?${query}`
    )
  }

  getWithoutNotSentByClientAndModule(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/without_not_sent_by_client_and_module/${client}/${module}?${query}`
    )
  }

  getAnalyzesByClientAndModule(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/analyzes_by_client_and_module/${client}/${module}?${query}`
    )
  }

  getHistoryByDeposit(depositId) {
    if (!depositId) {
      throw new Error('Deposit id is required!')
    }

    return service.get(`${this.baseURL}/history_by_deposit/${depositId}`)
  }

  hasDepositByDocumentType(documentTypeId) {
    if (!documentTypeId) {
      throw new Error('DocumentType Id is required!')
    }
    return service.get(
      `${this.baseURL}/has-deposit-by-document-type/${documentTypeId}`
    )
  }

  hasAnalyzeByDocumentType(documentTypeId) {
    if (!documentTypeId) {
      throw new Error('DocumentType Id is required!')
    }
    return service.get(
      `${this.baseURL}/has-analyze-by-document-type/${documentTypeId}`
    )
  }

  getByClientModuleStatus(
    client,
    module,
    limit,
    page,
    collaborator,
    unity,
    startDate,
    endDate,
    status,
    frequency,
    documentType
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!module) {
      throw new Error('Module is required!')
    }

    const query = qs.stringify({
      client,
      module,
      limit,
      page,
      unity,
      startDate,
      endDate,
      documentType,
      collaborator,
      frequency,
      status
    })
    return service.get(
      `/document-deposit/by_client_module_status/${client}/${module}?${query}`
    )
  }

  cancel(id) {
    return service.put(`${this.baseURL}/cancel/${id}`)
  }

  saveAnalyze(data) {
    const { _id } = data
    data.client = data.client ? data.client : store.getters.userClient
    return service.put(`${this.baseURL}/analyze/${_id}`, data)
  }

  saveAll(array) {
    array = array.map((item) => {
      item.client = item.client || store.getters.userClient
      return item
    })

    if (array.every((item) => item._id)) {
      return service.put(`${this.baseURL}/all`, array)
    } else if (array.every((item) => item._id == null)) {
      return service.post(`${this.baseURL}/all`, array)
    }

    throw new Error('Invalid data!')
  }

  getWorksheetByClientAndHierarchy(client, lang, unity,
    startDate,
    endDate,
    status,
    collaborator,
    frequency,
    documentType) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      client,
      unity,
      startDate,
      endDate,
      documentType,
      collaborator,
      frequency,
      status
    })

    return service.get(
      `${this.baseURL}/by_client_hierarchy_worksheet?${query}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getWorksheetWithoutNotSentByClientAndHierarchy(client,
    module,
    collaborator,
    unity,
    startDate,
    endDate,
    status,
    frequency,
    documentType,
    lang
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!module) {
      throw new Error('Module is required!')
    }

    const query = qs.stringify({
      client,
      module,
      unity,
      startDate,
      endDate,
      documentType,
      collaborator,
      frequency,
      status
    })

    return service.get(
      `${this.baseURL}/by_client_hierarchy_worksheet_without_not_sent?${query}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }
}

export const documentDepositService = new DocumentDepositService()
