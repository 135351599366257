const defaultCurrentVacationTabIndex = 0

const state = {
  currentVacationTabIndex: defaultCurrentVacationTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTVACATIONTABINDEX: (state, payload) => {
    state.currentVacationTabIndex = payload
  }
}

const actions = {
  setCurrentVacationTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTVACATIONTABINDEX', currentTabIndex)
  },
  clearCurrentVacationTabIndex({ commit }) {
    const defCurrentVacationTabIndex = 0
    commit('SET_CURRENTVACATIONTABINDEX', defCurrentVacationTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
