const tabs = {
  AdmissionsList: () => import('@/views/management/AdmissionsList'),
  PeopleTabs: () => import('@/views/management/PeopleTabs')
}

const state = {
  currentTab: tabs.AdmissionsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = state.tabs[tab]
  }
}

const actions = {
  setAdmissionTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
