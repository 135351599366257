const tabs = {
  CareerEvaluationsList: () => import('@/views/career/evaluation')
}

const state = {
  currentCareerEvaluationTab: tabs.CareerEvaluationsList,
  tabs: tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentCareerEvaluationTab = state.tabs[tab]
  }
}

const actions = {
  setCareerEvaluationTabs({ commit, dispatch }, tab) {
    dispatch('dmEvaluation/setViewToList', {}, { root: true })
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
