import { service } from './base-service'

const upload = async (file, resource = '/storage/upload') => {
  return service({
    method: 'POST',
    url: resource,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

const findById = async (fileId) => {
  return service.get(`/storage/${fileId}`)
}

const deleteFile = async (fileId) => {
  return service.delete(`/storage/${fileId}`)
}

export { upload, deleteFile, findById }
