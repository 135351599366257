const defaultCurrentTrainingFollowUpTabIndex = 0

const state = {
  currentTrainingFollowUpTabIndex: defaultCurrentTrainingFollowUpTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTTRAININGFOLLOWUPTABINDEX: (state, payload) => {
    state.currentTrainingFollowUpTabIndex = payload
  }
}

const actions = {
  setCurrentTrainingFollowUpTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTTRAININGFOLLOWUPTABINDEX', currentTabIndex)
  },
  clearCurrentTrainingFollowUpTabIndex({ commit }) {
    const defCurrentTrainingFollowUpTabIndex = 0
    commit('SET_CURRENTTRAININGFOLLOWUPTABINDEX', defCurrentTrainingFollowUpTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
