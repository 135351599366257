const tabs = {
  TrainingExecutionList: () => import('@/views/trainings/execution/execution-tabs/TrainingExecutionList.vue'),
  ScheduleExecutionList: () => import('@/views/trainings/execution/execution-tabs/ScheduleExecutionList.vue'),
  SchedulingExecution: () => import('@/views/trainings/execution/execution-tabs/SchedulingExecution.vue')
}

const state = {
  tabs,
  tab: tabs.List
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.tab = state.tabs[tab]
  }
}

const actions = {
  setTrainingExecutionListTab({ commit }) {
    commit('SET_TABS', 'TrainingExecutionList')
  },
  setScheduleExecutionListTab({ commit }) {
    commit('SET_TABS', 'ScheduleExecutionList')
  },
  setSchedulingExecutionTab({ commit }) {
    commit('SET_TABS', 'SchedulingExecution')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
