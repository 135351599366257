const defaultCandidateJob = {
  job: {},
  isCollaborator: false,
  profile: [],
  experience: [],
  otherInformation: [],
  hasBlockingRestriction: false,
  weight: null,
  isApproved: false,
  evaluations: [],
  skills: []
}

const state = {
  currentJobOfCandidate: defaultCandidateJob
}

const getters = {}

const mutations = {
  SET_CURRENTJOBOFCANDIDATE: (state, payload) => {
    state.currentJobOfCandidate = payload
  }
}

const actions = {
  loadCandidateJob({ commit }, candidateJobData) {
    commit('SET_CURRENTJOBOFCANDIDATE', candidateJobData)
  },
  clearCandidateJob({ commit }) {
    const defCandidateJob = {
      job: {},
      isCollaborator: false,
      profile: [],
      experience: [],
      otherInformation: [],
      hasBlockingRestriction: false,
      weight: null,
      isApproved: false,
      evaluations: [],
      skills: []
    }
    commit('SET_CURRENTJOBOFCANDIDATE', defCandidateJob)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
