import { documentBindService } from '@/services/document-bind-service'

const defaultDocument = () => ({
  _id: false,
  documentType: null,
  units: [],
  companies: [],
  positions: [],
  positionReviewers: [],
  employees: [],
  functionOrActivity: null
})

const state = {
  document: defaultDocument(),
  newBind: {
    has: false,
    message: ''
  }
}

const getters = {
  hasDocument: state => state.document._id != null
}

const mutations = {
  SET_DOCUMENT: (state, payload) => {
    state.document = payload
  }
}

const actions = {
  load({ commit }, document) {
    commit('SET_DOCUMENT', document)
  },
  clear({ commit }) {
    commit('SET_DOCUMENT', defaultDocument())
  },
  async save({ commit, rootState }, document) {
    document.client = rootState.user.userClient
    const { data } = await documentBindService.save(document)
    commit('SET_DOCUMENT', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
