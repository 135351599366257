import { actionPlanCauseAnalysisService } from '@/services/action-plan-cause-analyses-service'

const defaultActionPlanCauseAnalysis = () => ({
  eligible: null,
  causes: {
    customForm: null,
    questions: []
  },
  fiveWhys: []
})
const state = {
  actionPlanCauseAnalysis: defaultActionPlanCauseAnalysis(),
  lastWhy: null
}

const getters = {
  actionPlanCauseAnalysis: (state) => state.actionPlanCauseAnalysis
}
const mutations = {
  SET_ACTION_PLAN_CAUSE_ANALYSIS: (state, payload) => {
    state.actionPlanCauseAnalysis = payload

    state.lastWhy = [...payload.fiveWhys].reverse().find((item) => item.why)
      ? [...payload.fiveWhys].reverse().find((item) => item.why).why
      : null
  },
  RESET_ACTION_PLAN_CAUSE_ANALYSIS: (state) => {
    state.actionPlanCauseAnalysis = defaultActionPlanCauseAnalysis()
    state.lastWhy = null
  }
}

const actions = {
  load({ commit }, actionPlanCauseAnalysis) {
    commit('SET_ACTION_PLAN_CAUSE_ANALYSIS', actionPlanCauseAnalysis)
  },
  clear({ commit }) {
    commit('SET_ACTION_PLAN_CAUSE_ANALYSIS', defaultActionPlanCauseAnalysis())
  },
  async save({ commit, rootState }, actionPlanCauseAnalysis) {
    actionPlanCauseAnalysis.client = rootState.user.userClient
    const { data } = await actionPlanCauseAnalysisService.save(
      actionPlanCauseAnalysis
    )
    commit('SET_ACTION_PLAN_CAUSE_ANALYSIS', data)
    return data
  },
  async getActionPlanCauseAnalisys({ commit }, eligible) {
    const { data } = await actionPlanCauseAnalysisService.getByEligible(
      eligible
    )
    return commit('SET_ACTION_PLAN_CAUSE_ANALYSIS', data)
  },
  resetActionPlanCauseAnalisys({ commit }) {
    return commit('RESET_ACTION_PLAN_CAUSE_ANALYSIS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
