<template>
  <multiselect
    v-model="value"
    :options="schema.values"
    :multiple="true"
    :close-on-select="true"
    :min="schema.min || null"
    :max="schema.max || null"
    :allow-empty="!schema.required"
    :placeholder="$t('multiselect_placeholder')"
    :select-label="$t('multiselect_select_label')"
    :select-group-label="$t('multiselect_select_group_label')"
    :selected-label="$t('multiselect_selected_label')"
    :deselect-label="$t('multiselect_deselect_label')"
    :deselect-group-label="$t('multiselect_deselect_group_label')"
  >
    <template slot="maxElements">
      {{ $t("multiselect_max_elements").replace("{max}", schema.max) }}
    </template>
  </multiselect>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField]
}
</script>
