import store from '@/store'

const showLoader = () => {
  store.dispatch('app/showLoader')
}

const hideLoader = () => {
  store.dispatch('app/hideLoader')
}

export {
  showLoader,
  hideLoader
}
