const defaultCurrentUserTabIndex = 0

const state = {
  currentUserTabIndex: defaultCurrentUserTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTUSERTABINDEX: (state, payload) => {
    state.currentUserTabIndex = payload
  }
}

const actions = {
  setCurrentUserTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTUSERTABINDEX', currentTabIndex)
  },
  clearCurrentUserTabIndex({ commit }) {
    const defCurrentUserTabIndex = 0
    commit('SET_CURRENTUSERTABINDEX', defCurrentUserTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
