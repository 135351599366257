<template>
  <b-form-group>
    <b-form-input
      id="input-live"
      v-model="value"
      trim
      type="email"
      aria-describedby="input-live-help input-live-feedback"
      :disabled="schema.disabled"
      :readonly="schema.readonly"
      :state="isValidEmail"
    />
    <b-form-invalid-feedback v-if="!isValidEmail" id="input-live-feedback">
      {{ $t("informeemailvalido") }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField],
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  computed: {
    isValidEmail: function () {
      if (!this.value || this.value === null) {
        return true
      }
      return this.reg.test(this.value)
    }
  }
}
</script>
