const tabs = {
  ResearchesList: () => import('@/views/researches/ResearchesList'),
  ResearchTabs: () => import('@/views/researches/ResearchTabs'),
  ResearchView: () => import('@/views/researches/ResearchView'),
  ResearchAnswerReport: () => import('@/views/researches/ResearchAnswerReport')
}

const state = {
  currentTab: tabs.ResearchesList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = state.tabs[tab]
  }
}

const actions = {
  setTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
