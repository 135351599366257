<template>
  <div class="conditional-field">
    <vue-form-generator v-if="model && model[schema.conditionalQuestion] === schema.conditionalAnswer" :key="index" :schema="mySchema" :model="model" :options="formOptions" />
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'

export default {
  components: {
    'vue-form-generator': VueFormGenerator.component
  },
  mixins: [abstractField],
  data() {
    return {
      index: 0,
      mySchema: {
        fields: []
      }
    }
  },
  created() {
    this.mySchema.fields.push(this.schema.child)
  }
}
</script>
<style>
  .conditional-field {
    width: 100%;
    margin-left: -20px;
  }
</style>
