const defaultJobRegistration = () => ({
  _id: null,
  job: null,
  candidate: null,
  document: null,
  isCollaborator: null,
  optin: null,
  profile: null,
  experience: null,
  otherInformation: null,
  skills: null,
  hasBlockingRestriction: null,
  weight: null,
  isApproved: null,
  selection: null,
  jobAdherence: null,
  processCompletion: null,
  processFinalOpinion: null,
  finalComments: null,
  admissionChecklist: null,
  resume: null
})

const state = {
  jobRegistration: defaultJobRegistration()
}

const getters = {}

const mutations = {
  SET_JOB_REGISTRATION: (state, payload) => {
    state.jobRegistration = payload
  }
}

const actions = {
  loadJobRegistration({ commit }, payload) {
    commit('SET_JOB_REGISTRATION', payload)
  },
  clearJobRegistration({ commit }) {
    commit('SET_JOB_REGISTRATION', defaultJobRegistration())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
