var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "b-modal",
        {
          ref: "modalTermsOfUse",
          attrs: {
            id: "modalTermsOfUse",
            title: _vm.$t("termosdeuso"),
            size: "lg",
            "ok-only": true,
            "ok-title": _vm.$t("avancar"),
            "ok-disabled": !_vm.termsOfUse,
            "no-close-on-esc": true,
            "no-close-on-backdrop": true,
            "hide-header-close": true,
          },
          on: { ok: _vm.modalOk, hide: _vm.modalOk, cancel: _vm.modalOk },
        },
        [
          _c(
            "b-row",
            {
              staticClass: "justify-content-center",
              attrs: { "align-h": "center" },
            },
            [
              _c("b-col", { attrs: { col: "", md: "12" } }, [
                _c("object", {
                  attrs: {
                    data: _vm.termodeusoFile,
                    type: "application/pdf",
                    width: "135%",
                    height: "450px",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "checkbox-1",
                        name: "checkbox-1",
                        value: true,
                        "unchecked-value": false,
                      },
                      model: {
                        value: _vm.termsOfUse,
                        callback: function ($$v) {
                          _vm.termsOfUse = $$v
                        },
                        expression: "termsOfUse",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("aceitotermosdeuso")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _vm.device !== "mobile"
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "empresa mb-3" },
        [
          _c("div", { staticClass: "font-weight-bold mt-2" }, [
            _vm._v(_vm._s(_vm.$t("empresa")) + ":"),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "select-company", attrs: { md: "12" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-select",
                    {
                      staticClass: "custom-select-company",
                      attrs: { id: "user-perfil" },
                      on: { change: _vm.changeClient },
                      model: {
                        value: _vm.client,
                        callback: function ($$v) {
                          _vm.client = $$v
                        },
                        expression: "client",
                      },
                    },
                    [
                      _vm._l(_vm.clients, function (c, i) {
                        return _c(
                          "b-form-select-option",
                          {
                            key: i,
                            staticClass: "client-option",
                            attrs: { value: c.code, "preselect-first": false },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(c.name) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("img", {
                  staticClass: "right-menu-item lang",
                  attrs: { src: require("@/assets/brasil.png") },
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale("pt-BR")
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "right-menu-item lang",
                  attrs: { src: require("@/assets/espanha.png") },
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale("es")
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "right-menu-item lang",
                  attrs: { src: require("@/assets/usa.jpg") },
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale("en")
                    },
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.user
            ? _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom",
                      modifiers: { hover: true, bottom: true },
                    },
                  ],
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { title: _vm.name, trigger: "click" },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("../../assets/avatar2.png") },
                    }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm.device === "mobile"
                        ? [
                            _c("el-dropdown-item", [
                              _c(
                                "span",
                                { staticStyle: { display: "block" } },
                                [_vm._v(_vm._s(_vm.$t("linguagem")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLocale("pt-BR")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "margin-left": "10px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("portugues")))]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-dropdown-item", { attrs: { divided: "" } }, [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLocale("es")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "margin-left": "10px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("espanhol")))]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-dropdown-item", { attrs: { divided: "" } }, [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeLocale("en")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "margin-left": "10px",
                                        "font-weight": "600",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("ingles")))]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/" } },
                        [
                          _c("el-dropdown-item", { attrs: { divided: "" } }, [
                            _vm._v(_vm._s(_vm.$t("titletreinamentos"))),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(_vm._s(_vm.$t("sair"))),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }