var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("b-form-file", {
        attrs: {
          state: Boolean(_vm.value),
          multiple: _vm.schema.multiple === true,
          placeholder: _vm.$t("escolhaarquivo"),
          "drop-placeholder": _vm.$t("arquivo") + "...",
          enctype: "multipart/form-data",
        },
        model: {
          value: _vm.fieldValue,
          callback: function ($$v) {
            _vm.fieldValue = $$v
          },
          expression: "fieldValue",
        },
      }),
      _vm._v(" "),
      _vm.file
        ? _c(
            "div",
            { staticClass: "mt-2 dm-files__lista-files" },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-append",
                    { staticStyle: { width: "100%", "margin-top": "18px" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.file.originalName,
                          type: "text",
                          readonly: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-danger" },
                          on: {
                            click: function ($event) {
                              return _vm.remove()
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "trash", variant: "danger" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }