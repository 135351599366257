import { actionPlanService } from '@/services/action-plan-service'
import { eligibleService } from '@/services/eligible-service'

const defaultActionPlan = () => ({
  _id: null,
  eligible: null,
  actions: [],
  follows: [],
  type: null
})
const state = {
  actionPlan: defaultActionPlan(),
  actionPlanStep: null,
  collaborators: [],
  collaboratorSelected: null,
  leadership: null,
  eligibleCycleSteps: []
}

const getters = {
  hasActionPlan: (state) => state.actionPlan._id != null
}

const mutations = {
  SET_ACTION_PLAN: (state, payload) => {
    state.actionPlan = { ...state.actionPlan, ...payload }
  },
  SET_CYCLE_STEPS: (state, payload) => {
    state.eligibleCycleSteps = payload
  },
  SET_COLLABORATORS: (state, collaborators) => {
    const selectableCollaborators = collaborators.filter((collaborator) => {
      return (
        state.actionPlanStep.pdiClassifications.includes(
          collaborator.finalClassification._id
        ) ||
        state.actionPlanStep.prClassifications.includes(
          collaborator.finalClassification._id
        )
      )
    })

    state.collaborators = selectableCollaborators
  },
  SET_COLLABORATOR: (state, collaborator) => {
    state.collaboratorSelected = collaborator
    state.leadership = collaborator.leadership
    state.actionPlan.type = state.actionPlanStep.pdiClassifications.includes(
      collaborator.finalClassification._id
    )
      ? 'pdi'
      : 'pr'
  },
  RESET_SELECTED_COLLABORATOR: (state) => {
    state.collaboratorSelected = null
    state.leadership = null
    state.actionPlan = defaultActionPlan()
    state.eligibleCycleSteps = []
  },
  SET_ACTION_PLAN_STEP: (state, payload) => {
    state.actionPlanStep = payload
  }
}

const actions = {
  load({ commit }, actionPlan) {
    if (actionPlan.eligible._id._id) {
      commit('SET_CYCLE_STEPS', actionPlan.eligible._id.cycleSteps)
      actionPlan.eligible._id = actionPlan.eligible._id._id
    }
    commit('SET_ACTION_PLAN', actionPlan)
  },
  clear({ commit }) {
    commit('SET_ACTION_PLAN', defaultActionPlan())
  },
  setActionPlanStep({ commit }, actionPlanStep) {
    commit('SET_ACTION_PLAN_STEP', actionPlanStep)
  },
  async save({ commit, rootState, rootGetters }, actionPlan) {
    actionPlan.client = rootState.user.userClient
    if (actionPlan._id) {
      actionPlan.updatedBy = rootGetters.userId
    } else {
      actionPlan.createdBy = rootGetters.userId
    }
    const { data } = await actionPlanService.save(actionPlan)
    // commit('SET_ACTION_PLAN', data)
    return data
  },
  async approve({ commit, rootState, rootGetters }, actionPlan) {
    actionPlan.client = rootState.user.userClient
    if (actionPlan._id) {
      actionPlan.updatedBy = rootGetters.userId
    } else {
      actionPlan.createdBy = rootGetters.userId
    }
    const { data } = await actionPlanService.approve(actionPlan)
    // commit('SET_ACTION_PLAN', data)
    return data
  },
  async searchCollaborators(
    { rootState, rootGetters, commit },
    { cycle, leadership, term, unity }
  ) {
    const { user, actionPlan } = rootState
    if (actionPlan.actionPlan.eligible) {
      if (user.isCycleManager || rootGetters.isAdmin) {
        const { data } =
          await actionPlanService.searchCollaboratorsAsCycleManager(
            user.userClient,
            user.userCpf,
            cycle,
            term,
            unity
          )
        return commit('SET_COLLABORATORS', data)
      }
      if (cycle && leadership) {
        const { data } = await actionPlanService.searchCollaborators(
          user.userCpf,
          cycle,
          user.userClient,
          leadership,
          term,
          unity
        )
        return commit('SET_COLLABORATORS', data)
      } else {
        const { data } = await eligibleService.getById(
          actionPlan.actionPlan.eligible._id
        )
        return commit('SET_COLLABORATORS', [
          {
            _id: data._id,
            name: data.person.name,
            document: data.person.document,
            unity: data.unity.referenceId,
            position: data.position.name,
            leadership: data.leadership.person,
            finalClassification: data.finalClassification,
            admissionDate: data.admissionDate
          }
        ])
      }
    } else {
      if (user.isCycleManager || rootGetters.isAdmin) {
        const { data } =
          await actionPlanService.searchCollaboratorsAsCycleManagerWithouActionPlan(
            user.userClient,
            user.userCpf,
            cycle,
            term,
            unity
          )
        return commit('SET_COLLABORATORS', data)
      }
      if (cycle && leadership) {
        const { data } =
          await actionPlanService.searchCollaboratorsWithouActionPlan(
            user.userCpf,
            cycle,
            user.userClient,
            leadership,
            term,
            unity
          )
        return commit('SET_COLLABORATORS', data)
      } else {
        const { data } = await eligibleService.getByDocumentAndCycle(
          user.userCpf,
          cycle
        )

        if (
          actionPlan.actionPlanStep.pdiClassifications.includes(
            data.finalClassification._id
          )
        ) {
          return commit('SET_COLLABORATORS', [
            {
              _id: data._id,
              name: data.person.name,
              document: data.person.document,
              unity: data.unity.referenceId,
              position: data.position.name,
              leadership: data.leadership.person,
              finalClassification: data.finalClassification,
              admissionDate: data.admissionDate
            }
          ])
        }
        return commit('SET_COLLABORATORS', [])
      }
    }
  },

  setColaborator({ commit }, { collaborator }) {
    commit('SET_COLLABORATOR', collaborator)
  },
  setColaborators({ commit }, collaborators) {
    commit('SET_COLLABORATORS', collaborators)
  },
  resetSelectedCollaborator({ commit }) {
    commit('RESET_SELECTED_COLLABORATOR')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
