<template>
  <div class="video-preview">
    <b-embed type="iframe" :src="embed" allowfullscreen class="video-preview" />
  </div>
</template>

<script>
import DOMPurify from 'dompurify'
import { abstractField } from 'vue-form-generator/src/index'
import { isValidVideoDomain } from '@/utils/validate'

export default {
  mixins: [abstractField],
  computed: {
    src() {
      return DOMPurify.sanitize(this.schema.src)
    },
    embed() {
      if (!isValidVideoDomain(this.src)) return ''
      const link = this.src
      let id = null
      let linkVideo = ''
      if (link !== null) {
        if (link.includes('youtube') || link.includes('youtu.be')) {
          const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
          const match = link.match(regExp)
          id = match && match[2].length === 11 ? match[2] : null
          linkVideo = id ? `//www.youtube.com/embed/${id}` : link
        } else if (link.includes('vimeo')) {
          const str = link.split('/')
          id = str[str.length - 1]
          linkVideo = id ? `//player.vimeo.com/video/${id}` : link
        } else if (link.includes('drive.google.com/file/d')) {
          const str = link.split('/')
          id = str[str.length - 2]
          linkVideo = id
            ? `https://drive.google.com/file/d/${id}/preview`
            : link
        } else if (link.includes('drive.google.com/open?id')) {
          const str = link.split('=')
          id = str[str.length - 1]
          linkVideo = id
            ? `https://drive.google.com/file/d/${id}/preview`
            : link
        } else {
          linkVideo = link
        }
      }
      return linkVideo
    }
  }
}
</script>

