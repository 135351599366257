import qs from 'querystring'
import { BaseService } from './base-service'
import { service } from './base-service'

class UnityService extends BaseService {
  constructor() {
    super('/unity')
  }

  getTreeByClient = (client) => {
    return service.get(`/unity/tree/${client}`)
  }

  getTreeByClientWithoutNotMonitoring = (client) => {
    return service.get(`/unity/tree_without_not_monitoring/${client}`)
  }

  getTreeByHierarchy = (client) => {
    return service.get(`/unity/tree_by_hierarchy/${client}`)
  }

  getTreeByHierarchyWithoutNotMonitoring = (client) => {
    return service.get(`/unity/tree_by_hierarchy_without_not_monitoring/${client}`)
  }

  getTreeByHierarchySelectable = (client, fields) => {
    const query = qs.stringify({ fields })
    return service.get(`/unity/tree_by_hierarchy_selectable/${client}?${query}`)
  }

  getFullTreeByClient = (client, fields) => {
    const query = qs.stringify({ fields })
    return service.get(`/unity/full_tree/${client}?${query}`)
  }

  getByReferenceId = (referenceId) => {
    return service.get(`/unity/by_reference_id/${referenceId}`)
  }

  getNameByReferenceId = (referenceId) => {
    return service.get(`/unity/name/by_reference_id/${referenceId}`)
  }

  getAncestors = (unityId) => {
    return service.get(`/unity/ancestors/${unityId}`)
  }

  getAllChildren = (unityId) => {
    return service.get(`/unity/all_children/${unityId}`)
  }

  getMinClosingDate = (unityId) => {
    return service.get(`/unity/min_closing_date/${unityId}`)
  }

  getSuperiorHierarchyByUnity = (unityId, units, joinSeparator = ' - ') => {
    const unity = units.find(unity => unity.referenceId === unityId)
    const superiorUnits = unity ? unity.path.split('#') : []
    const hierarchy = superiorUnits.map(u => {
      const unt = units.find(unity => unity._id === u)
      return unt ? unt.name : ''
    }).join(joinSeparator)
    return hierarchy
  }

  delete(id, closingDate) {
    return service.delete(`${this.baseURL}/${id}/with_closing_date`, {
      headers: { 'Content-Type': 'application/json' },
      data: { closingDate }
    })
  }

  addUnityToUsers(client, unityReferenceId, userReferenceIds = []) {
    const url = `/unity/add-unity-to-users/${client}/${unityReferenceId}`
    return service.post(url, {
      userReferenceIds
    })
  }
}

export const unityService = new UnityService()
