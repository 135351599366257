import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
/* import componentsRouter from './modules/components'
import chartsRouter from './modules/charts'
import tableRouter from './modules/table'
import nestedRouter from './modules/nested' */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        // component: () => import('@/views/redirect/index')
        // redirect: `https://${process.env.VUE_AUTH_DEMARCO}/Account/Login`
        beforeEnter(to, from, next) {
          // Put the full page url including the protocol http(s) below
          window.location = `https://${process.env.VUE_AUTH_DEMARCO}/Account/Login`
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    /* beforeEnter(to, from, next) {
      // Put the full page url including the protocol http(s) below
      window.location = `https://${process.env.VUE_AUTH_DEMARCO}/Account/Login`
    },*/
    hidden: true
  },
  {
    path: '/shared-evaluation',
    component: () => import('@/views/trainings/academy/SharedEvaluation'),
    hidden: true
  },
  {
    path: '/error',
    component: () => import('@/views/auth/Error'),
    hidden: true
  },
  {
    path: '/terms-not-accepted',
    component: () => import('@/views/auth/TermsNotAccepted'),
    hidden: true
  },
  {
    path: '/shared-candidate-form',
    component: () => import('@/views/recruitment/SharedCandidateForm'),
    hidden: true
  },
  {
    path: '/shared-recruitment-form',
    component: () => import('@/views/recruitment/SharedRecruitmentForm'),
    hidden: true
  },
  {
    path: '/shared-recruitment-evaluation',
    component: () => import('@/views/recruitment/SharedRecruitmentEvaluation'),
    hidden: true
  },
  {
    path: '/shared-research',
    component: () => import('@/views/researches/SharedResearch'),
    hidden: true
  },
  {
    path: '/shared-answer-form',
    component: () => import('@/components/form/DMFormAnswer/DMFormAnswer'),
    hidden: true
  },
  {
    path: '/shared-answer-evaluation',
    component: () => import('@/components/DMEvaluation/DMEvaluationAnswer'),
    hidden: true
  },
  {
    path: '/nps/form',
    component: () => import('@/views/net-promoter-score/NetPromoterScoreForm'),
    hidden: true
  },
  {
    path: '/committee-invite/invite',
    component: () => import('@/views/invite/InviteForm'),
    hidden: true
  },
  {
    path: '/insurance-research/form',
    component: () =>
      import('@/views/recruitment/insurance-research/InsuranceResearchForm'),
    hidden: true
  },
  {
    path: '/auth/callback',
    component: () => import('@/views/auth/callback'),
    hidden: true
  },
  {
    path: '/auth/logout',
    component: () => import('@/views/auth/logout'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/home',
    hidden: true,
    alwaysShow: true
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/Home'),
        name: 'Home',
        meta: {
          title: 'home',
          icon: 'id-card',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'home'
    }
  },
  // {
  //   path: '/',
  //   redirect: '/management',
  //   hidden: true,
  //   alwaysShow: true,
  //   meta: {
  //     resource: 'movimentacaoregistro'
  //   }
  // },
  {
    path: '/client',
    component: Layout,
    redirect: '/client/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/client/ClientTabs'),
        name: 'Empresa',
        meta: {
          title: 'empresa',
          icon: 'company',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'empresa',
      icon: 'company'
    }
  },
  {
    path: '/configuration',
    component: Layout,
    redirect: '/configuration/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/configuration/index'),
        name: 'Configuração',
        meta: {
          title: 'configuracao',
          icon: 'user-cog',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'configuracao',
      icon: 'user-cog'
    }
  },
  {
    path: '/dossier',
    component: Layout,
    redirect: '/dossier/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/dossier/WorkplaceTabs'),
        name: 'Peoples',
        meta: {
          title: 'dossievirtual',
          icon: 'peoples',
          noCache: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'dossievirtual',
      icon: 'peoples'
    }
  },
  {
    path: '/recruitment',
    component: Layout,
    redirect: '/recruitment/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/recruitment/RecruitmentTabs'),
        name: '',
        meta: {
          title: 'recrutamentoselecao',
          icon: 'skill',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'recrutamentoselecao',
      icon: 'skill'
    }
  },
  {
    path: '/management',
    component: Layout,
    redirect: '/management/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/management/Management'),
        name: '',
        meta: {
          title: 'movimentacaoregistro',
          icon: 'id-card',
          affix: true,
          requiresAuth: true
        }
      },
      {
        path: '/people',
        component: () => import('@/views/management/AdmissionsList'),
        name: 'AdmissionsList',
        meta: {
          title: 'movimentacaoregistro'
        },
        requiresAuth: true,
        hidden: true
      },
      {
        path: '/people/:id',
        component: () => import('@/views/management/PeopleTabs'),
        name: 'PeopleTabs',
        meta: {
          title: 'movimentacaoregistro'
        },
        requiresAuth: true,
        hidden: true
      }
    ],
    meta: {
      resource: 'movimentacaoregistro',
      icon: 'id-card'
    }
  },
  {
    path: '/workday',
    component: Layout,
    redirect: '/workday/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/workday/WorkdayTabs'),
        name: '',
        meta: {
          title: 'jornadatrabalho',
          icon: 'calendar-check',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'jornadatrabalho',
      icon: 'calendar-check'
    }
  },
  {
    path: '/remuneration',
    component: Layout,
    redirect: '/remuneration/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/remuneration/RemunerationTabs'),
        name: '',
        meta: {
          title: 'remuneracaobeneficios',
          icon: 'dollar',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'remuneracaobeneficios',
      icon: 'dollar'
    }
  },
  {
    path: '/trainings',
    component: Layout,
    redirect: '/trainings/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/trainings/TrainingHome'),
        name: 'Treinamentos',
        meta: {
          title: 'integracaotreinamento',
          icon: 'education',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'integracaotreinamento',
      icon: 'education'
    }
  },
  {
    path: '/training-tabs',
    hidden: true,
    component: Layout,
    redirect: '/training-tabs/index',
    children: [
      {
        path: 'records',
        component: () => import('@/views/trainings/records/TrainingRecordsTabs'),
        name: 'Treinamentos',
        meta: {
          title: 'integracaotreinamento',
          icon: 'education',
          affix: true,
          requiresAuth: true
        }
      },
      {
        path: 'follow-up',
        component: () => import('@/views/trainings/follow-up/TrainingFollowUpTabs'),
        name: 'Treinamentos',
        meta: {
          title: 'integracaotreinamento',
          icon: 'education',
          affix: true,
          requiresAuth: true
        }
      },
      {
        path: 'academy',
        component: () => import('@/views/trainings/academy/TrainingAcademyTabs'),
        name: 'Treinamentos',
        meta: {
          title: 'integracaotreinamento',
          icon: 'education',
          affix: true,
          requiresAuth: true
        }
      },
      {
        path: 'execution',
        component: () => import('@/views/trainings/execution/ExecutionTabs'),
        name: 'Treinamentos',
        meta: {
          title: 'integracaotreinamento',
          icon: 'education',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'integracaotreinamento',
      icon: 'education'
    }
  },
  {
    path: '/researches',
    component: Layout,
    redirect: '/researches/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/researches/FollowTabs'),
        name: '',
        meta: {
          title: 'acompanhamentoretencao',
          icon: 'form',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'acompanhamentoretencao',
      icon: 'form'
    }
  },
  {
    path: '/career',
    component: Layout,
    redirect: '/career/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/career/CareerHome'),
        name: 'Career',
        meta: {
          title: 'desenvolvimentoecarreira',
          icon: 'signal',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'desenvolvimentoecarreira',
      icon: 'signal'
    }
  },
  {
    path: '/career-tabs',
    hidden: true,
    component: Layout,
    redirect: '/career-tabs/index',
    children: [
      {
        path: 'cycleconfiguration',
        component: () => import('@/views/career/CareerTabs'),
        name: 'Cycle Configuration',
        meta: {
          title: 'desenvolvimentoecarreira',
          icon: 'config',
          affix: true,
          requiresAuth: true
        }
      },
      {
        path: 'cycleexecution',
        component: () => import('@/views/career/CareerExecutionTabs'),
        name: 'Cycle Execution',
        meta: {
          title: 'desenvolvimentoecarreira',
          icon: 'config',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'desenvolvimentoecarreira',
      icon: 'signal'
    }
  },
  {
    path: '/reports',
    component: Layout,
    redirect: '/reports/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/reports/ReportsTabs'),
        name: 'Reports',
        meta: {
          title: 'analiseserelatorios',
          icon: 'analysis',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'analiseserelatorios',
      icon: 'analysis'
    }
  },
  {
    path: '/health',
    component: Layout,
    redirect: '/health/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/health/HealthTabs'),
        name: 'Health',
        meta: {
          title: 'avaliacoeseinspecoes',
          icon: 'health',
          affix: true,
          requiresAuth: true
        }
      }
    ],
    meta: {
      resource: 'saudeeseguranca',
      icon: 'health'
    }
  }
]

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

/* const onAuthRequired = async(from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    // Navigate to custom login page
    next({ path: '/login' })
  } else {
    next()
  }
}

router.beforeEach(onAuthRequired) */

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
