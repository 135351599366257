import { BaseService, service } from './base-service'
import qs from 'querystring'

const baseUrl = '/cycle-people-meeting'

export class CyclePeopleMeetingService extends BaseService {
  constructor() {
    super('/cycle-people-meeting')
  }
  async searchCollaborators(client, userDocument, cycle, term, unity = null) {
    if (!client || !cycle) throw new Error('client and cycle is required!')

    const query = qs.stringify({ term, unity, client })

    const url = `${baseUrl}/search-collaborators/${userDocument}/${cycle}?${query}`
    return service.get(url)
  }

  async searchCollaboratorsAsCycleManager(
    client,
    userDocument,
    cycle,
    term,
    unity = null
  ) {
    if (!client || !cycle) throw new Error('client and cycle is required!')

    const query = qs.stringify({ term, unity })

    const url = `${baseUrl}/search-collaborators-as-cycle-manager/${client}/${userDocument}/${cycle}?${query}`
    return service.get(url)
  }

  async searchAnswerAndQuestionsByCycleAndDocument(cycle, document, stepTypes) {
    if (!document || !cycle) throw new Error('document and cycle is required!')

    const query = qs.stringify({ steps: stepTypes })
    const url = `${baseUrl}/search-people-file-data/${cycle}/${document}?${query}`
    return service.get(url)
  }

  async getByCycleAndEligible(cycle, eligible) {
    if (!eligible || !cycle) throw new Error('EligibleId and cycle is required!')
    const url = `${baseUrl}/get-by-cycle-and-eligible/${cycle}/${eligible}`
    return service.get(url)
  }
}

export const cyclePeopleMeetingService = new CyclePeopleMeetingService()
