const defaultIndex = 0

const state = {
  currentResignationTabIndex: defaultIndex
}

const getters = {}

const mutations = {
  CHANGE_CURRENTRESIGNATIONTABINDEX: (state, payload) => {
    state.currentResignationTabIndex = payload
  }
}

const actions = {
  changeCurrentResignationTabIndex({ commit }, currentIndex) {
    commit('CHANGE_CURRENTRESIGNATIONTABINDEX', currentIndex)
  },
  clearCurrentResignationTabIndex({ commit }) {
    const defaultIndex = 0
    commit('CHANGE_CURRENTRESIGNATIONTABINDEX', defaultIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
