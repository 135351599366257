const pages = {
  InsuranceResearchList: () => import('@/views/recruitment/insurance-research/InsuranceResearchList'),
  RecruiterForm: () => import('@/views/recruitment/insurance-research/RecruiterForm')
}

const state = {
  currentInsuranceResearchPage: pages.InsuranceResearchList,
  pages
}

const mutations = {
  SET_INSURANCE_RESEARCH_PAGE: (state, page) => {
    state.currentInsuranceResearchPage = state.pages[page]
  }
}

const actions = {
  setInsuranceResearchPage({ commit }, page) {
    commit('SET_INSURANCE_RESEARCH_PAGE', page)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
