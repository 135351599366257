const defaultCurrentTrainingTabIndex = 0

const state = {
  currentTrainingTabIndex: defaultCurrentTrainingTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTTRAININGTABINDEX: (state, payload) => {
    state.currentTrainingTabIndex = payload
  }
}

const actions = {
  setCurrentTrainingTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTTRAININGTABINDEX', currentTabIndex)
  },
  clearCurrentTrainingTabIndex({ commit }) {
    const defCurrentTrainingTabIndex = 0
    commit('SET_CURRENTTRAININGTABINDEX', defCurrentTrainingTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
