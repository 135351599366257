<template>
  <div style="width: 100%">
    <b-form-file
      v-model="fieldValue"
      :state="Boolean(value)"
      enctype="multipart/form-data"
      :placeholder="$t('escolhaarquivo')"
      :drop-placeholder="$t('arquivo') + '...'"
      accept="image/*"
    />

    <div v-if="file" class="mt-2 dm-files__lista-files">
      <b-input-group>
        <b-input-group-append style="width: 100%; margin-top: 18px">
          <b-form-input :value="file.originalName" type="text" readonly />
          <b-button
            variant="outline-danger"
            @click="remove()"
          ><b-icon
            icon="trash"
            variant="danger"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField],
  data() {
    return {
      fieldValue: null,
      file: null
    }
  },
  watch: {
    async value() {
      this.setup()
    },
    fieldValue() {
      if (this.fieldValue !== this.value) this.value = this.fieldValue
    }
  },
  mounted() {
    this.setup()
  },
  methods: {
    remove() {
      this.value = null
      this.file = null
    },
    async setup() {
      if (this.value && !this.value._id) {
        if (this.fieldValue !== this.value) this.fieldValue = this.value
      } else if (this.value) {
        this.file = this.value
      }
    }
  }
}
</script>
<style lang="scss">
.dm-files {
  display: flex;
  flex-direction: column;
}
</style>
