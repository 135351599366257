<template>
  <b-form-checkbox v-model="value" name="check-button" size="lg" switch />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'
import * as _ from 'lodash'

export default {
  mixins: [abstractField],
  created() {
    if (!this.value && _.isBoolean(this.schema.default)) {
      this.value = this.schema.default
    }
  }
}
</script>
