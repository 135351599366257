
const state = {
  currentTab: ''
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = tab
  }
}

const actions = {
  setPeopleTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
