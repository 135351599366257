import { service } from './base-service'

const getAll = () => {
  return service.get('/module')
}

const getById = (moduleId) => {
  if (!moduleId) {
    throw new Error(' Id is required!')
  }
  return service.get(`/module/${moduleId}`)
}

const save = (data) => {
  const { _id } = data
  if (_id) { return service.put(`/module/${_id}`, data) }
  return service.post(`/module`, data)
}
const deleteModule = (moduleId) => {
  return service.delete(`/module/${moduleId}`)
}

export {
  getAll,
  getById,
  save,
  deleteModule
}
