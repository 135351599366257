import { service } from './base-service'

const getCandidateForms = () => {
  return service.get('/candidate-form')
}

const getCandidateFormById = (candidateFomId) => {
  if (!candidateFomId) {
    throw new Error('Candidate Form Id is required!')
  }
  return service.get(`/candidate-form/${candidateFomId}`)
}

const getCandidateFormsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/candidate-form/client/${client}`)
}

const getCandidateFormsByJob = (job) => {
  if (!job) {
    throw new Error('Job Id is required!')
  }
  return service.get(`/candidate-form/job/${job}`)
}

const getSharedCandidateFormsByJob = (job) => {
  if (!job) {
    throw new Error('Job Id is required!')
  }
  return service.get(`/candidate-form/shared-form/${job}`)
}

const getCandidateFormsByUser = (userId) => {
  if (!userId) {
    throw new Error('User ID is required')
  }
  return service.get(`/candidate-form/user/${userId}`)
}

const createCandidateForm = (candidateFomData) => {
  return service.post(`/candidate-form`, candidateFomData)
}

const updateCandidateForm = (candidateFomId, candidateFomData) => {
  return service.put(`/candidate-form/${candidateFomId}`, candidateFomData)
}

const saveCandidateForm = (candidateFomId, candidateFomData) => {
  if (candidateFomId) {
    return service.put(`/candidate-form/${candidateFomId}`, candidateFomData)
  }
  delete candidateFomData._id
  return service.post(`/candidate-form`, candidateFomData)
}

const deleteCandidateForm = (candidateFomId) => {
  return service.delete(`/candidate-form/${candidateFomId}`)
}

export {
  getCandidateForms,
  getCandidateFormById,
  getCandidateFormsByClient,
  getCandidateFormsByUser,
  getCandidateFormsByJob,
  getSharedCandidateFormsByJob,
  createCandidateForm,
  updateCandidateForm,
  saveCandidateForm,
  deleteCandidateForm
}
