import { clientConfigurationService } from '@/services/client-configuration-service'

const defaultClientConfiguration = {
  modules: [],
  hasBehavioralProfileTest: null,
  behavioralProfileProvider: null,
  hasInsuranceResearch: null,
  insuranceResearchProvider: null,
  integratesWithGEG: null,
  submitNPSandFeedback: null,
  approvedCandidateMessage: null,
  disapprovedCandidateMessage: null,
  undefinedCandidateMessage: null,
  hasUploadResume: null,
  uploadResumeOption: null,
  sendWhatsappInRecruitment: null,
  sendWhatsappInForms: null,
  isDismissalTypeMandatory: null,
  isDismissalReasonMandatory: null,
  isRoleMandatory: null
}

const state = {
  loaded: false,
  clientConfiguration: defaultClientConfiguration
}

const getters = {}

const mutations = {
  SET_LOADED: (state, loaded) => {
    state.loaded = loaded
  },

  SET_CLIENT_CONFIGURATION: (state, loaded) => {
    state.clientConfiguration = loaded
  }
}

const actions = {
  setLoaded({ commit }, clientConfiguration) {
    if (clientConfiguration) {
      commit('SET_CLIENT_CONFIGURATION', clientConfiguration)
    } else {
      commit('SET_CLIENT_CONFIGURATION', defaultClientConfiguration)
    }
    commit('SET_LOADED', !!clientConfiguration)
  },

  async updateClientConfiguration({ state, rootState, commit }) {
    const { data: clientConfiguration } =
      await clientConfigurationService.getByClient(rootState.user.userClient)
    commit('SET_CLIENT_CONFIGURATION', clientConfiguration)
    commit('SET_LOADED', true)
    return state.clientConfiguration
  },

  async updateIfEmpty({ dispatch }) {
    if (!state.loaded) {
      await dispatch('update')
    }
    return state.clientConfiguration
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
