import axios from 'axios'
import { getToken } from '@/utils/auth'
import store from '@/store'
import qs from 'querystring'

export const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000
})

service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      const client = store.getters.userClient
      config.headers['x-tenant-id'] = client
      config.headers['Authorization'] = `Bearer ${getToken()}`
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(null, error => {
  if (error.response && error.response.status === 403) {
    if (process.env.NODE_ENV === 'development') {
      location.href = `https://dmpeople.com.br/site/index.html`
      return store.dispatch('user/resetToken')
    }
    location.href = `${process.env.VUE_APP_BASE_API}/auth?next=${location.pathname}`
    store.dispatch('user/resetToken')
  }
  return Promise.reject(error)
})

export class BaseService {
  constructor(baseURL) {
    this.baseURL = baseURL
  }

  get(limit, page) {
    const query = qs.stringify({ limit, page })
    return service.get(`${this.baseURL}?${query}`)
  }

  getActives(limit, page) {
    const query = qs.stringify({ limit, page })
    return service.get(`${this.baseURL}/actives?${query}`)
  }

  getById(id) {
    if (!id) {
      throw new Error('Id is required!')
    }
    return service.get(`${this.baseURL}/${id}`)
  }

  getByClient(client, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(`${this.baseURL}/client/${client}?${query}`)
  }
  getByUnitAndDate(client, limit, page, unity, startDate, endDate) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({ client, limit, page, unity, startDate, endDate })
    return service.get(`${this.baseURL}/by_unit_dates?${query}`)
  }

  save(data) {
    const { _id } = data
    data.client = data.client ? data.client : store.getters.userClient
    if (_id) { return service.put(`${this.baseURL}/${_id}`, data) }
    return service.post(`${this.baseURL}/`, data)
  }

  delete(id) {
    return service.delete(`${this.baseURL}/${id}`)
  }

  getByKeyword(client, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page, word })
    return service.get(`${this.baseURL}/by_client_hierarchy_word?${query}`)
  }

  getByClientAndHierarchy(client, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page })
    return service.get(`${this.baseURL}/by_client_hierarchy?${query}`)
  }

  getWorksheetByClient(client, lang) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    return service.get(`${this.baseURL}/by_client_worksheet/${client}`, { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' })
  }

  getWorksheetByClientAndHierarchy(client, lang) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client })
    return service.get(`${this.baseURL}/by_client_hierarchy_worksheet?${query}`, { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' })
  }
}
