import { BaseService } from './base-service'
import { service } from './base-service'

class UserTypeConfigurationService extends BaseService {
  constructor() {
    super('/user-type-configuration')
  }

  getByUserType = (userType) => {
    return service.get(`/user-type-configuration/user-type/${userType}`)
  }
}

export const userTypeConfigurationService = new UserTypeConfigurationService()
