import { BaseService } from './base-service'
import { service } from './base-service'

class ClientService extends BaseService {
  constructor() {
    super('/client')
  }

  getByName = (name) => {
    return service.get(`/client/by_name/${name}`)
  }

  getByCode = (code) => {
    return service.get(`/client/by_code/${code}`)
  }

  getByUser = (user) => {
    return service.get(`/client/by_user/${user}`)
  }

  getByUserClients = (codes) => {
    return service.get(`/client/user_clients/${codes}`)
  }

  getByUserToken = () => {
    return service.get('/client/user_clients_by_token')
  }

  getMinClosingDate = (companyId) => {
    return service.get(`/client/min_closing_date/${companyId}`)
  }

  delete(id, closingDate) {
    return service.delete(`/client/${id}/with_closing_date`, {
      headers: { 'Content-Type': 'application/json' },
      data: { closingDate }
    })
  }
}

export const clientService = new ClientService()
