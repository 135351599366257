import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class DocumentTypeService extends BaseService {
  constructor() {
    super('/document-type')
  }

  getWithDeletedByClient = (client, module) => {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!module) {
      throw new Error('Module name is required!')
    }

    return service.get(`/document-type/with_deleted/${client}/${module}`)
  }

  async getByClientAndModuleWithDeleted(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module name is required!')
    }

    const query = qs.stringify({ limit, page })
    return service.get(`${this.baseURL}/${client}/${module}?${query}`)
  }

  async getByClientAndModule(client, module, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module name is required!')
    }

    const query = qs.stringify({ limit, page })
    return service.get(`${this.baseURL}/client_and_module/${client}/${module}?${query}`)
  }

  getByIdWithDeleted(id) {
    if (!id) {
      throw new Error('Id is required!')
    }
    return service.get(`${this.baseURL}/by_id/with_deleted/${id}`)
  }

  async copy(documentType, client) {
    const query = qs.stringify({ documentType, client })
    return service.post(`${this.baseURL}/copy?${query}`)
  }
}

export const documentTypeService = new DocumentTypeService()
