<template>
  <div id="app">
    <router-view />
    <DMReportNotification />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DMReportNotification from './components/DMReportNotification'
export default {
  name: 'App',
  components: {
    DMReportNotification
  },
  created() {
    if (this.token) {
      this.getScreens()
      this.getModules()
    }
  },

  computed: {
    ...mapState('user', ['token'])
  },
  methods: {
    ...mapActions('screens', ['getScreens']),
    ...mapActions('modules', ['getModules'])
  }
}
</script>
