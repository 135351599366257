const defaultCurrentInsuranceResearchTabIndex = 0

const state = {
  currentInsuranceResearchTabIndex: defaultCurrentInsuranceResearchTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTINSURANCERESEARCHTABINDEX: (state, payload) => {
    state.currentInsuranceResearchTabIndex = payload
  }
}

const actions = {
  setCurrentInsuranceResearchTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTINSURANCERESEARCHTABINDEX', currentTabIndex)
  },
  clearCurrentInsuranceResearchTabIndex({ commit }) {
    const defCurrentInsuranceResearchTabIndex = 0
    commit('SET_CURRENTINSURANCERESEARCHTABINDEX', defCurrentInsuranceResearchTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
