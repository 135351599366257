const tabs = {
  CareerFormsList: () => import('@/views/career/form')
}

const state = {
  currentCareerFormTab: tabs.CareerFormsList,
  tabs: tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentCareerFormTab = state.tabs[tab]
  }
}

const actions = {
  setCareerFormTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
