import { BaseService, service } from './base-service'
import qs from 'querystring'
import store from '@/store'

class LeadershipHierarchy extends BaseService {
  constructor() {
    super('/leadership-hierarchy')
  }

  saveMany(data) {
    data.forEach(element => {
      element.client = element.client ? element.client : store.getters.userClient
    })
    return service.post(`/leadership-hierarchy/save_many`, data)
  }

  getByLeadership = (leadership, limit, page) => {
    if (!leadership) {
      throw new Error('Leadership Id is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `/leadership-hierarchy/by_leadership/${leadership}?${query}`
    )
  };

  getByLeadershipSimple = (leadership, limit, page) => {
    if (!leadership) {
      throw new Error('Leadership Id is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `/leadership-hierarchy/by_leadership_simple/${leadership}?${query}`
    )
  };

  getPeopleByLeadership = (leadership, limit, page) => {
    if (!leadership) {
      throw new Error('Leadership Id is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `/leadership-hierarchy/by_leadership/${leadership}/people?${query}`
    )
  };
  getBySubordinate = (subordinate, limit, page) => {
    if (!subordinate) {
      throw new Error('Subordinate Id is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `/leadership-hierarchy/by_subordinate/${subordinate}?${query}`
    )
  };

  getByClientAndHierarchySimple(client, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page })
    return service.get(
      `/leadership-hierarchy/by_client_hierarchy_simple?${query}`
    )
  }

  isValidInitialDate = (subordinate, initialDate, id) => {
    if (!subordinate) {
      throw new Error('Subordinate Id is required!')
    }
    if (!initialDate) {
      throw new Error('Initial date is required!')
    }
    const query = qs.stringify({ initialDate, id })
    return service.get(
      `/leadership-hierarchy/is_valid_initial_date/${subordinate}?${query}`
    )
  };
  isValidInitialDateSimple = (subordinate, initialDate, id) => {
    if (!subordinate) {
      throw new Error('Subordinate Id is required!')
    }
    if (!initialDate) {
      throw new Error('Initial date is required!')
    }
    const query = qs.stringify({ initialDate, id })
    return service.get(
      `/leadership-hierarchy/is_valid_initial_date_simple/${subordinate}?${query}`
    )
  };

  getByKeywordSimple(client, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page, word })
    return service.get(
      `/leadership-hierarchy/by_client_hierarchy_word_simple?${query}`
    )
  }

  getActiveByKeyword(client, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page, word })
    return service.get(
      `/leadership-hierarchy/active_by_client_hierarchy_word?${query}`
    )
  }

  getByUnitAndDateSimple(client, limit, page, unity, startDate, endDate) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      client,
      limit,
      page,
      unity,
      startDate,
      endDate
    })
    return service.get(`/leadership-hierarchy/by_unit_dates_simple?${query}`)
  }

  getActiveBondsByLeadership(
    client,
    limit,
    page,
    collaborator,
    unity,
    leadership
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      limit,
      page,
      collaborator,
      unity,
      leadership
    })
    return service.get(
      `/leadership-hierarchy/search/byLeadership/${client}?${query}`
    )
  }

  getActiveBondsByLeadershipWorksheetSummary(
    client,
    limit,
    page,
    lang,
    collaborator,
    unity,
    leadership
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      limit,
      page,
      collaborator,
      unity,
      leadership
    })
    return service.get(
      `/leadership-hierarchy/search/byLeadership/${client}/worksheet?${query}`,
      {
        headers: { 'x-custom-lang': lang },
        responseType: 'arraybuffer'
      }
    )
  }

  getActiveBondsByLeadershipWorksheetComplete(
    client,
    limit,
    page,
    lang,
    collaborator,
    unity,
    leadership
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    const query = qs.stringify({
      limit,
      page,
      collaborator,
      unity,
      leadership
    })
    return service.get(
      `/leadership-hierarchy/search/byLeadership/${client}/worksheet/complete?${query}`,
      { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' }
    )
  }
}

export const leadershipHierarchyService = new LeadershipHierarchy()
