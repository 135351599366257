const tabs = {
  ExamsList: () => import('@/views/recruitment/ExamTabs')
  // Clinics: () => import('@/views/recruitment/JobTabs')
}

const state = {
  examTab: tabs.ExamsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.examTab = state.tabs[tab]
  }
}

const actions = {
  setExamTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
