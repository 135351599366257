const defaultCurrentClientDocumentTabIndex = 0

const state = {
  currentClientDocumentTabIndex: defaultCurrentClientDocumentTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTCLIENTDOCUMENTTABINDEX: (state, payload) => {
    state.currentClientDocumentTabIndex = payload
  }
}

const actions = {
  setCurrentClientDocumentTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTCLIENTDOCUMENTTABINDEX', currentTabIndex)
  },
  clearCurrentClientDocumentTabIndex({ commit }) {
    const defCurrentClientDocumentTabIndex = 0
    commit('SET_CURRENTCLIENTDOCUMENTTABINDEX', defCurrentClientDocumentTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
