const defaultCurrentStructureTabIndex = 0

const state = {
  currentStructureTabIndex: defaultCurrentStructureTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTSTRUCTURETABINDEX: (state, payload) => {
    state.currentStructureTabIndex = payload
  }
}

const actions = {
  setCurrentStructureTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTSTRUCTURETABINDEX', currentTabIndex)
  },
  clearCurrentStructureTabIndex({ commit }) {
    const defCurrentStructureTabIndex = 0
    commit('SET_CURRENTSTRUCTURETABINDEX', defCurrentStructureTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
