const tabs = {
  List: () => import('@/views/trainings/follow-up/follow-up-tabs/scheduledTrainings/ScheduledTrainings'),
  Schedule: () => import('@/views/trainings/follow-up/follow-up-tabs/scheduledTrainings/ScheduleTabs')
}

const state = {
  tabs,
  tab: tabs.List
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.tab = state.tabs[tab]
  }
}

const actions = {
  setListTab({ commit }) {
    commit('SET_TABS', 'List')
  },
  setScheduleTab({ commit }) {
    commit('SET_TABS', 'Schedule')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
