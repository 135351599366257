import { companyDocumentDepositService } from '@/services/company-document-deposit-service'
import * as _ from 'lodash'

const state = {
  document: null,
  newDeposit: {
    has: false,
    message: ''
  }
}

const getters = {
  hasDocument: ({ document }) => {
    if (_.isArray(document)) {
      return document.length > 0
    }
    return document && document._id != null
  }
}

const mutations = {
  SET_DOCUMENT: (state, payload) => {
    state.document = payload
  }
}

const actions = {
  load({ commit }, document) {
    commit('SET_DOCUMENT', document)
  },
  clear({ commit }) {
    commit('SET_DOCUMENT', null)
  },
  async save({ commit, rootState }, document) {
    if (_.isArray(document)) {
      const documents = document.map((doc) => {
        doc.client = rootState.user.userClient
        return doc
      })
      const { data } = await companyDocumentDepositService.saveAll(documents)
      commit('SET_DOCUMENT', data)
    } else {
      document.client = rootState.user.userClient
      const { data } = await companyDocumentDepositService.save(document)
      commit('SET_DOCUMENT', data)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
