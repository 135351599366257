const defaultCurrentAbsenceTabIndex = 0

const state = {
  currentAbsenceTabIndex: defaultCurrentAbsenceTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTABSENCETABINDEX: (state, payload) => {
    state.currentAbsenceTabIndex = payload
  }
}

const actions = {
  setCurrentAbsenceTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTABSENCETABINDEX', currentTabIndex)
  },
  clearCurrentAbsenceTabIndex({ commit }) {
    const defCurrentAbsenceTabIndex = 0
    commit('SET_CURRENTABSENCETABINDEX', defCurrentAbsenceTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
