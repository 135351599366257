const defaultUserType = {
  _id: null,
  type: null,
  description: null,
  client: null,
  createdByAdmin: false
}

const state = {
  currentUserType: defaultUserType
}

const getters = {}

const mutations = {
  SET_USERTYPE: (state, payload) => {
    state.currentUserType = payload
  }
}

const actions = {
  loadUserType({ commit }, userTypeData) {
    commit('SET_USERTYPE', userTypeData)
  },
  clearUserType({ commit, rootState }) {
    const defUserType = {
      _id: null,
      type: null,
      description: null,
      client: null,
      createdByAdmin: false
    }
    commit('SET_USERTYPE', defUserType)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
