
const defaultSkillsFormAnswer = {
  _id: null,
  document: null,
  name: null,
  job: null,
  form: null,
  skills: null,
  file: null,
  score: null,
  percentScore: null,
  opinion: null,
  justification: null,
  client: null,
  createdBy: null,
  updatedBy: null
}

const state = {
  skillsFormAnswer: defaultSkillsFormAnswer
}

const getters = {}

const mutations = {
  SET_SKILLSFORMANSWER: (state, payload) => {
    state.skillsFormAnswer = payload
  }
}

const actions = {
  loadSkillsFormAnswer({ commit }, skillsFormAnswerData) {
    commit('SET_SKILLSFORMANSWER', skillsFormAnswerData)
  },
  clearSkillsFormAnswer({ commit }) {
    const defSkillsFormAnswer = {
      _id: null,
      document: null,
      name: null,
      job: null,
      form: null,
      skills: null,
      score: null,
      percentScore: null,
      file: null,
      opinion: null,
      justification: null,
      client: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_SKILLSFORMANSWER', defSkillsFormAnswer)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
