import * as agencyService from '@/services/agency-service'

const defaultAgency = {
  _id: null,
  active: true,
  name: null,
  document: null,
  type: null,
  email: null,
  phone: '',
  country: null,
  postalCode: null,
  city: null,
  state: null,
  neighborhood: null,
  street: null,
  num: null,
  client: null,
  deletedBy: null,
  deletedAt: null
}

const state = {
  agency: defaultAgency
}

const getters = {}

const mutations = {
  SET_AGENCY: (state, payload) => {
    state.agency = payload
  }
}

const actions = {
  loadAgencyForm({ commit }, agencyFormData) {
    commit('SET_AGENCY', agencyFormData)
  },
  save({ commit, rootState }, agencyFormData) {
    agencyFormData.client = rootState.user.userClient
    agencyService.save(agencyFormData)
      .then(({ data }) => {
        commit('SET_AGENCY', data)
      })
  },
  async remove({ commit }, agencyId) {
    return agencyService.delete(agencyId)
      .then(() => {
        commit('SET_AGENCY', defaultAgency)
        // Message.success(i18n.t('treinamentoexcluido'))
        return Promise.resolve()
      })
  },
  clear({ commit }) {
    const defAgency = {
      _id: null,
      active: true,
      name: null,
      document: null,
      type: null,
      email: null,
      phone: '',
      country: null,
      postalCode: null,
      city: null,
      state: null,
      neighborhood: null,
      street: null,
      num: null,
      client: null,
      deletedBy: null,
      deletedAt: null
    }
    commit('SET_AGENCY', defAgency)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
