import { BaseService, service } from './base-service'
import qs from 'querystring'

export class CustomFormService extends BaseService {
  constructor() {
    super('/custom-form')
  }

  async delete(id) {
    const res = await super.delete(id)
    return res
  }

  async getWorkplaceFormsByKeyword(client, document, limit, page, keyword) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ keyword, limit, page })
    return service.get(
      `${this.baseURL}/allow-on-workplace/${client}/${document}/?${query}`
    )
  }

  async getFormsByKeyword(
    client,
    module,
    answerPath,
    limit,
    page,
    keyword,
    isTraining = false
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module name is required!')
    }

    if (!answerPath) {
      throw new Error('Answer path is required!')
    }

    const query = qs.stringify({ keyword, limit, page, isTraining })
    return service.get(
      `${this.baseURL}/${client}/${module}/${answerPath}?${query}`
    )
  }

  async changeAllowInWorkplace(form) {
    const allow = form.allowInWorkplace
    return service.put(
      `${this.baseURL}/change-allow-on-workplace/${form._id}`,
      { allow }
    )
  }

  async copy(form, client, lang) {
    const query = qs.stringify({ form, client })
    return service.post(`${this.baseURL}/copy?${query}`, null, {
      headers: { 'x-custom-lang': lang }
    })
  }

  async multipleCopy(form, clients, lang) {
    const body = { clients }
    return service.post(`${this.baseURL}/multiple-copy/${form}`, body, {
      headers: { 'x-custom-lang': lang }
    })
  }

  async getByClientModule(client, module, options) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module name is required!')
    }
    const query = qs.stringify(options)

    return service.get(`${this.baseURL}/${client}/${module}?${query}`)
  }

  async getByClientModuleAndAnswerType(client, module, answerType, options) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    if (!module) {
      throw new Error('Module name is required!')
    }

    if (!answerType) {
      throw new Error('Answer type is required!')
    }

    const query = qs.stringify(options)

    return service.get(
      `${this.baseURL}/${client}/${module}/by_answer_type/${answerType}?${query}`
    )
  }
}

export const customFormService = new CustomFormService()
