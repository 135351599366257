import { cycleService } from '@/services/cycle-service'
import moment from 'moment'

const defaultCycle = () => ({
  _id: null,
  name: '',
  locale: 'pt-BR',
  useResultsIndex: true,
  bondTypes: [],
  positionTypes: [],
  positions: [],
  cycleApplication: null,
  daysAfterAdmission: null,
  admittedUntil: null,
  startDate: null,
  endDate: null,
  createdByAdmin: null
})

const state = {
  cycle: defaultCycle(),
  loadingEligible: null,
  cycleHasFinished: false
}

// Cycles with custom steps only (at this moment, only SKAP)
const CycleApplicationsWithSkapBehavior = 'skills_acquisition_process'

const getters = {
  hasCycle: (state) => state.cycle._id !== null,
  hasCommittee: (state) => state.cycle.willBeCommittee !== false,
  hasSkapBehavior: (state) => CycleApplicationsWithSkapBehavior.includes(state.cycle.cycleApplication)
}

const mutations = {
  SET_CYCLE: (state, payload) => {
    state.cycle = payload
    state.cycleHasFinished = payload.endDate && moment(payload.endDate).isBefore(moment())
  },
  SET_LOADINGELIGIBLE: (state, payload) => {
    state.loadingEligible = payload
  }
}

const actions = {
  load({ commit }, cycle) {
    commit('SET_CYCLE', cycle)
  },
  clear({ commit }) {
    commit('SET_CYCLE', defaultCycle())
    commit('SET_LOADINGELIGIBLE', null)
  },
  async save({ commit, rootState }, cycle) {
    cycle.client = rootState.user.userClient
    const { data } = await cycleService.save(cycle)
    commit('SET_CYCLE', data)
    commit('SET_LOADINGELIGIBLE', null)
    // Message.success(i18n.t('treinamentocadastrado'))
  },
  eligibleLoaded({ commit }, alertOptions) {
    commit('SET_LOADINGELIGIBLE', alertOptions)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
