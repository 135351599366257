import { customFormService } from '@/services/custom-form-service'

const defaultCustomForm = () => ({
  _id: null,
  client: '',
  module: '',
  answerPath: '',
  name: '',
  description: '',
  maximumTime: '',
  weightedAnswers: false,
  showHeader: false,
  formTemplate: {
    groups: [],
    fields: [],
    queries: []
  },
  answered: 0
})

const state = {
  requestForm: defaultCustomForm()
}

const getters = {
  hasRequestForm: (state) => state.CustomForm._id != null
}

const mutations = {
  SET_REQUEST_FORM: (state, payload) => {
    if (payload._doc) {
      state.requestForm = Object.assign(state.requestForm, payload._doc)
      state.requestForm.formTemplate = payload.formTemplate
    } else state.requestForm = payload
  }
}

const actions = {
  async load({ commit }, customForm) {
    const { data } = await customFormService.getById(customForm._id)
    commit('SET_REQUEST_FORM', data)
  },
  clear({ commit }) {
    commit('SET_REQUEST_FORM', defaultCustomForm())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
