var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-input", {
    directives: [{ name: "limitInputDate", rawName: "v-limitInputDate" }],
    attrs: {
      type: "date",
      placeholder: "",
      "today-button": "",
      "close-button": "",
      "hide-header": "",
      min: _vm.schema.min || "1900-01-01",
      max: _vm.schema.max || "2099-12-31",
      "label-today-button": _vm.$t("selecionarhoje"),
      "label-close-button": _vm.$t("fechar"),
      locale: _vm.$store.getters.locale,
    },
    on: { blur: _vm.customValidateDate },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }