var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-row flex-wrap" },
    [
      _c(
        "b-link",
        {
          staticClass: "classic",
          attrs: {
            href: _vm.schema.href,
            active: "",
            target: "_blank",
            "router-tag": "button",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.link) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }