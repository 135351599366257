const tabs = {
  cycleCustomSteps: () => import('@/views/career/cycle-steps/CycleCustomSteps'),
  cycleDefaultSteps: () => import('@/views/career/cycle-steps/CycleDefaultSteps')
}

const state = {
  currentCycleStepTabs: tabs.cycleDefaultSteps,
  currentCycleStepTabsIndex: 0,
  tabs: tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentCycleStepTabsIndex = tab === 'cycleDefaultSteps' ? 0 : 1
    state.currentCycleStepTabs = state.tabs[tab]
  }
}

const actions = {
  setCycleStepTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
