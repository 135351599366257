var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-select",
    {
      attrs: {
        id: "dmselect",
        options: _vm.schema.values,
        placeholder: _vm.$t("escolhaopcao"),
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      [
        _c(
          "b-form-select-option",
          { attrs: { value: undefined, disabled: "" } },
          [_vm._v("-- " + _vm._s(_vm.$t("escolhaopcao")) + " --")]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }