import * as admissionService from '@/services/admission-service'
import moment from 'moment'

const defaultAdmission = () => ({
  _id: null,
  person: null,
  email: null,
  client: null,
  admissionDate: null,
  serviceProvisionStartDate: null,
  unity: null,
  position: null,
  salary: null,
  workday: null,
  bond: null,
  company: null,
  role: null,
  customFields: [{}, {}],
  active: true,
  deletedBy: null,
  deletedAt: null,
  isLeadership: false,
  reason: null,
  categoryCNH: null,
  registerCNH: null,
  firstCNH: null,
  emissionCNH: null,
  validityCNH: null,
  renachCNH: null,
  mirrorCNH: null,
  statusCNH: null,
  stateCNH: null,
  cityCNH: null,
  driverCountry: null,
  driverState: null,
  driverCity: null,
  driverLicenceType: null,
  driverLicenceExpirationDate: null,
  driverPermissionInBrazilExpirationDate: null
})

const state = {
  admission: Object.assign({}, defaultAdmission())
}

const getters = {
  formattedServiceProvisionStartDate: state => {
    if (state.admission && state.admission.serviceProvisionStartDate) {
      return moment.utc(state.admission.serviceProvisionStartDate).format('YYYY-MM-DD').toString()
    }
    return ''
  }
}

const mutations = {
  SET_ADMISSION: (state, payload) => {
    state.admission = payload
  }
}

const actions = {

  loadAdmission({ commit }, admissionData) {
    commit('SET_ADMISSION', admissionData)
  },

  clearAdmission({ commit }) {
    commit('SET_ADMISSION', defaultAdmission())
  },

  async saveAdmission({ rootState, commit }, payload) {
    const { persist } = payload
    let { admission } = payload
    if (persist) {
      if (!payload.client) {
        payload.client = rootState.user.userClient
      }

      const { data } = await admissionService.saveAdmission(admission)
      admission = Object.assign(admission, data)
    }
    commit('SET_ADMISSION', admission)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
