import { service } from './base-service'
import qs from 'querystring'

const getAdmissions = () => {
  return service.get('/admission')
}

const getAdmissionById = admissionId => {
  if (!admissionId) {
    throw new Error('Admission Id is required!')
  }
  return service.get(`/admission/${admissionId}`)
}

const getAdmissionsByClient = client => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/admission/client/${client}`)
}

const getByUnitAndDate = (client, limit, page, unity, startDate, endDate) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    client,
    limit,
    page,
    unity,
    startDate,
    endDate
  })
  return service.get(`/admission/by_unit_dates?${query}`)
}

const getByUnitAndDateSimple = (
  client,
  limit,
  page,
  unity,
  startDate,
  endDate
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({
    client,
    limit,
    page,
    unity,
    startDate,
    endDate
  })
  return service.get(`/admission/by_unit_dates_simple?${query}`)
}

const getWorksheetByClientAndHierarchy = (client, lang) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ client })
  return service.get(`/admission/by_client_hierarchy_worksheet?${query}`, {
    headers: { 'x-custom-lang': lang },
    responseType: 'arraybuffer'
  })
}

const getWorksheetCompletedByClientAndHierarchy = (client, lang) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ client })
  return service.get(
    `/admission/completed_by_client_hierarchy_worksheet?${query}`,
    { headers: { 'x-custom-lang': lang }, responseType: 'arraybuffer' }
  )
}

const getAdmissionsByPerson = person => {
  if (!person) {
    throw new Error('Client name is required!')
  }
  return service.get(`/admission/person/${person}`)
}

const getAdmissionsByClientAndHierarchy = (client, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({ client, limit, page })
  return service.get(`/admission/by_client_hierarchy?${query}`)
}

const getAdmissionsByClientAndHierarchySimple = (
  client,
  limit,
  page,
  unity,
  startDate,
  endDate
) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({
    client,
    limit,
    page,
    unity,
    startDate,
    endDate
  })

  return service.get(`/admission/by_client_hierarchy_simple?${query}`)
}

const getAdmissionsByKeyword = (client, limit, page, word) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({ client, limit, page, word })
  return service.get(`/admission/by_client_hierarchy_word?${query}`)
}

const getAdmissionsByKeywordSimple = (client, limit, page, word) => {
  if (!client) {
    throw new Error('Client name is required!')
  }

  const query = qs.stringify({ client, limit, page, word })
  return service.get(`/admission/by_client_hierarchy_word_simple?${query}`)
}

const getAdmissionsByPersonAndClient = (person, client) => {
  if (!person) {
    throw new Error('Person Id is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(
    `/admission/by_client_hierarchy?person=${person}&client=${client}`
  )
}

const createAdmission = admissionData => {
  return service.post(`/admission`, admissionData)
}

const updateAdmission = (admissionId, admissionData) => {
  return service.put(`/admission/${admissionId}`, admissionData)
}

const saveAdmission = admissionData => {
  if (admissionData._id) {
    return service.put(`/admission/${admissionData._id}`, admissionData)
  }
  delete admissionData._id
  return service.post(`/admission`, admissionData)
}

const deleteAdmission = (admissionId, person) => {
  const query = qs.stringify({ person })
  return service.delete(`/admission/${admissionId}?${query}`)
}

export {
  getAdmissions,
  getAdmissionById,
  getAdmissionsByClient,
  getAdmissionsByPerson,
  getAdmissionsByClientAndHierarchy,
  getAdmissionsByPersonAndClient,
  getByUnitAndDate,
  createAdmission,
  updateAdmission,
  deleteAdmission,
  getWorksheetByClientAndHierarchy,
  getWorksheetCompletedByClientAndHierarchy,
  saveAdmission,
  getAdmissionsByKeyword,
  getAdmissionsByKeywordSimple,
  getAdmissionsByClientAndHierarchySimple,
  getByUnitAndDateSimple
}
