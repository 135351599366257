const defaultCurrentCareerTabIndex = 0

const state = {
  currentCareerTabIndex: defaultCurrentCareerTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTCAREERTABINDEX: (state, payload) => {
    state.currentCareerTabIndex = payload
  }
}

const actions = {
  setCurrentCareerTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTCAREERTABINDEX', currentTabIndex)
  },
  clearCurrentCareerTabIndex({ commit }) {
    const defCurrentCareerTabIndex = 0
    commit('SET_CURRENTCAREERTABINDEX', defCurrentCareerTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
