const tabs = {
  JobsList: () => import('@/views/recruitment/JobsList'),
  JobTabs: () => import('@/views/recruitment/JobTabs')
}

const state = {
  currentJobTab: tabs.JobsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentJobTab = state.tabs[tab]
  }
}

const actions = {
  setJobTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
