const defaultCurrentSelectionTabIndex = 0

const state = {
  currentSelectionTabIndex: defaultCurrentSelectionTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTSELECTIONTABINDEX: (state, payload) => {
    state.currentSelectionTabIndex = payload
  }
}

const actions = {
  setCurrentSelectionTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTSELECTIONTABINDEX', currentTabIndex)
  },
  clearCurrentSelectionTabIndex({ commit }) {
    const defCurrentSelectionTabIndex = 0
    commit('SET_CURRENTSELECTIONTABINDEX', defCurrentSelectionTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
