import * as jobService from '@/services/job-service'

const defaultJob = {
  _id: null,
  position: null,
  code: null,
  name: null,
  unity: null,
  reason: null,
  quantity: null,
  deadline: null,
  requestDate: null,
  recruitmentType: null,
  additionalRequirements: null,
  schedule: null,
  approverName: null,
  approverEmail: null,
  approverPhone: null,
  approverMessage: null,
  status: null,
  approvalDate: null,
  locale: null,
  client: null,
  createdBy: null,
  updatedBy: null,
  finishDate: null,
  finishedBy: null,
  hasBehavioralProfileTest: false
}

const state = {
  job: defaultJob,
  jobForAdmission: defaultJob
}

const getters = {}

const mutations = {
  SET_JOB: (state, payload) => {
    state.job = payload
  },

  SET_JOB_FOR_ADMISSION: (state, payload) => {
    state.jobForAdmission = payload
  }
}

const actions = {
  loadJob({ commit }, jobData) {
    commit('SET_JOB', jobData)
  },
  loadJobForAdmission({ commit }, jobData) {
    commit('SET_JOB_FOR_ADMISSION', jobData)
  },
  clearJob({ commit, rootState }) {
    const defJob = {
      _id: null,
      position: null,
      code: null,
      name: null,
      unity: null,
      reason: null,
      quantity: null,
      deadline: null,
      requestDate: null,
      recruitmentType: null,
      additionalRequirements: null,
      schedule: null,
      approverName: null,
      approverEmail: null,
      approverPhone: null,
      approverMessage: null,
      status: null,
      approvalDate: null,
      locale: null,
      client: null,
      createdBy: null,
      updatedBy: null,
      finishDate: null,
      finishedBy: null,
      hasBehavioralProfileTest: false
    }
    commit('SET_JOB', defJob)
  },
  clearJobForAdmission({ commit, rootState }) {
    const defJob = {
      _id: null,
      position: null,
      code: null,
      name: null,
      unity: null,
      reason: null,
      quantity: null,
      deadline: null,
      requestDate: null,
      recruitmentType: null,
      additionalRequirements: null,
      schedule: null,
      approverName: null,
      approverEmail: null,
      approverPhone: null,
      approverMessage: null,
      status: null,
      approvalDate: null,
      locale: null,
      client: null,
      createdBy: null,
      updatedBy: null,
      finishDate: null,
      finishedBy: null,
      hasBehavioralProfileTest: false
    }
    commit('SET_JOB_FOR_ADMISSION', defJob)
  },
  save({ commit, rootState }, job) {
    job.client = rootState.user.userClient
    jobService.saveJob(job)
      .then(({ data }) => {
        commit('SET_JOB', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
