const defaultCurrentTrainingsRegisterTabIndex = 0

const state = {
  currentTrainingsRegisterTabIndex: defaultCurrentTrainingsRegisterTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTTRAININGSREGISTERTABINDEX: (state, payload) => {
    state.currentTrainingsRegisterTabIndex = payload
  }
}

const actions = {
  setCurrentTrainingsRegisterTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTTRAININGSREGISTERTABINDEX', currentTabIndex)
  },
  clearCurrentTrainingsRegisterTabIndex({ commit }) {
    const defCurrentTrainingsRegisterTabIndex = 0
    commit('SET_CURRENTTRAININGSREGISTERTABINDEX', defCurrentTrainingsRegisterTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
