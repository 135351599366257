var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("b-form-input", {
        attrs: {
          id: "input-live",
          trim: "",
          type: "email",
          "aria-describedby": "input-live-help input-live-feedback",
          disabled: _vm.schema.disabled,
          readonly: _vm.schema.readonly,
          state: _vm.isValidEmail,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      !_vm.isValidEmail
        ? _c(
            "b-form-invalid-feedback",
            { attrs: { id: "input-live-feedback" } },
            [_vm._v("\n    " + _vm._s(_vm.$t("informeemailvalido")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }