// TODO: refatorar dependentes para utilizar provider/inject e excluir este store

const state = {
  alert: {},
  submitButtonErrors: []
}

const mutations = {
  DISABLE_SUBMIT_BUTTON(state, key) {
    if (state.submitButtonErrors.indexOf(key) === -1) {
      state.submitButtonErrors.push(key)
    }
  },
  ENABLE_SUBMIT_BUTTON(state, key) {
    const index = state.submitButtonErrors.findIndex((k) => k === key)
    if (index >= 0) {
      state.submitButtonErrors.splice(index, 1)
    }
  }
}

function mutationSet(setConst, attr) {
  const setFn = (state, value) => {
    state[attr] = value
  }
  mutations[setConst] = setFn
}
mutationSet('SET_ALERT', 'alert')
mutationSet('SET_SUBMIT_BUTTON', 'submitButtonErrors')

const actions = {
  emitAlert({ commit }, alert) {
    commit('SET_ALERT', alert)
  },
  validate({ commit }) {
    commit('SET_ALERT', {})
  },

  disableSubmit({ commit }, key) {
    commit('DISABLE_SUBMIT_BUTTON', key || 'anonymous')
  },
  enableSubmit({ commit }, key) {
    commit('ENABLE_SUBMIT_BUTTON', key || 'anonymous')
  },
  clearSubmit({ commit }) {
    commit('SET_SUBMIT_BUTTON', [])
  },

  setup({ dispatch }) {
    dispatch('validate')
    dispatch('clearSubmit')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
