const defaultCurrentJobTabIndex = 0

const state = {
  currentJobTabIndex: defaultCurrentJobTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTJOBTABINDEX: (state, payload) => {
    state.currentJobTabIndex = payload
  }
}

const actions = {
  setCurrentJobTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTJOBTABINDEX', currentTabIndex)
  },
  clearCurrentJobTabIndex({ commit }) {
    const defCurrentJobTabIndex = 0
    commit('SET_CURRENTJOBTABINDEX', defCurrentJobTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
