import { service } from './base-service'
import qs from 'querystring'

const getJobs = () => {
  return service.get('/job')
}

const getJobById = (jobId) => {
  if (!jobId) {
    throw new Error('Job Id is required!')
  }
  return service.get(`/job/${jobId}`)
}

const getJobsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/job/client/${client}`)
}

const getJobsByUnity = (unity) => {
  if (!unity) {
    throw new Error('Unity Id is required!')
  }
  return service.get(`/job/unity/${unity}`)
}

const getJobsByUnityAndStatus = (unity, status) => {
  if (!unity) {
    throw new Error('Unity Id is required!')
  }
  const query = qs.stringify({ status })
  return service.get(`/job/unity/status/${unity}?${query}`)
}

const getJobsByApproverandClient = (email, client) => {
  if (!email) {
    throw new Error('Approver email is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/job/by_client_approver?client=${client}&email=${email}`)
}

const getJobsByClientAndHierarchy = (client, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/job/by_client_hierarchy?client=${client}&limit=${limit}&page=${page}`)
}

const getJobsByClientAndHierarchyAndStatusSimple = (client, status, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ client, status, limit, page })
  return service.get(`/job/by_client_hierarchy_status/simple?${query}`)
}

const getJobsByClientAndHierarchyAndStatus = (client, status, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ client, status, limit, page })
  return service.get(`/job/by_client_hierarchy_status?${query}`)
}

const getJobsByFilter = (client, status, unityId, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  const query = qs.stringify({ client, status, limit, page, unityId })
  return service.get(`/job/by_filter?${query}`)
}

const getJobsByClientAndHierarchyAndStatusAndKeyword = (client, status, keyword, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  if (!status) {
    throw new Error('Status is required!')
  }
  const query = qs.stringify({ client, status, keyword, limit, page })
  return service.get(`/job/by_client_hierarchy_status_word?${query}`)
}

const getJobsByClientAndHierarchyAndKeyword = (client, limit, page, word) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  if (!word) {
    throw new Error('Word is required!')
  }
  return service.get(`/job/by_client_hierarchy_word?client=${client}&limit=${limit}&page=${page}&word=${word}`)
}

const createJob = (jobData) => {
  return service.post(`/job`, jobData)
}

const updateJob = (jobId, jobData) => {
  return service.put(`/job/${jobId}`, jobData)
}

const saveJob = (jobId, jobData) => {
  if (jobId) {
    return service.put(`/job/${jobId}`, jobData)
  }
  delete jobData._id
  return service.post(`/job`, jobData)
}

const deleteJob = (jobId) => {
  return service.delete(`/job/${jobId}`)
}

const duplicateJob = (jobId) => {
  return service.post(`/job/${jobId}/duplicate`)
}

const getNPSResult = (jobId) => {
  return service.get(`job/${jobId}/nps/result`)
}

export {
  getJobs,
  getJobById,
  getJobsByClient,
  getJobsByUnity,
  getJobsByUnityAndStatus,
  getJobsByApproverandClient,
  getJobsByClientAndHierarchy,
  getJobsByClientAndHierarchyAndStatus,
  getJobsByClientAndHierarchyAndStatusSimple,
  getJobsByClientAndHierarchyAndStatusAndKeyword,
  getJobsByClientAndHierarchyAndKeyword,
  createJob,
  updateJob,
  deleteJob,
  saveJob,
  duplicateJob,
  getNPSResult,
  getJobsByFilter
}

