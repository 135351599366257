import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

export class CycleService extends BaseService {
  constructor() {
    super('/cycle')
  }

  async getCyclesByKeyword(client, limit, page, word) {
    const res = await super.getByKeyword(client, limit, page, word)
    return res
  }

  async getAnnounceableCycles(client, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page })
    return service.get(`${this.baseURL}/announceable-cycles?${query}`)
  }

  async setResultsIndex(cycleId, useResultsIndex) {
    return service.post(
      `${this.baseURL}/${cycleId}/use-results-index/${useResultsIndex}`
    )
  }

  async getByWordAndLeadership(client, keyword, isManager, personBondId, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, keyword, isManager, personBondId, limit, page })
    return service.get(`${this.baseURL}/by-person-bond-and-leadership?${query}`)
  }

  async copy(cycleId, client) {
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }
    if (!client) {
      throw new Error('Client name is required!')
    }
    return service.post(`${this.baseURL}/${cycleId}/copy/${client}`)
  }

  async duplicate(cycleId) {
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }
    return service.post(`${this.baseURL}/${cycleId}/duplicate`)
  }

  async sendAnnouncement(cycleId, announcement, lang) {
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }
    return service.post(`${this.baseURL}/${cycleId}/sendAnnouncement`,
      { announcement, lang })
  }
}

export const cycleService = new CycleService()
