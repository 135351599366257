<template>
  <!--<b-input v-model="value" v-mask="'(##) #####-####'" type="text" />-->
  <vue-tel-input
    v-model="value"
    valid-characters-only
    default-country="BR"
    :placeholder="$t('informetelefone')"
    :readonly="schema.readonly"
    @country-changed="countryChangedHandle"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField],
  methods: {
    countryChangedHandle(countryObject) {
      this.schema.country = countryObject
    }
  }
}
</script>

<style scoped>
.vue-tel-input {
  width: 100%;
}
</style>
