import * as _ from 'lodash'
import { newTrainingService } from '@/services/new-training-service'
import { newTrainingEligibleService } from '@/services/new-training-eligible-service'

const defaultEligible = () => ({
  _id: null,
  newTrainingSchedule: null,
  deleted: null,
  deletedAt: null,
  deletedByPersonChange: null,
  status: null,
  statusHistory: [],
  realizedAt: null,
  extensionDate: null,
  schedules: []
})

const state = {
  eligible: defaultEligible(),
  schedule: null
}

const mutations = {
  SET_ELIGIBLE: (state, eligible) => {
    state.eligible = eligible

    const scheduleId = _.get(state, 'schedule.newTrainingSchedule')
    if (scheduleId) {
      state.schedule = eligible.schedules.find(
        (schedule) => schedule.newTrainingSchedule === scheduleId
      )
    }
  },
  SET_SCHEDULE_BY_ID: (state, scheduleId) => {
    state.schedule = state.eligible.schedules.find(
      (schedule) => schedule.newTrainingSchedule === scheduleId
    )
  }
}

const actions = {
  async load({ commit }, eligibleId) {
    const { data: eligible } = await newTrainingEligibleService.getById(
      eligibleId
    )

    const { data: training } = await newTrainingService.getById(
      eligible.newTraining._id
    )

    eligible.schedules = eligible.schedules.map((scheduleEligible) => {
      const scheduleTraining = training.schedules.find(
        (s) => s._id === scheduleEligible.newTrainingSchedule
      )
      return {
        ...scheduleTraining,
        ...scheduleEligible,

        newTraining: training
      }
    })

    commit('SET_ELIGIBLE', eligible)
  },

  async setSchedule({ dispatch, commit }, { eligibleId, scheduleId }) {
    await dispatch('load', eligibleId)

    commit('SET_SCHEDULE_BY_ID', scheduleId)
  },

  clear({ commit }) {
    commit('SET_ELIGIBLE', defaultEligible())
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
