const defaultJobRegistration = () => ({
  candidateId: null,
  registrations: [],
  registrationsCopy: [],
  positions: [],
  showAlertDanger: false,
  showAlertSucess: false,
  textAlerta: null,
  dismissSecs: 5,
  dismissCountDown: 0,
  client: null,
  dismissSecsShare: 3,
  dismissCountDownShare: 0,
  keyword: null,
  totalRows: 1,
  currentPage: 1,
  perPage: 20,
  pageOptions: [5, 10, 15],
  sortBy: '',
  sortDesc: true,
  sortDirection: 'desc',
  filterData: null,
  filterOn: [],
  units: [],
  jobsList: [],
  approvedJobs: [],
  filter: {
    unityId: null,
    jobId: null,
    status: null,
    statusJob: 'approved'
  },
  normalizer(node) {
    return {
      id: node.referenceId,
      label: node.name,
      children: node.children || []
    }
  },
  registrationToCopy: null,
  jobsToCopyRegistration: [],
  jobToCopyRegistration: null,
  populate: {
    path: ['resume,job._id'],
    select: ['_id name originalName file,_id status position']
  },
  registrationDetails: {},
  registrationProfile: {},
  disapprovedCandidate: null
})

const state = {
  s: defaultJobRegistration()
}

const getters = {}

const mutations = {
  SET_FILTERS: (state, payload) => {
    state.s = payload
  }
}

const actions = {
  clearSelectionListState({ commit }) {
    commit('SET_FILTERS', defaultJobRegistration())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
