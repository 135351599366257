const tabs = {
  DocumentParameterizeList: () => import('@/views/client/ClientDocumentParameterizeList'),
  DocumentParameterize: () => import('@/views/client/ClientDocumentParameterize')
}

const state = {
  currentDocumentParameterizeTab: tabs.DocumentParameterizeList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentDocumentParameterizeTab = state.tabs[tab]
  },
  SET_ALERT: (state, alert) => {
    state.alert = alert
  }
}

const actions = {
  setCurrentDocumentParameterizeTab({ commit }, tab) {
    commit('SET_TABS', tab)
  },
  setAlert({ commit }, alert) {
    commit('SET_ALERT', alert)
  },
  popAlert({ commit, state }) {
    const alert = state.alert
    commit('SET_ALERT', null)
    return alert
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
