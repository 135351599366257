import * as approvalService from '@/services/approval-service'

const defaultApproval = {
  _id: null,
  vacation: false,
  vacancy: false,
  createdByAdmin: false,
  client: null,
  createdBy: null,
  updatedBy: null
}

const state = {
  approval: defaultApproval
}

const getters = {}

const mutations = {
  SET_APPROVAL: (state, payload) => {
    state.approval = payload
  }
}

const actions = {
  loadApproval({ commit }, approvalData) {
    commit('SET_APPROVAL', approvalData)
  },
  clearApproval({ commit }) {
    const defApproval = {
      _id: null,
      vacation: false,
      vacancy: false,
      createdByAdmin: false,
      client: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_APPROVAL', defApproval)
  },
  save({ commit, rootState }, approval) {
    approval.client = rootState.user.userClient
    approvalService.saveApproval(approval)
      .then(({ data }) => {
        commit('SET_APPROVAL', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
