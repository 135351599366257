<template v-slot:view>
  <autocomplete
    ref="autocompletePersonDocument"
    auto-select
    :search="filterPeople"
    :get-result-value="getPeopleResult"
    :debounce-time="500"
    style="width: 100%"
  />
</template>
<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField],
  data() {
    return {
      colaboratorsDb: [
        {
          name: 'Kaique Silva',
          _id: '5fc7eb885f6ad33af2f8d014'
        },
        {
          name: 'Marcos Samuel',
          _id: '5fc7eb885f6ad33af2f8d014'
        },
        {
          name: 'Arthur Gomes',
          _id: '5fc7eb885f6ad33af2f8d014'
        },
        {
          name: 'Orlando Silva',
          _id: '5fc7eb885f6ad33af2f8d014'
        },
        {
          name: 'Thiago Abreu',
          _id: '5fc7eb885f6ad33af2f8d014'
        },
        {
          name: 'Daiane Barbosa',
          _id: '5fc7eb885f6ad33af2f8d014'
        }
      ]
    }
  },
  methods: {
    filterPeople(input) {
      return this.colaboratorsDb.filter(person => {
        return person.name.toLowerCase().includes(input.toLowerCase())
      })
    },
    getPeopleResult(result) {
      return result.name
    }
  }
}
</script>
