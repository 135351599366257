
const tabs = {
  UserType: () => import('@/views/client/UserType'),
  UserTypeConfiguration: () => import('@/views/client/UserTypeConfiguration')
}

const state = {
  currentUserTypeTab: tabs.UserType,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentUserTypeTab = state.tabs[tab]
  }
}

const actions = {
  setUserTypeTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
