const defaultCurrentRecruitmentTab = null

const state = {
  currentRecruitmentTab: defaultCurrentRecruitmentTab
}

const getters = {}

const mutations = {
  SET_CURRENTRECRUITMENTTAB: (state, payload) => {
    state.currentRecruitmentTab = payload
  }
}

const actions = {
  loadCurrentRecruitmentTab({ commit }, currentTab) {
    commit('SET_CURRENTRECRUITMENTTAB', currentTab)
  },
  clearCurrentRecruitmentTab({ commit }) {
    const defCurrentRecruitmentTab = null
    commit('SET_CURRENTRECRUITMENTTAB', defCurrentRecruitmentTab)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
