import { documentDepositService } from '@/services/document-deposit-service'

const state = {
  depositId: null,
  newAnalyze: {
    has: false,
    message: ''
  }
}

const getters = {
  hasAnalyze: state => state.depositId != null
}

const mutations = {
  SET_DEPOSIT_ID: (state, payload) => {
    state.depositId = payload
  }
}

const actions = {
  load({ commit }, analyze) {
    commit('SET_DEPOSIT_ID', analyze)
  },
  clear({ commit }) {
    commit('SET_DEPOSIT_ID', null)
  },
  async save({ commit }, deposit) {
    const { data } = await documentDepositService.saveAnalyze(deposit)
    commit('SET_DEPOSIT_ID', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
