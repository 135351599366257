import { authService } from '@/utils/request'

export function login(data) {
  return authService({
    url: '/vue-element-admin/user/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return authService({
    url: '/auth/user_info',
    method: 'get'
  })
}

export function logout() {
  return authService({
    url: '/auth/logout',
    method: 'get'
  })
}
