<template>
  <div class="dm-field-video-upload">
    <b-input
      type="text"
      :value="localValue"
      :placeholder="$t('informelinkvideo')"
      :state="isChanged ? isValid : undefined"
      @input="localValue = $event"
    />
    <b-form-invalid-feedback :state="isChanged ? isValid : undefined">
      {{ $t("informelinkvideo") }}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'
import { isValidVideoDomain } from '@/utils/validate'

export default {
  mixins: [abstractField],
  data() {
    return { isChanged: false }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.isChanged = true
        this.value = newValue
        this.$emit('input', newValue)
      }
    },
    isValid() {
      if (!this.localValue) return false
      return isValidVideoDomain(this.localValue)
    }
  },
  created() {
    if (this.value) this.isChanged = true
  }
}
</script>

<style lang="scss" scoped>
.dm-field-video-upload {
  width: 100%;
}
</style>
