import { service } from './base-service'

const getSkillsForms = () => {
  return service.get('/skills-form')
}

const getSkillsFormById = (skillsFormId) => {
  if (!skillsFormId) {
    throw new Error('SkillsForm Id is required!')
  }
  return service.get(`/skills-form/${skillsFormId}`)
}

const getSkillsFormsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/skills-form/client/${client}`)
}

const getSkillsFormsByPosition = (position) => {
  if (!position) {
    throw new Error('Position Id is required!')
  }
  return service.get(`/skills-form/position/${position}`)
}

const getSkillsFormsByKeyword = (client, word) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/skills-form/by_client_word?client=${client}&word=${word}`)
}

const getSkillsFormsByUser = (userId) => {
  if (!userId) {
    throw new Error('User ID is required')
  }
  return service.get(`/skills-form/user/${userId}`)
}

const createSkillsForm = (skillsFormData) => {
  return service.post(`/skills-form`, skillsFormData)
}

const updateSkillsForm = (skillsFormId, skillsFormData) => {
  return service.put(`/skills-form/${skillsFormId}`, skillsFormData)
}

const saveSkillsForm = (skillsFormId, skillsFormData) => {
  if (skillsFormId) {
    return service.put(`/skills-form/${skillsFormId}`, skillsFormData)
  }
  delete skillsFormData._id
  return service.post(`/skills-form`, skillsFormData)
}

const deleteSkillsForm = (skillsFormId) => {
  return service.delete(`/skills-form/${skillsFormId}`)
}

export {
  getSkillsForms,
  getSkillsFormById,
  getSkillsFormsByClient,
  getSkillsFormsByPosition,
  getSkillsFormsByKeyword,
  getSkillsFormsByUser,
  createSkillsForm,
  updateSkillsForm,
  saveSkillsForm,
  deleteSkillsForm
}
