const tabs = {
  EvaluationsList: () => import('@/views/trainings/academy/EvaluationsList'),
  Evaluation: () => import('@/views/trainings/academy/Evaluation'),
  EvaluationView: () => import('@/views/trainings/academy/EvaluationView'),
  AnswerReport: () => import('@/views/trainings/academy/AnswerReport')
}

const state = {
  currentTab: tabs.EvaluationsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentTab = state.tabs[tab]
  }
}

const actions = {
  setTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
