<template>
  <div class="d-flex flex-row flex-wrap">
    <b-link
      :href="schema.href"
      active
      target="_blank"
      router-tag="button"
      class="classic"
    >
      {{ link }}
    </b-link>
  </div>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

const LINK_CHAR_MAX = 150

export default {
  mixins: [abstractField],
  computed: {
    link() {
      let link = this.schema.href
      if (link.length > LINK_CHAR_MAX) {
        link = link.slice(0, LINK_CHAR_MAX) + '...'
      }
      return link
    }
  }
}
</script>
