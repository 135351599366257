const tabs = {
  CommitteePlanningList: () => import('@/views/career/PlanningCommitteeList'),
  CommitteePlanning: () => import('@/views/career/PlanningCommittee')

}

const state = {
  currentCommitePlanningTab: tabs.CommitteePlanningList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentCommitePlanningTab = state.tabs[tab]
  }
}

const actions = {
  setCommitteePlanningRegistrationTab({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
