import qs from 'querystring'
import { BaseService, service } from './base-service'
import store from '@/store'

export class ActionPlanService extends BaseService {
  constructor() {
    super('/action-plan')
  }

  translateBackend(plan) {
    plan.collaborator = plan.collaborator._id
    plan.follows = plan.follows.map((f) => f.date)
    return plan
  }

  translateFrontend(plan) {
    const dateFormat = (d) => d.substr(0, 10)
    const sortByValue = (fa, fb) => fa.value > fb.value

    plan.actions = plan.actions.map((action) => ({
      ...action,
      dateStarted: dateFormat(action.dateStarted),
      dateEnded: dateFormat(action.dateEnded),
      follows: action.follows
        .map((f) => ({
          ...f,
          date: dateFormat(f.date),
          value: new Date(f.date)
        }))
        .sort(sortByValue)
    }))

    const actionFollows = plan.actions.reduce(
      (follows, action) => follows.concat(action.follows),
      []
    )

    plan.follows = plan.follows
      .map((follow) => {
        const date = dateFormat(follow)
        const isFollowed = actionFollows.some((follow) => follow.date === date)

        return {
          date,
          isFollowed,
          value: new Date(follow)
        }
      })
      .sort(sortByValue)

    return plan
  }

  searchCollaborators = async (
    userDocument,
    cycle,
    client,
    leadership,
    term,
    unity = null
  ) => {
    if (!cycle || !leadership) {
      throw new Error('cycle and leadership is required!')
    }
    const query = qs.stringify({ term, unity })

    const url = `${this.baseURL}/search-collaborators/${userDocument}/${client}/${cycle}/${leadership}?${query}`
    return service.get(url)
  };

  searchCollaboratorsAsCycleManager = async (
    client,
    userDocument,
    cycle,
    term,
    unity = null
  ) => {
    if (!client || !cycle) throw new Error('client and cycle is required!')

    const query = qs.stringify({ term, unity })
    const url = `${this.baseURL}/search-collaborators-as-cycle-manager-or-admin/${client}/${userDocument}/${cycle}?${query}`
    return service.get(url)
  };

  searchCollaboratorsWithouActionPlan = async (
    userDocument,
    cycle,
    client,
    leadership,
    term,
    unity = null
  ) => {
    if (!cycle || !leadership) {
      throw new Error('cycle and leadership is required!')
    }
    const query = qs.stringify({ term, unity })

    const url = `${this.baseURL}/search-collaborators-without-action-plan/${userDocument}/${client}/${cycle}/${leadership}?${query}`
    return service.get(url)
  };

  searchCollaboratorsAsCycleManagerWithouActionPlan = async (
    client,
    userDocument,
    cycle,
    term,
    unity = null
  ) => {
    if (!client || !cycle) throw new Error('client and cycle is required!')

    const query = qs.stringify({ term, unity })
    const url = `${this.baseURL}/search-collaborators-as-cycle-manager-or-admin-without-action-plan/${client}/${userDocument}/${cycle}?${query}`
    return service.get(url)
  };
  getByClientAndCycleSimpleAsCollaborator(client, cycle, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client-and-cycle-simple-as-collaborator/${client}/${cycle}?${query}`
    )
  }
  getByClientAndCycleAsCollaborator(client, cycle, limit, page) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client-and-cycle-as-collaborator/${client}/${cycle}?${query}`
    )
  }

  async getByEligible(eligible) {
    if (!eligible) {
      throw new Error('Eligible is required!')
    }

    return await service.get(`${this.baseURL}/by_eligible/${eligible}`)
  }

  getByClientAndCycleSimpleAsCycleManagerOrSystemAdmin(
    client,
    cycle,
    limit,
    page
  ) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client-and-cycle-simple-as-cycle-manager-or-system-admin/${client}/${cycle}?${query}`
    )
  }

  getByKeywordAsLeadership(client, cycle, leadership, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({
      client,
      cycle,
      leadership,
      limit,
      page,
      word
    })
    return service.get(
      `${this.baseURL}/by_client_and_cycle_hierarchy_word_as_leadership?${query}`
    )
  }

  getByKeyword(client, cycle, limit, page, word) {
    if (!client) {
      throw new Error('Client name is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ client, limit, page, word, cycle })
    return service.get(`${this.baseURL}/by_client_hierarchy_word?${query}`)
  }

  getByClientAndCycleSimpleAsLeadership(
    client,
    cycle,
    leadership,
    limit,
    page
  ) {
    if (!client || !leadership) {
      throw new Error('Client name and leadership is required!')
    }
    if (!cycle) {
      throw new Error('Cycle is required!')
    }
    const query = qs.stringify({ limit, page })
    return service.get(
      `${this.baseURL}/client-and-cycle-simple-as-leadership/${client}/${cycle}/${leadership}?${query}`
    )
  }

  async getFormsByCycle(cycleId) {
    if (!cycleId) {
      throw new Error('Cycle id is required!')
    }

    return await service.get(`${this.baseURL}/forms_by_cycle/${cycleId}`)
  }

  async getQuestionsAndAnswers(form, cycle, eligible) {
    if (!form) {
      throw new Error('formId required!')
    }
    if (!cycle) {
      throw new Error('cycleId is required!')
    }
    if (!eligible) {
      throw new Error('eligibleDocument is required!')
    }

    const query = qs.stringify({ form, cycle, eligible })
    return await service.get(`${this.baseURL}/questions_and_answers?${query}`)
  }
  async getByCycle(client, limit, page, cycleId, word = '') {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, limit, page, word })
    const res = await service.get(
      `${this.baseURL}/by_cycle_and_keyword/${cycleId}?${query}`
    )
    res.data.docs = res.data.docs.map(this.translateFrontend)
    return res
  }

  async getByWordAndCycleAndLeadership(client, word, isLeaderShip, cycleId) {
    if (!client) {
      throw new Error('Client name is required!')
    }

    const query = qs.stringify({ client, word, isLeaderShip, cycleId })
    return await service.get(
      `${this.baseURL}/by_cycle_and_keyword/${cycleId}?${query}`
    )
  }

  async approve(data) {
    const { _id } = data
    data.client = data.client ? data.client : store.getters.userClient
    return service.put(`${this.baseURL}/${_id}/approve`, data)
  }

  saveFollow(data) {
    if (!data.actionPlan) {
      throw new Error('Action plan id is required!')
    }
    return service.put(`${this.baseURL}/${data.actionPlan}/save_follow`, data)
  }
}

export const actionPlanService = new ActionPlanService()
