const tabs = {
  RecruitmentFormsList: () => import('@/views/recruitment/RecruitmentFormsList'),
  RecruitmentForm: () => import('@/views/recruitment/RecruitmentForm'),
  RecruitmentFormView: () => import('@/views/recruitment/RecruitmentFormView'),
  RecruitmentFormAnswerReport: () => import('@/views/recruitment/FilterFormAnswers')
}

const state = {
  currentRecruitmentFormTab: tabs.RecruitmentFormsList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentRecruitmentFormTab = state.tabs[tab]
  }
}

const actions = {
  setRecruitmentFormTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
