const defaultCurrentTrainingContentTabIndex = 0

const state = {
  currentTrainingContentTabIndex: defaultCurrentTrainingContentTabIndex
}

const getters = {}

const mutations = {
  SET_CURRENTTRAININGCONTENTTABINDEX: (state, payload) => {
    state.currentTrainingContentTabIndex = payload
  }
}

const actions = {
  setCurrentTrainingContentTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTTRAININGCONTENTTABINDEX', currentTabIndex)
  },
  clearCurrentTrainingContentTabIndex({ commit }) {
    const defCurrentTrainingContentTabIndex = 0
    commit('SET_CURRENTTRAININGCONTENTTABINDEX', defCurrentTrainingContentTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
