const defaultCurrentCycleTabIndex = 0

const state = {
  currentCycleTabIndex: defaultCurrentCycleTabIndex,
  loadingEligible: true
}

const getters = {}

const mutations = {
  SET_CURRENTCYCLETABINDEX: (state, payload) => {
    state.currentCycleTabIndex = payload
  }
}

const actions = {
  setCurrentCycleTabIndex({ commit }, currentTabIndex) {
    commit('SET_CURRENTCYCLETABINDEX', currentTabIndex)
  },
  clearCurrentCycleTabIndex({ commit }) {
    const defCurrentCycleTabIndex = 0
    commit('SET_CURRENTCYCLETABINDEX', defCurrentCycleTabIndex)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
