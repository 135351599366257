const state = {
  type: null,
  message: null,
  timer: 0
}

const mutations = {
  showSuccess: (state, message) => {
    state.type = 'success'
    state.message = message
    state.timer = 5
  },
  showDanger: (state, message) => {
    state.type = 'danger'
    state.message = message
    state.timer = 5
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
