<template>
  <vue-feedback-reaction
    ref="feedbackReaction"
    :labels="schema.emojiLabels"
    :value="value"
    @input="parseNumber"
  />
</template>

<script>
import { VueFeedbackReaction } from 'vue-feedback-reaction'
import { abstractField } from 'vue-form-generator/src/index'
import { toInteger } from 'lodash'

export default {
  components: {
    VueFeedbackReaction
  },
  mixins: [abstractField],
  watch: {
    value(value) {
      this.$refs.feedbackReaction.reactionValue = value.toString()
    }
  },
  methods: {
    parseNumber(value) {
      this.value = toInteger(value)
    }
  }
}
</script>

<style>
@media screen and (max-width: 470px) {
  .vue-feedback-reaction .reaction {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    margin: 0 auto;
    padding: 0 5px;
    cursor: pointer;
    font-size: 10px;
  }

  .vue-reaction img {
    height: 28px;
  }
}

.vue-form-generator
  .form-group:not(.field-vueMultiSelect)
  .vue-feedback-reaction
  .reaction
  span {
  text-align: center;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
</style>

