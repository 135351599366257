<template>
  <b-input v-model="value" type="time" placeholder="--:--" />
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'

export default {
  mixins: [abstractField]
}
</script>
