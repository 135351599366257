const defaultTraining = () => ({
  schedules: [],
  allSchedules: []
})

const defaultSchedule = () => ({
  startDate: null,
  endDate: null,
  extensionDate: null,
  timeDuration: null,
  instructor: null,
  index: null,
  newTrainingForm: null,
  newTrainingEvaluation: null,
  generalAdherence: null,
  mainAdherence: null,
  status: null,
  training: defaultTraining()
})

const state = {
  training: defaultTraining(),
  schedule: defaultSchedule()
}

const getters = {}

const mutations = {
  SET_SCHEDULE: (state, schedule) => {
    state.schedule = schedule
    state.training = schedule.training
  },

  SET_SCHEDULE_BY_ID: (state, newScheduleId) => {
    const training = state.training

    if (!training) {
      state.schedule = defaultSchedule()
    }

    const newSchedule = training.schedules.find(s => s._id === newScheduleId)
    newSchedule.training = training
    state.schedule = newSchedule
  }
}

const actions = {
  load({ commit }, schedule) {
    commit('SET_SCHEDULE', schedule)
  },
  loadById({ commit }, scheduleId) {
    commit('SET_SCHEDULE_BY_ID', scheduleId)
  },
  clear({ commit }) {
    commit('SET_SCHEDULE', defaultSchedule())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
