
const tabs = {
  UsersList: () => import('@/views/client/UsersList'),
  UserTabs: () => import('@/views/client/UserTabs')
}

const state = {
  currentUserTab: tabs.UsersList,
  tabs
}

const mutations = {
  SET_TABS: (state, tab) => {
    state.currentUserTab = state.tabs[tab]
  }
}

const actions = {
  setUserTabs({ commit }, tab) {
    commit('SET_TABS', tab)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
