var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-feedback-reaction", {
    ref: "feedbackReaction",
    attrs: { labels: _vm.schema.emojiLabels, value: _vm.value },
    on: { input: _vm.parseNumber },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }