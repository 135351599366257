import { BaseService, service } from './base-service'
import store from '@/store'
import qs from 'querystring'

export class CustomEvaluationService extends BaseService {
  constructor() {
    super('/custom-evaluation')
  }

  async getByModuleAndClient(module, page, limit, keyword = '') {
    const client = store.getters.userClient
    const options = {}

    options.limit = limit || 20
    options.page = page || 1
    if (keyword) options.keyword = keyword

    const query = new URLSearchParams(options).toString()
    return await service.get(`/custom-evaluation/${client}/${module}?${query}`)
  }

  async getByModuleAndClientWithoutPagination(client, module) {
    return await service.get(`/custom-evaluation/${client}/${module}`)
  }

  async copy(evaluation, client, lang) {
    const query = qs.stringify({ evaluation, client })
    return service.post(`${this.baseURL}/copy?${query}`, null, {
      headers: { 'x-custom-lang': lang }
    })
  }

  async multipleCopy(evaluation, clients, lang) {
    const body = { clients }
    return service.post(`${this.baseURL}/multiple-copy/${evaluation}`, body, {
      headers: { 'x-custom-lang': lang }
    })
  }
}

export const customEvaluationService = new CustomEvaluationService()
