import { BaseService } from './base-service'
import { service } from './base-service'
import qs from 'querystring'

class CentralDemarcoUserService extends BaseService {
  constructor() {
    super('/central-demarco-user')
  }
  getAllByClient = (client) => {
    return service.get(`/central-demarco-user/all_by_client/${client}`)
  };

  getDeletedByClient = (client, limit, page) => {
    const query = qs.stringify({ limit, page })
    return service.get(`/central-demarco-user/inactive/${client}?${query}`)
  };

  getByReferenceId = (referenceId) => {
    return service.get(`/central-demarco-user/by_reference_id/${referenceId}`)
  };

  getByEmail = (email) => {
    return service.get(`/central-demarco-user/by_email/${email}`)
  };

  getDeletedByEmail = (email) => {
    return service.get(`/central-demarco-user/inactive_by_email/${email}`)
  };

  restore = (email) => {
    return service.put(`/central-demarco-user/restore/${email}`)
  };

  getUsersWithoutSpecificUnity = (
    client,
    unityReferenceId,
    filter = { name: null, email: null, userType: null, limit: 20, page: 1 },
    options = { limit: 20, page: 1 }
  ) => {
    const query = new URLSearchParams()
    if (filter.name) query.append('name', filter.name)
    if (filter.email) query.append('email', filter.email)
    if (filter.type) query.append('type', filter.type)
    if (options.limit) query.append('limit', options.limit)
    if (options.page) query.append('page', options.page)
    const url = `/central-demarco-user/get-users-without-specific-unity/${client}/${unityReferenceId}?${query.toString()}`
    return service.get(url)
  };
}

export const centralDemarcoUserService = new CentralDemarcoUserService()
