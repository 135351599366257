import { AbilityBuilder, Ability } from '@casl/ability'

export function defineAbilityFor(userProfile, userModules, clientConfigurations, screens, userTypeConfiguration) {
  const { can, cannot, rules } = new AbilityBuilder(Ability)

  let modules = 'all'
  let modulesUser = []
  if (!clientConfigurations && !userModules) {
    can('manage', 'all')
    return new Ability(rules)
  }
  modules = clientConfigurations ? clientConfigurations.modules.map(({ name }) => name) : modules
  modulesUser = modules
  if (userModules) {
    modulesUser = Array.isArray(userModules.modules) ? userModules.modules.map(({ name }) => name) : modulesUser
  }
  switch (userProfile) {
    case 'admin_sistema':
      can('manage', modules)
      break
    case 'usuario':
      can('manage', modulesUser)
      break
    default:
      can('manage', modules)
  }

  screens = screens || []
  for (const screen of screens) {
    if (userProfile === 'admin_sistema') {
      can('manage', screen._id)
    } else {
      const permission = userTypeConfiguration ? userTypeConfiguration.accesses[screen._id] : null
      if (!permission || permission === 'noaccess') {
        cannot('read', screen._id)
      } else {
        can(permission, screen._id)
      }
    }
  }

  return rules
}

export const ability = new Ability([])
