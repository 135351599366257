import { documentDepositService } from '@/services/document-deposit-service'

const defaultDocument = () => ({
  _id: null,
  collaborator: { name: null, _id: null, document: null },
  personbond: { position: null, _id: null, unity: null },
  documentType: null,
  occurrenceType: null,
  occurrenceId: null,
  status: null,
  frequency: null,
  competence: null,
  nextDueDate: null,
  dueDate: null,
  deposit: {
    history: []
  },
  deposits: [],
  analysis: []
})

const state = {
  document: defaultDocument(),
  newDeposit: {
    has: false,
    message: ''
  }
}

const getters = {
  hasDocument: (state) => state.document._id != null
}

const mutations = {
  SET_DOCUMENT: (state, payload) => {
    state.document = payload
  }
}

const actions = {
  load({ commit }, document) {
    commit('SET_DOCUMENT', document)
  },
  clear({ commit }) {
    commit('SET_DOCUMENT', defaultDocument())
  },
  async save({ commit, rootState }, document) {
    document.client = rootState.user.userClient
    const { data } = await documentDepositService.save(document)
    commit('SET_DOCUMENT', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
