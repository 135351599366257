const defaultCentralDemarcoUser = {
  referenceId: null,
  name: null,
  email: null,
  documentType: 'cpf',
  document: null,
  phone: null,
  phoneCountry: null,
  birthDate: null,
  termsOfUse: false,
  profile: null,
  type: null,
  position: null,
  worksAt: null,
  clients: [],
  isManager: false,
  isCycleManager: false,
  optin: false,
  createdByAdmin: false
}

const state = {
  currentCentralDemarcoUser: defaultCentralDemarcoUser
}

const getters = {}

const mutations = {
  SET_CENTRAL_DEMARCO_USER: (state, payload) => {
    state.currentCentralDemarcoUser = payload
  }
}

const actions = {
  loadCentralDemarcoUser({ commit }, centralDemarcoUserData) {
    commit('SET_CENTRAL_DEMARCO_USER', centralDemarcoUserData)
  },
  clearCentralDemarcoUser({ commit, rootState }) {
    const defCentralDemarcoUser = {
      referenceId: null,
      name: null,
      email: null,
      documentType: 'cpf',
      document: null,
      phone: null,
      phoneCountry: null,
      birthDate: null,
      profile: null,
      termsOfUse: false,
      type: null,
      position: null,
      worksAt: null,
      clients: [],
      isManager: false,
      isCycleManager: false,
      optin: false,
      createdByAdmin: false
    }
    commit('SET_CENTRAL_DEMARCO_USER', defCentralDemarcoUser)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
