import { service } from './base-service'

const getBonds = () => {
  return service.get('/bond')
}

const getBondById = (bondId) => {
  if (!bondId) {
    throw new Error('Bond Id is required!')
  }
  return service.get(`/bond/${bondId}`)
}

const getBondsByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/bond/client/${client}`)
}

const getBondsWithDeletedByClient = (client) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/bond/withDeleted/client/${client}`)
}

const createBond = (bondData) => {
  return service.post(`/bond`, bondData)
}

const updateBond = (bondId, bondData) => {
  return service.put(`/bond/${bondId}`, bondData)
}

const deleteBond = (bondId) => {
  return service.delete(`/bond/${bondId}`)
}

export {
  getBonds,
  getBondById,
  getBondsByClient,
  getBondsWithDeletedByClient,
  createBond,
  updateBond,
  deleteBond
}
