import { service } from './base-service'

const baseUrl = '/cycle-performance-evaluation'

export const searchCollaborators = async (
  userDocument,
  cycle,
  leadership,
  term
) => {
  if (!cycle || !leadership) {
    throw new Error('cycle and leadership is required!')
  }

  const url = `${baseUrl}/search-collaborators/${userDocument}/${cycle}/${leadership}?term=${term}`
  return service.get(url)
}

export const searchCollaboratorsAsCycleManager = async (
  client,
  userDocument,
  cycle,
  term
) => {
  if (!client || !cycle) throw new Error('client and cycle is required!')

  const url = `${baseUrl}/search-collaborators-as-cycle-manager/${client}/${userDocument}/${cycle}?term=${term}`
  return service.get(url)
}

export const getById = async (id) => {
  return service.get(`${baseUrl}/${id}`)
}

export const getResultByCollaborator = async (cycle, collaborator) => {
  const url = `${baseUrl}/by-collaborator/${cycle}/${collaborator}`
  return service.get(url)
}

export const create = async performanceEvaluation => {
  const url = `${baseUrl}/`
  return service.post(url, performanceEvaluation)
}

export const addGoal = async (performanceEvaluation, goal) => {
  const url = `${baseUrl}/${performanceEvaluation}/goal`
  return service.post(url, goal)
}

export const updateGoal = async (performanceEvaluation, goalId, goal) => {
  const url = `${baseUrl}/${performanceEvaluation}/goal/${goalId}`
  return service.put(url, goal)
}

export const deleteGoal = async (performanceEvaluation, goalId) => {
  const url = `${baseUrl}/${performanceEvaluation}/goal/${goalId}`
  return service.delete(url)
}

export const calculateResult = async (client, cycle, weights, achievements) => {
  const url = `${baseUrl}/calculate`
  const data = { client, cycle, weights, achievements }
  return service.post(url, data)
}

