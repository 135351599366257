<template>
  <b-form-group>
    <b-form-input
      v-model="value"
      type="number"
      aria-describedby="input-live-feedback"
      :min="schema.min"
      :max="schema.max"
      :state="validaNumero"
      @change="validaNumero2"
    />
    <b-form-invalid-feedback id="input-live-feedback">
      {{
        $t("valorminimo") +
          ": " +
          (schema.min || "-") +
          " - " +
          $t("valormaximo") +
          ": " +
          (schema.max || "-")
      }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { abstractField } from 'vue-form-generator/src/index'
import * as _ from 'lodash'

export default {
  mixins: [abstractField],
  computed: {
    validaNumero() {
      if (this.schema.min && this.value < parseFloat(this.schema.min)) {
        return false
      } else if (this.schema.max && this.value > parseFloat(this.schema.max)) {
        return false
      }
      return true
    }
  },
  methods: {
    validaNumero2(raw) {
      const value = _.parseInt(raw)
      if (!_.isInteger(value)) {
        this.value = null
      } else if (this.schema.min && value < parseFloat(this.schema.min)) {
        this.value = null
      } else if (this.schema.max && value > parseFloat(this.schema.max)) {
        this.value = null
      }
    }
  }
}
</script>

<style>
.hint {
  color: red;
  font-size: 12px;
  font-weight: bold;
}
</style>
