const state = {
  answered: false
}

const getters = {}

const mutations = {
  CHANGE_TO_ANSWERED: state => {
    state.answered = true
  },
  CHANGE_TO_UNANSWERED: state => {
    state.answered = false
  }
}

const actions = {
  changeToAnswered ({ commit }) {
    commit('CHANGE_TO_ANSWERED')
  },
  changeToUnanswered ({ commit }) {
    commit('CHANGE_TO_UNANSWERED')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
