import { service } from './base-service'

const getRecruitmentEvaluations = () => {
  return service.get('/recruitment-evaluation')
}

const getRecruitmentEvaluationById = (evaluationId) => {
  if (!evaluationId) {
    throw new Error('Recruitment Evaluation Id is required!')
  }
  return service.get(`/recruitment-evaluation/${evaluationId}`)
}

const getRecruitmentEvaluationAndJobStatus = (evaluationId, jobId) => {
  if (!evaluationId) {
    throw new Error('Evaluation Id is required!')
  }
  if (!jobId) {
    throw new Error('Job Id is required!')
  }
  return service.get(`/recruitment-evaluation/shared?evaluationId=${evaluationId}&jobId=${jobId}`)
}

const getRecruitmentEvaluationsByClient = (client, limit, page) => {
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/recruitment-evaluation/client/${client}?limit=${limit}&page=${page}`)
}

const getRecruitmentEvaluationsByKeyword = (client, limit, page, word) => {
  if (!word) {
    throw new Error('Keyword is required!')
  }
  if (!client) {
    throw new Error('Client name is required!')
  }
  return service.get(`/recruitment-evaluation/by_client_word?client=${client}&limit=${limit}&page=${page}&word=${word}`)
}

const getRecruitmentEvaluationsByUser = (userId) => {
  if (!userId) {
    throw new Error('User ID is required')
  }
  return service.get(`/recruitment-evaluation/user/${userId}`)
}

const createRecruitmentEvaluation = (evaluationData) => {
  return service.post(`/recruitment-evaluation`, evaluationData)
}

const updateRecruitmentEvaluation = (evaluationId, evaluationData) => {
  return service.put(`/recruitment-evaluation/${evaluationId}`, evaluationData)
}

const saveRecruitmentEvaluation = (evaluationId, evaluationData) => {
  if (evaluationId) {
    return service.put(`/recruitment-evaluation/${evaluationId}`, evaluationData)
  }
  delete evaluationData._id
  return service.post(`/recruitment-evaluation`, evaluationData)
}

const deleteRecruitmentEvaluation = (evaluationId) => {
  return service.delete(`/recruitment-evaluation/${evaluationId}`)
}

export {
  getRecruitmentEvaluations,
  getRecruitmentEvaluationById,
  getRecruitmentEvaluationAndJobStatus,
  getRecruitmentEvaluationsByClient,
  getRecruitmentEvaluationsByKeyword,
  getRecruitmentEvaluationsByUser,
  saveRecruitmentEvaluation,
  createRecruitmentEvaluation,
  updateRecruitmentEvaluation,
  deleteRecruitmentEvaluation
}
