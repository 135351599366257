import * as candidateFormService from '@/services/candidate-form-service'

const defaultCandidateForm = {
  _id: null,
  job: null,
  formName: null,
  formDescription: null,
  linkVideo: null,
  linkName: null,
  link: null,
  image: null,
  sections: [],
  fields: [],
  files: [],
  client: null,
  createdBy: null,
  updatedBy: null
}

const state = {
  candidateForm: defaultCandidateForm
}

const getters = {}

const mutations = {
  SET_CANDIDATEFORM: (state, payload) => {
    state.candidateForm = payload
  }
}

const actions = {
  loadCandidateForm({ commit }, candidateFormData) {
    commit('SET_CANDIDATEFORM', candidateFormData)
  },
  clearCandidateForm({ commit }) {
    const defCandidateForm = {
      _id: null,
      job: null,
      formName: null,
      formDescription: null,
      linkVideo: null,
      linkName: null,
      link: null,
      image: null,
      sections: [],
      fields: [],
      files: [],
      client: null,
      createdBy: null,
      updatedBy: null
    }
    commit('SET_CANDIDATEFORM', defCandidateForm)
  },
  save({ commit, rootState }, candidateForm) {
    candidateForm.client = rootState.user.userClient
    candidateFormService.saveCandidateForm(candidateForm)
      .then(({ data }) => {
        commit('SET_CANDIDATEFORM', data)
        // Message.success(i18n.t('treinamentocadastrado'))
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
